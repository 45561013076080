import React, { Component } from 'react'
import { Modal, Button, Table, Input, Mentions, Row, Col, Form, Switch, Popconfirm, Select, message, Spin, Upload } from 'antd'
import { connect } from 'react-redux'
import { Tabs } from 'antd';
import NatureTrailLocationData from './nature-trail-location-data';
import { getNatureTrailLocationsByNatureTrailId, getNatureTrailLocationById, deleteLocationById } from '../../actions/nature-trail';
import Dragger from 'antd/lib/upload/Dragger';
import { MenuOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import './natural-trail.scss';
import NatureTrailRatingByUser from './nature-trail-rating-by-user';
import NatureTrailReportMessageByUser from './nature-trail-report-message-by-user';
import ImgCrop from 'antd-img-crop';
import SendPushMessage from './send-push-message';
import NatureTrailOwner from './nature-trail-owner';

const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableContainera = SortableContainer(props => <tbody {...props} />);
const DragableBodyRow = ({ index, className, style, ...restProps }) => (
    <SortableItem index={restProps['data-row-key']} {...restProps} />
);

const { TabPane } = Tabs;
const { Option } = Select;

const mapDispatch = (dispatch) => {
    return {
        getNatureTrailLocationsByNatureTrailId: (natureTrailId) => dispatch((getNatureTrailLocationsByNatureTrailId(natureTrailId))),
        getNatureTrailLocationById: (locationId) => dispatch((getNatureTrailLocationById(locationId))),
        deleteLocationById: (locationId) => dispatch(deleteLocationById(locationId))
    }
}


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class ConnectedNewNaturalTrail extends Component {
    formRef = React.createRef();

    state = {
        savingDescription: "Mentés folyamatban...",
        spinning: false,
        choosedRecord: null,
        addNewLocationButtonIsActive: true,
        previewVisible: false,
        previewImage: '',
        previewBadgeImage: '',
        previewDeactiveBadgeImage: '',
        previewTitle: '',
        previewLogo: '',
        badge: {
            name: "",
            description: ""
        },
        title: "",
        description: "",
        natureTrailType: [],
        lat: 0,
        lon: 0,
        triggerDistance: 0,
        estimatedTime: 0,
        fullRoadLength: 0,
        natureTrailLocationIds: [],
        loading: false,
        uploaded: false,
        natureTrailLocationTab: true,
        description_short: "",
        description_long: "",
        history: true,
        objectsFromLocations: [],
        count: 2,
        columns: [
            {
                title: '',
                dataIndex: 'sort',
                width: '4em',
                className: 'drag-visible',
                render: () => <DragHandle />,
            },
            {
                title: 'Állomás név',
                dataIndex: 'title',
                className: 'drag-visible',
            },
            {
                title: ' ',
                width: '4em',
                dataIndex: 'operation',
                render: (text, record) =>
                    this.state.objectsFromLocations.length >= 1 ? (
                        <Button type="primary" shape="circle" onClick={() => this.handleShow(record)} icon={<EditOutlined />} size="small" />

                    ) : null,
            },
            {
                title: ' ',
                width: '4em',
                dataIndex: 'operation',
                render: (text, record) =>
                    this.state.objectsFromLocations.length >= 1 ? (

                        <Popconfirm title="Biztos törlöd?" onConfirm={() => this.handleDelete(record.key)}>
                            {<DeleteOutlined />}
                        </Popconfirm>
                    ) : null,
            },
        ],
        file: [],
        gpxfile: [],
        badgeFile: [],
        logoFile: [],
        badgeDeactiveFile: [],
        imageHash: Date.now(),
        dataFilesFromLocations: [],
        saveButtonDisbled: true,
        center: {
            lat: 51.505,
            lng: -0.09,
        },
        marker: {
            lat: 51.505,
            lng: -0.09,
        },
        zoom: 13,
        draggable: true,
        showLogo: false

    }
    refmarker = React.createRef()
    array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    componentDidMount = async () => {
        if (this.props.natureTrailData[0] !== undefined) {
            this.setState({
                id: this.props.natureTrailData[0].id,
                name: this.props.natureTrailData[0].name,
                title: this.props.natureTrailData[0].name,
                history: this.props.natureTrailData[0].history,
                showLogo: this.props.natureTrailData[0].showLogo,
                ownerEmail1: this.props.natureTrailData[0].ownerEmail1,
                ownerEmail2: this.props.natureTrailData[0].ownerEmail2,
                logoSavedFileURL: this.props.natureTrailData[0].logoSavedFileURL,
                description_long: this.props.natureTrailData[0].description_long,
                description_short: this.props.natureTrailData[0].description_short,
                lat: this.props.natureTrailData[0].lat,
                lon: this.props.natureTrailData[0].lon,
                scaleOverrideAndroid: this.props.natureTrailData[0].scaleOverrideAndroid,
                scaleOverrideIos: this.props.natureTrailData[0].scaleOverrideIos,
                markerWidthIos: this.props.natureTrailData[0].markerWidthIos,
                markerWidthAndroid: this.props.natureTrailData[0].markerWidthAndroid,
                triggerDistance: this.props.natureTrailData[0].triggerDistance,
                estimatedTime: this.props.natureTrailData[0].estimatedTime,
                fullRoadLength: this.props.natureTrailData[0].fullRoadLength,
                gpxSavedFileName: this.props.natureTrailData[0].gpx,
                locations: this.props.natureTrailData[0].natureTrailLocation,
                region: [this.props.natureTrailData[0].region],
                nationalPark: [this.props.natureTrailData[0].nationalPark],
                badge: this.props.natureTrailData[0].badge,
                natureTrailType: [this.props.natureTrailData[0].natureTrailType],
                natureTrailLocationIds: this.props.natureTrailData[0].natureTrailLocation.map(item => { return item.id })
            })
            this.props.setAtiveEditingNaturalTrailName(this.props.natureTrailData[0].name)
            const sortedArray = this.props.natureTrailData[0].natureTrailLocation;
            sortedArray.sort(function (a, b) { return a.sequenceId - b.sequenceId });
            const convertedLocationData = sortedArray.map((item, i) => {
                return {
                    index: i,
                    id: item.id,
                    key: item.sequenceId,
                    natureTrailId: this.props.natureTrailData[0].id,
                    name: item.name,
                    title: item.name,
                    lat: item.lat,
                    lon: item.lon,
                    triggerDistance: item.triggerDistance,
                    startTitle: item.name,
                    startDescription: item.description,
                    startPictureSavedFileName: item.startPictureSavedFileName,
                    finishPictureSavedFileName: item.finishPictureSavedFileName,
                    finishDescription: item.finishDescription,
                    finishTitle: item.finishTitle,
                    natureTrailFileName: item.natureTrailFileName,
                    triggerType: item.triggerType,
                    questions: item.question,
                    qrcode: item.qrcode,
                    movieFileURL: item.movieFileURL,
                    musicFileURL: item.musiccFileURL,
                    iphone3dFileName: item.iphone3dFileName,
                    android3dFileName: item.android3dFileName,
                    contentType: item.contentType.id,
                    isYoutube: item.isYoutube,
                    scaleOverrideAndroid: item.scaleOverrideAndroid,
                    scaleOverrideIos: item.scaleOverrideIos,
                    markerWidthAndroid: item.markerWidthAndroid,
                    markerWidthIos: item.markerWidthIos
                }
            })
            const filteredIdAndSeqquencePair = convertedLocationData.map(item => {
                return {
                    id: item.id,
                    sequenceId: item.index
                }
            })
            const maxCount = Math.max.apply(Math, this.props.natureTrailData[0].natureTrailLocation.map(function (o) { return o.sequenceId; }))
            this.setState({ objectsFromLocations: convertedLocationData, count: maxCount, newIdAndSequenceIdPair: filteredIdAndSeqquencePair })
        } else {
            this.props.setAtiveEditingNaturalTrailName("")
            this.setState({ name: null, title: null, history: 0, showLogo: false, description_long: null, description_short: null, lat: 0, lon: 0, locations: [] })
        }
        if (this.props.nationalParks[0] !== undefined && this.props.user.group.id === 2) {
            const locationAdminNaturelPark = this.props.nationalParks.filter(item => { return item.id === this.props.user.nationalParkId })
            const locationAdminRegion = this.props.regions.filter(item => { return item.id === this.props.user.regionId })
            this.setState({ nationalPark: locationAdminNaturelPark, region: locationAdminRegion })
        }

    }

    setEditedLocation = (savedData) => {
        if (this.state.choosedRecord.id !== undefined) {
            const actualArray = this.state.objectsFromLocations.filter(item => item.id === savedData.id);
            if (actualArray.length > 0) {
                actualArray[0].name = savedData.name;
                actualArray[0].id = savedData.id;
                actualArray[0].title = savedData.name;
                actualArray[0].lat = savedData.lat;
                actualArray[0].lon = savedData.lon;
                actualArray[0].triggerDistance = savedData.triggerDistance;
                actualArray[0].startTitle = savedData.name;
                actualArray[0].startDescription = savedData.description;
                actualArray[0].startPictureSavedFileName = savedData.startPictureSavedFileName;
                actualArray[0].finishPictureSavedFileName = savedData.finishPictureSavedFileName;
                actualArray[0].finishDescription = savedData.finishDescription;
                actualArray[0].finishTitle = savedData.finishTitle;
                actualArray[0].finishTitle = savedData.finishTitle;
                actualArray[0].natureTrailFileName = savedData.natureTrailFileName;
                actualArray[0].natureTrailFileName = savedData.natureTrailFileName;
                actualArray[0].triggerType = savedData.triggerType;
                actualArray[0].question = savedData.question;
                actualArray[0].qrcode = savedData.qrcode;
                actualArray[0].qrcode = savedData.qrcode;
                actualArray[0].movieFileURL = savedData.movieFileURL;
                actualArray[0].musicFileURL = savedData.musicFileURL;
                //actualArray[0].contentType = savedData.contentType.id;
                actualArray[0].isYoutube = savedData.isYoutube;
                actualArray[0].scaleOverrideIos = savedData.scaleOverrideIos;
                actualArray[0].scaleOverrideAndroid = savedData.scaleOverrideAndroid;
                actualArray[0].markerWidthAndroid = savedData.markerWidthAndroid;
                actualArray[0].markerWidthIos = savedData.markerWidthIos;
            }
        } else {
            const actualArray = this.state.objectsFromLocations.filter(item => item.key === this.state.choosedRecord.key);
            if (actualArray.length > 0) {
                actualArray[0].name = savedData.name;
                actualArray[0].id = savedData.id;
                actualArray[0].title = savedData.name;
                actualArray[0].lat = savedData.lat;
                actualArray[0].lon = savedData.lon;
                actualArray[0].triggerDistance = savedData.triggerDistance;
                actualArray[0].startTitle = savedData.name;
                actualArray[0].startDescription = savedData.description;
                actualArray[0].startPictureSavedFileName = savedData.startPictureSavedFileName;
                actualArray[0].finishPictureSavedFileName = savedData.finishPictureSavedFileName;
                actualArray[0].finishDescription = savedData.finishDescription;
                actualArray[0].finishTitle = savedData.finishTitle;
                actualArray[0].finishTitle = savedData.finishTitle;
                actualArray[0].natureTrailFileName = savedData.natureTrailFileName;
                actualArray[0].natureTrailFileName = savedData.natureTrailFileName;
                actualArray[0].triggerType = savedData.triggerType;
                actualArray[0].question = savedData.question;
                actualArray[0].qrcode = savedData.qrcode;
                actualArray[0].qrcode = savedData.qrcode;
                actualArray[0].movieFileURL = savedData.movieFileURL;
                actualArray[0].musicFileURL = savedData.musicFileURL;
                actualArray[0].isYoutube = savedData.isYoutube;
                actualArray[0].scaleOverrideAndroid = savedData.scaleOverrideAndroid;
                actualArray[0].scaleOverrideIos = savedData.scaleOverrideIos;
                actualArray[0].markerWidthAndroid = savedData.markerWidthIos;
                actualArray[0].markerWidthIos = savedData.markerWidthIos;
            }
        }
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = URL.createObjectURL(file);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };
    priviewModalOpen = () => {
        this.setState({
            previewVisible: true,
        });
    }

    handleDelete = async key => {
        const objectsFromLocations = [...this.state.objectsFromLocations];
        const findedLocation = objectsFromLocations.filter(item => item.key === key);
        if (findedLocation[0].id !== undefined) {
            await this.props.deleteLocationById(findedLocation[0].id);
        }
        this.removeLocationFromList(key)
        const filteredNewList = objectsFromLocations.filter(item => item.key !== key)
        this.setState({
            objectsFromLocations: filteredNewList
        });

        const changedArrayWithNewIndex = filteredNewList.map((item, i) => {
            return {
                index: i,
                id: item.id,
                key: item.key,
                natureTrailId: item.natureTrailId,
                name: item.name,
                title: item.title,
                lat: item.lat,
                lon: item.lon,
                triggerDistance: item.triggerDistance,
                startTitle: item.name,
                startDescription: item.description,
                finishDescription: item.finishDescription,
                finishTitle: item.finishTitle,
                natureTrailFileName: item.natureTrailFileName,
                iphone3dFileName: item.iphone3dFileName,
                android3dFileName: item.android3dFileName,
                triggerType: item.triggerType,
                questions: item.question,
                qrcode: item.qrcode,
                movieFileURL: item.movieFileURL,
                contentType: item.contentType,
                isYoutube: item.isYoutube,
                scaleOverrideIos: item.scaleOverrideIos,
                scaleOverrideAndroid: item.scaleOverrideAndroid,
                markerWidthAndroid: item.markerWidthAndroid,
                markerWidthIos: item.markerWidthIos
            }
        })
        this.setState({ objectsFromLocations: changedArrayWithNewIndex });
        const filteredIdAndSeqquencePair = changedArrayWithNewIndex.map(item => {
            return {
                id: item.id,
                sequenceId: item.index
            }
        })
        this.setState({ newIdAndSequenceIdPair: filteredIdAndSeqquencePair })



    };
    onSortEnd = async ({ oldIndex, newIndex }) => {
        const actualArray = this.state.objectsFromLocations
        if (oldIndex !== newIndex) {
            const newData = this.array_move(actualArray, oldIndex, newIndex).filter(el => !!el)
            const changedArrayWithNewIndex = newData.map((item, i) => {
                return {
                    index: i,
                    id: item.id,
                    key: item.key,
                    natureTrailId: item.natureTrailId,
                    name: item.name,
                    title: item.title,
                    lat: item.lat,
                    lon: item.lon,
                    triggerDistance: item.triggerDistance,
                    startTitle: item.name,
                    startDescription: item.description,
                    finishDescription: item.finishDescription,
                    finishTitle: item.finishTitle,
                    natureTrailFileName: item.natureTrailFileName,
                    triggerType: item.triggerType,
                    questions: item.question,
                    qrcode: item.qrcode,
                    movieFileURL: item.movieFileURL,
                    contentType: item.contentType,
                    isYoutube: item.isYoutube,
                    scaleOverrideAndroid: item.scaleOverrideAndroid,
                    scaleOverrideIos: item.scaleOverrideIos,
                    markerWidthAndroid: item.markerWidthAndroid,
                    markerWidthIos: item.markerWidthIos,
                    iphone3dFileName: item.iphone3dFileName,
                    android3dFileName: item.android3dFileName,
                }
            })
            this.setState({ objectsFromLocations: changedArrayWithNewIndex });
            const filteredIdAndSeqquencePair = changedArrayWithNewIndex.map(item => {
                return {
                    id: item.id,
                    sequenceId: item.index
                }
            })
            this.setState({ newIdAndSequenceIdPair: filteredIdAndSeqquencePair })
            this.activateSaveButton()
            this.forceUpdate()
        }
    };
    handleAdd = () => {
        const newData = {
            key: this.state.count + 1,
            sequenceId: this.state.count + 1,
            index: this.state.count + 1,
            id: undefined,
            natureTrailId: this.state.id,
            name: null,
            lat: 0,
            lon: 0,
            triggerDistance: 0,
            startDescription: null,
            natureTrailFileName: null,
            movieFileURL: null,
            iphone3dFileName: null,
            android3dFileName: null,


        };
        this.deactivateSaveButon();
        this.setState({
            objectsFromLocations: [...this.state.objectsFromLocations, newData,],
            count: this.state.count + 1,
            addNewLocationButtonIsActive: false
        });
    };

    handleSave = row => {
        this.activateSaveButton()
        const newData = [...this.state.objectsFromLocations];
        const index = newData.findIndex(item => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        this.setState({
            objectsFromLocations: newData,

        });
    };

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.setState({ uploaded: true })
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
            this.forceUpdate()
        }
    };

    inputChangeByTargetValue = (event) => {
        this.setState({ [event.target.name]: event.target.value }, () => this.checkFormValid())
        if ([event.target.name] === "title") {
            this.props.setAtiveEditingNaturalTrailName(event.target.value)
        }
    }
    changeShortDesc = (value) => {
        this.setState({ description_short: value }, () => this.checkFormValid())
    }

    changeLongDesc = (value) => {
        this.setState({ description_long: value }, () => this.checkFormValid())
    }
    changeEstimatedTime = (event) => {
        this.setState({ estimatedTime: event.target.value }, () => this.checkFormValid())
    }
    changeFullRoadLength = (event) => {
        this.setState({ fullRoadLength: event.target.value }, () => this.checkFormValid())
    }

    changehistory = () => {
        this.setState({ history: !this.state.history }, () => this.checkFormValid())
    }
    changeShowLogo = () => {
        this.setState({ showLogo: !this.state.showLogo }, () => this.checkFormValid())
    }

    beforeUpload = (file) => {

        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
        } else {
            this.setState({ previewImage: URL.createObjectURL(file) })
            this.setState({ file: [file] }, () => this.checkFormValid())
        }
        return isLt2M;;
    }

    beforeUploadLogo = (file) => {

        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 1MB!');
        } else {
            this.setState({ previewLogo: URL.createObjectURL(file) })
            this.setState({ logoFile: [file] }, () => this.checkFormValid())
        }
        return isLt2M;;
    }

    beforeUploadBadge = (file) => {

        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
        } else {
            this.setState({ previewBadgeImage: URL.createObjectURL(file) })
            this.setState({ badgeFile: [file] }, () => this.checkFormValid())
        }
        return isLt2M;;
    }
    beforeUploadDeactiveBadge = (file) => {

        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 1MB!');
        } else {
            this.setState({ previewDeactiveBadgeImage: URL.createObjectURL(file) })
            this.setState({ badgeDeactiveFile: [file] }, () => this.checkFormValid())
        }
        return isLt2M;;
    }

    beforeUploadGPX = (file) => {
        this.setState({ gpxfile: [file] }, () => this.checkFormValid())
        return false;
    }

    removeFile = () => {
        this.setState({ file: [] });
    };

    removeGPXFile = () => {
        this.setState({ gpxfile: [] });
    };

    removeBadgeFile = () => {
        this.setState({ badgeFile: [] });
    };
    removeDeactiveBadgeFile = () => {
        this.setState({ badgeDeactiveFile: [] });
    };
    changeSavingTitle = () => {
        this.setState({ savingDescription: "A csempék generálása több időt vesz igénybe, kis türelmet." })
    }
    saveNatureTrail = async () => {

        const myVar = setTimeout(this.changeSavingTitle, 5000);
        const saveNaturalTrail = {
            id: undefined,
            name: this.state.title,
            title: this.state.name,
            description_short: this.state.description_short,
            description_long: this.state.description_long,
            lat: this.state.lat,
            lon: this.state.lon,
            badge: {
                name: this.state.title,
                description: null
            },
            triggerDistance: this.state.triggerDistance,
            history: this.state.history,
            showLogo: this.state.showLogo,
            estimatedTime: this.state.estimatedTime,
            fullRoadLength: this.state.fullRoadLength,
            scaleOverrideIos: this.state.scaleOverrideIos,
            scaleOverrideAndroid: this.state.scaleOverrideAndroid,
            region: this.state.region !== undefined && this.state.region.length > 0 ? this.state.region : [this.props.regions[0]],
            nationalPark: this.state.nationalPark !== undefined && this.state.nationalPark.length > 0 ? this.state.nationalPark : [this.props.nationalParks[0]],
            natureTrailType: this.state.natureTrailType !== undefined && this.state.natureTrailType.length > 0 ? this.state.natureTrailType[0] : [this.props.naturalTrailTypes[0]],
            natureTrailLocations: this.props.natureTrailData !== undefined && this.props.natureTrailData[0] !== undefined ? this.props.natureTrailData[0].natureTrailLocation.map(item => { return item.id }) : [],
            newIdAndSequenceIdPair: this.state.newIdAndSequenceIdPair,
            natureTrailLocationIds: this.state.natureTrailLocationIds,
        };
        const files = {
            base: this.state.file,
            gpx: this.state.gpxfile,
            badge: this.state.badgeFile,
            deactiveBadge: this.state.badgeDeactiveFile,
            logo: this.state.logoFile
        }
        if (this.props.natureTrailData[0] !== undefined) {
            this.setSpinningTrue();
            saveNaturalTrail.id = this.props.natureTrailData[0].id
            await this.props.updateNT(saveNaturalTrail, files)
            if (this.props.status === 'saved') {
                this.setSpinningFalse()
                this.setState({ savingDescription: "Mentés folyamatban..." })
                clearTimeout(myVar)
                message.success("Sikeres mentés!")
            }
            if (this.props.status === 'error') {
                this.setState({ savingDescription: "Mentés folyamatban..." })
                this.setSpinningFalse()
                clearTimeout(myVar)

                message.error("Hiba a mentést közben!")

            }
        } else {
            // Kérésre kivéve 06.27
            // if (files.base === undefined || files.base[0] === undefined
            //     || files.gpx === undefined || files.gpx[0] === undefined) {
            //     message.warning("Kezdő képet kötelező feltölteni a tanösvény létrehozásánál.")
            // } else if (files.gpx === undefined || files.gpx[0] === undefined) {
            //     message.warning("GPX file-t kötelező feltölteni a tanösvény létrehozásánál.")

            // } else if (saveNaturalTrail.title === undefined || saveNaturalTrail.title === "") {
            //     message.warning("GPX file-t kötelező feltölteni a tanösvény létrehozásánál.")
            // }
            // else {
            //     this.props.saveNT(saveNaturalTrail, files)
            // }
            if (saveNaturalTrail.name === undefined || saveNaturalTrail.name === "" || saveNaturalTrail.name === null) {
                message.warning("Név megadása kötelező!")
            } else {
                await this.props.saveNT(saveNaturalTrail, files)
                if (this.props.status === 'saved') {
                    this.setSpinningFalse()
                    message.success("Sikeres mentés!")
                }
                if (this.props.status === 'error') {
                    this.setSpinningFalse()
                }
                this.handleCancel()
            }
        }
    }

    handleCancel = () => {
        this.props.close();
    }

    handleCancelPhoto = () => {
        this.setState({ previewVisible: false })
    }

    setDeactiveAddNewButton = () => {
        this.setState({ addNewLocationButtonIsActive: false, saveButtonDisbled: true }
        )

    }

    setActiveAddNewButton = () => {
        this.setState({ addNewLocationButtonIsActive: true, saveButtonDisbled: false })

    }


    setFiles = (id, file) => {
        const data = {
            id: id,
            file: file
        };
        const filteredFileList = this.state.dataFilesFromLocations.filter(item => item.id === data.id)
        if (filteredFileList.length !== 0) {
            if (filteredFileList[0].file !== data.file) {
                var tempData = [...this.state.dataFilesFromLocations];
                var index = tempData.findIndex(obj => obj.id === data.id);
                tempData[index].file = data.file;
                this.setState({ dataFilesFromLocations: tempData });
            }
        } else {
            this.setState({ dataFilesFromLocations: [...this.state.dataFilesFromLocations, data] })
        }
    }

    addLocationToList = (id) => {
        const filtered = this.state.natureTrailLocationIds.filter(item => item === id);
        if (filtered.length === 0) {
            this.setState({ count: this.state.count + 1, natureTrailLocationIds: [...this.state.natureTrailLocationIds, id] }, () => this.checkFormValid())
        }
        this.checkFormValid()

    }

    removeLocationFromList = (id) => {
        const filteredLocationList = this.state.natureTrailLocationIds.filter(item => item !== id)
        this.setState({ count: this.state.count - 1, natureTrailLocationIds: filteredLocationList }, () => this.checkFormValid())


        this.checkFormValid()

    }

    activateSaveButton = () => {
        this.setState({ saveButtonDisbled: false })
    }

    deactivateSaveButon = () => {
        this.setState({ saveButtonDisbled: true })
    }

    checkFormValid = () => {
        if (this.state.title !== null && this.state.title !== undefined && this.state.title !== "") {
            this.activateSaveButton();
        } else {
            this.deactivateSaveButon();
        }
    }

    selectRegion = (event) => {
        const filteredRegion = this.props.regions.filter(item => item.value === event)
        this.setState({ region: filteredRegion })
        this.checkFormValid()
    }

    selectNationalPark = (event) => {
        const filteredNationalPark = this.props.nationalParks.filter(item => item.value === event)
        this.setState({ nationalPark: filteredNationalPark })
        this.checkFormValid()
    }

    selectNatureTrailType = (event) => {
        const filteredNatureTrailType = this.props.naturalTrailTypes.filter(item => item.value === event)
        this.setState({ natureTrailType: filteredNatureTrailType })
        this.checkFormValid()
    }

    setSpinningTrue = () => {
        this.setState({ spinning: true })
    }

    setSpinningFalse = () => {
        this.setState({ spinning: false })
    }

    handleShow = async (record) => {
        //trigger hiba miatt átírva 2020.08.27
        this.setState({ spinning: true, choosedRecord: null }, async () => {
            if (record && record.id) {
                await this.props.getNatureTrailLocationById(record.id)
                const savedLoadedLocation = {
                    index: record.index,
                    id: this.props.natureTrailLocation.id,
                    key: this.props.natureTrailLocation.sequenceId,
                    natureTrailId: record.natureTrailId,
                    name: this.props.natureTrailLocation.name,
                    title: this.props.natureTrailLocation.name,
                    lat: this.props.natureTrailLocation.lat,
                    lon: this.props.natureTrailLocation.lon,
                    triggerDistance: this.props.natureTrailLocation.triggerDistance,
                    startTitle: this.props.natureTrailLocation.name,
                    startDescription: this.props.natureTrailLocation.description,
                    startPictureSavedFileName: this.props.natureTrailLocation.startPictureSavedFileName,
                    finishPictureSavedFileName: this.props.natureTrailLocation.finishPictureSavedFileName,
                    finishDescription: this.props.natureTrailLocation.finishDescription,
                    finishTitle: this.props.natureTrailLocation.finishTitle,
                    natureTrailFileName: this.props.natureTrailLocation.natureTrailFileName,
                    triggerType: this.props.natureTrailLocation.triggerType,
                    questions: this.props.natureTrailLocation.question,
                    qrcode: this.props.natureTrailLocation.qrcode,
                    movieFileURL: this.props.natureTrailLocation.movieFileURL,
                    musicFileURL: this.props.natureTrailLocation.musiccFileURL,
                    iphone3dFileName: this.props.natureTrailLocation.iphone3dFileName,
                    android3dFileName: this.props.natureTrailLocation.android3dFileName,
                    contentType: this.props.natureTrailLocation.contentType.id,
                    isYoutube: this.props.natureTrailLocation.isYoutube,
                    scaleOverrideAndroid: this.props.natureTrailLocation.scaleOverrideAndroid,
                    scaleOverrideIos: this.props.natureTrailLocation.scaleOverrideIos,
                    markerWidthAndroid: this.props.natureTrailLocation.markerWidthAndroid,
                    markerWidthIos: this.props.natureTrailLocation.markerWidthIos
                }
                this.setState({ choosedRecord: savedLoadedLocation })
                this.setState({ spinning: false })

            } else {
                const newNTLocation = {
                    index: record.index,
                    sequenceId: record.index,
                    contentType: 1,
                    finishDescription: undefined,
                    finishTitle: undefined,
                    id: undefined,
                    isYoutube: false,
                    key: record.key,
                    lat: 0.0,
                    lon: 0.0,
                    triggerDistance: 0,
                    movieFileURL: "",
                    iphone3dFileName: "",
                    android3dFileName: "",
                    name: "",
                    natureTrailFileName: undefined,
                    natureTrailId: this.props.natureTrailData !== undefined && this.props.natureTrailData[0] !== undefined && this.props.natureTrailData[0].id === undefined ? this.props.natureTrailData[0].id : undefined,
                    qrcode: undefined,
                    questions: [],
                    questionAnswer: [],
                    startDescription: undefined,
                    startTitle: undefined,
                    title: undefined,
                    triggerType: undefined,
                    scaleOverrideIos: 1,
                    scaleOverrideAndroid: 1,
                    markerWidthAndroid: 10,
                    markerWidthIos: 10
                }
                this.setState({ choosedRecord: newNTLocation })
                this.setState({ spinning: false })
            }
        })
        //2020.08.27 trigger type probléma 

        // await this.props.getNatureTrailLocationById(record.id)
        // if (this.props.natureTrailLocationStatus === 'loaded') {
        //     this.setState({ spinning: false })
        //     if (this.props.natureTrailLocation.id !== undefined) {
        //         this.setState({ choosedRecord: record })
        //     } else {
        //         const newNTLocation = {
        //             contentType: 1,
        //             finishDescription: undefined,
        //             finishTitle: undefined,
        //             id: undefined,
        //             isYoutube: false,
        //             key: record.key,
        //             lat: 0.0,
        //             lon: 0.0,
        //             triggerDistance: 0,
        //             movieFileURL: "",
        //             iphone3dFileName: "",
        //             android3dFileName: "",
        //             name: "",
        //             natureTrailFileName: undefined,
        //             natureTrailId: this.props.natureTrailData !== undefined && this.props.natureTrailData[0] !== undefined && this.props.natureTrailData[0].id === undefined ? this.props.natureTrailData[0].id : undefined,
        //             qrcode: undefined,
        //             questions: [],
        //             questionAnswer: [],
        //             startDescription: undefined,
        //             startTitle: undefined,
        //             title: undefined,
        //             triggerType: undefined,
        //             scaleOverrideIos: 1,
        //             scaleOverrideAndroid: 1,
        //             markerWidthAndroid: 10,
        //             markerWidthIos: 10
        //         }
        //         this.setState({ choosedRecord: newNTLocation })
        //     }
        // } else {
        //     this.setState({ spinning: false })

        // }
    }


    render() {
        const formItemLayout = {
            labelCol: {
                span: 6,
            },
            wrapperCol: {
                span: 48,
            },
        };
        const onFinish = values => {
            console.log('Received values of form: ', values);
        };
        const columns = this.state.columns;
        const DraggableContainer = props => (
            <SortableContainera
                useDragHandle
                helperClass="row-dragging"
                onSortEnd={this.onSortEnd}
                {...props}
            />
        );
        const beforeCrop = (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                message.error('A képnek kissebbnek kell lennie mint 5MB!');
                return false
            } else {
                //this.setState({ preview1a: URL.createObjectURL(file), picture1afile: file, saveButtonActive: true })
                return true
            }
        }
        const beforeCropBadge = (file) => {
            const isLt2M = file.size / 1024 / 1024 < 1;
            if (!isLt2M) {
                message.error('A képnek kissebbnek kell lennie mint 1MB!');
                return false
            } else {
                //this.setState({ preview1a: URL.createObjectURL(file), picture1afile: file, saveButtonActive: true })
                return true
            }
        }

        return (
            <Spin spinning={this.state.spinning} tip={this.state.savingDescription} className="customSpin" style={{
                zIndex: 100000000
            }} size="large">
                <div >
                    <Tabs defaultActiveKey="1" style={{ paddingTop: "0px", height: "75vh" }} >
                        <TabPane style={{ height: "65vh", overflowY: "auto" }} tab="Alapadatok" key="1">
                            <Form
                                name="validate_other"
                                {...formItemLayout}
                                onFinish={onFinish}
                                ref={this.formRef}
                            >
                                <Row justify="center">
                                    <Col span={11}>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="title"
                                                    label="Név"
                                                    rules={[{ required: true, message: 'A név mező nem maradhat üresen' }]}
                                                >
                                                    <Input name="title" defaultValue={this.props.natureTrailData[0] !== undefined ? this.props.natureTrailData[0].name : null} onChange={this.inputChangeByTargetValue} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item name="switch" label="Tanösvény tipus" valuePropName="checked">
                                                    <Switch defaultChecked={this.state.history} onChange={this.changehistory} /> {this.state.history === true ? "Kötött" : "Kötetlen"}
                                                </Form.Item>
                                                <Form.Item name="region" label="Régió" valuePropName="checked">
                                                    <Select
                                                        disabled={this.props.user.group.id === 2 ? true : false}
                                                        onSelect={this.selectRegion}
                                                        value={this.state.region !== undefined && this.state.region[0] !== undefined ? this.state.region[0].value : this.props.regions[0].value
                                                        }
                                                    >
                                                        {this.props.regions.map(item => {
                                                            return (<Option key={item.id} value={item.value}>{item.value}</Option>)
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="nationalPark" label="Nemzeti park" valuePropName="checked">
                                                    <Row gutter={16} justify="space-between" style={{ height: "10vh" }}>
                                                        <Col span={18}>
                                                            <Select
                                                                disabled={this.props.user.group.id === 2 ? true : false}
                                                                onSelect={this.selectNationalPark}
                                                                value={this.state.nationalPark !== undefined && this.state.nationalPark[0] !== undefined ? this.state.nationalPark[0].value :
                                                                    (this.props.nationalParks && this.props.nationalParks[0] && this.props.nationalParks[0].value ? this.props.nationalParks[0].value : "Nincs érték")
                                                                }
                                                            >
                                                                {this.props.nationalParks.map(item => {
                                                                    return (<Option key={item.id} value={item.value}>{item.value}</Option>)
                                                                })}
                                                            </Select>
                                                            <Row gutter={[24, 24]} justify="space-between" style={{ paddingTop: 26 }}>
                                                                <Col>
                                                                    <Switch checked={this.state.showLogo} onChange={this.changeShowLogo} /> {this.state.showLogo === true ? "Saját logó" : "Nemzeti park logo"}
                                                                </Col>
                                                                {/* ---------------------------------------------------- */}

                                                                <Col>
                                                                    <ImgCrop rotate aspect={1 / 1}
                                                                        beforeCrop={beforeCropBadge}
                                                                        modalTitle="Saját logó(maximum 1 MB)">
                                                                        <Upload
                                                                            multiple={false}
                                                                            beforeUpload={this.beforeUploadLogo}
                                                                            fileList={this.state.logoFile}
                                                                            onChange={this.handleChange}
                                                                            showUploadList={false}
                                                                            accept=".png,.jpg,.jpeg">
                                                                            <Button>Saját logó</Button>
                                                                        </Upload>
                                                                    </ImgCrop>
                                                                </Col>
                                                            </Row>


                                                        </Col>
                                                        <Col span={6} justify="center" align="center">
                                                            { }
                                                            {this.state.showLogo ? (this.state.logoFile && this.state.logoFile[0] ?
                                                                <img
                                                                    src={
                                                                        this.state.previewLogo}
                                                                    alt=" Nincs kép"
                                                                    style={{ height: '10vh', maxWidth: "10vw" }}
                                                                /> :
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.logoSavedFileURL}
                                                                    alt=" Nincs kép"
                                                                    style={{ height: '10vh', maxWidth: "10vw" }}
                                                                />) :
                                                                this.state.nationalPark && this.state.nationalPark[0] &&
                                                                <img
                                                                    src={
                                                                        this.state.nationalPark !== undefined && this.state.nationalPark[0].logoSavedFileURL !== undefined ? (process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.nationalPark[0].logoSavedFileURL) : null}
                                                                    alt=" Nincs kép"
                                                                    style={{ height: '10vh', maxWidth: "10vw" }}
                                                                />}
                                                        </Col>
                                                    </Row>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="natureTrailType" label="Típus" valuePropName="checked">
                                                    <Select
                                                        onSelect={this.selectNatureTrailType}
                                                        value={this.state.natureTrailType !== undefined && this.state.natureTrailType[0] !== undefined ? this.state.natureTrailType[0].value : this.props.naturalTrailTypes[0].value
                                                        }
                                                    >
                                                        {this.props.naturalTrailTypes.map(item => {
                                                            return (<Option key={item.id} value={item.value}>{item.value}</Option>)
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item name="description_short" label="Rövid leírás" valuePropName="checked">
                                                    <Mentions rows="3" value={this.state.description_short} name="description_short" onChange={this.changeShortDesc}>
                                                    </Mentions>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item name="description_long" label="Hosszú leírás" valuePropName="checked">
                                                    <Mentions rows="6" value={this.state.description_long} name="description_long" onChange={this.changeLongDesc}>
                                                    </Mentions>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col span={12} >
                                        <Row gutter={16} justify="center" align="center">
                                            <Col span={10}>
                                                <Form.Item name="estimatedTime" label="Idő (perc)" valuePropName="checked">
                                                    <Input name="estimatedTime" type="number" min="0" value={this.state.estimatedTime} onChange={this.changeEstimatedTime} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item name="fullRoadLength" label="Hossz (km)" valuePropName="checked">
                                                    <Input name="fullRoadLength" step=".01" min="0" type="number" value={this.state.fullRoadLength} onChange={this.changeFullRoadLength} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} justify="center" align="center">
                                            <Col span={10}>
                                                <Form.Item name="lat" label="Lat" valuePropName="checked">
                                                    <Input type="number" step=".000001" value={this.state.lat} name="lat" onChange={this.inputChangeByTargetValue} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item name="lon" label="Lon" valuePropName="checked">
                                                    <Input type="number" step=".000001" value={this.state.lon} name="lon" onChange={this.inputChangeByTargetValue} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24} >
                                            <Col span={22}>
                                                <Form.Item name="triggerDistance" label="Trigger távolság (méter)" valuePropName="checked">
                                                    <Input type="number" value={this.state.triggerDistance} name="triggerDistance" onChange={this.inputChangeByTargetValue} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16} justify="center" align="center">
                                            <Col span={10} >
                                                <Form.Item >
                                                    <Form.Item
                                                        name="dragger"
                                                        // valuePropName="fileList"
                                                        // getValueFromEvent={normFile}
                                                        // multiple={false}
                                                        noStyle>
                                                        <ImgCrop rotate aspect={16 / 9}
                                                            beforeCrop={beforeCrop}
                                                            modalTitle="Kép (maximum 5 MB)">
                                                            <Dragger
                                                                id="firstPicture"
                                                                name="filesPicture"
                                                                multiple={false}
                                                                beforeUpload={this.beforeUpload}
                                                                fileList={this.state.file}
                                                                listType={"text"}
                                                                onRemove={this.removeFile}
                                                                showUploadList={false}
                                                                // defaultFileList={this.state.gpx}
                                                                //onPreview={this.handlePreview}
                                                                onChange={this.handleChange}
                                                                accept=".png,.jpg,.jpeg">
                                                                {/* <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p> */}
                                                                <p className="ant-upload-text">Tanösvény fő képe</p>
                                                                {/* <p className="ant-upload-text">Elfogadott formátumok:</p> */}
                                                                <p>(.png .jpg .jpeg) max 5 MB</p>
                                                            </Dragger>
                                                        </ImgCrop>
                                                    </Form.Item>
                                                </Form.Item>
                                            </Col>

                                            <Col span={10} >
                                                <Form.Item>
                                                    <Form.Item
                                                        name="dragger"
                                                        // valuePropName="fileList"
                                                        // getValueFromEvent={normFile}
                                                        // multiple={false}
                                                        noStyle>
                                                        <Dragger
                                                            id="finishPicture"
                                                            name="filesGpx"
                                                            multiple={false}
                                                            beforeUpload={this.beforeUploadGPX}
                                                            fileList={this.state.gpxfile}
                                                            listType={"text"}
                                                            showUploadList={false}
                                                            onRemove={this.removeGPXFile}
                                                            // onPreview={this.handlePreview}
                                                            onChange={this.handleChange}
                                                            accept=".gpx">
                                                            {/* <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p> */}
                                                            <p className="ant-upload-text"> GPX file</p>
                                                            <p > (.gpx)</p>
                                                            {/* {this.state.gpxfile !== undefined
                                                                && this.state.gpxfile[0] !== undefined
                                                                && this.state.gpxfile[0].name !== undefined ?
                                                                <p> {this.state.gpxfile[0].name}</p>
                                                                :
                                                                <p>{this.state.gpxSavedFileName !== undefined ? this.state.gpxSavedFileName : ""}</p>
                                                            } */}
                                                        </Dragger>
                                                    </Form.Item>
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                        <Row gutter={16} justify="center" align="center">
                                            <Col span={10} ></Col>
                                            <Col span={10}  >
                                                {this.state.gpxfile !== undefined
                                                    && this.state.gpxfile[0] !== undefined
                                                    && this.state.gpxfile[0].name !== undefined ?
                                                    <Row gutter={16} align="middle" justify="center">

                                                        <Col span={20}>
                                                            <p> {this.state.gpxfile[0].name}</p>
                                                        </Col>
                                                        <Col span={4} style={{ paddingTop: "-1em" }}>
                                                            <p> <DeleteOutlined onClick={this.removeGPXFile} /></p>
                                                        </Col>
                                                    </Row> :
                                                    <Row>
                                                        <Col span={24}>
                                                            <p>{this.state.gpxSavedFileName !== undefined ? this.state.gpxSavedFileName : ""}</p>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                        <Row gutter={24} justify="center" align="center">
                                            <Col span={10} justify="center" align="center">
                                                {this.state.file[0] === undefined && this.props.natureTrailData[0] !== undefined ?
                                                    <img onClick={this.priviewModalOpen}
                                                        src={
                                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + "/nature-trail/get-nature-trail-picure/?natureTrailPicureName=" + this.props.natureTrailData[0].natureTrailSavedFileName + "&id=" + this.props.natureTrailData[0].id}
                                                        alt=" Nincs tanösvény fő kép"
                                                        style={{ maxHeight: '16vh', maxWidth: "16vw", cursor: 'zoom-in' }}
                                                    /> :
                                                    <img onClick={this.priviewModalOpen}
                                                        alt="" style={{ maxHeight: '16vh', maxWidth: "16vw", cursor: 'zoom-in' }}
                                                        src={this.state.previewImage} />}
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item>
                                                    <Form.Item
                                                        name="badge"
                                                        // valuePropName="fileList"
                                                        // getValueFromEvent={normFile}
                                                        // multiple={false}
                                                        noStyle>
                                                        <ImgCrop rotate aspect={1 / 1}
                                                            shape="round"
                                                            beforeCrop={beforeCropBadge}
                                                            fillColor="transparent"
                                                            modalTitle="Kitűző kép (maximum 1 MB)">
                                                            <Dragger
                                                                id="badgePicture"
                                                                name="badge"
                                                                multiple={false}
                                                                beforeUpload={this.beforeUploadDeactiveBadge}
                                                                fileList={this.state.badgeDeactiveFile}
                                                                listType={"text"}
                                                                onRemove={this.removeDeactiveBadgeFile}
                                                                showUploadList={false}
                                                                onChange={this.handleChange}
                                                                accept=".png">
                                                                <p className="ant-upload-text"> Badge deaktív</p>
                                                                <p> (.png) max 1MB</p>
                                                            </Dragger>
                                                        </ImgCrop>
                                                    </Form.Item>
                                                </Form.Item>
                                                <Col span={24} justify="center" align="center">
                                                    {this.state.badgeDeactiveFile[0] === undefined && this.props.natureTrailData[0] !== undefined ?
                                                        <img
                                                            src={
                                                                this.state.badge !== undefined && this.state.badge.badgeDeactiveSavedPictureURL !== undefined ? (process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.badge.badgeDeactiveSavedPictureURL) : null}
                                                            alt=" Nincs kép"
                                                            style={{ height: '10vh', maxWidth: "10vw" }}
                                                        /> :
                                                        <img
                                                            alt="" style={{ height: '10vh', maxWidth: "10vw" }}
                                                            src={this.state.previewDeactiveBadgeImage} />}
                                                </Col>
                                            </Col>

                                            <Col span={5}>
                                                <Form.Item>
                                                    <Form.Item
                                                        name="badge"
                                                        // valuePropName="fileList"
                                                        // getValueFromEvent={normFile}
                                                        // multiple={false}
                                                        noStyle>
                                                        <ImgCrop rotate aspect={1 / 1}
                                                            shape="round"
                                                            fillColor="transparent"
                                                            beforeCrop={beforeCropBadge}
                                                            modalTitle="Kitűző kép (maximum 1 MB)">
                                                            <Dragger
                                                                id="badgePicture"
                                                                name="badge"
                                                                multiple={false}
                                                                beforeUpload={this.beforeUploadBadge}
                                                                fileList={this.state.badgeFile}
                                                                listType={"text"}
                                                                onRemove={this.removeBadgeFile}
                                                                showUploadList={false}
                                                                onChange={this.handleChange}
                                                                accept=".png">
                                                                <p className="ant-upload-text"> Badge aktív</p>
                                                                <p> (.png) max 1MB</p>
                                                            </Dragger>
                                                        </ImgCrop>
                                                    </Form.Item>
                                                </Form.Item>
                                                <Col span={24} justify="center" align="center">
                                                    {this.state.badgeFile[0] === undefined && this.props.natureTrailData[0] !== undefined ?
                                                        <img
                                                            src={
                                                                this.state.badge !== undefined && this.state.badge.badgeSavedPictureURL !== undefined ? (process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.badge.badgeSavedPictureURL) : null}
                                                            alt=" Nincs kép"
                                                            style={{ height: '10vh', maxWidth: "10vw" }}
                                                        /> :
                                                        <img
                                                            alt="" style={{ height: '10vh', maxWidth: "10vw" }}
                                                            src={this.state.previewBadgeImage} />}


                                                </Col>
                                            </Col>

                                        </Row>
                                        <Modal
                                            centered={true}
                                            bodyStyle={{ maxHeight: "80vh" }}
                                            visible={this.state.previewVisible}
                                            title={"Picture"}
                                            footer={null}
                                            onCancel={this.handleCancelPhoto}
                                        >
                                            <div align="center" justify="center">
                                                {this.state.file[0] === undefined && this.props.natureTrailData[0] !== undefined ?
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + "/nature-trail/get-nature-trail-picure/?natureTrailPicureName=" + this.props.natureTrailData[0].natureTrailSavedFileName + "&id=" + this.props.natureTrailData[0].id}
                                                        alt="Nincs tanösvény fő kép"
                                                        style={{ width: '100%', maxWidth: "80vh", height: "100%", maxHeight: " 80vh" }}
                                                    /> :
                                                    <img
                                                        alt=" Nincs tanösvény fő kép"
                                                        style={{ width: '100%', maxWidth: "80vh", height: "100%", maxHeight: " 80vh" }}
                                                        src={this.state.previewImage} />}
                                            </div>
                                        </Modal>
                                    </Col>
                                </Row>
                            </Form>
                        </TabPane>
                        <TabPane style={{ maxHeight: "64vh" }} tab="Állomások" disabled={!this.state.natureTrailLocationTab} key="2">
                            <div >
                                <Button
                                    disabled={!this.state.addNewLocationButtonIsActive}
                                    onClick={this.handleAdd}
                                    type="primary"
                                    style={{
                                        marginBottom: 16
                                    }}
                                >
                                    Állomás hozzáadás
                            </Button>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={6}>
                                        <Table
                                            scroll={{ y: '45vh' }}
                                            rowKey="index"
                                            components={{
                                                body: {
                                                    wrapper: DraggableContainer,
                                                    row: DragableBodyRow,
                                                },
                                            }}
                                            dataSource={this.state.objectsFromLocations}
                                            columns={columns}
                                            pagination={false}
                                        />
                                    </Col>
                                    <Col className="gutter-row" span={18}>
                                        {this.state.choosedRecord !== null && <NatureTrailLocationData
                                            natureTrailId={this.props.natureTrailData !== undefined && this.props.natureTrailData[0] !== undefined ? this.props.natureTrailData[0].id : undefined}
                                            setFiles={this.setFiles}
                                            setLocation={this.setLocation}
                                            record={this.state.choosedRecord}
                                            // cell={data}
                                            setEditedLocation={this.setEditedLocation}
                                            setActiveAddNewButton={this.setActiveAddNewButton}
                                            setDeactiveAddNewButton={this.setDeactiveAddNewButton}
                                            addLocationToList={this.addLocationToList}
                                            removeLocationFromList={this.removeLocationFromList}
                                            setSpinningFalse={this.setSpinningFalse}
                                            setSpinningTrue={this.setSpinningTrue}
                                        ></NatureTrailLocationData>}
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane style={{ maxHeight: "64vh" }} tab="Értékelések" disabled={!this.state.natureTrailLocationTab} key="3">
                            <NatureTrailRatingByUser
                                natureTrailId={this.props.natureTrailData !== undefined && this.props.natureTrailData[0] !== undefined && this.props.natureTrailData[0].id !== undefined ? this.props.natureTrailData[0].id : undefined}
                            />
                        </TabPane>
                        <TabPane style={{ maxHeight: "64vh" }} tab="Hibajelentések" disabled={!this.state.natureTrailLocationTab} key="4">
                            <NatureTrailReportMessageByUser
                                natureTrailId={this.props.natureTrailData !== undefined && this.props.natureTrailData[0] !== undefined && this.props.natureTrailData[0].id !== undefined ? this.props.natureTrailData[0].id : undefined}
                            />
                        </TabPane>
                        <TabPane style={{ maxHeight: "64vh" }} tab="Értesítés küldés" disabled={!this.state.natureTrailLocationTab || this.props.natureTrailData === undefined || this.props.natureTrailData[0] === undefined} key="5">
                            <SendPushMessage
                                natureTrailId={this.props.natureTrailData !== undefined && this.props.natureTrailData[0] !== undefined && this.props.natureTrailData[0].id !== undefined ? this.props.natureTrailData[0].id : undefined}
                            />
                        </TabPane>
                        <TabPane style={{ maxHeight: "64vh" }} tab="Tanösvény gazda" disabled={!this.state.natureTrailLocationTab || this.props.natureTrailData === undefined || this.props.natureTrailData[0] === undefined} key="6">
                            <NatureTrailOwner
                                natureTrailId={this.props.natureTrailData !== undefined && this.props.natureTrailData[0] !== undefined && this.props.natureTrailData[0].id !== undefined ? this.props.natureTrailData[0].id : undefined}
                                ownerEmail1={this.state.ownerEmail1}
                                ownerEmail2={this.state.ownerEmail2}
                            />
                        </TabPane>
                    </Tabs >
                    <div style={{ paddingTop: "1em" }}>
                        <Button disabled={this.state.saveButtonDisbled} key="save" type="primary" onClick={this.saveNatureTrail}>
                            Mentés
                        </Button>
                        <Button key="back" onClick={this.props.close}>
                            Mégsem
                    </Button>
                    </div>
                </div >


            </Spin>
        )
    }
}
const mapState = (state) => {
    return {
        user: state.loginReducer.user,
        message: state.natureTrailReducer.message,
        code: state.natureTrailReducer.code,
        status: state.natureTrailReducer.status,
        natureTrailLocations: state.natureTrailReducer.natureTrailLocations,
        natureTrailLocationStatus: state.natureTrailReducer.natureTrailLocationStatus,
        natureTrailLocation: state.natureTrailReducer.natureTrailLocation,
        natureTrailLocationsStatus: state.natureTrailReducer.natureTrailLocationsStatus,
        naturalTrailTypes: state.natureTrailReducer.naturalTrailTypes,
        naturalTrailTypesStatus: state.natureTrailReducer.naturalTrailTypesStatus,
        regions: state.natureTrailReducer.regions,
        regionsStatus: state.natureTrailReducer.regionsStatus,
        nationalParks: state.natureTrailReducer.nationalParks,
        nationalParksStatus: state.natureTrailReducer.nationalParksStatus,
        spinning: state.spinningReducer.spinning
    }
}

const NewNaturalTrail = connect(mapState, mapDispatch)(ConnectedNewNaturalTrail)
export default NewNaturalTrail;
