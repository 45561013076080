import axios from "axios";
import { TESTER } from "../constants/action-types";

export const getAllTester = () => {
    return async (dispatch) => {
        dispatch({ type: TESTER.GET_ALL_TESTER_LOADING });
        await axios
            .get("/users/testers", {})
            .then((response) => {
                dispatch({
                    type: TESTER.GET_ALL_TESTER_LOADED,
                    payload: response.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: TESTER.GET_ALL_TESTER_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
}; export const saveTester = (tester) => {
    return async (dispatch) => {
        dispatch({ type: TESTER.SAVE_TESTER_START });
        await axios
            .post("/users/testers", tester)
            .then((response) => {
                dispatch({ type: TESTER.SAVE_TESTER_FINISH });
            })
            .catch((err) => {
                dispatch({
                    type: TESTER.SAVE_TESTER_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};



export const modifyTester = (tester) => {
    return async (dispatch) => {
        dispatch({ type: TESTER.SAVE_TESTER_START });
        await axios
            .put("/users/testers/" + tester.id, tester)
            .then((response) => {
                dispatch({ type: TESTER.SAVE_TESTER_FINISH });
            })
            .catch((err) => {
                dispatch({
                    type: TESTER.SAVE_TESTER_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};

export const deleteTester = (id) => {
    return async () => {
        await axios
            .delete("/users/testers/" + id)
            .catch((err) => console.log(err));
    };
};
