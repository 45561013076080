import { BADGE } from "../constants/action-types";

const initialState = {
    allBadge: [],
    languagedBadge: [],
    languagedBadgeStatus: null,
    oneBadge: {},
    status: null,
    languagedBadgeSaveStatus: null,
    message: {},
    code: null
};

const badgeReducer = (state = initialState, action) => {
    switch (action.type) {
        case BADGE.GET_ALL_BADGE_LOADING:
            return Object.assign({}, state, {
                allBadge: [],
                message: {},
                status: "loading",
                code: null
            });
        case BADGE.GET_ALL_BADGE_LOADED:
            return Object.assign({}, state, {
                allBadge: action.payload.badges,
                status: "loaded",
                message: {},
                code: null
            });
        case BADGE.GET_ALL_BADGE_ERROR:
            return Object.assign({}, state, {
                allBadge: [],
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case BADGE.GET_ONE_BADGE_LOADING:
            return Object.assign({}, state, {
                oneBadge: [],
                message: {},
                status: "loading",
                code: null
            });
        case BADGE.GET_ONE_BADGE_LOADED:
            return Object.assign({}, state, {
                oneBadge: action.payload.badge,
                status: "loaded",
                message: {},
                code: null
            });
        case BADGE.GET_ONE_BADGE_ERROR:
            return Object.assign({}, state, {
                oneBadge: [],
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case BADGE.SAVE_NEW_BADGE_SAVING:
            return Object.assign({}, state, {
                message: {},
                status: "saving",
                code: null
            });
        case BADGE.SAVE_NEW_BADGE_SAVED:
            return Object.assign({}, state, {
                status: "saved",
                message: {},
                code: null
            });
        case BADGE.SAVE_NEW_BADGE_ERROR:
            return Object.assign({}, state, {
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case BADGE.SAVE_EDITED_BADGE_SAVING:
            return Object.assign({}, state, {
                message: {},
                status: "saving",
                code: null
            });
        case BADGE.SAVE_EDITED_BADGE_SAVED:
            return Object.assign({}, state, {
                status: "saved",
                message: {},
                code: null
            });
        case BADGE.SAVE_EDITED_BADGE_ERROR:
            return Object.assign({}, state, {
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case BADGE.SET_ACTIVE_DEACTIVE_SENDING:
            return Object.assign({}, state, {
                message: {},
                statusActivateDeactivate: "saving",
                code: null
            });
        case BADGE.SET_ACTIVE_DEACTIVE_SENDED:
            return Object.assign({}, state, {
                statusActivateDeactivate: "saved",
                message: {},
                code: null
            });
        case BADGE.SET_ACTIVE_DEACTIVE_ERROR:
            return Object.assign({}, state, {
                statusActivateDeactivate: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case BADGE.GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_LOADING:
            return Object.assign({}, state, {
                languagedBadge: [],
                languagedBadgeStatus: "loaded",
                message: {},
                code: null
            });
        case BADGE.GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_LOADED:
            return Object.assign({}, state, {
                languagedBadge: action.payload.data,
                languagedBadgeStatus: "loaded",
                message: {},
                code: null
            });
        case BADGE.GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_ERROR:
            return Object.assign({}, state, {
                languagedBadge: [],
                languagedBadgeStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case BADGE.BADGE_LANGUAGE_SAVEING:
            return Object.assign({}, state, {
                languagedBadgeSaveStatus: "saving",
                message: {},
                code: null
            });
        case BADGE.BADGE_LANGUAGE_SAVED:
            return Object.assign({}, state, {
                languagedBadgeSaveStatus: "saved",
                message: {},
                code: null
            });
        case BADGE.BADGE_LANGUAGE_SAVE_ERROR:
            return Object.assign({}, state, {
                languagedBadgeSaveStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        default:
            return state;
    }
};
export default badgeReducer;
