import React, { Component } from "react";
import { routes } from "../../constants/routes";
import ProtectedRoute from "../ProtectedRoute/protected-route";
import { Switch, Route } from "react-router";
import MissingRight from "./missing-right";

export default class Body extends Component {
  render() {
    return (
      <div>
        <Switch>
          {/* {this.props.landing && <NavLink to="/nt/landing" component={Landing} />}
        {this.props.natureTrail && <NavLink to="/nt/naure-trail" component={NatureTrail} />}
        {this.props.natureTrailLocation && <NavLink to="/nt/naure-trail-locations" component={NatureTrailLocations} />}
        {this.props.natureTrailObject && <NavLink to="/nt/naure-trail-objects" component={NatureTrailObjects} />}
        {this.props.adminUser && <NavLink to="/nt/landing" component={Admins} />}
        {this.props.gamers && <NavLink to="/nt/landing" component={Gamers} />}
        {this.props.language && <NavLink to="/nt/naure-trail-objects" component={Languages} />} */}
          {routes.map((current) => {
            return (
              <ProtectedRoute
                path={current.path}
                component={current.component}
                key={current.path}
                rights={current.rights}
              />
            );
          })}
          <Route path="/nt/missing-right" component={MissingRight} />
        </Switch>
      </div>
    );
  }
}
