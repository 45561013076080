import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllUser, getUserById, deleteUserById } from "../../actions/user";
import {Space, Button, Table, Popconfirm, Input} from "antd";
import GamerDataModal from "./gamer-data-modal";
import {CheckOutlined, CloseOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const mapDispatch = (dispatch) => {
  return {
    getAllUser: () => dispatch(getAllUser()),
    getUserById: (userId) => dispatch(getUserById(userId)),
    deleteUserById: (userId) => dispatch(deleteUserById(userId)),
  };
};

class ConnectedGamers extends Component {
  state = {
    users: [],
    visible: false,
    modalOpen: false,
    userNatureTrails: [],
    sortedInfo: {},
    pageSize: 10,
  };
  componentDidMount = async () => {
    await this.props.getAllUser();
    /* const users = this.props.users.map((user, i) => {
      return {
        key: user.id,
        id: user.id,
        email: user.email,
      };
    });
    const fileredUsers = users.filter(
      (user) => user.id !== this.props.actualUser.id
    );
    this.setState({ users: fileredUsers }); */
  };

  showUserData = async (row) => {
    await this.props.getUserById(row.id);
    const userNatureTrails = this.props.user.natureTrails.map((user, i) => {
      return {
        ...user,
        key: user.id,
      };
    });
    await this.setState({ userNatureTrails: userNatureTrails });
    this.setState({ modalOpen: true });
  };

  deleteUser = async (row) => {
    await this.props.deleteUserById(row.id);
    await this.props.getAllUser();
    /* const users = this.props.users.map((user, i) => {
      return {
        ...user,
        key: user.id,
      };
    });
    this.setState({ users: users }); */
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
                       setSelectedKeys,
                       selectedKeys,
                       confirm,
                       clearFilters,
                     }) => (
        <div style={{ padding: 8 }}>
          <Input
              ref={(node) => {
                this.searchInput = node;
              }}
              value={selectedKeys[0]}
              onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                  this.handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
              Keresés
            </Button>
            <Button
                onClick={() => this.handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
            >
              Törlés
            </Button>
          </Space>
        </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>

        record[dataIndex]
            ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
            />
        ) : (
            text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
      pageSize: pagination.pageSize,
    });
  };

  render() {
    const columns = [
      { title: "Email", dataIndex: "email", key: "email", ...this.getColumnSearchProps("email"),
        sorter: (a, b) => (a.email ?? "" ).localeCompare(b.email ?? "") },
      { title: "Név", dataIndex: "name", key: "name", ...this.getColumnSearchProps("name"),
        sorter: (a, b) => (a.name ?? "" ).localeCompare(b.name ?? "") },
      {
        title: "Közösségi",
        key: "social",
        render: (text, row) => (
          <Space size="middle">
            {row.appleId || row.facebookId ? (
              <CheckOutlined />
            ) : (
                <CloseOutlined />
              )}
          </Space>
        ),
      },
      /* {
        title: "Adatok",
        key: "showData",
        render: (text, row) => (
          <Space size="middle">
            <Button onClick={() => this.showUserData(row)}>Adatok</Button>
          </Space>
        ),
      }, */
      {
        title: "Törlés",
        key: "delete",
        render: (text, row) => (
          <Popconfirm
            title="Biztosan törölni akarja a felhasználót?"
            okText="Igen"
            cancelText="Nem"
            onConfirm={() => this.deleteUser(row)}
          >
            <Space size="middle">
              <Button>Törlés</Button>
            </Space>
          </Popconfirm>
        ),
      },
    ];
    return (
      <div>
        <h1>Játékosok</h1>
        <Table rowKey="id" columns={columns} dataSource={this.props.users} onChange={this.handleChangeTable} />
        <GamerDataModal
          open={this.state.modalOpen}
          close={this.closeModal}
          userId={this.state.id}
          userNatureTrails={this.state.userNatureTrails}
        />
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    message: state.userReducer.message,
    code: state.userReducer.code,
    locationAdminsStatus: state.userReducer.locationAdminsStatus,
    users: state.userReducer.users,
    user: state.userReducer.user,
    actualUser: state.loginReducer.user,
  };
};

const Gamers = connect(mapState, mapDispatch)(ConnectedGamers);
export default Gamers;
