import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Spin, Select, Button, message } from "antd";
import { getAllMobileEnv, modifyMobileEnv } from "../../actions/mobile-env";

const { Option } = Select;
const mapDispatchToProps = (dispatch) => {
  return {
    getAllMobileEnv: () => dispatch(getAllMobileEnv()),
    modifyMobileEnv: (env) => dispatch(modifyMobileEnv(env)),
  };
};

class ConnectedPushNotificationManager extends Component {
  state = {
    spinning: false,
    pushNotificationValue: 30,
    saveButtonIsActive: false,
  };

  componentDidMount = async () => {
    this.setState({ spinning: true });
    await this.props.getAllMobileEnv();
    if (this.props.status === "loaded") {
      if (this.props.envs[0]?.value)
        this.setState({
          pushNotificationValue: parseInt(this.props.envs[0].value),
        });
    }
    this.setState({ spinning: false });
  };
  handleChange = (value) => {
    if (parseInt(this.props.envs[0].value) === value) {
      this.setState({
        pushNotificationValue: value,
        saveButtonIsActive: false,
      });
    } else {
      this.setState({ pushNotificationValue: value, saveButtonIsActive: true });
    }
  };
  handleSaveChanges = async () => {
    this.setState({ spinning: true });
    const obj = {
      id: this.props.envs[0].id,
      key: this.props.envs[0].key,
      value: this.state.pushNotificationValue,
    };
    await this.props.modifyMobileEnv(obj);
    if (this.props.saveStatus && this.props.saveStatus === "saved") {
      message.success("Sikeres mentés!");
    } else {
      message.error("Hiba a mentésnél!");
    }
    await this.props.getAllMobileEnv();
    if (this.props.status === "loaded") {
      if (this.props.envs[0]?.value)
        this.setState({
          pushNotificationValue: parseInt(this.props.envs[0].value),
        });
    }
    this.setState({ saveButtonIsActive: false, spinning: false });
  };

  render() {
    return (
      <Spin
        spinning={this.state.spinning}
        className="customSpin"
        style={{
          zIndex: 100000000,
        }}
        size="large"
      >
        <div>
          <h1>Inaktivitási értesítő</h1>

          <Row gutter={[24, 24]}>
            <Col span={24}>
              A felhasználó automatikus értesítése, a lent megadott intervallumot elérő inaktivitás esetén.
              0 érték beállításakor az inaktivitás figyelő számlálója kikapcsolásra kerül.
            </Col>
            <Col span={6}>
              <Select
                value={this.state.pushNotificationValue}
                style={{
                  width: "100%",
                }}
                onChange={this.handleChange}
              >
                <Option key={0} value={0}>
                  0
                </Option>
                <Option key={15} value={15}>
                  15 nap
                </Option>
                <Option key={30} value={30}>
                  30 nap
                </Option>
                <Option key={45} value={45}>
                  45 nap
                </Option>
                <Option key={60} value={60}>
                  60 nap
                </Option>
              </Select>
            </Col>
            <Col span={2}>
              <Button
                onClick={this.handleSaveChanges}
                disabled={!this.state.saveButtonIsActive}
                type="primary"
              >
                Mentés
              </Button>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    envs: state.mobileEnvReducer.envs,
    status: state.mobileEnvReducer.status,
    saveStatus: state.mobileEnvReducer.saveStatus,
  };
};
const PushNotificationManager = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedPushNotificationManager);

export default PushNotificationManager;
