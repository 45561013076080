import axios from "axios";
import { USER } from "../constants/action-types";

export const getAllAdmin = () => {
  return async (dispatch) => {
    dispatch({ type: USER.GET_ALL_ADMIN_LOADING });
    await axios
      .get("/users/admins", {})
      .then((response) => {
        dispatch({
          type: USER.GET_ALL_ADMIN_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: USER.GET_ALL_ADMIN_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};

export const saveAdmin = (locationAdmin) => {
  return async (dispatch) => {
    dispatch({ type: USER.SAVE_ADMIN_START });
    await axios
      .post("/users/admins", locationAdmin)
      .then((response) => {
        dispatch({ type: USER.SAVE_ADMIN_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: USER.SAVE_ADMIN_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};

export const modifyAdmin = (locationAdmin) => {
  return async (dispatch) => {
    dispatch({ type: USER.SAVE_ADMIN_START });
    await axios
      .put("/users/admins/" + locationAdmin.id, locationAdmin)
      .then((response) => {
        dispatch({ type: USER.SAVE_ADMIN_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: USER.SAVE_ADMIN_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};

export const deleteAdmin = (id) => {
  return async () => {
    await axios
      .delete("/users/admins/" + id)
      .catch((err) => console.log(err));
  };
};
