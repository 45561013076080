import axios from "axios";
import { USER } from "../constants/action-types";

export const getUserNatureTrail = (userId) => {
    return async (dispatch) => {
        dispatch({ type: USER.USER_NATURETRAIL_LOADING });
        await axios
            .get("/users/user-nature-trails", {
                params: {
                    id: userId
                }
            })
            .then((response) => {
                dispatch({
                    type: USER.USER_NATURETRAIL_LOADED,
                    payload: response.data
                });
            })
            .catch((err) => {
                dispatch({
                    type: USER.USER_NATURETRAIL_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getAllUser = () => {
    return async (dispatch) => {
        dispatch({ type: USER.GET_ALL_USER_LOADING });
        await axios
            .get("/users/all-user", {
            })
            .then((response) => {
                dispatch({
                    type: USER.GET_ALL_USER_LOADED,
                    payload: response.data.users

                });
            })
            .catch((err) => {
                dispatch({
                    type: USER.GET_ALL_USER_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};

export const getUserById = (userId) => {
    return async (dispatch) => {
        dispatch({ type: USER.USER_DATA_LOADING });
        await axios
            .get("/users/user-by-id", {
                params: {
                    id: userId
                }
            })
            .then((response) => {
                dispatch({
                    type: USER.USER_DATA_LOADED,
                    payload: response.data

                });
            })
            .catch((err) => {
                dispatch({
                    type: USER.USER_DATA_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};

export const deleteUserById = (id) => {
    return async (dispatch) => {
        dispatch({ type: USER.USER_DELETE_SENDING });
        await axios
            .delete("/users/delete-user-by-id/" + id)
            .then((response) => {
                dispatch({
                    type: USER.USER_DELETE_SENDED
                });
            })
            .catch((err) => {
                dispatch({
                    type: USER.USER_DELETE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    }
};
