import { Button, Col, Input, Mentions, message, Popconfirm, Row } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendPushMessage } from '../../actions/nature-trail';
const mapDispatchToProps = (dispatch) => {
    return {
        sendPushMessage: (pushObj) => dispatch((sendPushMessage(pushObj))),

    };
};
class ConnectedSendPushMessage extends Component {

    state = {
        titleLimit: 60,
        descriptionLimit: 160,
        title: "Cím",
        description: "Üzenet:",
        titleValue: "",
        descriptionValue: ""
    }

    componentDidMount = () => {

    }

    changeTitle = (event) => {
        const actualValue = event.target.value
        if (actualValue.length <= this.state.titleLimit) {
            this.setState({ titleValue: actualValue })
        } else {
            message.warning("A cím nem lehet több " + this.state.titleLimit + " karakternél.")
        }
    }

    changeDescription = (event) => {
        const actualValue = event
        if (actualValue.length <= this.state.descriptionLimit) {
            this.setState({ descriptionValue: actualValue })
        } else {
            message.warning("A cím nem lehet több " + this.state.titleLimit + " karakternél.")
        }
    }

    sendPushMessage = async () => {
        // if (this.state.titleValue.trim() === "") {
        //     message.error("A cím nem amradhat üresen!")
        // }
        if (this.state.descriptionValue.trim() === "") {
            message.error("A leírás nem maradhat üresen!")
        } else {
            const sendObj = {
                natureTrailId: this.props.natureTrailId,
                // title: this.state.titleValue,
                description: this.state.descriptionValue
            }
            await this.props.sendPushMessage(sendObj)
            if (this.props.pushSendingStatus === 'sended') {
                message.success("Sikeresen kiküldve a felhasználóknak!")
            } else {
                message.error("Hiba lépett fel a kiküldés közben.")
            }
        }
    }
    render() {
        return <div>
            {/* <h4>Közvetlen üzenet küldése</h4> */}
            <Row align="middle" justify="center">
                <Col span={12}>
                    <Row gutter={[16, 16]}>
                        {/* <Col span={24}><h3>{this.state.title}</h3></Col>
                        <Col span={24}> 
                             <Input
                                onChange={this.changeTitle}
                                value={this.state.titleValue}
                            />
                            <p>
                                <strong>Hátralévő karekterek: </strong>{this.state.titleLimit}/{this.state.titleValue.length}
                            </p>
                        </Col> */}
                        <Col span={24}><h3>{this.state.description}</h3></Col>
                        <Col span={24}>
                            <Mentions
                                rows={3}
                                onChange={this.changeDescription}
                                value={this.state.descriptionValue}
                            />
                            <p>
                                <strong>Hátralévő karekterek: </strong>{this.state.descriptionLimit}/{this.state.descriptionValue.length}
                            </p>
                        </Col>
                    </Row>
                    <Row align="middle" justify="center">
                        <Col>
                            <Popconfirm
                                title="Biztosan kiküldi az üzenetet?"
                                okText="Igen"
                                cancelText="Nem"
                                onConfirm={this.sendPushMessage}
                            >
                                <Button type="primary" >Küldés</Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </Col>


            </Row>

        </div>;
    }
}
const mapStateToProps = (state) => ({
    pushSendingStatus: state.natureTrailReducer.pushSendingStatus
});
const SendPushMessage = connect(mapStateToProps, mapDispatchToProps)(ConnectedSendPushMessage);
export default SendPushMessage;