import axios from "axios";
import { MOBILE_ENV } from "../constants/action-types";

export const getAllMobileEnv = () => {
  return async (dispatch) => {
    dispatch({ type: MOBILE_ENV.GET_ALL_MOBILE_ENV_LOADING });
    await axios
      .get("/mobile-environment", {})
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: MOBILE_ENV.GET_ALL_MOBILE_ENV_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: MOBILE_ENV.GET_ALL_MOBILE_ENV_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const modifyMobileEnv = (obj) => {
  return async (dispatch) => {
    dispatch({ type: MOBILE_ENV.SAVE_MOBILE_ENV_START });
    await axios
      .put("/mobile-environment", obj)
      .then((response) => {
        dispatch({ type: MOBILE_ENV.SAVE_MOBILE_ENV_FINISH });
      })
      .catch((err) => {
        dispatch({
          type: MOBILE_ENV.SAVE_MOBILE_ENV_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
