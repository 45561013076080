import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLanguagesList } from '../../actions/language';
import { Row, Col, Table, Space } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
;
const mapDispatchToProps = (dispatch) => {
    return {
        getLanguagesList: () => dispatch(getLanguagesList()),

    };
};
class ConnectedLanguages extends Component {
    componentDidMount = async () => {
        await this.props.getLanguagesList();
    }
    render() {
        const columns = [
            { title: 'Nyelv teljes neve', dataIndex: 'name', key: 'name' },
            { title: 'Nyelv kód', dataIndex: 'code', key: 'code' },
            {
                title: 'Zászló', dataIndex: 'flag', key: 'flag', width: "10vw", render: (rating, record) => {
                    return (
                        <div >
                            {record.flagURL !== undefined && record.flagURL !== null && record.flagURL !== "" ?
                                <Space style={{ width: "10px" }} >
                                    <img
                                        src={
                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + record.flagURL}
                                        alt="Hiányzó kép"
                                        width="30px"
                                    />
                                </Space> :
                                <div >
                                    <FileImageOutlined size="5em" />Nincs kép
                                </div>
                            }
                        </div>
                    )
                }
            }
        ]
        return <div>
            <h1> Elérhető nyelvek</h1>
            <Row gutter={24}>
                <Col span={12}>
                    <Table
                        //scroll={{ y: '100%', x: 'max-content' }}
                        key="id"
                        size="small"
                        overflowY="auto"
                        columns={columns}
                        dataSource={this.props.languages}
                    />
                </Col>
            </Row>

        </div>;
    }
}
const mapStateToProps = (state) => {
    return {
        message: state.languagesReducer.message,
        code: state.languagesReducer.code,
        languages: state.languagesReducer.languages,
        languagesStatus: state.languagesReducer.languagesStatus,
    }
};
const Languages = connect(mapStateToProps, mapDispatchToProps)(ConnectedLanguages);
export default Languages;