import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Row, Space, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createOneNatureTrailType, getAllNatureTrailTypes, setActiveodDeactive, updateNatureTrailType } from '../../actions/nature-trail';
const mapDispatchToProps = (dispatch) => {
    return {
        getAllNatureTrailTypes: () => dispatch(getAllNatureTrailTypes()),
        createOneNatureTrailType: () => dispatch(createOneNatureTrailType()),
        setActiveodDeactive: (id) => dispatch(setActiveodDeactive(id)),
        updateNatureTrailType: (natureTrailType) => dispatch(updateNatureTrailType(natureTrailType)),
    };
};
class ConnectedNatureTrailTypeEditor extends Component {
    state = {
        columns: [
            {
                title: 'Név',
                dataIndex: 'value',

            },
            {
                title: "Aktív",
                key: "delete",
                render: (text, row) => (
                    <Space size="middle">
                        <Checkbox onChange={() => this.activateDeactivate(row)} checked={row.isActive} ></Checkbox>

                    </Space>
                ),
            },
            {
                title: "Módosít",
                key: "edit",
                render: (text, row) => (
                    <Space size="middle">
                        <Button onClick={() => this.showDataAndEdit(row)} icon={<EditOutlined />} shape="circle"></Button>
                    </Space>
                ),
            },
        ],
        selectedObj: {},
        tableData: []
    }
    componentDidMount = async () => {
        this.refrestTable()
    }

    addNewNatureTailType = async () => {
        await this.props.createOneNatureTrailType();
        this.refrestTable()
    }

    selectRow = (record, rowIndex) => {
        this.setState()
    }

    activateDeactivate = async (row) => {
        await this.props.setActiveodDeactive(row.id)
        this.refrestTable()
    }

    showDataAndEdit = (row) => {
        this.setState({ selectedObj: Object.assign({}, row) })
    }
    refrestTable = async () => {
        await this.props.getAllNatureTrailTypes()
        this.setState({ tableData: this.props.naturalTrailTypes })
    }

    changeSelectedTypeValue = (event) => {
        const actValue = event.target.value
        const obj = this.state.selectedObj
        obj.value = actValue
        this.setState({
            selectedObj: obj
        })
    }

    saveActualType = async () => {
        await this.props.updateNatureTrailType(this.state.selectedObj)
        this.refrestTable()
    }

    render() {
        return <div>
            <div>
                <h1>Tanösvény tipusok</h1>
                <Row gutter={24} style={{ height: "5vh" }}>
                    <Button type="primary" onClick={this.addNewNatureTailType}> <PlusCircleOutlined />Új Tanösvény tipus</Button>
                </Row>
                <Row gutter={24} style={{ height: "60vh" }}>
                    <Col span={8}>
                        <Table
                            scroll={{ y: '60vh', x: 'max-content' }}
                            size="small"
                            overflowY="auto"
                            rowKey="id"
                            dataSource={this.state.tableData}
                            columns={this.state.columns}
                            pagination={false}
                        />
                    </Col>
                    <Col span={16}>
                        {this.state.selectedObj.value && <Row gutter={[24, 16]}>
                            <Col span={24}><b>Típus név:</b></Col>
                            <Col span={24}>
                                <Input value={this.state.selectedObj.value} onChange={this.changeSelectedTypeValue}></Input>
                            </Col>
                            <Col span={24}> <Button onClick={this.saveActualType}>Mentés</Button></Col>
                        </Row>}
                    </Col>
                </Row>
            </div>
        </div>;
    }
}
const mapStateToProps = (state) => ({
    naturalTrailTypes: state.natureTrailReducer.allNaturalTrailTypes
});
const NatureTrailTypeEditor = connect(mapStateToProps, mapDispatchToProps)(ConnectedNatureTrailTypeEditor);
export default NatureTrailTypeEditor;