import { CONTENT } from "../constants/action-types";

const initialState = {
    movie: {},
    moviestatus: null,
    sound: {},
    soundStatus: null,
    d3d: {},
    d3dStatus: null,
    message: null,
    code: null
};

const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTENT.MOVIE_LOADING:
            return Object.assign({}, state, {
                movie: {},
                moviestatus: "loading",
                message: null,
                code: null
            })
        case CONTENT.MOVIE_LOADED:
            return Object.assign({}, state, {
                movie: action.payload.data,
                moviestatus: 'laoded',
                message: null,
                code: null
            })
        case CONTENT.MOVIE_ERROR:
            return Object.assign({}, state, {
                movie: {},
                moviestatus: 'error',
                message: action.payload.message,
                code: action.payload.code
            })
        case CONTENT.SOUND_LOADING:
            return Object.assign({}, state, {
                sound: {},
                soundStatus: "loading",
                message: null,
                code: null
            })
        case CONTENT.SOUND_LOADED:
            return Object.assign({}, state, {
                sound: action.payload.data,
                soundStatus: 'laoded',
                message: null,
                code: null
            })
        case CONTENT.SOUND_ERROR:
            return Object.assign({}, state, {
                sound: {},
                soundStatus: 'error',
                message: action.payload.message,
                code: action.payload.code
            })
        case CONTENT.D3D_LOADING:
            return Object.assign({}, state, {
                d3d: {},
                d3dStatus: "loading",
                message: null,
                code: null
            })
        case CONTENT.D3D_LOADED:
            return Object.assign({}, state, {
                d3d: action.payload.data,
                d3dStatus: 'laoded',
                message: null,
                code: null
            })
        case CONTENT.D3D_ERROR:
            return Object.assign({}, state, {
                d3d: {},
                d3dStatus: 'error',
                message: action.payload.message,
                code: action.payload.code
            })
        default:
            return state;
    }
};

export default contentReducer;