import React, { Component } from 'react'
import Axios from 'axios';
;

export default class TermsOfUseEN extends Component {
    state = {
        html: ""
    }

    componentDidMount = async () => {
        const asd = await Axios.get(process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + "/files/get-html?htmlName=terms_en.html")
        this.setState({ html: asd.data })
    }

    render() {
        return (
            <div dangerouslySetInnerHTML={{ __html: this.state.html }}>

            </div>
        )
    }
}
