import { FORGOTPASSWORD } from "../constants/action-types";

const initialState = {
    status: null,
    message: {},
    code: null
};

const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORGOTPASSWORD.FORGOTPASSWORD_LOADING:
            return Object.assign({}, state, {
                message: {},
                status: "sending",
                code: null
            });
        case FORGOTPASSWORD.FORGOTPASSWORD_LOADED:
            return Object.assign({}, state, {
                status: "sended",
                message: {},
                code: null
            });
        case FORGOTPASSWORD.FORGOTPASSWORD_ERROR:
            return Object.assign({}, state, {
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        default:
            return state;
    }
};

export default forgotPasswordReducer;
