import axios from "axios";
import { LANGUAGES } from "../constants/action-types";

export const getLanguagesList = () => {
    return async (dispatch) => {
        dispatch({ type: LANGUAGES.LANGUAGES_CODE_LOADING });
        await axios
            .get("languages/code", {})
            .then(({ data }) => {
                dispatch({
                    type: LANGUAGES.LANGUAGES_CODE_LOADED,
                    payload: { data: data.languages },
                });
            })
            .catch((err) => {
                dispatch({
                    type: LANGUAGES.LANGUAGES_CODE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getNatureTrailByLanguageCode = (natureTrailid, userId, userLanguage) => {
    return async (dispatch) => {
        dispatch({ type: LANGUAGES.NATURE_TRAIL_BY_LAGUAGE_CODE_LOADING });
        await axios
            .get("nature-trail/nature-trails-by-id-and-language-code", {
                params: {
                    id: natureTrailid,
                    userId: userId,
                    userLanguages: userLanguage
                }
            })
            .then(({ data }) => {
                dispatch({
                    type: LANGUAGES.NATURE_TRAIL_BY_LAGUAGE_CODE_LOADED,
                    payload: { data: data.natureTrail },
                });
            })
            .catch((err) => {
                dispatch({
                    type: LANGUAGES.NATURE_TRAIL_BY_LAGUAGE_CODE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getLangugedQuestionAnswersByIdAndLanguageCode = (questionId, languageCode) => {
    return async (dispatch) => {
        dispatch({ type: LANGUAGES.LANGUAGED_QUESTION_LOADING });
        await axios
            .get("languages/lanugaged-question-ansers-by-id-and-langugage-code", {
                params: {
                    questionId: questionId,
                    languageCode: languageCode
                }
            })
            .then(({ data }) => {
                dispatch({
                    type: LANGUAGES.LANGUAGED_QUESTION_LOADED,
                    payload: { data: data.questionAnswers },
                });
            })
            .catch((err) => {
                dispatch({
                    type: LANGUAGES.LANGUAGED_QUESTION_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getLangugedPairMatchByIdAndLanguageCode = (pairMatchId, languageCode) => {
    return async (dispatch) => {
        dispatch({ type: LANGUAGES.LANGUAGED_PAIR_MATCH_LOADING });
        await axios
            .get("languages/lanugaged-pair-match-by-id-and-langugage-code", {
                params: {
                    pairMatchId: pairMatchId,
                    languageCode: languageCode
                }
            })
            .then(({ data }) => {
                dispatch({
                    type: LANGUAGES.LANGUAGED_PAIR_MATCH_LOADED,
                    payload: { data: data.pairMatches },
                });
            })
            .catch((err) => {
                dispatch({
                    type: LANGUAGES.LANGUAGED_PAIR_MATCH_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};

export const saveNatureTraiLanguages = (languageFile, files) => {
    const formData = new FormData();
    //files

    languageFile.languageObj.natureTrailLocation.forEach(element => {
        if (element.movieFile) {
            formData.append("movie", element.movieFile[0]);
        }
        if (element.musicFile) {
            formData.append("music", element.musicFile[0]);
        }
        if (element.questionnaires.length > 0) {
            element.questionnaires.forEach(async questionnaires => {
                if (questionnaires.musicFile) {
                    formData.append("questionMusic", questionnaires.musicFile[0]);
                }
                if (questionnaires.answers && questionnaires.answers.length > 0) {
                    console.log(questionnaires.answers);
                    questionnaires.answers.map(async answers => {
                        if (answers.musicFile) {
                            console.log("VAN FILE");
                            formData.append("answerMusic", answers.musicFile[0]);
                        }
                    })
                }
            }

            )
        }
    }
    )
    //data
    formData.append("languageFile", JSON.stringify(languageFile));

    return async (dispatch) => {
        dispatch({ type: LANGUAGES.LANGUAGE_SAVING });
        await axios
            .post("/languages/create-language", formData,
        )
            .then((response) => {
                dispatch({
                    type: LANGUAGES.LANGUAGE_SAVED,
                    payload: { data: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: LANGUAGES.LANGUAGE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}

export const activeDeactiveLanguagedNatureTrail = (natureTrailId, languageCode) => {
    return async (dispatch) => {
        dispatch({ type: LANGUAGES.NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_LOADING });
        await axios
            .get("languages/active-check", {
                params: {
                    natureTrailId: natureTrailId,
                    languageCode: languageCode
                }
            })
            .then(({ data }) => {
                dispatch({
                    type: LANGUAGES.NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_LOADED,
                    payload: { data: data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: LANGUAGES.NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};