import { NATURETRAIL } from "../constants/action-types";

const initialState = {
    data: [],
    status: null,
    message: {},
    code: null,
    natureTrailLocations: [],
    natureTrailLocationsStatus: null,
    naturalTrailTypes: [],
    naturalTrailTypesStatus: null,
    allNaturalTrailTypes: [],
    allNaturalTrailTypesStatus: null,
    deleteMessage: {},
    deleteStatus: null,
    deleteCode: null,
    locationSavingStatus: null,
    savedNatureTrailLocation: {},
    triggerTypes: [],
    triggerTypesStatus: null,
    nationalParks: [],
    nationalParksStatus: null,
    regions: [],
    regionsStatus: null,
    ratings: [],
    ratingStatus: null,
    comments: [],
    commentsStatus: null,
    natureTrailLocation: {},
    natureTrailLocationStatus: null,
    testMessage: {},
    testStatus: null,
    testCode: null,
    createdType: {},
    createdTypeStatus: null,
    updateNaturalTrailTypesStatus: null,
    allNationalParks: [],
    allNationalParksStatus: null,
    nationalParkCreatingStatus: null,
    nationalParkSavingStatus: null,
    pushSendingStatus: null,
    owner1SaveStatus: null,
    owner2SaveStatus: null,
};

const natureTrailReducer = (state = initialState, action) => {
    switch (action.type) {
        case NATURETRAIL.NATURETRAIL_LOADING:
            return Object.assign({}, state, {
                data: [],
                message: {},
                status: "loading",
                code: null
            });
        case NATURETRAIL.NATURETRAIL_LOADED:
            return Object.assign({}, state, {
                data: action.payload.data,
                status: "loaded",
                message: {},
                code: null
            });
        case NATURETRAIL.NATURETRAIL_ERROR:
            return Object.assign({}, state, {
                data: [],
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.NATURETRAIL_SAVE_SENDING:
            return Object.assign({}, state, {
                //user: {},
                message: {},
                status: "saving",
                code: null
            });
        case NATURETRAIL.NATURETRAIL_SAVE_SENDED:
            return Object.assign({}, state, {
                // naureTrail: action.payload.user,
                status: "saved",
                message: {},
                code: null
            });
        case NATURETRAIL.NATURETRAIL_SAVE_ERROR:
            return Object.assign({}, state, {
                // user: {},
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_BY_ID_LOADING:
            return Object.assign({}, state, {
                natureTrailLocations: [],
                natureTrailLocationsStatus: "loading",
                message: {},
                code: null
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_BY_ID_LOADED:
            return Object.assign({}, state, {
                natureTrailLocations: action.payload.data,
                natureTrailLocationsStatus: "loaded",
                message: {},
                code: null
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_BY_ID_ERROR:
            return Object.assign({}, state, {
                natureTrailLocations: [],
                natureTrailLocationsStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.NATURETRAIL_TYPE_LOADING:
            return Object.assign({}, state, {
                naturalTrailTypes: [],
                message: {},
                naturalTrailTypesStatus: "loading",
                code: null
            });
        case NATURETRAIL.NATURETRAIL_TYPE_LOADED:
            return Object.assign({}, state, {
                naturalTrailTypes: action.payload.data.natureTrailTypes,
                naturalTrailTypesStatus: "loaded",
                message: {},
                code: null
            });
        case NATURETRAIL.NATURETRAIL_TYPE_ERROR:
            return Object.assign({}, state, {
                naturalTrailTypes: [],
                naturalTrailTypesStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.ALL_NATURETRAIL_TYPE_LOADING:
            return Object.assign({}, state, {
                allNaturalTrailTypes: [],
                message: {},
                allNaturalTrailTypesStatus: "loading",
                code: null
            });
        case NATURETRAIL.ALL_NATURETRAIL_TYPE_LOADED:
            return Object.assign({}, state, {
                allNaturalTrailTypes: action.payload.data.natureTrailTypes,
                allNaturalTrailTypesStatus: "loaded",
                message: {},
                code: null
            });
        case NATURETRAIL.ALL_NATURETRAIL_TYPE_ERROR:
            return Object.assign({}, state, {
                allNaturalTrailTypes: [],
                allNaturalTrailTypesStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.NATURETRAIL_TYPE_UPDATE_SAVING:
            return Object.assign({}, state, {
                message: {},
                updateNaturalTrailTypesStatus: "saving",
                code: null
            });
        case NATURETRAIL.NATURETRAIL_TYPE_UPDATE_SAVED:
            return Object.assign({}, state, {
                updateNaturalTrailTypesStatus: "saved",
                message: {},
                code: null
            });
        case NATURETRAIL.NATURETRAIL_TYPE_UPDATE_ERROR:
            return Object.assign({}, state, {
                updateNaturalTrailTypesStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.NATURETRAIL_DELETE_SENDING:
            return Object.assign({}, state, {
                deleteMessage: {},
                deleteStatus: 'sending',
                deleteCode: null
            });
        case NATURETRAIL.NATURETRAIL_DELETE_SENDED:
            return Object.assign({}, state, {
                deleteMessage: {},
                deleteStatus: 'success',
                deleteCode: null
            });
        case NATURETRAIL.NATURETRAIL_DELETE_ERROR:
            return Object.assign({}, state, {
                deleteStatus: 'error',
                deleteMessage: action.payload.message,
                deleteCode: action.payload.code
            });
        case NATURETRAIL.NATURETRAIL_TEST_SENDING:
            return Object.assign({}, state, {
                testMessage: {},
                testStatus: 'sending',
                testCode: null
            });
        case NATURETRAIL.NATURETRAIL_TEST_SENDED:
            return Object.assign({}, state, {
                testMessage: {},
                testStatus: 'success',
                testCode: null
            });
        case NATURETRAIL.NATURETRAIL_TEST_ERROR:
            return Object.assign({}, state, {
                testStatus: 'error',
                testMessage: action.payload.message,
                testCode: action.payload.code
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_SAVING:
            return Object.assign({}, state, {
                savedNatureTrailLocation: {},
                message: {},
                locationSavingStatus: "sending",
                code: null
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_SAVED:
            return Object.assign({}, state, {
                savedNatureTrailLocation: action.payload.data,
                message: {},
                locationSavingStatus: "sended",
                code: null
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_SAVE_ERROR:
            return Object.assign({}, state, {
                savedNatureTrailLocation: {},
                triggerTypesStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.TRIGGERTYPES_LOADING:
            return Object.assign({}, state, {
                triggerTypes: [],
                triggerTypesStatus: "loading",
                message: {},
                code: null
            });
        case NATURETRAIL.TRIGGERTYPES_LOADED:
            return Object.assign({}, state, {
                triggerTypes: action.payload.data,
                triggerTypesStatus: "loaded",
                message: {},
                code: null
            });
        case NATURETRAIL.TRIGGERTYPES_ERROR:
            return Object.assign({}, state, {
                triggerTypes: [],
                triggerTypesStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.REGION_LOADING:
            return Object.assign({}, state, {
                regions: [],
                regionsStatus: "loading",
                message: {},
                code: null
            });
        case NATURETRAIL.REGION_LOADED:
            return Object.assign({}, state, {
                regions: action.payload.data,
                regionsStatus: "loaded",
                message: {},
                code: null
            });
        case NATURETRAIL.REGION_ERROR:
            return Object.assign({}, state, {
                regions: [],
                regionsStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.NATIONAL_PARK_LOADING:
            return Object.assign({}, state, {
                nationalParks: [],
                nationalParksStatus: "loading",
                message: {},
                code: null
            });
        case NATURETRAIL.NATIONAL_PARK_LOADED:
            return Object.assign({}, state, {
                nationalParks: action.payload.data,
                nationalParksStatus: "loaded",
                message: {},
                code: null
            });
        case NATURETRAIL.NATIONAL_PARK_ERROR:
            return Object.assign({}, state, {
                nationalParks: [],
                nationalParksStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.ALL_NATIONAL_PARK_LOADING:
            return Object.assign({}, state, {
                allNationalParks: [],
                allNationalParksStatus: "loading",
                message: {},
                code: null
            });
        case NATURETRAIL.ALL_NATIONAL_PARK_LOADED:
            return Object.assign({}, state, {
                allNationalParks: action.payload.data,
                allNationalParksStatus: "loaded",
                message: {},
                code: null
            });
        case NATURETRAIL.ALL_NATIONAL_PARK_ERROR:
            return Object.assign({}, state, {
                allNationalParks: [],
                allNationalParksStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_BY_LOCATION_ID_LOADING:
            return Object.assign({}, state, {
                natureTrailLocation: {},
                natureTrailLocationStatus: "loading",
                message: {},
                code: null
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_BY_LOCATION_ID_LOADED:
            return Object.assign({}, state, {
                natureTrailLocation: action.payload.data,
                natureTrailLocationStatus: "loaded",
                message: {},
                code: null
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_BY_LOCATION_ID_ERROR:
            return Object.assign({}, state, {
                natureTrailLocation: [],
                natureTrailLocationStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_DELETE_SENDING:
            return Object.assign({}, state, {
                message: {},
                deleteStatus: 'deleting',
                code: null
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_DELETE_SENDED:
            return Object.assign({}, state, {
                message: {},
                deleteStatus: 'deleted',
                code: null
            });
        case NATURETRAIL.NATURETRAIL_LOCATION_DELETE_ERROR:
            return Object.assign({}, state, {
                message: {},
                deleteStatus: 'error',
                code: null
            });
        case NATURETRAIL.NATURETRAIL_RATING_BY_ID_LOADING:
            return Object.assign({}, state, {
                ratings: [],
                message: {},
                ratingStatus: 'loading',
                code: null
            });
        case NATURETRAIL.NATURETRAIL_RATING_BY_ID_LOADED:
            return Object.assign({}, state, {
                ratings: action.payload.ratings,
                message: {},
                ratingStatus: 'loaded',
                code: null
            });
        case NATURETRAIL.NATURETRAIL_RATING_BY_ID_ERROR:
            return Object.assign({}, state, {
                ratings: [],
                message: action.payload.message,
                ratingStatus: 'loaded',
                code: action.payload.code
            });
        case NATURETRAIL.NATURETRAIL_COMMENTS_BY_ID_LOADING:
            return Object.assign({}, state, {
                comments: [],
                message: {},
                commentsStatus: 'loading',
                code: null
            });
        case NATURETRAIL.NATURETRAIL_COMMENTS_BY_ID_LOADED:
            return Object.assign({}, state, {
                comments: action.payload.comments,
                message: {},
                ratingStatus: 'loaded',
                code: null
            });
        case NATURETRAIL.NATURETRAIL_COMMENTS_BY_ID_ERROR:
            return Object.assign({}, state, {
                comments: [],
                message: action.payload.message,
                commentstatus: 'loaded',
                code: action.payload.code
            });
        case NATURETRAIL.NATURETRAIL_TYPE_CREATE_SAVING:
            return Object.assign({}, state, {
                createdType: {},
                message: {},
                createdTypeStatus: 'saving',
                code: null
            });
        case NATURETRAIL.NATURETRAIL_TYPE_CREATE_SAVED:
            return Object.assign({}, state, {
                createdType: action.payload.createdType,
                message: {},
                createdTypeStatus: 'saved',
                code: null
            });
        case NATURETRAIL.NATURETRAIL_TYPE_CREATE_ERROR:
            return Object.assign({}, state, {
                createdType: {},
                message: {},
                createdTypeStatus: 'error',
                code: null
            });
        case NATURETRAIL.NATIONAL_PARK_SAVING:
            return Object.assign({}, state, {
                message: {},
                nationalParkSavingStatus: 'saving',
                code: null
            });
        case NATURETRAIL.NATIONAL_PARK_SAVED:
            return Object.assign({}, state, {
                message: {},
                nationalParkSavingStatus: 'saved',
                code: null
            });
        case NATURETRAIL.NATIONAL_PARK_SAVING_ERROR:
            return Object.assign({}, state, {
                message: {},
                nationalParkSavingStatus: 'error',
                code: action.payload.code
            });
        case NATURETRAIL.NATIONAL_PARK_CREATING:
            return Object.assign({}, state, {
                message: {},
                nationalParkCreatingStatus: 'saving',
                code: null
            });
        case NATURETRAIL.NATIONAL_PARK_CREATED:
            return Object.assign({}, state, {
                message: {},
                nationalParkCreatingStatus: 'saved',
                code: null
            });
        case NATURETRAIL.NATIONAL_PARK_CREATING_ERROR:
            return Object.assign({}, state, {
                message: {},
                nationalParkCreatingStatus: 'error',
                code: action.payload.code
            });
        case NATURETRAIL.PUSH_SENDING:
            return Object.assign({}, state, {
                message: {},
                pushSendingStatus: 'sending',
                code: null
            });
        case NATURETRAIL.PUSH_SENDED:
            return Object.assign({}, state, {
                message: {},
                pushSendingStatus: 'sended',
                code: null
            });
        case NATURETRAIL.PUSH_ERROR:
            return Object.assign({}, state, {
                message: {},
                pushSendingStatus: 'error',
                code: action.payload.code
            });
        case NATURETRAIL.OWNER_1_SAVING:
            return Object.assign({}, state, {
                message: {},
                owner1SaveStatus: 'saving',
                code: null
            });
        case NATURETRAIL.OWNER_1_SAVED:
            return Object.assign({}, state, {
                message: {},
                owner1SaveStatus: 'saved',
                code: null
            });
        case NATURETRAIL.OWNER_1_ERROR:
            return Object.assign({}, state, {
                message: {},
                owner1SaveStatus: 'error',
                code: action.payload.code
            });
        case NATURETRAIL.OWNER_2_SAVING:
            return Object.assign({}, state, {
                message: {},
                owner2SaveStatus: 'saving',
                code: null
            });
        case NATURETRAIL.OWNER_2_SAVED:
            return Object.assign({}, state, {
                message: {},
                owner2SaveStatus: 'saved',
                code: null
            });
        case NATURETRAIL.OWNER_2_ERROR:
            return Object.assign({}, state, {
                message: {},
                owner2SaveStatus: 'error',
                code: action.payload.code
            });
        default:
            return state;
    }
};

export default natureTrailReducer;
