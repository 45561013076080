import React, { Component } from 'react'
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import { Col, Input, Tabs, Divider, Row } from 'antd'
import ReactPlayer from 'react-player'

import { getMovieContent, deleteMovieFile } from '../../actions/content'
import { connect } from 'react-redux'


const { TabPane } = Tabs;

const mapDispatch = (dispatch) => {
    return {
        getMovieContent: (locationId) => dispatch(getMovieContent(locationId)),
        deleteMovieFile: (locationId) => dispatch(deleteMovieFile(locationId)),
    }
}

class ConnectedLocationVideoContent extends Component {
    state = {
        videoFromUser: "",
        youtubeLink: "",
        movieLink: "",
        isUploadedMovie: false,
        key: "1",
        isYoutube: false
    }

    componentDidMount = async () => {
        if (this.props.natureTrailId) {
            await this.props.getMovieContent(this.props.natureTrailId)
        }
        if (this.props.movieFileURL !== undefined && this.props.movieFileURL !== null && this.props.isYoutube !== undefined && this.props.isYoutube === false) {
            this.setState({ videoFromUser: process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.movieFileURL, isUploadedMovie: false, key: "1" })
        }
        else {
            if (this.props.isYoutube !== undefined && this.props.isYoutube === true) {
                this.setState({ videoFromUser: "", youtubeLink: this.props.movieFileURL, isUploadedMovie: false, key: "2" })
            }
            else {
                this.setState({ videoFromUser: "", isUploadedMovie: false, key: "1" })
            }
        }
        this.setPreview()
    }
    //2020.08.29
    setPreview = () => {
        if (this.props.video[0] !== undefined && this.props.isYoutube === false) {
            const videoTeszt = URL.createObjectURL(this.props.video[0])
            this.setState({ videoFromUser: videoTeszt })
        }
        else if (this.props.youtubeLink !== "" && this.props.isYoutube === true) {
            this.setState({ videoFromUser: "", youtubeLink: this.props.youtubeLink })
        }
        else if (this.props.movie.movieFileURL !== undefined && this.props.movie.movieFileURL !== "" && this.props.movie.movieFileURL !== null && this.props.isYoutube === false) {
            this.setState({ videoTeszt: process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.movie.movieFileURL })
        }
        else if (
            this.props.movie.movieFileURL !== ""
            && this.props.movie.movieFileURL !== null
            && this.props.isYoutube === true
            && this.props.youtubeLink === "") {
            this.setState({ videoFromUser: "", youtubeLink: this.props.movie.movieFileURL })
        }
        else {
            this.setState({ videoFromUser: "", youtubeLink: "", isUploadedMovie: false })
        }


    }
    handleChange = info => {
        if (this.props.video[0] !== undefined) {
            const videoTeszt = URL.createObjectURL(this.props.video[0])
            this.setState({ videoFromUser: videoTeszt, isUploadedMovie: true, isYoutube: false }, () => { this.props.setIsYoutube(false) })
        }
        else {
            if (this.props.movieFileURL !== undefined && this.props.movieFileURL !== null) {
                this.setState({ videoFromUser: process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.movieFileURL, isUploadedMovie: false })
            }
            else {
                this.setState({ videoFromUser: "", isUploadedMovie: false })
            }

        }
    };

    setYoutubeVideo = (event) => {
        const link = event.target.value
        this.setState({ youtubeLink: link, isUploadedMovie: false, isYoutube: true }, () => { this.props.setYoutubeLink(this.state.youtubeLink) })
        this.props.setIsYoutube(true);

    }

    changeTab = (key) => {
        this.setState({
            key: key
        }, () => {
            if (this.state.key === "1") {
                this.props.setIsYoutube(false)
                this.setState({ isYoutube: false })
            }
            if (this.state.key === "2") {
                this.props.setIsYoutube(true)
                this.setState({ isYoutube: true })
                this.props.setYoutubeLink(this.state.youtubeLink)

            }
        })
    }

    handelDeleteMovie = async () => {
        this.props.removeVideo();
        if (this.props.natureTrailId && this.state.key === "1") {
            await this.props.deleteMovieFile(this.props.natureTrailId)
        }
        this.setState({ videoFromUser: "" })
    }

    render() {
        return (
            <div>
                <Tabs defaultActiveKey={this.props.isYoutube === true ? "2" : "1"} onChange={this.changeTab} >
                    <TabPane tab="Helyi file feltölése" key="1">
                        <Row gutter={[24, 24]} align="middle" justify="center" style={{ textAlign: "-webkit-center" }}>
                            <Divider />
                            <Col span={12}>
                                <Dragger
                                    style={{ maxHeight: "10em" }}
                                    name="file"
                                    multiple={true}
                                    beforeUpload={this.props.setVideo}
                                    fileList={this.props.video}
                                    showUploadList={false}
                                    listType={"text"}
                                    onRemove={this.props.removeVideo}
                                    // onPreview={this.handlePreview}
                                    onChange={this.handleChange}
                                    accept=".mp4, .mov"
                                ><div>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Új video feltölése</p>
                                        <p className="ant-upload-text">.mp4, .mov</p>
                                    </div>
                                </Dragger>
                            </Col >
                            <Col span={2}>
                                <DeleteOutlined onClick={this.handelDeleteMovie} />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="YoutubeLink" key="2" >
                        <div style={{ justifyContent: "center", justifyItems: "center", alignItems: "center" }}>
                            <Divider />
                            <Input value={this.state.youtubeLink} onChange={this.setYoutubeVideo}></Input>
                        </div>
                    </TabPane>
                </Tabs>
                <Divider plain>Alkalmazásban megjelenő videó</Divider>
                <Col span={48} style={{ textAlign: "-webkit-center" }}>
                    {this.state.key === "1" ?
                        < ReactPlayer volume={1} height="40vh" controls url={this.state.videoFromUser} /> :
                        <ReactPlayer volume={1} height="40vh" controls url={this.state.youtubeLink} />}
                </Col>

            </div >

        )
    }
}

const mapState = (state) => {
    return {
        movie: state.contentReducer.movie,
        message: state.contentReducer.message,
        code: state.contentReducer.code,
        status: state.contentReducer.status,
    }
}

const LocationVideoContent = connect(mapState, mapDispatch)(ConnectedLocationVideoContent)
export default LocationVideoContent;
