import React, { Component } from "react";

import {
    Table,
    Space,
    Button,
    message,
    Row,
    Col,
    Drawer,
    Checkbox,
    Input,
    Switch,
} from "antd";
import { connect } from "react-redux";
import {
    getNatureTrails,
    saveNatureTrail,
    getNatureTrailTypes,
    deleteNatureTrailById,
    updateNatureTrail,
    getTriggerTypes,
    getRegions,
    getNationalParks,
    setTestableNatureTrailById,
} from "../../actions/nature-trail";
import { getUserNatureTrail } from "../../actions/user";
import StarRatingComponent from "react-star-rating-component";
import {
    PlusCircleOutlined,
    FileImageOutlined,
    EditOutlined,
    CommentOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import NewNaturalTrail from "./new-natural-trail";
import NatureTrailLanguage from "./nature-trail-languages";
import { getLanguagesList } from "../../actions/language";
import Highlighter from "react-highlight-words";

const mapDispatch = (dispatch) => {
    return {
        getNatureTrails: (userId) => dispatch(getNatureTrails(userId)),
        getUserNatureTrail: (userId) => dispatch(getUserNatureTrail(userId)),
        saveNatureTrail: (naturalTrail, files) =>
            dispatch(saveNatureTrail(naturalTrail, files)),
        updateNatureTrail: (naturalTrail) =>
            dispatch(updateNatureTrail(naturalTrail)),
        getNatureTrailTypes: () => dispatch(getNatureTrailTypes()),
        deleteNatureTrailById: (id, isActive) =>
            dispatch(deleteNatureTrailById(id, isActive)),
        setTestableNatureTrailById: (id, isTestable) =>
            dispatch(setTestableNatureTrailById(id, isTestable)),
        getTriggerTypes: () => dispatch(getTriggerTypes()),
        getRegions: () => dispatch(getRegions()),
        getNationalParks: () => dispatch(getNationalParks()),
        getLanguagesList: () => dispatch(getLanguagesList()),
    };
};

class ConnectedNatureTrail extends Component {
    state = {
        tableData: [],
        newNaturalTrailModalOpen: false,
        filteredNatureTrailDataByID: [],
        saveNT: {},
        isActive: false,
        isTestable: false,
        activeEditingNaturalTrailName: "",
        spinning: false,
        spinningOnLanguage: false,
        languageDrawer: false,
        pageSize: 10,
        selectedRowKeys: [],
        sortedInfo: {},
    };
    componentDidMount = async () => {
        await this.props.getNatureTrails(this.props.user.id);
        await this.props.getNatureTrailTypes();
        await this.props.getTriggerTypes();
        await this.props.getRegions();
        await this.props.getNationalParks();
        await this.props.getLanguagesList();
        this.transformDataToTable();
    };

    saveNT = async (nt, files) => {
        this.setSpinningTrue();

        await this.props.saveNatureTrail(nt, files);
        if (this.props.status === "saved" || this.props.status === "error") {
            this.setSpinningFalse();
        }
        if (this.props.code !== undefined) {
            if (this.props.code === 2005) {
                message.error("A GPX file nem megfelelő formátumú.");
            }
        }
    };

    updateNT = async (nt, files) => {
        this.setSpinningTrue();

        await this.props.saveNatureTrail(nt, files);
        if (this.props.status === "saved" || this.props.status === "error") {
            this.setSpinningFalse();
        }
        if (this.props.code !== undefined) {
            if (this.props.code === 2005) {
                message.error("A GPX file nem megfelelő formátumú.");
            }
        }
    };
    transformDataToTable = () => {
        if (this.props.status === "loaded") {
            const tempData = this.props.natureTrails.map((natureTrail, i) => {
                let created = new Date(natureTrail.created * 1000);
                let modified = new Date(natureTrail.modified * 1000);
                return {
                    key: i,
                    id: natureTrail.id,
                    name: natureTrail.title ? natureTrail.title : "",
                    title: natureTrail.title ? natureTrail.title : "",
                    gpx: natureTrail.gpxFileName,
                    info: natureTrail.description_short,
                    description_long: natureTrail.description_long,
                    description_short: natureTrail.description_short,
                    lat: natureTrail.lat,
                    lon: natureTrail.lon,
                    triggerDistance: natureTrail.triggerDistance,
                    isActive: natureTrail.isActive,
                    isTestable: natureTrail.isTestable,
                    history: natureTrail.history,
                    logoSavedFileName: natureTrail.logoSavedFileName,
                    logoSavedFileURL: natureTrail.logoSavedFileURL,
                    showLogo: natureTrail.showLogo,
                    ownerEmail1: natureTrail.ownerEmail1,
                    ownerEmail2: natureTrail.ownerEmail2,
                    estimatedTime: natureTrail.estimatedTime,
                    fullRoadLength: natureTrail.fullRoadLength,
                    rating:
                        natureTrail.rating !== null || natureTrail.rating !== undefined
                            ? natureTrail.rating
                            : 0,
                    natureTrailSavedFileName: natureTrail.natureTrailSavedFileName,
                    natureTrailSavedFileURL: natureTrail.natureTrailSavedFileURL,
                    created: created.toLocaleDateString(),
                    modified: modified.toLocaleDateString(),
                    natureTrailType:
                        natureTrail.natureTrailType !== undefined &&
                            natureTrail.natureTrailType !== null
                            ? natureTrail.natureTrailType
                            : [],
                    region:
                        natureTrail.region !== undefined && natureTrail.region !== null
                            ? natureTrail.region
                            : [],
                    nationalPark:
                        natureTrail.nationalPark !== undefined &&
                            natureTrail.nationalPark !== null
                            ? natureTrail.nationalPark
                            : [],
                    badge:
                        natureTrail.badge !== undefined && natureTrail.badge[0] !== null
                            ? natureTrail.badge[0]
                            : [],
                    natureTrailLocation: natureTrail.natureTrailLocation,
                };
            });
            this.setState({ tableData: tempData });
        } else this.setState({ tableData: [] });
    };

    EditNaturalTrail = async (row) => {
        const fileredNT = this.state.tableData.filter((item) => item.id === row.id);
        this.setState({
            filteredNatureTrailDataByID: fileredNT,
            newNaturalTrailModalOpen: true,
        });
    };

    clickOnAddNewNaturalTrail = async () => {
        this.setState({
            newNaturalTrailModalOpen: true,
            filteredNatureTrailDataByID: [],
        });
    };

    closeModal = async () => {
        this.setState({
            newNaturalTrailModalOpen: false,
            filteredNatureTrailDataByID: [],
        });
        await this.props.getNatureTrails(this.props.user.id);
        await this.props.getNatureTrailTypes();
        this.transformDataToTable();
    };

    deleteNatureTrail = async (row) => {
        const isActive = !row.isActive;
        await this.props.deleteNatureTrailById(row.id, isActive);
        await this.props.getNatureTrails(this.props.user.id);
        await this.props.getNatureTrailTypes();
        if (
            this.props.deleteStatus !== "sending" &&
            this.props.deleteStatus === "error"
        ) {
            if (this.props.deleteCode === 7003) {
                message.error(this.props.deleteMessage);
            } else {
                message.error("Hibás adatkitöltés, nem aktiválható a tanösvény!");
            }
        }
        if (this.props.deleteStatus === "success") {
            if (isActive === true) {
                message.success("Sikeres aktiválás");
            } else {
                message.success("Sikeres deaktiválás");
            }
        }
        this.transformDataToTable();
    };

    testNatureTrail = async (row) => {
        const isTestable = !row.isTestable;
        await this.props.setTestableNatureTrailById(row.id, isTestable);
        await this.props.getNatureTrails(this.props.user.id);
        await this.props.getNatureTrailTypes();
        if (
            this.props.testStatus !== "sending" &&
            this.props.testStatus === "error"
        ) {
            if (this.props.testCode === 7003) {
                message.error(this.props.testMessage);
            } else {
                message.error("Hibás adatkitöltés, nem aktiválható a tanösvény!");
            }
        }
        if (this.props.testStatus === "success") {
            if (isTestable === true) {
                message.success("Sikeres tesztre aktiválás");
            } else {
                message.success("Sikeres tesztről deaktiválás");
            }
        }
        this.transformDataToTable();
    };

    setAtiveEditingNaturalTrailName = (name) => {
        this.setState({ activeEditingNaturalTrailName: name });
    };

    setSpinningTrue = () => {
        this.setState({ spinning: true });
    };

    setSpinningFalse = () => {
        this.setState({ spinning: false });
    };
    setSpinningLanguageTrue = () => {
        this.setState({ spinningOnLanguage: true });
    };

    setSpinningLanguageFalse = () => {
        this.setState({ spinningOnLanguage: false });
    };
    showDrawer = (row) => {
        const fileredNT = this.state.tableData.filter((item) => item.id === row.id);
        this.setState({
            filteredNatureTrailDataByID: fileredNT,
            languageDrawer: true,
        });
    };

    onClose = () => {
        this.setState({ languageDrawer: false });
    };

    handleChangeTable = (pagination, filters, sorter) => {
        this.setState({
            sortedInfo: sorter,
            pageSize: pagination.pageSize,
        });
    };
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Keresés
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Törlés
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>

            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : "",
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    render() {
        const columns = [
            {
                title: "Tanösvény neve",
                dataIndex: "title",
                width: "15vw",
                key: "id",
                ...this.getColumnSearchProps("title"),
                sorter: (a, b) => a.title.localeCompare(b.title),
            },
            {
                title: "Rövid leírás",
                dataIndex: "info",
                key: "info",
                ...this.getColumnSearchProps("info"),

                // render: (info, record) => {
                //     return (
                //         <div>
                //             {/* {record.info.substr(0, 120)} {record.info.length > 48 ? "..." : ""}</p> */}
                //             {record.info}
                //         </div>
                //     );
                // },
            },
            {
                title: "Kezdőkép",
                dataIndex: "picture",
                key: "picture",
                width: "10vw",
                render: (rating, record) => {
                    return (
                        <div>
                            {record.natureTrailSavedFileURL !== undefined &&
                                record.natureTrailSavedFileURL !== null &&
                                record.natureTrailSavedFileURL !== "" ? (
                                <Space style={{ width: "20px" }}>
                                    <img
                                        src={
                                            process.env.REACT_APP_API_URL +
                                            process.env.REACT_APP_VERSION +
                                            record.natureTrailSavedFileURL
                                        }
                                        alt="Hiányzó kép"
                                        width="60px"
                                    />
                                </Space>
                            ) : (
                                <div>
                                    <FileImageOutlined size="5em" />
                                    Nincs kép
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                title: "Értékelés",
                dataIndex: "rating",
                key: "rating",
                width: "10vw",
                sorter: (a, b) => a.rating - b.rating,
                render: (rating) => {
                    return (
                        <StarRatingComponent
                            name="rate2"
                            editing={false}
                            // renderStarIcon={() => <span></span>}
                            starCount={5}
                            value={rating}
                        />
                    );
                },
            },
            {
                title: "Módosítás",
                key: "showData",
                width: "5vw",
                align: "center",

                render: (text, row) => (
                    <Button
                        onClick={() => this.EditNaturalTrail(row)}
                        shape="circle"
                        icon={<EditOutlined />}
                    ></Button>
                ),
            },
            {
                title: "Nyelv",
                key: "language",
                width: "5vw",
                align: "center",
                render: (text, row) => (
                    <Button
                        icon={<CommentOutlined />}
                        shape="circle"
                        onClick={() => this.showDrawer(row)}
                    ></Button>
                ),
            },
            {
                title: "Teszt",
                key: "test",
                width: "10vw",
                align: "center",
                sorter: (a, b) => a.isTestable - b.isTestable,
                render: (text, row) => (
                    <Space size="middle">
                        <Switch
                            checked={row.isTestable}
                            onChange={() => this.testNatureTrail(row)}
                        />
                        {/* {row.isTestable === true ? <CheckCircleOutlined style={{ color: "green" }} /> : <CloseCircleOutlined style={{ color: "red" }} />} */}
                    </Space>
                ),
            },
            {
                title: "Aktív",
                key: "delete",
                width: "5vw",
                align: "center",
                sorter: (a, b) => a.isActive - b.isActive,
                render: (text, row) => (
                    <Space size="middle">
                        {/* <Checkbox onChange={() => this.deleteNatureTrail(row)} checked={row.isActive} ></Checkbox> */}
                        <Switch
                            checked={row.isActive}
                            onChange={() => this.deleteNatureTrail(row)}
                        />
                        {/* <Button
                            style={{
                                backgroundColor: row.isActive === true ? "forestgreen" : "",
                                color: row.isActive === true ? "white" : ""
                            }}>
                            {row.isActive === true ? "Deactivate" : "Activate"}
                        </Button> */}
                    </Space>
                ),
            },
        ];
        const locale = {
            prevText: "Előző",
            nextText: "Következő",
            items_per_page: "/ oldal",
        };

        return (
            <div>
                <div>
                    <h1>Tanösvények</h1>
                    <Row gutter={24} style={{ height: "5vh" }}>
                        <Button type="primary" onClick={this.clickOnAddNewNaturalTrail}>
                            {" "}
                            <PlusCircleOutlined />
                            Új tanösvény
                        </Button>
                    </Row>
                    <Row gutter={24} style={{ height: "70vh" }}>
                        <Col span={24}>
                            <Table
                                onChange={this.handleChangeTable}
                                pagination={{
                                    position: ["bottomCenter"],
                                    pageSize: this.state.pageSize,
                                    showSizeChanger: true,
                                    locale: locale,
                                }}
                                rowClassName={(record, index) => {
                                    return record.isActive === true
                                        ? "row-active"
                                        : "row-deactive";
                                }}
                                scroll={{ y: "55vh" }}
                                size="small"
                                // overflowY="auto"
                                columns={columns}
                                dataSource={this.state.tableData}
                            />
                        </Col>

                        <Drawer
                            width={"90%"}
                            //bodyStyle={{ height: "75vh", paddingTop: "0", paddingBottom: "0" }}
                            //style={{ minHeight: "80vh !important", maxHeight: "80vh !important", }}
                            visible={this.state.newNaturalTrailModalOpen}
                            title={
                                this.state.filteredNatureTrailDataByID[0] !== undefined
                                    ? "Tanösvény módosítása:  " +
                                    this.state.activeEditingNaturalTrailName
                                    : "Új Tanösvény létrehozás:  " +
                                    this.state.activeEditingNaturalTrailName
                            }
                            onCancel={this.closeModal}
                            onClose={this.closeModal}
                            maskClosable={false}
                            closable={!this.state.spinning}
                            footer={[]}
                        >
                            {this.state.newNaturalTrailModalOpen && (
                                <NewNaturalTrail
                                    setAtiveEditingNaturalTrailName={
                                        this.setAtiveEditingNaturalTrailName
                                    }
                                    saveNT={this.saveNT}
                                    updateNT={this.updateNT}
                                    open={this.state.newNaturalTrailModalOpen}
                                    close={this.closeModal}
                                    natureTrailData={this.state.filteredNatureTrailDataByID}
                                />
                            )}
                        </Drawer>
                    </Row>
                </div>
                {this.state.filteredNatureTrailDataByID &&
                    this.state.filteredNatureTrailDataByID[0] && (
                        <Drawer
                            title="Nyelv kezelő felület"
                            placement="right"
                            width="90%"
                            destroyOnClose={true}
                            onClose={this.onClose}
                            maskClosable={false}
                            visible={this.state.languageDrawer}
                            closable={!this.state.spinningOnLanguage}
                            footer={[]}
                        >
                            <NatureTrailLanguage
                                natureTrailData={this.state.filteredNatureTrailDataByID[0]}
                                setSpinningLanguageTrue={this.setSpinningLanguageTrue}
                                setSpinningLanguageFalse={this.setSpinningLanguageFalse}
                            />
                        </Drawer>
                    )}
            </div>
        );
    }
}
const mapState = (state) => {
    return {
        user: state.loginReducer.user,
        message: state.natureTrailReducer.message,
        code: state.natureTrailReducer.code,
        status: state.natureTrailReducer.status,
        natureTrails: state.natureTrailReducer.data,
        deleteStatus: state.natureTrailReducer.deleteStatus,
        deleteCode: state.natureTrailReducer.deleteCode,
        deleteMessage: state.natureTrailReducer.deleteMessage,
        testStatus: state.natureTrailReducer.testStatus,
        testCode: state.natureTrailReducer.testCode,
        testMessage: state.natureTrailReducer.testMessage,
        languages: state.languagesReducer.languages,
        languagesStatus: state.languagesReducer.languagesStatus,
    };
};

const NatureTrail = connect(mapState, mapDispatch)(ConnectedNatureTrail);
export default NatureTrail;
