import axios from "axios";
import { CONTENT } from "../constants/action-types";

export const getMovieContent = (locationId) => {
    return async (dispatch) => {
        dispatch({ type: CONTENT.MOVIE_LOADING });
        await axios
            .get("/nature-trail/get-location-movie", {
                params: {
                    locationId: locationId
                }
            })
            .then((response) => {
                dispatch({
                    type: CONTENT.MOVIE_LOADED,
                    payload: { data: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: CONTENT.MOVIE_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};

export const getSoundContent = (locationId) => {
    return async (dispatch) => {
        dispatch({ type: CONTENT.SOUND_LOADING });
        await axios
            .get("/nature-trail/get-location-sound",
                {
                    params: {
                        locationId: locationId
                    }
                })
            .then((response) => {
                dispatch({
                    type: CONTENT.SOUND_LOADED,
                    payload: { data: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: CONTENT.SOUND_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};

export const get3DContent = (locationId) => {
    return async (dispatch) => {
        dispatch({ type: CONTENT.D3D_LOADING });
        await axios
            .get("/nature-trail/get-location-3d",
                {
                    params: {
                        locationId: locationId
                    }
                })
            .then((response) => {
                dispatch({
                    type: CONTENT.D3D_LOADED,
                    payload: { data: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: CONTENT.D3D_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};

export const deleteAndroid3dFile = (locationId) => {
    return async (dispatch) => {
        dispatch({ type: CONTENT.DELETE_ANDROID_3D_DELETING });
        await axios
            .post("/nature-trail/delete-location-android-3d", {
                locationId: locationId
            })
            .then((response) => {
                dispatch({
                    type: CONTENT.DELETE_ANDROID_3D_DELETED,
                    payload: { data: response.data }
                })
            })
            .catch((err) => {
                dispatch({
                    type: CONTENT.DELETE_ANDROID_3D_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};
export const deleteIphone3dFile = (locationId) => {
    return async (dispatch) => {
        dispatch({ type: CONTENT.DELETE_IPHONE_3D_DELETING });
        await axios
            .post("/nature-trail/delete-location-iphone-3d", {
                locationId: locationId
            })
            .then((response) => {
                dispatch({
                    type: CONTENT.DELETE_IPHONE_3D_DELETED,
                    payload: { data: response.data }
                })
            })
            .catch((err) => {
                dispatch({
                    type: CONTENT.DELETE_IPHONE_3D_ERROR
                    ,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};
export const deleteMusicFile = (locationId) => {
    return async (dispatch) => {
        dispatch({ type: CONTENT.DELETE_MUSIC_DELETING });
        await axios
            .post("/nature-trail/delete-location-sound", {
                locationId: locationId
            })
            .then((response) => {
                dispatch({
                    type: CONTENT.DELETE_MUSIC_DELETED,
                    payload: { data: response.data }
                })
            })
            .catch((err) => {
                dispatch({
                    type: CONTENT.DELETE_MUSIC_ERROR
                    ,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};
export const deleteMovieFile = (locationId) => {
    return async (dispatch) => {
        dispatch({ type: CONTENT.DELETE_MOVIE_DELETING });
        await axios
            .post("/nature-trail/delete-location-video", {
                locationId: locationId
            })
            .then((response) => {
                dispatch({
                    type: CONTENT.DELETE_MOVIE_DELETED,
                    payload: { data: response.data }
                })
            })
            .catch((err) => {
                dispatch({
                    type: CONTENT.DELETE_MOVIE_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};