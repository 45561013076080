import React, { Component } from 'react'
import { Input, Button, Checkbox, Row, Col, message, Select, Upload, Modal, Divider, Mentions } from 'antd'
import ImgCrop from "antd-img-crop";
import { connect } from 'react-redux';
import { CommentOutlined, PauseCircleOutlined, PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
;


const { Option } = Select;

const mapDispatch = (dispatch) => {
    return {
    }
}

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class ConnectedQuestionAnswers extends Component {
    state = {
        play: false,
        playAnswer1: false,
        playAnswer2: false,
        playAnswer3: false,
        playAnswer4: false,
        question: "",
        fileList: [],
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        answers: [],
        picture1file: [],
        picture2file: [],
        picture3file: [],
        picture4file: [],
        preview1: "",
        preview2: "",
        preview3: "",
        preview4: "",
        previewQuestion: "",
        // picture1Base64: "",
        // picture2Base64: "",
        // picture3Base64: "",
        // picture4Base64: "",
        //    picture1PreviewImage: '',
        //picture2PreviewImage: '',
        //    picture3PreviewImage: '',
        picture4PreviewImage: '',
        actualPictureInModal: "",
        moreAnswersAllowed: false,
        saveButtonActive: false,
        questionAudiofile: [],
        questionPicturefile: [],
        questionSound: {},
        answer1AudioFile: [],
        answer2AudioFile: [],
        answer3AudioFile: [],
        answer4AudioFile: [],
        actualType: [{ key: 1, value: "Szöveg" }],
        actualContentType: [{ key: 1, value: "Szöveg" }],
        actualAnswerCount: 4,
        questionType: [
            { key: 1, value: "Szöveg" },
            { key: 2, value: "Kép" },
            { key: 3, value: "Kép - szöveg" },
            { key: 4, value: "Hang" },
        ],
        questionContentType: [
            { key: 1, value: "Szöveg" },
            { key: 2, value: "Szöveg és kép" },
            { key: 3, value: "Szöveg és hang" },
        ]
        ,
        isImage: false,
        questionLimit: 1500,
        answersLimit: 1500,
        popupLimit: 160,
        goodMessage: "",
        wrongMessage: ""
    }

    audio = new Audio("")
    answer1audio = new Audio("")
    answer2audio = new Audio("")
    answer3audio = new Audio("")
    answer4audio = new Audio("")

    componentDidMount = async () => {
        if (this.props.questionAnswersData !== undefined) {
            this.stopAllAudio()
            this.audio.src = this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].soundURL
                ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].soundURL : this.audio.src
            this.answer1audio.src = this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers && this.props.questionAnswersData[0].answers[0] && this.props.questionAnswersData[0].answers[0].soundURL
                ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[0].soundURL : this.answer1audio.src
            this.answer2audio.src = this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers && this.props.questionAnswersData[0].answers[1] && this.props.questionAnswersData[0].answers[1].soundURL
                ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[1].soundURL : this.answer2audio.src
            this.answer3audio.src = this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers && this.props.questionAnswersData[0].answers[2] && this.props.questionAnswersData[0].answers[2].soundURL
                ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[2].soundURL : this.answer3audio.src
            this.answer4audio.src = this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers && this.props.questionAnswersData[0].answers[3] && this.props.questionAnswersData[0].answers[3].soundURL
                ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[3].soundURL : this.answer4audio.src
            this.setState({
                previewQuestion: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].pictureURL !== undefined ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].pictureURL : "",
                picture1file: [],
                picture2file: [],
                picture3file: [],
                picture4file: [],
                questionSound: {},
                answer1AudioFile: [],
                answer2AudioFile: [],
                answer3AudioFile: [],
                answer4AudioFile: [],
                preview1: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers !== undefined && this.props.questionAnswersData[0].answers[0] !== undefined ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[0].imageURL : "",
                preview2: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers !== undefined && this.props.questionAnswersData[0].answers[1] ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[1].imageURL : "",
                preview3: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers !== undefined && this.props.questionAnswersData[0].answers[2] ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[2].imageURL : "",
                preview4: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers !== undefined && this.props.questionAnswersData[0].answers[3] ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[3].imageURL : "",
                croppedPictureFile1: {},
                croppedPictureFile2: {},
                croppedPictureFile3: {},
                croppedPictureFile4: {},
                wrongMessage: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].wrongAnswerMessage !== undefined && this.props.questionAnswersData[0].wrongAnswerMessage !== null ? this.props.questionAnswersData[0].wrongAnswerMessage : "",
                goodMessage: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].goodAnswerMessage !== undefined && this.props.questionAnswersData[0].goodAnswerMessage !== null ? this.props.questionAnswersData[0].goodAnswerMessage : "",
                actualType: this.props.questionAnswersData[0] !== undefined &&
                    this.props.questionAnswersData[0].questionType !== undefined &&
                    this.props.questionAnswersData[0].questionType !== 0 ?
                    this.state.questionType.filter(actualType => actualType.key === this.props.questionAnswersData[0].questionType)
                    :
                    [{ key: 1, value: "Szöveg" }],
                actualContentType: this.props.questionAnswersData[0] !== undefined &&
                    this.props.questionAnswersData[0].questionContentType !== undefined &&
                    this.props.questionAnswersData[0].questionContentType !== 0 ?
                    this.state.questionContentType.filter(actualType => actualType.key === this.props.questionAnswersData[0].questionContentType)
                    :
                    [{ key: 1, value: "Szöveg" }],
                question: this.props.questionAnswersData !== undefined && this.props.questionAnswersData[0].question !== undefined ? this.props.questionAnswersData[0].question : "",
                actualAnswerCount: this.props.questionAnswersData !== undefined && this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answerCount ? this.props.questionAnswersData[0].answerCount : 4,
                answers: this.props.questionAnswersData !== undefined && this.props.questionAnswersData[0].answers.length > 0 ? [...this.props.questionAnswersData[0].answers] :
                    [{
                        new: true,
                        image: null,
                        isGood: false,
                        key: 1,
                        sequenceId: 1,
                        text: "",
                        imageURL: "",
                        soundURL: "",
                    }, {
                        new: true,
                        image: null,
                        isGood: false,
                        key: 2,
                        sequenceId: 2,
                        text: "",
                        imageURL: "",
                        soundURL: "",
                    }, {
                        new: true,
                        image: null,
                        isGood: false,
                        key: 3,
                        sequenceId: 3,
                        text: "",
                        imageURL: "",
                        soundURL: ""
                    }, {
                        new: true,
                        image: null,
                        isGood: false,
                        key: 4,
                        sequenceId: 4,
                        text: "",
                        imageURL: "",
                        soundURL: "",
                    }],

            })
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.questionAnswersData !== this.props.questionAnswersData && this.props.questionAnswersData !== undefined) {
            this.stopAllAudio()
            this.audio.src = this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].soundURL
                ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].soundURL : this.audio.src
            this.answer1audio.src = this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers && this.props.questionAnswersData[0].answers[0] && this.props.questionAnswersData[0].answers[0].soundURL
                ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[0].soundURL : this.answer1audio.src
            this.answer2audio.src = this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers && this.props.questionAnswersData[0].answers[1] && this.props.questionAnswersData[0].answers[1].soundURL
                ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[1].soundURL : this.answer2audio.src
            this.answer3audio.src = this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers && this.props.questionAnswersData[0].answers[2] && this.props.questionAnswersData[0].answers[2].soundURL
                ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[2].soundURL : this.answer3audio.src
            this.answer4audio.src = this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers && this.props.questionAnswersData[0].answers[3] && this.props.questionAnswersData[0].answers[3].soundURL
                ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[3].soundURL : this.answer4audio.src
            this.setState({
                previewQuestion: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].pictureURL !== undefined ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].pictureURL : "",
                picture1file: [],
                picture2file: [],
                picture3file: [],
                picture4file: [],
                questionSound: {},
                answer1AudioFile: [],
                answer2AudioFile: [],
                answer3AudioFile: [],
                answer4AudioFile: [],
                preview1: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers !== undefined && this.props.questionAnswersData[0].answers[0] !== undefined ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[0].imageURL : "",
                preview2: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers !== undefined && this.props.questionAnswersData[0].answers[1] ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[1].imageURL : "",
                preview3: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers !== undefined && this.props.questionAnswersData[0].answers[2] ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[2].imageURL : "",
                preview4: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answers !== undefined && this.props.questionAnswersData[0].answers[3] ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.questionAnswersData[0].answers[3].imageURL : "",
                croppedPictureFile1: {},
                croppedPictureFile2: {},
                croppedPictureFile3: {},
                croppedPictureFile4: {},
                wrongMessage: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].wrongAnswerMessage !== undefined && this.props.questionAnswersData[0].wrongAnswerMessage !== null ? this.props.questionAnswersData[0].wrongAnswerMessage : "",
                goodMessage: this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].goodAnswerMessage !== undefined && this.props.questionAnswersData[0].goodAnswerMessage !== null ? this.props.questionAnswersData[0].goodAnswerMessage : "",
                actualType: this.props.questionAnswersData[0] !== undefined &&
                    this.props.questionAnswersData[0].questionType !== undefined &&
                    this.props.questionAnswersData[0].questionType !== 0 ?
                    this.state.questionType.filter(actualType => actualType.key === this.props.questionAnswersData[0].questionType)
                    :
                    [{ key: 1, value: "Szöveg" }],
                actualContentType: this.props.questionAnswersData[0] !== undefined &&
                    this.props.questionAnswersData[0].questionContentType !== undefined &&
                    this.props.questionAnswersData[0].questionContentType !== 0 ?
                    this.state.questionContentType.filter(actualType => actualType.key === this.props.questionAnswersData[0].questionContentType)
                    :
                    [{ key: 1, value: "Szöveg" }],
                question: this.props.questionAnswersData !== undefined && this.props.questionAnswersData[0].question !== undefined ? this.props.questionAnswersData[0].question : "",
                actualAnswerCount: this.props.questionAnswersData !== undefined && this.props.questionAnswersData[0] !== undefined && this.props.questionAnswersData[0].answerCount ? this.props.questionAnswersData[0].answerCount : 4,
                answers: this.props.questionAnswersData !== undefined && this.props.questionAnswersData[0].answers.length > 0 ? [...this.props.questionAnswersData[0].answers] :
                    [{
                        new: true,
                        image: null,
                        isGood: false,
                        key: 1,
                        sequenceId: 1,
                        text: "",
                        imageURL: "",
                        soundURL: "",
                    }, {
                        new: true,
                        image: null,
                        isGood: false,
                        key: 2,
                        sequenceId: 2,
                        text: "",
                        imageURL: "",
                        soundURL: "",
                    }, {
                        new: true,
                        image: null,
                        isGood: false,
                        key: 3,
                        sequenceId: 3,
                        text: "",
                        imageURL: "",
                        soundURL: ""
                    }, {
                        new: true,
                        image: null,
                        isGood: false,
                        key: 4,
                        sequenceId: 4,
                        text: "",
                        imageURL: "",
                        soundURL: "",
                    }],

            })
        }
    }

    componentWillUnmount = () => {
        this.stopAllAudio()
    }

    changeQuestion = (event) => {
        //const value = event.target.value
        const actualValue = event
        if (actualValue.length <= this.state.questionLimit) {
            this.setState({ question: event, saveButtonActive: true })
        } else {
            message.error("A kérdés karakterszáma maximum " + this.state.questionLimit + " lehet.")
        }

    }

    changeAnswer = (event, key, sequenceId) => {
        const actualValue = event.target.value

        const editedAnswers = this.state.answers.map(item => {
            if (item.key === key) {
                if (actualValue.length <= this.state.answersLimit) {
                    item.text = actualValue
                    item.sequenceId = sequenceId
                } else {
                    message.error("A válasz karakterszáma maximum " + this.state.answersLimit + " lehet.")
                }
            }
            return item
        })
        this.setState({ answers: editedAnswers, saveButtonActive: true })
        this.forceUpdate();
    }

    checkBoxChange = (event, key) => {
        if (this.state.moreAnswersAllowed) {
            const editedAnswers = this.state.answers.map(item => {
                if (item.key === key) {
                    item.isGood = event.target.checked
                }
                return item
            })
            this.setState({ answers: editedAnswers, saveButtonActive: true })
        } else {
            const editedAnswers = this.state.answers.map(item => {
                if (item.key === key) {
                    item.isGood = event.target.checked
                }
                else {
                    item.isGood = false
                }
                return item
            })
            this.setState({ answers: editedAnswers, saveButtonActive: true })

        }
    }

    checkBoxChangeAllowedMoreAnswers = (event) => {
        this.setState({ moreAnswersAllowed: event.target.checked })
    }

    saveQuestionAndAnswers = async () => {

        // if (this.state.actualAnswerCount === 2) {
        //     sendAswers = [this.state.answers[0], this.state.answers[1]]
        // }
        // else if (this.state.actualAnswerCount === 3) {
        //     sendAswers = [this.state.answers[0], this.state.answers[1], this.state.answers[2]]
        // } else {
        //     sendAswers = this.state.answers
        // }

        const createdQuestionAndAnswers = {
            answerCount: this.state.actualAnswerCount,
            question: this.state.question,
            // answers: sendAswers,

            answers: this.state.answers,
            moreAnswersAllowed: this.state.moreAnswersAllowed,
            isImage: this.state.isImage,
            questionType: this.state.actualType[0].key,
            questionContentType: this.state.actualContentType[0].key,
            goodAnswerMessage: this.state.goodMessage,
            wrongAnswerMessage: this.state.wrongMessage

        }

        const files = {
            picture1: this.state.croppedPictureFile1,
            picture2: this.state.croppedPictureFile2,
            picture3: this.state.croppedPictureFile3,
            picture4: this.state.croppedPictureFile4,
            questionSound: this.state.questionSound,
            questionPicture: this.state.questionPicturefile,
            sound1: this.state.answer1AudioFile,
            sound2: this.state.answer2AudioFile,
            sound3: this.state.answer3AudioFile,
            sound4: this.state.answer4AudioFile,
        }


        if (this.props.saveQuestionAndAnswers === undefined || this.state.question === "" || this.state.answers.length === 0) {
            message.error("Hibás mentési folyamat. A kérdés nem maradhat üresen")
        } else {
            if (createdQuestionAndAnswers.question.length > this.state.questionLimit) {
                message.error("A kérdés karakterszáma maximum " + this.state.questionLimit + " lehet.")
            }
            else if (createdQuestionAndAnswers.answers[0].text.length > this.state.answersLimit) {
                message.error("Az első válasz karakterszáma maximum " + this.state.answersLimit + " lehet.")
            }
            else if (createdQuestionAndAnswers.answers[1].text.length > this.state.answersLimit) {
                message.error("A második válasz karakterszáma maximum " + this.state.answersLimit + " lehet.")
            }
            else if (this.state.actualAnswerCount >= 3 && createdQuestionAndAnswers.answers[2].text.length > this.state.answersLimit) {
                message.error("A harmadik válasz karakterszáma maximum " + this.state.answersLimit + " lehet.")
            }
            else if (this.state.actualAnswerCount === 4 && createdQuestionAndAnswers.answers[3].text.length > this.state.answersLimit) {
                message.error("A negyedik válasz karakterszáma maximum " + this.state.answersLimit + " lehet.")
            } else {
                await this.props.saveQuestionAndAnswers(createdQuestionAndAnswers, files);
                if (this.props.saveStatus === "saved") {
                    this.setState({ answers: this.props.questionAnswers.answers })
                    this.setState({ question: this.props.questionAnswers.question })
                    this.setState({ saveButtonActive: false })
                    message.success("Sikeres mentés!")
                }
                else (
                    message.error("Hiba a mentés során!")
                )
            }


        }
    }

    selectQuestionType = (event) => {
        const filteredType = this.state.questionType.filter(currentType => currentType.value === event)
        this.setState({ actualType: filteredType, saveButtonActive: true });
    }
    selectQuestionContentType = (event) => {
        const filteredType = this.state.questionContentType.filter(currentType => currentType.value === event)
        this.setState({ actualContentType: filteredType, saveButtonActive: true });
    }

    handlePreview = async file => {
        this.setState({
            previewVisible: true,
        });
    };

    priviewModalOpen = (event) => {
        this.setState({
            previewVisible: true,
            actualPictureInModal: event.target.src
        });
    }

    handleCancelPhoto = () => {
        this.setState({ previewVisible: false })
    }

    beforeUploadPicture1 = async (file) => {
        const base64picture = await getBase64(file);
        const answers = this.state.answers;
        answers[0].image = 'picture1';
        this.setState({ preview1: base64picture, answers: answers, picture1file: [file] })
        this.setState({ croppedPictureFile1: this.covertBase64ToFile(this.state.preview1, this.state.picture1file[0].name), saveButtonActive: true })
        return false;
    }

    beforeUploadPicture2 = async (file) => {
        const base64picture = await getBase64(file);
        const answers = this.state.answers;
        answers[1].image = 'picture2';
        this.setState({ preview2: base64picture, answers: answers, picture2file: [file] })
        this.setState({ croppedPictureFile2: this.covertBase64ToFile(this.state.preview2, this.state.picture2file[0].name), saveButtonActive: true })
        return false;
    }

    beforeUploadPicture3 = async (file) => {
        const base64picture = await getBase64(file);
        const answers = this.state.answers;
        answers[2].image = 'picture3';
        this.setState({ preview3: base64picture, answers: answers, picture3file: [file] })
        this.setState({ croppedPictureFile3: this.covertBase64ToFile(this.state.preview3, this.state.picture3file[0].name), saveButtonActive: true })
        return false;
    }

    beforeUploadPicture4 = async (file) => {
        const base64picture = await getBase64(file);
        const answers = this.state.answers;
        answers[3].image = 'picture4';
        this.setState({ preview4: base64picture, answers: answers, picture4file: [file] })
        this.setState({ croppedPictureFile4: this.covertBase64ToFile(this.state.preview4, this.state.picture4file[0].name), saveButtonActive: true })
        return false;
    }

    beforeUploadQuestionPicture = async (file) => {
        const previewURL = URL.createObjectURL(file);
        this.setState({ previewQuestion: previewURL, questionPicturefile: [file] })
        return false;
    }
    beforeUploadQuestuionAudio = async (file) => {
        this.stopAllAudio();
        if (file.type && file.type === "audio/mpeg") {
            this.audio = new Audio(URL.createObjectURL(file))
            this.setState({ audio: this.audio, play: false, questionSound: file, saveButtonActive: true, })
        } else {
            message.error("Nem megfelelő formátum lett kiválasztva")
        }
        return false;
    }

    stopAllAudio = () => {
        this.audio.pause(); this.audio.currentTime = 0
        this.answer1audio.pause(); this.answer1audio.currentTime = 0
        this.answer2audio.pause(); this.answer2audio.currentTime = 0
        this.answer3audio.pause(); this.answer3audio.currentTime = 0
        this.answer4audio.pause(); this.answer4audio.currentTime = 0
    }

    beforeUploadAnswer1Audio = async (file) => {
        this.stopAllAudio()
        if (file.type && file.type === "audio/mpeg") {
            this.answer1audio = new Audio(URL.createObjectURL(file))
            this.setState({ answer1audio: this.answer1audio, playAnswer1: false, answer1AudioFile: file, saveButtonActive: true })
        } else {
            message.error("Nem megfelelő formátum lett kiválasztva")
        }
        return false;
    }
    beforeUploadAnswer2Audio = async (file) => {
        this.stopAllAudio()
        if (file.type && file.type === "audio/mpeg") {
            this.answer2audio = new Audio(URL.createObjectURL(file))
            this.setState({ answer2audio: this.answer2audio, playAnswer2: false, answer2AudioFile: file, saveButtonActive: true })
        } else {
            message.error("Nem megfelelő formátum lett kiválasztva")
        }
        return false;
    }
    beforeUploadAnswer3Audio = async (file) => {
        this.stopAllAudio()
        if (file.type && file.type === "audio/mpeg") {
            this.answer3audio = new Audio(URL.createObjectURL(file))
            this.setState({ answer3audio: this.answer3audio, playAnswer3: false, answer3AudioFile: file, saveButtonActive: true })
        } else {
            message.error("Nem megfelelő formátum lett kiválasztva")
        }
        return false;
    }
    beforeUploadAnswer4Audio = async (file) => {
        this.stopAllAudio()
        if (file.type && file.type === "audio/mpeg") {
            this.answer4audio = new Audio(URL.createObjectURL(file))
            this.setState({ answer4audio: this.answer4audio, playAnswer4: false, answer4AudioFile: file, saveButtonActive: true })
        } else {
            message.error("Nem megfelelő formátum lett kiválasztva")
        }
        return false;
    }


    togglePlay = () => {
        this.stopAllAudio()
        this.setState({
            playAnswer1: false,
            playAnswer2: false,
            playAnswer3: false,
            playAnswer4: false,
        })
        this.setState({ play: !this.state.play }, () => {
            this.state.play ? this.audio.play() : this.audio.pause(); this.audio.currentTime = 0
        });
    }
    togglePlayAnswer1 = () => {
        this.stopAllAudio()
        this.setState({
            play: false,
            playAnswer2: false,
            playAnswer3: false,
            playAnswer4: false,
        })
        this.setState({ playAnswer1: !this.state.playAnswer1 }, () => {
            this.state.playAnswer1 ? this.answer1audio.play() : this.answer1audio.pause(); this.answer1audio.currentTime = 0
        });
    }
    togglePlayAnswer2 = () => {
        this.stopAllAudio()
        this.setState({
            play: false,
            playAnswer1: false,
            playAnswer3: false,
            playAnswer4: false,
        })
        this.setState({ playAnswer2: !this.state.playAnswer2 }, () => {
            this.state.playAnswer2 ? this.answer2audio.play() : this.answer2audio.pause(); this.answer2audio.currentTime = 0
        });
    }
    togglePlayAnswer3 = () => {
        this.stopAllAudio()
        this.setState({
            play: false,
            playAnswer2: false,
            playAnswer1: false,
            playAnswer4: false,
        })
        this.setState({ playAnswer3: !this.state.playAnswer3 }, () => {
            this.state.playAnswer3 ? this.answer3audio.play() : this.answer3audio.pause(); this.answer3audio.currentTime = 0
        });
    }
    togglePlayAnswer4 = () => {
        this.stopAllAudio()
        this.setState({
            play: false,
            playAnswer2: false,
            playAnswer3: false,
            playAnswer1: false,
        })
        this.setState({ playAnswer4: !this.state.playAnswer4 }, () => {
            this.state.playAnswer4 ? this.answer4audio.play() : this.answer4audio.pause(); this.answer4audio.currentTime = 0
        });
    }


    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.setState({ uploaded: true })
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    };

    covertBase64ToFile = (base64String, outName) => {
        const block = base64String.split(";");
        const contentType = block[0].split(":")[1];
        const realData = block[1].split(",")[1];
        const blob = b64toBlob(realData, contentType);
        const file = new File([blob], outName)
        return file
    }

    selectAnswerCount = (event) => {
        this.setState({ actualAnswerCount: event, saveButtonActive: true })
    }

    changeWrongMessage = (event) => {
        const value = event.target.value
        if (value.length < this.state.popupLimit) {
            this.setState({ wrongMessage: value, saveButtonActive: true })
        } else {
            message.warning("Az üzenet karakterszámának kell lennie mint " + this.state.popupLimit + ".")
        }

    }
    changeGoodMessage = (event) => {
        const value = event.target.value
        if (value.length < this.state.popupLimit) {
            this.setState({ goodMessage: value, saveButtonActive: true })
        } else {
            message.warning("Az üzenet karakterszámának kell lennie mint " + this.state.popupLimit + ".")
        }
    }

    render() {
        const beforeCrop = (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                message.error('A képnek kissebbnek kell lennie mint 5MB!');
                return false
            } else {
                //this.setState({ preview1a: URL.createObjectURL(file), picture1afile: file, saveButtonActive: true })
                return true
            }

        }
        return (
            <div>
                {this.props.isVisible === true ?
                    <Row gutter={[24, 16]}>
                        <Col span={24}
                            style={{ maxHeight: "5vh" }} >
                            <Button disabled={!this.state.saveButtonActive} type="primary" onClick={this.saveQuestionAndAnswers}> Kérdéssor mentése</Button>
                        </Col>
                        <Col span={6}>
                            <div><b>Kérdés típusa:</b></div>

                            <Select value={this.state.actualContentType[0].value} style={{ width: "100%" }} onSelect={this.selectQuestionContentType}
                            >
                                {this.state.questionContentType.map(item => { return (< Option value={item.value} > {item.value}</Option>) })}
                            </Select>
                        </Col>
                        <Col span={6}>
                            <div><b>Válaszok típusa:</b></div>

                            <Select value={this.state.actualType[0].value} style={{ width: "100%" }} onSelect={this.selectQuestionType}
                            >
                                {this.state.questionType.map(item => { return (< Option value={item.value} > {item.value}</Option>) })}
                            </Select>
                        </Col>
                        <Col span={6}>
                            <div><b>Válaszok szám:</b></div>
                            <Select style={{ width: "100%" }} value={this.state.actualAnswerCount} onSelect={this.selectAnswerCount}
                            >
                                < Option value={2} > {2}</Option>
                                < Option value={3} > {3}</Option>
                                < Option value={4} > {4}</Option>
                            </Select>
                        </Col>

                        <Col span={12}>

                            <div><b>Kérdés:</b></div>
                            <Mentions
                                rows={4}
                                // prefix={<div>{this.state.question && this.state.question.length ? this.state.questionLimit - this.state.question.length : this.state.questionLimit} <Divider type="vertical"></Divider></div>}
                                value={this.state.question}
                                onChange={this.changeQuestion}
                            ></Mentions>
                            <div><strong>Hátralévő karekterek: </strong>{this.state.questionLimit}/{this.state.question && this.state.question.length ? this.state.questionLimit - this.state.question.length : this.state.questionLimit}</div>

                        </Col>
                        {this.state.actualContentType[0].key === 3 &&
                            <Col span={12}>
                                <div style={{ display: 'flex', paddingTop: "16px" }}>

                                    <div style={{ paddingRight: '24px' }}>
                                        <Button
                                            disabled={(this.state.questionSound && this.state.questionSound.size) ||
                                                (this.props.questionAnswersData && this.props.questionAnswersData[0] && this.props.questionAnswersData[0].soundURL && this.props.questionAnswersData[0].soundURL !== "")
                                                ? false : true}
                                            onClick={this.togglePlay}
                                            icon={
                                                !this.state.play ?
                                                    <PlayCircleOutlined style={{ fontSize: "2vh" }} /> :
                                                    <PauseCircleOutlined style={{ fontSize: "2vh" }} />
                                            }>
                                            {this.state.play ? 'Pause' : 'Play'}
                                        </Button>
                                    </div>
                                    <Upload
                                        multiple={false}
                                        beforeUpload={this.beforeUploadQuestuionAudio}
                                        fileList={this.state.questionAudiofile}
                                        // onRemove={this.removeFile}
                                        onChange={this.handleChange}
                                        showUploadList={false}
                                        accept=".mp3">
                                        <Button>Hang kiválasztása (kérdés)</Button>
                                    </Upload>
                                </div>
                            </Col>
                        }

                        {this.state.actualContentType[0].key === 2 &&
                            <Col span={12}>
                                <div style={{ display: 'flex', paddingTop: "16px", justifyContent: "space-around", alignItems: "center" }}>


                                    <Col className="gutter-row" span={4} >
                                        <img onClick={this.priviewModalOpen}
                                            id={2}
                                            alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                            src={this.state.previewQuestion}
                                        ></img>
                                    </Col>
                                    <Col className="gutter-row" span={18} style={{ flex: 0 }} >
                                        <ImgCrop rotate
                                            beforeCrop={beforeCrop}
                                            modalTitle="Kép (maximum 5 MB)">

                                            <Upload
                                                multiple={false}
                                                beforeUpload={this.beforeUploadQuestionPicture}
                                                fileList={this.state.questionPicturefile}
                                                // onRemove={this.removeFile}
                                                onChange={this.handleChange}
                                                showUploadList={false}

                                                accept=".png,.jpg,.jpeg">
                                                <Button>Kérdés kép feltöltés</Button>
                                            </Upload>
                                        </ImgCrop>
                                    </Col>
                                </div>
                            </Col>}

                        <Col span={24} style={{ padding: "0px", display: "flex" }}>
                            <Col span={12}>
                                <div>Helyes válasz üzenet: </div>
                                <Input
                                    // prefix={<div>{this.state.popupLimit - (this.state.goodMessage && this.state.goodMessage.length ? this.state.goodMessage.length : 0)}<Divider type="vertical"></Divider></div>}
                                    value={this.state.goodMessage}
                                    onChange={this.changeGoodMessage}
                                ></Input>
                                <p><strong>Hátralévő karekterek: </strong>{this.state.popupLimit}/{this.state.popupLimit - this.state.goodMessage.length}</p>
                            </Col>
                            <Col span={12}>
                                <div>Helytelen üzenet: </div>
                                <Input
                                    // prefix={<div>{this.state.popupLimit - (this.state.wrongMessage && this.state.wrongMessage.length ? this.state.wrongMessage.length : 0)}<Divider type="vertical"></Divider></div>}
                                    value={this.state.wrongMessage}
                                    onChange={this.changeWrongMessage}
                                ></Input>
                                <p><strong>Hátralévő karekterek: </strong>{this.state.popupLimit}/{this.state.popupLimit - this.state.wrongMessage.length}</p>
                            </Col>
                        </Col>

                        <Col span={24} >

                            <Row gutter={[24, 16]}>
                                <Col span={8}>
                                    <Checkbox onChange={this.checkBoxChangeAllowedMoreAnswers}> Több jó válasz is lehetséges</Checkbox>
                                </Col>
                            </Row>
                            <Row gutter={[24, 16]} style={{ maxHeight: "40vh", overflowY: "auto" }}>
                                <Col span={24}>
                                    {this.state.actualType[0].value === this.state.questionType[0].value && this.state.answers && this.state.answers[0] &&
                                        <div>
                                            <Row gutter={24} style={{ paddingTop: "1em " }}>
                                                <Col span={1}>
                                                    <Checkbox checked={this.state.answers[0].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[0].key)}></Checkbox>
                                                </Col>
                                                <Col span={23}>
                                                    <Input
                                                        // prefix={<div>{this.state.answersLimit - this.state.answers[0].text.length}<Divider type="vertical"></Divider></div>}
                                                        key={this.state.answers[0].key}
                                                        value={this.state.answers[0].text}
                                                        onChange={(event) => this.changeAnswer(event, this.state.answers[0].key, 1)}>{this.state.answers[0].answer}</Input>
                                                    <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[0].text.length}</p>

                                                </Col>
                                                {/* <Col span={2}>
                                                    <Button onClick={() => this.deleteAnswer(item)}>
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Col> */}
                                            </Row>
                                            <Row gutter={24} style={{ paddingTop: "1em " }}>
                                                <Col span={1}>
                                                    <Checkbox checked={this.state.answers[1].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[1].key)}></Checkbox>
                                                </Col>
                                                <Col span={23}>
                                                    <Input
                                                        // prefix={<div>{this.state.answersLimit - this.state.answers[1].text.length}<Divider type="vertical"></Divider></div>}
                                                        key={this.state.answers[1].key}
                                                        value={this.state.answers[1].text}
                                                        onChange={(event) => this.changeAnswer(event, this.state.answers[1].key, 2)}>{this.state.answers[1].answer}</Input>
                                                    <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[1].text.length}</p>

                                                </Col>
                                                {/* <Col span={2}>
                                                    <Button onClick={() => this.deleteAnswer(item)}>
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Col> */}
                                            </Row>
                                            {this.state.actualAnswerCount >= 3 &&
                                                <Row gutter={24} style={{ paddingTop: "1em " }}>
                                                    <Col span={1}>
                                                        <Checkbox checked={this.state.answers[2].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[2].key)}></Checkbox>
                                                    </Col>
                                                    <Col span={23}>
                                                        <Input
                                                            // prefix={<div>{this.state.answersLimit - this.state.answers[2].text.length}<Divider type="vertical"></Divider></div>}
                                                            key={this.state.answers[2].key}
                                                            value={this.state.answers[2].text}
                                                            onChange={(event) => this.changeAnswer(event, this.state.answers[2].key, 3)}>{this.state.answers[2].answer}</Input>
                                                        <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[2].text.length}</p>

                                                    </Col>
                                                    {/* <Col span={2}>
                                                    <Button onClick={() => this.deleteAnswer(item)}>
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Col> */}
                                                </Row>
                                            }
                                            {this.state.actualAnswerCount === 4 &&
                                                <Row gutter={24} style={{ paddingTop: "1em " }}>
                                                    <Col span={1}>
                                                        <Checkbox checked={this.state.answers[3].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[3].key)}></Checkbox>
                                                    </Col>
                                                    <Col span={23}>
                                                        <Input
                                                            // prefix={<div>{this.state.answersLimit - this.state.answers[3].text.length}<Divider type="vertical"></Divider></div>}
                                                            key={this.state.answers[3].key}
                                                            value={this.state.answers[3].text}
                                                            onChange={(event) => this.changeAnswer(event, this.state.answers[3].key, 4)}>{this.state.answers[3].answer}</Input>
                                                        <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[3].text.length}</p>

                                                    </Col>
                                                    {/* <Col span={2}>
                                                    <Button onClick={() => this.deleteAnswer(item)}>
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Col> */}
                                                </Row>
                                            }
                                        </div>}
                                    {this.state.actualType[0].value === this.state.questionType[1].value && this.state.answers &&
                                        <div>
                                            <Row gutter={[24, 10]} align="middle">
                                                <Col span={2}>
                                                    <Checkbox checked={this.state.answers[0].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[0].key)}> </Checkbox>
                                                </Col>
                                                <Col className="gutter-row" span={4} >
                                                    <img onClick={this.priviewModalOpen}
                                                        id={1}
                                                        alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                        src={this.state.preview1}
                                                    ></img>
                                                </Col>
                                                <Col className="gutter-row" span={18} >
                                                    <ImgCrop rotate
                                                        beforeCrop={beforeCrop}
                                                        modalTitle="Kép (maximum 5 MB)">

                                                        <Upload
                                                            multiple={false}
                                                            beforeUpload={this.beforeUploadPicture1}
                                                            fileList={this.state.picture1file}
                                                            // onRemove={this.removeFile}
                                                            onChange={this.handleChange}
                                                            showUploadList={false}
                                                            accept=".png,.jpg,.jpeg">
                                                            <Button>1. kép kiválasztása</Button>
                                                        </Upload>
                                                    </ImgCrop>
                                                </Col>
                                            </Row>
                                            <Row gutter={[24, 10]} align="middle">
                                                <Col span={2}>
                                                    <Checkbox checked={this.state.answers[1].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[1].key)}> </Checkbox>
                                                </Col>
                                                <Col className="gutter-row" span={4} >
                                                    <img onClick={this.priviewModalOpen}
                                                        id={2}
                                                        alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                        src={this.state.preview2}
                                                    ></img>
                                                </Col>
                                                <Col className="gutter-row" span={18} >
                                                    <ImgCrop rotate
                                                        beforeCrop={beforeCrop}
                                                        modalTitle="Kép (maximum 5 MB)">

                                                        <Upload
                                                            multiple={false}
                                                            beforeUpload={this.beforeUploadPicture2}
                                                            fileList={this.state.picture2file}
                                                            // onRemove={this.removeFile}
                                                            onChange={this.handleChange}
                                                            showUploadList={false}

                                                            accept=".png,.jpg,.jpeg">
                                                            <Button>2. kép kiválasztása</Button>
                                                        </Upload>
                                                    </ImgCrop>
                                                </Col>
                                            </Row>
                                            {this.state.actualAnswerCount >= 3 &&
                                                <Row gutter={[24, 10]} align="middle">
                                                    <Col span={2}>
                                                        <Checkbox checked={this.state.answers[2].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[2].key)}> </Checkbox>
                                                    </Col>                                                <Col className="gutter-row" span={4} >
                                                        <img onClick={this.priviewModalOpen}
                                                            id={3}
                                                            alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                            src={this.state.preview3}
                                                        ></img>
                                                    </Col>
                                                    <Col className="gutter-row" span={18} >
                                                        <ImgCrop rotate
                                                            beforeCrop={beforeCrop}
                                                            modalTitle="Kép (maximum 5 MB)">

                                                            <Upload
                                                                multiple={false}
                                                                beforeUpload={this.beforeUploadPicture3}
                                                                fileList={this.state.picture3file}
                                                                showUploadList={false}

                                                                // onRemove={this.removeFile}
                                                                onChange={this.handleChange}
                                                                accept=".png,.jpg,.jpeg">
                                                                <Button>3. kép kiválasztása</Button>
                                                            </Upload>
                                                        </ImgCrop>
                                                    </Col>
                                                </Row>}
                                            {this.state.actualAnswerCount === 4 && <Row gutter={[24, 10]} align="middle">
                                                <Col span={2}>
                                                    <Checkbox checked={this.state.answers[3].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[3].key)}> </Checkbox>
                                                </Col>                                                <Col className="gutter-row" span={4} >
                                                    <img onClick={this.priviewModalOpen}
                                                        id={4}
                                                        alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                        src={this.state.preview4}
                                                    ></img>
                                                </Col>
                                                <Col className="gutter-row" span={18} >
                                                    <ImgCrop rotate
                                                        beforeCrop={beforeCrop}
                                                        modalTitle="Kép (maximum 5 MB)">

                                                        <Upload
                                                            multiple={false}
                                                            beforeUpload={this.beforeUploadPicture4}
                                                            fileList={this.state.picture4file}
                                                            // onRemove={this.removeFile}
                                                            onChange={this.handleChange}
                                                            showUploadList={false}
                                                            accept=".png,.jpg,.jpeg">
                                                            <Button>3. kép kiválasztása</Button>
                                                        </Upload>
                                                    </ImgCrop>
                                                </Col>
                                            </Row>}
                                            <Modal
                                                visible={this.state.previewVisible}
                                                title={"Kép"}
                                                footer={null}
                                                onCancel={this.handleCancelPhoto}
                                            >
                                                <div>
                                                    <img alt="example" style={{ width: '100%' }} src={this.state.actualPictureInModal} />
                                                </div>
                                            </Modal>
                                        </div>}
                                    {this.state.actualType[0].value === this.state.questionType[2].value && this.state.answers &&
                                        <div>

                                            <Row style={{ minHeight: "2em" }} gutter={[24, 10]} align="middle" justify="center">
                                                <Col span={2}>
                                                    <Checkbox checked={this.state.answers[0].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[0].key)}> </Checkbox>
                                                </Col>
                                                <Col className="gutter-row" span={4} >
                                                    <img onClick={this.priviewModalOpen}
                                                        id={1}
                                                        alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                        src={this.state.preview1}
                                                    ></img>
                                                </Col>
                                                <Col span={12}>
                                                    <Input
                                                        // prefix={<div>{this.state.answersLimit - this.state.answers[0].text.length}<Divider type="vertical"></Divider></div>}

                                                        key={this.state.answers[0].key}
                                                        value={this.state.answers[0].text}
                                                        onChange={(event) => this.changeAnswer(event, this.state.answers[0].key, 1)}
                                                    >{this.state.answers[0].answer}</Input>
                                                    <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[0].text.length}</p>

                                                </Col>
                                                <Col className="gutter-row" span={6} >
                                                    <ImgCrop rotate
                                                        beforeCrop={beforeCrop}
                                                        modalTitle="Kép (maximum 5 MB)">

                                                        <Upload
                                                            multiple={false}
                                                            beforeUpload={this.beforeUploadPicture1}
                                                            fileList={this.state.picture1file}
                                                            // onRemove={this.removeFile}
                                                            onChange={this.handleChange}
                                                            showUploadList={false}
                                                            accept=".png,.jpg,.jpeg">
                                                            <Button>1. kép kiválasztása</Button>
                                                        </Upload>
                                                    </ImgCrop>
                                                </Col>
                                            </Row>
                                            <Row style={{ minHeight: "2em" }} gutter={[24, 10]} align="middle" justify="center">
                                                <Col span={2}>
                                                    <Checkbox checked={this.state.answers[1].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[1].key)}> </Checkbox>
                                                </Col>
                                                <Col className="gutter-row" span={4} >
                                                    <img onClick={this.priviewModalOpen}
                                                        id={2}
                                                        alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                        src={this.state.preview2}
                                                    ></img>
                                                </Col>
                                                <Col span={12}>
                                                    <Input
                                                        // prefix={<div>{this.state.answersLimit - this.state.answers[1].text.length}<Divider type="vertical"></Divider></div>}
                                                        key={this.state.answers[1].key}
                                                        value={this.state.answers[1].text}
                                                        onChange={(event) => this.changeAnswer(event, this.state.answers[1].key, 2)}
                                                    >{this.state.answers[1].answer}
                                                    </Input>
                                                    <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[1].text.length}</p>

                                                </Col>

                                                <Col className="gutter-row" span={6} >
                                                    <ImgCrop rotate
                                                        beforeCrop={beforeCrop}
                                                        modalTitle="Kép (maximum 5 MB)">

                                                        <Upload
                                                            multiple={false}
                                                            beforeUpload={this.beforeUploadPicture2}
                                                            fileList={this.state.picture2file}
                                                            // onRemove={this.removeFile}
                                                            onChange={this.handleChange}
                                                            showUploadList={false}

                                                            accept=".png,.jpg,.jpeg">
                                                            <Button>2. kép kiválasztása</Button>
                                                        </Upload>
                                                    </ImgCrop>
                                                </Col>
                                            </Row>
                                            {this.state.actualAnswerCount >= 3 && <Row style={{ minHeight: "2em" }} gutter={[24, 10]} align="middle" justify="center">
                                                <Col span={2}>
                                                    <Checkbox checked={this.state.answers[2].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[2].key)}> </Checkbox>
                                                </Col>
                                                <Col className="gutter-row" span={4} >
                                                    <img onClick={this.priviewModalOpen}
                                                        id={3}
                                                        alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                        src={this.state.preview3}
                                                    ></img>
                                                </Col>
                                                <Col span={12}>
                                                    <Input
                                                        // prefix={<div>{this.state.answersLimit - this.state.answers[2].text.length}<Divider type="vertical"></Divider></div>}
                                                        key={this.state.answers[2].key}
                                                        value={this.state.answers[2].text}
                                                        onChange={(event) => this.changeAnswer(event, this.state.answers[2].key, 3)}
                                                    >{this.state.answers[0].answer}</Input>
                                                    <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[2].text.length}</p>

                                                </Col>

                                                <Col className="gutter-row" span={6} >
                                                    <ImgCrop rotate
                                                        beforeCrop={beforeCrop}
                                                        modalTitle="Kép (maximum 5 MB)">

                                                        <Upload
                                                            multiple={false}
                                                            beforeUpload={this.beforeUploadPicture3}
                                                            fileList={this.state.picture3file}
                                                            showUploadList={false}

                                                            // onRemove={this.removeFile}
                                                            onChange={this.handleChange}
                                                            accept=".png,.jpg,.jpeg">
                                                            <Button>3. kép kiválasztása</Button>
                                                        </Upload>
                                                    </ImgCrop>
                                                </Col>
                                            </Row>
                                            }
                                            {this.state.actualAnswerCount === 4 && <Row style={{ minHeight: "2em" }} gutter={[24, 10]} align="middle" justify="center">
                                                <Col span={2}>
                                                    <Checkbox checked={this.state.answers[3].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[3].key)}> </Checkbox>
                                                </Col>
                                                <Col className="gutter-row" span={4} >
                                                    <img onClick={this.priviewModalOpen}
                                                        id={4}
                                                        alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                        src={this.state.preview4}
                                                    ></img>
                                                </Col>
                                                <Col span={12}>
                                                    <Input
                                                        // prefix={<div>{this.state.answersLimit - this.state.answers[3].text.length}<Divider type="vertical"></Divider></div>}
                                                        key={this.state.answers[3].key}
                                                        value={this.state.answers[3].text}
                                                        onChange={(event) => this.changeAnswer(event, this.state.answers[3].key, 4)}
                                                    >{this.state.answers[3].answer}</Input>
                                                    <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[3].text.length}</p>

                                                </Col>

                                                <Col className="gutter-row" span={6} >
                                                    <ImgCrop rotate
                                                        beforeCrop={beforeCrop}
                                                        modalTitle="Kép (maximum 5 MB)">

                                                        <Upload
                                                            multiple={false}
                                                            beforeUpload={this.beforeUploadPicture4}
                                                            fileList={this.state.picture4file}
                                                            // onRemove={this.removeFile}
                                                            onChange={this.handleChange}
                                                            showUploadList={false}
                                                            accept=".png,.jpg,.jpeg">
                                                            <Button>4. kép kiválasztása</Button>
                                                        </Upload>
                                                    </ImgCrop>
                                                </Col>
                                            </Row>
                                            }

                                        </div>}
                                    {this.state.actualType[0].value === this.state.questionType[3].value && this.state.answers &&
                                        <div>
                                            <Row style={{ minHeight: "2em" }} gutter={[24, 10]} align="middle" justify="center">
                                                <Col span={2}>
                                                    <Checkbox checked={this.state.answers[0].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[0].key)}> </Checkbox>
                                                </Col>
                                                <Col className="gutter-row" span={4} >
                                                    <div style={{ paddingRight: '24px' }}>
                                                        <Button
                                                            disabled={(this.state.answer1AudioFile && this.state.answer1AudioFile.size) ||
                                                                (this.props.questionAnswersData &&
                                                                    this.props.questionAnswersData[0] &&
                                                                    this.props.questionAnswersData[0].answers &&
                                                                    this.props.questionAnswersData[0].answers[0] &&
                                                                    this.props.questionAnswersData[0].answers[0].soundURL &&
                                                                    this.props.questionAnswersData[0].answers[0].soundURL !== "")
                                                                ? false : true}
                                                            onClick={this.togglePlayAnswer1}
                                                            icon={
                                                                !this.state.playAnswer1 ?
                                                                    <PlayCircleOutlined style={{ fontSize: "2vh" }} /> :
                                                                    <PauseCircleOutlined style={{ fontSize: "2vh" }} />
                                                            }>
                                                            {this.state.playAnswer1 ? 'Pause' : 'Play'}
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <Input
                                                        // prefix={<div>{this.state.answersLimit - this.state.answers[0].text.length}<Divider type="vertical"></Divider></div>}

                                                        key={this.state.answers[0].key}
                                                        value={this.state.answers[0].text}
                                                        onChange={(event) => this.changeAnswer(event, this.state.answers[0].key, 1)}
                                                    >{this.state.answers[0].answer}
                                                    </Input>
                                                    <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[0].text.length}</p>

                                                </Col>
                                                <Col className="gutter-row" span={6} >
                                                    <Upload
                                                        multiple={false}
                                                        beforeUpload={this.beforeUploadAnswer1Audio}
                                                        fileList={this.state.answer1AudioFile}
                                                        // onRemove={this.removeFile}
                                                        onChange={this.handleChange}
                                                        showUploadList={false}
                                                        accept=".mp3">
                                                        <Button>1. hang kiválasztása</Button>
                                                    </Upload>
                                                </Col>
                                            </Row>
                                            <Row style={{ minHeight: "2em" }} gutter={[24, 10]} align="middle" justify="center">
                                                <Col span={2}>
                                                    <Checkbox checked={this.state.answers[1].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[1].key)}> </Checkbox>
                                                </Col>
                                                <Col className="gutter-row" span={4} >
                                                    <div style={{ paddingRight: '24px' }}>
                                                        <Button
                                                            disabled={(this.state.answer2AudioFile && this.state.answer2AudioFile.size) ||
                                                                (this.props.questionAnswersData && this.props.questionAnswersData[0] &&
                                                                    this.props.questionAnswersData[0].answers &&
                                                                    this.props.questionAnswersData[0].answers[1] &&
                                                                    this.props.questionAnswersData[0].answers[1].soundURL
                                                                    && this.props.questionAnswersData[0].answers[1].soundURL !== "")
                                                                ? false : true}
                                                            onClick={this.togglePlayAnswer2}
                                                            icon={
                                                                !this.state.playAnswer2 ?
                                                                    <PlayCircleOutlined style={{ fontSize: "2vh" }} /> :
                                                                    <PauseCircleOutlined style={{ fontSize: "2vh" }} />
                                                            }>
                                                            {this.state.playAnswer2 ? 'Pause' : 'Play'}
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <Input
                                                        // prefix={<div>{this.state.answersLimit - this.state.answers[1].text.length}<Divider type="vertical"></Divider></div>}
                                                        key={this.state.answers[1].key}
                                                        value={this.state.answers[1].text}
                                                        onChange={(event) => this.changeAnswer(event, this.state.answers[1].key, 2)}
                                                    >{this.state.answers[1].answer}
                                                    </Input>
                                                    <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[1].text.length}</p>

                                                </Col>

                                                <Col className="gutter-row" span={6} >
                                                    <Upload
                                                        multiple={false}
                                                        beforeUpload={this.beforeUploadAnswer2Audio}
                                                        fileList={this.state.answer2AudioFile}
                                                        // onRemove={this.removeFile}
                                                        onChange={this.handleChange}
                                                        showUploadList={false}
                                                        accept=".mp3">
                                                        <Button>2. hang kiválasztása</Button>
                                                    </Upload>
                                                </Col>
                                            </Row>
                                            {this.state.actualAnswerCount >= 3 && <Row style={{ minHeight: "2em" }} gutter={[24, 10]} align="middle" justify="center">
                                                <Col span={2}>
                                                    <Checkbox checked={this.state.answers[2].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[2].key)}> </Checkbox>
                                                </Col>
                                                <Col className="gutter-row" span={4} >
                                                    <div style={{ paddingRight: '24px' }}>
                                                        <Button
                                                            disabled={((this.state.answer3AudioFile && this.state.answer3AudioFile.size)) ||
                                                                (this.props.questionAnswersData && this.props.questionAnswersData[0] &&
                                                                    this.props.questionAnswersData[0].answers &&
                                                                    this.props.questionAnswersData[0].answers[2] &&
                                                                    this.props.questionAnswersData[0].answers[2].soundURL
                                                                    && this.props.questionAnswersData[0].answers[2].soundURL !== "")
                                                                ? false : true}
                                                            onClick={this.togglePlayAnswer3}
                                                            icon={
                                                                !this.state.playAnswer3 ?
                                                                    <PlayCircleOutlined style={{ fontSize: "2vh" }} /> :
                                                                    <PauseCircleOutlined style={{ fontSize: "2vh" }} />
                                                            }>
                                                            {this.state.playAnswer3 ? 'Pause' : 'Play'}
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <Input
                                                        // prefix={<div>{this.state.answersLimit - this.state.answers[2].text.length}<Divider type="vertical"></Divider></div>}
                                                        key={this.state.answers[2].key}
                                                        value={this.state.answers[2].text}
                                                        onChange={(event) => this.changeAnswer(event, this.state.answers[2].key, 3)}
                                                    >{this.state.answers[0].answer}</Input>
                                                    <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[2].text.length}</p>

                                                </Col>

                                                <Col className="gutter-row" span={6} >
                                                    <Upload
                                                        multiple={false}
                                                        beforeUpload={this.beforeUploadAnswer3Audio}
                                                        fileList={this.state.answer3AudioFile}
                                                        // onRemove={this.removeFile}
                                                        onChange={this.handleChange}
                                                        showUploadList={false}
                                                        accept=".mp3">
                                                        <Button>3. hang kiválasztása</Button>
                                                    </Upload>
                                                </Col>
                                            </Row>
                                            }
                                            {this.state.actualAnswerCount === 4 && <Row style={{ minHeight: "2em" }} gutter={[24, 10]} align="middle" justify="center">
                                                <Col span={2}>
                                                    <Checkbox checked={this.state.answers[3].isGood} onChange={(event) => this.checkBoxChange(event, this.state.answers[3].key)}> </Checkbox>
                                                </Col>
                                                <Col className="gutter-row" span={4} >
                                                    <div style={{ paddingRight: '24px' }}>
                                                        <Button
                                                            disabled={(this.state.answer4AudioFile && this.state.answer4AudioFile.size) ||
                                                                (this.props.questionAnswersData && this.props.questionAnswersData[0] &&
                                                                    this.props.questionAnswersData[0].answers &&
                                                                    this.props.questionAnswersData[0].answers[3] &&
                                                                    this.props.questionAnswersData[0].answers[3].soundURL
                                                                    && this.props.questionAnswersData[0].answers[3].soundURL !== "")
                                                                ? false : true}
                                                            onClick={this.togglePlayAnswer4}
                                                            icon={
                                                                !this.state.playAnswer4 ?
                                                                    <PlayCircleOutlined style={{ fontSize: "2vh" }} /> :
                                                                    <PauseCircleOutlined style={{ fontSize: "2vh" }} />
                                                            }>
                                                            {this.state.playAnswer4 ? 'Pause' : 'Play'}
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <Input
                                                        // prefix={<div>{this.state.answersLimit - this.state.answers[3].text.length}<Divider type="vertical"></Divider></div>}
                                                        key={this.state.answers[3].key}
                                                        value={this.state.answers[3].text}
                                                        onChange={(event) => this.changeAnswer(event, this.state.answers[3].key, 4)}
                                                    >{this.state.answers[3].answer}</Input>
                                                    <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.answers[3].text.length}</p>

                                                </Col>
                                                <Col className="gutter-row" span={6} >
                                                    <Upload
                                                        multiple={false}
                                                        beforeUpload={this.beforeUploadAnswer4Audio}
                                                        fileList={this.state.answer4AudioFile}
                                                        // onRemove={this.removeFile}
                                                        onChange={this.handleChange}
                                                        showUploadList={false}
                                                        accept=".mp3">
                                                        <Button>4. hang kiválasztása</Button>
                                                    </Upload>
                                                </Col>
                                            </Row>
                                            }

                                        </div>}
                                </Col>
                            </Row>
                            <Modal
                                visible={this.state.previewVisible}
                                title={"Előnézet"}
                                footer={null}
                                onCancel={this.handleCancelPhoto}
                            >
                                <div>
                                    <img alt="Nincs kép" style={{ width: '100%' }} src={this.state.actualPictureInModal} />
                                </div>
                            </Modal>
                        </Col>

                    </Row > :
                    <div style={{ height: "85vh" }}>Nincs Kérdés kiválasztva</div>
                }
            </div >
        )
    }
}
const mapState = (state) => {
    return {
        message: state.natureTrailReducer.message,
        code: state.natureTrailReducer.code,
        questionAnswers: state.questionReducer.questionAnswers,
        saveStatus: state.questionReducer.saveStatus,
    }
}

const QuestionAnswers = connect(mapState, mapDispatch)(ConnectedQuestionAnswers)
export default QuestionAnswers;
