import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Row, Col, Button, Divider, Mentions, Input, Tooltip, message } from 'antd';
import { SaveFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { getAllBadgeLanguageByBadgeId, saveLanguagedBadge } from '../../actions/badge';
import { getLanguagesList } from '../../actions/language';

const mapDispatch = (dispatch) => {
    return {
        getLanguagesList: () => dispatch(getLanguagesList()),
        getAllBadgeLanguageByBadgeId: (badgeId, lanuageCode) => dispatch(getAllBadgeLanguageByBadgeId(badgeId, lanuageCode)),
        saveLanguagedBadge: (saveObject) => dispatch(saveLanguagedBadge(saveObject))

    }
}

class ConnectedBageLanguages extends Component {
    state = {
        selectedLanguage: {},
        choosed: true,
        choosedId: undefined,
        description: "",
        name: ""

    }
    componentDidMount = async () => {
        await this.props.getLanguagesList()
        this.setState({
            selectedLanguage: {
                code: this.props.languages[1].code,
                id: this.props.languages[1].id
            },
            choosed: true
        }, () => this.getLanguagedTrailDataAndSetState())
    }
    getLanguagedTrailDataAndSetState = async () => {
        await this.props.getAllBadgeLanguageByBadgeId(this.props.badgeId, this.state.selectedLanguage.code)
        if (this.props.languagedBadge[0] !== undefined) {
            this.setState({
                name: this.props.languagedBadge[0].name,
                description: this.props.languagedBadge[0].description,
                choosedId: this.props.languagedBadge[0].id
            })
        }
        else {
            this.setState({ name: "", description: "", choosedId: undefined })
        }
    }

    // selectLanguageCode = (value) => {
    //     console.log(value)
    //     const filteredCode = this.props.languages.filter(item =>
    //         item.code === value)
    //     if (filteredCode[0].code === "hu") {
    //         this.setState({
    //             selectedLanguage: {
    //                 code: filteredCode[0].code,
    //                 id: filteredCode[0].id
    //             },
    //             choosed: false,
    //             name: "",
    //             description: ""
    //         }
    //         )
    //     } else {
    //         this.setState({
    //             selectedLanguage: {
    //                 code: filteredCode[0].code,
    //                 id: filteredCode[0].id
    //             },
    //             choosed: true
    //         }, async () => await this.getLanguagedTrailDataAndSetState())
    //     }
    // }


    setName = (event) => {
        const name = event.target.value
        this.setState({ name: name })
    }

    changeDescription = (value) => {
        this.setState({ description: value })
    }
    saveLanguagedBadge = async () => {
        const saveObj = {
            parentBadgeId: this.props.badgeId,
            code: this.state.selectedLanguage.code,
            languagedBadge: {
                id: this.state.choosedId,
                description: this.state.description,
                name: this.state.name
            }
        }
        if (this.props.badgeId === undefined || this.props.badgeId == null) {
            message.error("Nyelv hozzáadása előtt el kell mentni a kitűzőt")
        } else {
            await this.props.saveLanguagedBadge(saveObj);
            if (this.props.languagedBadgeSaveStatus === "saved") {
                message.success("Sikeres mentés")
            } else {
                message.error("Hiba a mentés során!")
            }
            await this.getLanguagedTrailDataAndSetState();
            this.props.updateList()
        }



    }


    render() {
        return (
            <div>
                <Row gutter={24} align="middle" justify="space-between">
                    <Col span={12}>
                        {/* <Select
                            style={{ width: "100%" }}
                            onSelect={this.selectLanguageCode}
                            value={this.state.selectedLanguage.code}
                        >
                            {this.props.languages.map(item => {
                                return (<Option key={item.id} value={item.code}>{item.code}</Option>)
                            })}
                        </Select> */}
                    </Col>

                    <Col span={12}
                        style={{
                            justifyContent: "flex-end",
                            display: "flex"
                        }}>
                        {
                            this.state.choosed &&
                            <Button type="primary" icon={<SaveFilled />} onClick={this.saveLanguagedBadge} >Mentés </Button>}

                    </Col>

                </Row>
                {this.state.choosed &&
                    <React.Fragment>
                        <Divider></Divider>
                        <Row gutter={24}>

                            <Col span={24}>
                                <h4><Tooltip
                                    placement="topLeft"
                                    title={this.props.name}
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip> Kitűző neve*:</h4>
                            </Col>
                            <Col span={24}>
                                <Input required={true} value={this.state.name} onChange={this.setName}></Input>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <h4><Tooltip
                                    placement="topLeft"
                                    title={this.props.description}
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip> Leírás:</h4>
                            </Col>
                            <Col span={24}>
                                <Mentions rows="6"
                                    placeholder="Kitűző rövid leírásának a helye."
                                    title="Válasz"
                                    value={this.state.description}
                                    onChange={this.changeDescription}
                                >
                                </Mentions>
                            </Col>
                        </Row>
                    </React.Fragment>
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        languages: state.languagesReducer.languages,
        languagesStatus: state.languagesReducer.languagesStatus,
        languagedBadge: state.badgeReducer.languagedBadge,
        languagedBadgeStatus: state.badgeReducer.languagedBadgeStatus,
        languagedBadgeSaveStatus: state.badgeReducer.languagedBadgeSaveStatus,
    }
};
const BageLanguages = connect(mapStateToProps, mapDispatch)(ConnectedBageLanguages);
export default BageLanguages;
