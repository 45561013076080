import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAllLocationAdmin,
  saveLocationAdmin,
  deleteLocationAdmin,
  modifyLocationAdmin,
} from "../../actions/location-admin";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Select,
  Form,
  message,
  Popconfirm,
} from "antd";
import { getNationalParks, getRegions } from "../../actions/nature-trail";

import { SaveFilled } from "@ant-design/icons";

const mapDispatch = (dispatch) => {
  return {
    getAllLocationAdmin: () => dispatch(getAllLocationAdmin()),
    getNationalParks: () => dispatch(getNationalParks()),
    getRegions: () => dispatch(getRegions()),
    saveLocationAdmin: (locationAdmin) =>
      dispatch(saveLocationAdmin(locationAdmin)),
    deleteLocationAdmin: (id) => dispatch(deleteLocationAdmin(id)),
    modfiyLocationAdmin: (locationAdmin) =>
      dispatch(modifyLocationAdmin(locationAdmin)),
  };
};

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedLocationAdmins extends Component {
  formRef = React.createRef();

  state = {
    selectedId: null,
    email: "",
    name: "",
    password: "",
    selectedNationalPark: null,
    selectedRegion: null,
    modify: false,
  };

  componentDidMount = async () => {
    await this.props.getAllLocationAdmin();
    await this.props.getNationalParks();
    await this.props.getRegions();
  };

  handleChangeNationalPark = (event) => {
    this.setState({ selectedNationalPark: event });
  };
  handleChangeRegion = (event) => {
    this.setState({ selectedRegion: event });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  modifyLocationAdmin = (locationAdmin) => {
    this.setState({
      modify: true,
      password: "",
      email: locationAdmin.email,
      name: locationAdmin.name,
      selectedNationalPark: locationAdmin.nationalParkId,
      selectedRegion: locationAdmin.regionId,
      selectedId: locationAdmin.id,
    });
    this.formRef.current.setFieldsValue({
      password: "",
      email: locationAdmin.email,
      name: locationAdmin.name,
      nationalPark: locationAdmin.nationalParkId,
      region: locationAdmin.regionId,
    });
  };

  handleCancelModify = () => {
    this.setToDefault();
  }

  setToDefault = () => {
    this.formRef.current.resetFields();
    this.setState({
      modify: false,
      password: "",
      name: "",
      email: "",
      selectedNationalPark: null,
      selectedRegion: null,
      selectedId: null,
    });
  };

  submitForm = async () => {
    const locationAdminToSave = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      nationalParkId: this.state.selectedNationalPark,
      regionId: this.state.selectedRegion,
    };
    if (this.state.modify) {
      locationAdminToSave.id = this.state.selectedId;
      if (locationAdminToSave.password === "") {
        delete locationAdminToSave.password;
      }
      await this.props.modfiyLocationAdmin(locationAdminToSave);
    } else {
      await this.props.saveLocationAdmin(locationAdminToSave);
    }

    if (this.props.locationAdminsStatus) {
      message.success("Sikeres mentés!", 2);
      await this.props.getAllLocationAdmin();
      this.setToDefault();
    } else {
      message.error("Sikertelen mentés!", 2);
    }
  };

  deleteUser = async (user) => {
    await this.props.deleteLocationAdmin(user.id);
    await this.props.getAllLocationAdmin();
  };

  render() {
    const columns = [
      { title: "E-mail cím", dataIndex: "email", key: "email" },
      { title: "Név", dataIndex: "name", key: "name" },
      {
        title: "Adatok",
        key: "showData",
        render: (text, row) => (
          <Space size="middle">
            <Button onClick={() => this.modifyLocationAdmin(row)}>
              Módosítás
            </Button>
          </Space>
        ),
      },
      {
        title: "Törlés",
        key: "delete",
        render: (text, row) => (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarja a felhasználót?"
              okText="Igen"
              cancelText="Nem"
              onConfirm={() => this.deleteUser(row)}
            >
              <Button>Törlés</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col span={16} style={{ padding: "1em" }}>
            <h1>Helyszín Adminok</h1>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={this.props.locationAdmins}
              locale={{ emptyText: "Nincsenek helyszínadminok!" }}
              //loading={!this.props.status && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
              }}
              size="small"
            />
          </Col>
          <Col span={8} style={{ padding: "1em" }}>
            <h1>{this.state.modify ? "Helyszínadmin módosítás" : "Új helyszínadmin"}</h1>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="project"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="Név"
                rules={[
                  {
                    required: true,
                    message: "Kérem adja meg a nevet!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email cím"
                rules={[
                  {
                    required: true,
                    message: "Kérem adja meg az email címet!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Jelszó"
                rules={[
                  {
                    required: !this.state.modify,
                    message: "Kérem adja meg a jelszót!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="nationalPark"
                label="Nemzeti Park"
                rules={[
                  {
                    required: true,
                    message: "Kérem válasszon nemzeti parkot!",
                  },
                ]}
              >
                <Select
                  name="nationalPark"
                  value={this.state.selectedNationalPark}
                  onChange={this.handleChangeNationalPark}
                >
                  {this.props.nationalParks.map((nationalPark) => {
                    return (
                      <Option value={nationalPark.id} key={nationalPark.id}>
                        {nationalPark.value}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="region"
                label="Régió"
                rules={[
                  {
                    required: true,
                    message: "Kérem válasszon régiót!",
                  },
                ]}
              >
                <Select
                  name="region"
                  value={this.state.selectedRegion}
                  onChange={this.handleChangeRegion}
                >
                  {this.props.regions.map((region) => {
                    return (
                      <Option value={region.id} key={region.id}>
                        {region.value}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ margin: "0.5em" }}
                >
                  <SaveFilled />
                  Mentés
                </Button>
                <Button onClick={this.handleCancelModify}>
                  Mégse
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    message: state.natureTrailReducer.message,
    code: state.natureTrailReducer.code,
    status: state.natureTrailReducer.status,
    natureTrails: state.natureTrailReducer.data,
    locationAdmins: state.userReducer.locationAdmins.locationAdmins,
    nationalParks: state.natureTrailReducer.nationalParks,
    regions: state.natureTrailReducer.regions,
    locationAdminsStatus: state.userReducer.locationAdminsStatus,
  };
};

const LocationAdmins = connect(mapState, mapDispatch)(ConnectedLocationAdmins);
export default LocationAdmins;
