import axios from "axios";
import { PHOTO } from "../constants/action-types";

export const getPhotosByNatureTrailId = (id) => {
    return async (dispatch) => {
        dispatch({ type: PHOTO.PHOTO_ANSWER_BY_NATURETRAIL_ID_LOADING });
        await axios
            .get("photo/all-by-nature-trail-location-id", {
                params: {
                    id: id
                }
            })
            .then((response) => {
                dispatch({
                    type: PHOTO.PHOTO_ANSWER_BY_NATURETRAIL_ID_LOADED,
                    payload: { data: response.data.photos },
                });
            })
            .catch((err) => {
                dispatch({
                    type: PHOTO.PHOTO_ANSWER_BY_NATURETRAIL_ID_ID_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}

export const getPhotoByQuestionId = (id) => {
    return async (dispatch) => {
        dispatch({ type: PHOTO.PHOTO_ANSWER_BY_QUESTION_ID_LOADING });
        await axios
            .get("photo/one-by-id", {
                params: {
                    id: id
                }
            })
            .then((response) => {
                dispatch({
                    type: PHOTO.PHOTO_ANSWER_BY_QUESTION_ID_LOADED,
                    payload: { data: response.data.photo },
                });
            })
            .catch((err) => {
                dispatch({
                    type: PHOTO.PHOTO_ANSWER_BY_QUESTION_ID_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}

export const savePhoto = (photo) => {
    return async (dispatch) => {
        dispatch({ type: PHOTO.PHOTO_ANSWER_SAVING });
        await axios
            .post("/photo/save-photo", photo
            )
            .then((response) => {
                dispatch({
                    type: PHOTO.PHOTO_ANSWER_SAVED,
                    payload: { data: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: PHOTO.PHOTO_ANSWER_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}