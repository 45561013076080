import {EditOutlined, InboxOutlined, PlusCircleOutlined, SearchOutlined} from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Input, message, Row, Space, Table } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllNationalParks, saveNatinalPark, createOneNationalPark, setActiveOrDeactiveNationalPark } from '../../actions/nature-trail';
import Highlighter from "react-highlight-words";
import './style.scss';

const mapDispatchToProps = (dispatch) => {
    return {
        getAllNationalParks: () => dispatch(getAllNationalParks()),
        createOneNationalPark: () => dispatch(createOneNationalPark()),
        setActiveOrDeactiveNationalPark: (id) => dispatch(setActiveOrDeactiveNationalPark(id)),
        saveNatinalPark: (nationalParkData, files) => dispatch(saveNatinalPark(nationalParkData, files))
    };
};
class ConnectedNationalParkEditor extends Component {
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
                             setSelectedKeys,
                             selectedKeys,
                             confirm,
                             clearFilters,
                         }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Keresés
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Törlés
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>

            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : "",
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    handleChangeTable = (pagination, filters, sorter) => {
        this.setState({
            sortedInfo: sorter,
            pageSize: pagination.pageSize,
        });
    };

    state = {
        columns: [
            {
                title: 'Név',
                dataIndex: 'value',
                ...this.getColumnSearchProps("value"),
                sorter: (a, b) => (a.value ?? "" ).localeCompare(b.value ?? "")
            },
            {
                title: "Aktív",
                key: "delete",
                render: (text, row) => (
                    <Space size="middle">
                        <Checkbox onChange={() => this.activateDeactivate(row)} checked={row.isActive} ></Checkbox>

                    </Space>
                ),
            },
            {
                title: "Módosít",
                key: "edit",
                render: (text, row) => (
                    <Space size="middle">
                        <Button onClick={() => this.showDataAndEdit(row)} icon={<EditOutlined />} shape="circle"></Button>
                    </Space>
                ),
            },
        ],
        selectedObj: {},
        tableData: [],
        logoFile: [],
        sortedInfo: {},
        pageSize: 10,
    }
    componentDidMount = async () => {
        this.refrestTable()
    }

    addNewNationalPark = async () => {
        await this.props.createOneNationalPark();
        this.refrestTable()
    }

    activateDeactivate = async (row) => {
        await this.props.setActiveOrDeactiveNationalPark(row.id)
        this.refrestTable()
    }

    showDataAndEdit = (row) => {
        this.setState({ selectedObj: Object.assign({}, row), logoFile: [] },
            () => {
                if (this.state.selectedObj.logoSavedFileURL && this.state.selectedObj.logoSavedFileURL !== null) {
                    const selectedObj = this.state.selectedObj
                    selectedObj.logoSavedFileURL = process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.selectedObj.logoSavedFileURL
                    this.setState({ selectedObj: selectedObj })
                }
            })
    }

    refrestTable = async () => {
        await this.props.getAllNationalParks()
        this.setState({ tableData: this.props.allNationalParks })
    }

    changeSelectedNationalParkValue = (event) => {
        const actValue = event.target.value
        const obj = this.state.selectedObj
        obj.value = actValue
        this.setState({
            selectedObj: obj
        })
    }
    //TODO
    saveNatinalPark = async () => {
        await this.props.saveNatinalPark(this.state.selectedObj, this.state.logoFile)
        if (this.props.nationalParkSavingStatus === "saved") {
            message.success("Sikeres mentés")
        }
        else {
            message.warning("Hiba a mentés közben")
        }
        this.refrestTable()
    }
    //upload methods
    beforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 1;
        const actObj = this.state.selectedObj

        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 1MB!');
        } else {
            actObj.logoSavedFileURL = URL.createObjectURL(file)
            this.setState({ logoFile: [file], selectedObj: actObj })
        }
        return isLt2M;
    }

    removeLogofile = () => {
        this.setState({ logoFile: [] })
    }
    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.setState({ uploaded: true })

        }
    };

    render() {
        const beforeCrop = (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                message.error('A képnek kissebbnek kell lennie mint 5MB!');
                return false
            } else {
                //this.setState({ preview1a: URL.createObjectURL(file), picture1afile: file, saveButtonActive: true })
                return true
            }
        }
        return <div>
            <div>
                <h1>Nemzeti parkok</h1>
                <Row gutter={24} style={{ height: "5vh" }}>
                    <Button type="primary" onClick={this.addNewNationalPark}> <PlusCircleOutlined />Új Nemzeti park</Button>
                </Row>
                <Row gutter={24} style={{ height: "60vh" }}>
                    <Col span={8}>
                        <div className="nat-parks-table">
                        <Table
                            scroll={{ y: '60vh' }}
                            size="small"
                            overflowY="auto"
                            rowKey="id"
                            dataSource={this.state.tableData}
                            columns={this.state.columns}
                            pagination={false}
                            onChange={this.handleChangeTable}
                        />
                        </div>
                    </Col>
                    <Col span={16}>
                        {this.state.selectedObj.value && <Row gutter={[24, 16]}>
                            <Col span={24} style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                                display: "flex"
                            }}>
                                <b>Nemzeti park név:</b>
                                <Button onClick={this.saveNatinalPark}>Mentés</Button>
                            </Col>
                            <Col span={24}>
                                <Input value={this.state.selectedObj.value} onChange={this.changeSelectedNationalParkValue}></Input>
                            </Col>
                            <Col span={24}>
                                {/* <ImgCrop rotate aspect={1 / 1}
                                    beforeCrop={beforeCrop}
                                    // shape="round"
                                    fillColor="transparent"
                                > */}
                                <Dragger
                                    style={{ maxHeight: "10em" }}
                                    name="file"
                                    beforeUpload={this.beforeUpload}
                                    fileList={this.state.logoFile}
                                    listType={"text"}
                                    showUploadList={false}
                                    onRemove={this.removeLogofile}
                                    onChange={this.handleChange}
                                    accept=".png,.jpg,.jpeg">
                                    <div>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Logo helye (max 1 MB)</p>
                                        <p className="ant-upload-text">*.png, *.jpg, *.jpeg</p>
                                    </div>
                                </Dragger>
                                {/* </ImgCrop> */}
                                <Divider></Divider>
                            </Col>
                            <Col span={24} style={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex"
                            }}>
                                <img
                                    alt="" style={{ maxWidth: "20vw", maxHeight: "20vh" }}
                                    src={this.state.selectedObj.logoSavedFileURL} />
                            </Col>
                        </Row>}
                    </Col>
                </Row>
            </div>
        </div>;
    }
}
const mapStateToProps = (state) => ({
    allNationalParks: state.natureTrailReducer.allNationalParks,
    allNationalParksStatus: state.natureTrailReducer.allNationalParksStatus,
    nationalParkSavingStatus: state.natureTrailReducer.nationalParkSavingStatus,
    naturalTrailTypes: state.natureTrailReducer.allNaturalTrailTypes
});
const NationalParkEditor = connect(mapStateToProps, mapDispatchToProps)(ConnectedNationalParkEditor);
export default NationalParkEditor;