import { STATISTICS } from "../constants/action-types";

const initialState = {
    natureTrailByRating: [],
    natureTrailByRatingStatus: null,
    bestUsers: [],
    bestUsersStatus: null,
    code: {},
    message: null
};

const statisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case STATISTICS.TOP_NATURE_TRAIL_LOADING:
            return Object.assign({}, state, {
                natureTrailByRating: [],
                natureTrailByRatingStatus: "loading",
                code: {},
                message: null
            })
        case STATISTICS.TOP_NATURE_TRAIL_LOADED:
            return Object.assign({}, state, {
                natureTrailByRating: action.payload,
                natureTrailByRatingStatus: "loaded",
                code: {},
                message: null
            })
        case STATISTICS.TOP_NATURE_TRAIL_ERROR:
            return Object.assign({}, state, {
                natureTrailByRating: [],
                natureTrailByRatingStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            })
        case STATISTICS.BEST_USER_LOADING:
            return Object.assign({}, state, {
                bestUsers: [],
                bestUsersStatus: "loading",
                code: {},
                message: null
            })
        case STATISTICS.BEST_USER_LOADED:
            return Object.assign({}, state, {
                bestUsers: action.payload,
                bestUsersStatus: "loaded",
                code: {},
                message: null
            })
        case STATISTICS.BEST_USER_ERROR:
            return Object.assign({}, state, {
                bestUsers: [],
                bestUsersStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            })
        default:
            return state;
    }
};

export default statisticsReducer;
