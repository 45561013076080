import React, { Component } from 'react'

export default class NatureTrailObjects extends Component {
    render() {
        return (
            <div>
                <h1>Tanösvény elemek  </h1>
            </div>
        )
    }
}
