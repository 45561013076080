import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Mentions, Button, message } from 'antd';
import { getPhotosByNatureTrailId, savePhoto, } from '../../actions/photo';

const mapDispatch = (dispatch) => {
    return {
        getPhotosByNatureTrailId: (natureTrailId) => dispatch((getPhotosByNatureTrailId(natureTrailId))),
        savePhoto: (photo) => dispatch(savePhoto(photo))
    }
}

class ConnectedPhoto extends Component {
    state = {
        key: undefined,
        actualTitle: "",
        actualTitleCharacterLimit: 50,
        actualDescription: "",
        actualDescriptionCharacterLimit: 160,
    }

    componentDidMount = async () => {
        if (this.props.naturalTrailLocationId !== undefined) {
            await this.props.getPhotosByNatureTrailId(this.props.naturalTrailLocationId);

            if (this.props.status === "loaded" && this.props.photoByLocation[0] !== undefined) {
                this.setState({
                    key: this.props.photoByLocation[0].id,
                    actualTitle: this.props.photoByLocation[0].title,
                    actualDescription: this.props.photoByLocation[0].description
                })
            }
        }
    }

    changeTitle = (event) => {
        const actualValue = event
        if (actualValue.length <= this.state.actualTitleCharacterLimit) {
            this.setState({ actualTitle: event })
        } else {
            message.error("A cím karakterszáma maximum " + this.state.actualTitleCharacterLimit + " lehet.")
        }
    }

    changeDescription = (event) => {
        const actualValue = event
        if (actualValue.length <= this.state.actualDescriptionCharacterLimit) {
            this.setState({ actualDescription: event })
        } else {
            message.error("A cím karakterszáma maximum " + this.state.actualDescriptionCharacterLimit + " lehet.")
        }
    }

    savePhoto = async () => {
        const saveObj = {
            key: this.state.key,
            natureTrailLocationId: this.props.naturalTrailLocationId,
            title: this.state.actualTitle.trim(),
            description: this.state.actualDescription.trim()
        }
        if (this.state.actualTitle.length > this.state.actualTitleCharacterLimit) {
            message.error("A cím karakterszáma maximum " + this.state.actualTitleCharacterLimit + " lehet.")
        }
        else if (this.state.actualDescription.length > this.state.actualDescriptionCharacterLimit) {
            message.error("A leírás karakterszáma maximum " + this.state.actualDescriptionCharacterLimit + " lehet.")
        } else {
            await this.props.savePhoto(saveObj);
            console.log(this.props.photo);
            console.log(this.props.saveStatus);
            if (this.props.saveStatus === "saved" && this.props.photo !== undefined) {
                await this.props.getPhotosByNatureTrailId(this.props.naturalTrailLocationId);
                this.setState({
                    key: this.props.photo.id,
                    actualTitle: this.props.photo.title,
                    actualDescription: this.props.photo.description
                })
                this.props.setPhotoId(this.props.photo.id)
                message.success("Sikeres mentés")
            }
            else {
                message.error("Hiba a mentés során")
            }
        }
    }



    render() {
        return (
            <div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <h3>Cím</h3>
                </div>
                <Mentions rows="1"
                    title="Kérdés"
                    value={this.state.actualTitle}
                    onChange={this.changeTitle}
                >
                </Mentions>
                <p><strong>Hátralévő karekterek: </strong>{this.state.actualTitleCharacterLimit}/{this.state.actualTitleCharacterLimit - this.state.actualTitle.length}</p>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <h3>Leírás</h3>
                </div>
                <Mentions rows="5"
                    title="Válasz"
                    value={this.state.actualDescription}
                    onChange={this.changeDescription}
                >
                </Mentions>
                <p><strong>Hátralévő karekterek: </strong>{this.state.actualDescriptionCharacterLimit}/{this.state.actualDescriptionCharacterLimit - this.state.actualDescription.length}</p>
                <div style={{ paddingTop: "2vh" }} >

                    <Button onClick={this.savePhoto}>Mentés/Hozzáadás</Button>
                </div>
            </div>
        )
    }
}

const mapState = (state) => {
    return {
        photoByLocation: state.photoReducer.photoByLocation,
        photo: state.photoReducer.photo,
        status: state.photoReducer.status,
        saveStatus: state.photoReducer.saveStatus,
        //photoByLocation
    }
}

const Photo = connect(mapState, mapDispatch)(ConnectedPhoto)
export default Photo;
