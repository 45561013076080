import React, { Component } from "react";
import { Input, Button, Form, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./login.scss";
import { login } from "../../actions/login";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import HttpService from "../../http";
import vandorLogo from "../../static/login_vandor_logo.png";

const mapDispatch = (dispatch) => {
  return { login: (email, password) => dispatch((login(email, password))) }
}
class ConnectedLogin extends Component {
  state = {
    password: null,
    email: null,
    loginSuccesful: false
  }
  componentDidMount = () => { HttpService.setAxiosDefaults() }
  handlePasswordChange = (event) => {
    if (event.target.value !== undefined) {
      this.setState({ password: event.target.value })
    }
  }
  handleEmailChange = (event) => {
    if (event.target.value !== undefined) {
      this.setState({ email: event.target.value })
    }
  }
  clickOnLoginButton = async () => {
    await this.props.login(this.state.email, this.state.password)
    if (this.props.status !== 'loaded') {
      if (this.props.code === 1002) {
        // message.error(this.props.message);
        message.error("Érvénytelen email vagy jelszó")
      }
      else {
        message.error("Sikertelen bejelentkezés!");
      }
    } else {
      HttpService.setAxiosDefaults();
      HttpService.setTokenHeader(this.props.token)
      this.setState({ loginSuccesful: true })
    }
  }


  render() {
    return (
      <div style={{
        height: "100vh",
        backgroundImage: `url("/login_background.jpg")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100%",
        minWidth: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <div className="login-page-container"
        >
          <Form
            name="normal_login"
            className="login-form registrationform"
            initialValues={{ remember: true }}
          //onFinish={onFinish}
          >
            <div style={{
              height: "76px",
              width: "338px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              paddingBottom: "4vh"
            }}>
              <img src={vandorLogo} alt="Logo" style={{
                height: "76px",
                width: "46px"
              }} />
              <div style={{
                height: "27px",
                width: " 270px",
                color: "#FFFFFF",
                fontWeight: 900,
                fontFamily: "Publica Sans",
                fontSize: "27px",
                letterSpacing: 0,
                lineHeight: "35px"
              }}>
                CMS Bejelentkezés
            </div>
            </div>

            <Form.Item
              name="username"
              rules={[{ required: true, message: "Kérlek add meg az e-mail címed!" }]}
            >
              <Input
                className="site-form-item-icon"

                prefix={<UserOutlined />}
                placeholder="Email"
                onChange={this.handleEmailChange}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Kérlek add meg a jelszavad!" }]}
            >
              <Input
                className="site-form-item-icon"
                prefix={<LockOutlined />}
                type="password"
                placeholder="Jelszó"
                onChange={this.handlePasswordChange}
              />
            </Form.Item>



            <Form.Item
              style={{
                textAlignLast: "center",
              }}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-button"
                onClick={this.clickOnLoginButton}
              >
                Belépés
            </Button>
              {/* Or <a href="/registration">register now!</a> */}

              <Form.Item>

                <a className="login-form-forgot" href="/forgot-password">
                  Elfelejtettem a jelszavam
            </a>
              </Form.Item>
            </Form.Item>{
              this.state.loginSuccesful && <Redirect Form="**" to="/nt/landing" />
            }
          </Form>
        </div>
      </div>
    );
  }
}
const mapState = (state) => {
  return {
    token: state.loginReducer.token,
    message: state.loginReducer.message,
    code: state.loginReducer.code,
    status: state.loginReducer.status
  }
}

const Login = connect(mapState, mapDispatch)(ConnectedLogin)
export default Login;
