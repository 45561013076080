import { LANGUAGES } from "../constants/action-types";

const initialState = {
    languages: [],
    languagesStatus: null,
    languagesSavingStatus: null,
    activeDeactive: [],
    activeDeactiveStatus: null,
    languagedQuestion: {},
    languagedQuestionStatus: null,
    languagedPairMatch: {},
    languagedPairMatchStatus: null,
    message: {},
    code: null
};

const languagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LANGUAGES.LANGUAGES_CODE_LOADING:
            return Object.assign({}, state, {
                languages: [],
                languagesStatus: "loading",
                message: {},
                code: null
            })
        case LANGUAGES.LANGUAGES_CODE_LOADED:
            return Object.assign({}, state, {
                languages: action.payload.data,
                languagesStatus: "loaded",
                message: {},
                code: null
            })
        case LANGUAGES.LANGUAGES_CODE_ERROR:
            return Object.assign({}, state, {
                languages: [],
                languagesStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case LANGUAGES.NATURE_TRAIL_BY_LAGUAGE_CODE_LOADING:
            return Object.assign({}, state, {
                natureTrailByLanguage: [],
                natureTrailByLanguageStatus: "loading",
                message: {},
                code: null
            })
        case LANGUAGES.NATURE_TRAIL_BY_LAGUAGE_CODE_LOADED:
            return Object.assign({}, state, {
                natureTrailByLanguage: action.payload.data,
                natureTrailByLanguageStatus: "loaded",
                message: {},
                code: null
            })
        case LANGUAGES.NATURE_TRAIL_BY_LAGUAGE_CODE_ERROR:
            return Object.assign({}, state, {
                natureTrailByLanguage: [],
                natureTrailByLanguageStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case LANGUAGES.LANGUAGE_SAVING:
            return Object.assign({}, state, {

                languagesSavingStatus: "saving",
                message: {},
                code: null
            })
        case LANGUAGES.LANGUAGE_SAVED:
            return Object.assign({}, state, {

                languagesSavingStatus: "saved",
                message: {},
                code: null
            })
        case LANGUAGES.LANGUAGE_ERROR:
            return Object.assign({}, state, {

                languagesStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case LANGUAGES.NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_LOADING:
            return Object.assign({}, state, {
                activeDeactive: [],
                activeDeactiveStatus: "loading",
                message: {},
                code: null
            })
        case LANGUAGES.NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_LOADED:
            return Object.assign({}, state, {
                activeDeactive: action.payload.data,
                activeDeactiveStatus: "loaded",
                message: {},
                code: null
            })
        case LANGUAGES.NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_ERROR:
            return Object.assign({}, state, {
                activeDeactive: [],
                activeDeactiveStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case LANGUAGES.LANGUAGED_QUESTION_LOADING:
            return Object.assign({}, state, {
                languagedQuestion: {},
                languagedQuestionStatus: "loading",
                message: {},
                code: null
            })
        case LANGUAGES.LANGUAGED_QUESTION_LOADED:
            return Object.assign({}, state, {
                languagedQuestion: action.payload.data,
                languagedQuestionStatus: "loaded",
                message: {},
                code: null
            })
        case LANGUAGES.LANGUAGED_QUESTION_ERROR:
            return Object.assign({}, state, {
                languagedQuestion: {},
                languagedQuestionStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case LANGUAGES.LANGUAGED_PAIR_MATCH_LOADING:
            return Object.assign({}, state, {
                languagedPairMatch: {},
                languagedPairMatchStatus: "loading",
                message: {},
                code: null
            })
        case LANGUAGES.LANGUAGED_PAIR_MATCH_LOADED:
            return Object.assign({}, state, {
                languagedPairMatch: action.payload.data,
                languagedPairMatchStatus: "loaded",
                message: {},
                code: null
            })
        case LANGUAGES.LANGUAGED_PAIR_MATCH_ERROR:
            return Object.assign({}, state, {
                languagedPairMatch: {},
                languagedPairMatchStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        default:
            return state;
    }
};

export default languagesReducer;
