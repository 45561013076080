import axios from "axios";
import { USER } from "../constants/action-types";

export const getAllLocationAdmin = () => {
  return async (dispatch) => {
    dispatch({ type: USER.GET_ALL_LOCATION_ADMIN_LOADING });
    await axios
      .get("/users/location-admins", {})
      .then((response) => {
        dispatch({
          type: USER.GET_ALL_LOCATION_ADMIN_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: USER.GET_ALL_LOCATION_ADMIN_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
}; export const saveLocationAdmin = (locationAdmin) => {
  return async (dispatch) => {
    dispatch({ type: USER.SAVE_LOCATION_ADMIN_START });
    await axios
      .post("/users/location-admins", locationAdmin)
      .then((response) => {
        dispatch({ type: USER.SAVE_LOCATION_ADMIN_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: USER.SAVE_LOCATION_ADMIN_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};



export const modifyLocationAdmin = (locationAdmin) => {
  return async (dispatch) => {
    dispatch({ type: USER.SAVE_LOCATION_ADMIN_START });
    await axios
      .put("/users/location-admins/" + locationAdmin.id, locationAdmin)
      .then((response) => {
        dispatch({ type: USER.SAVE_LOCATION_ADMIN_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: USER.SAVE_LOCATION_ADMIN_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};

export const deleteLocationAdmin = (id) => {
  return async () => {
    await axios
      .delete("/users/location-admins/" + id)
      .catch((err) => console.log(err));
  };
};
