import React, { Component } from 'react'
import { Select } from 'antd'
import Location3DContent from './location-3d-content';
import LocationVideoContent from './location-video-content';
import LocationMusicContent from './location-music-content';

const { Option } = Select;

export default class NatureTrailLocationContent extends Component {
    state = {
        actualContent: 1,
        contentType: [
            { key: 1, value: "AR/3D" },
            { key: 2, value: "Video" },
            { key: 3, value: "Hang" },
        ],
    }
    componentDidMount = () => {
        if (this.props.contentType !== undefined) {
            this.setState({ actualContent: this.props.contentType })

        }
    }
    handleChange = (event) => {
        this.setState({ actualContent: event });
        this.props.setContentType(event)
    }

    renderChoosedContent = () => {
        switch (this.state.actualContent) {
            case 1:
                return <Location3DContent
                    natureTrailId={this.props.natureTrailId}
                    setIphone3Dfiles={this.props.setIphone3Dfiles}
                    iphone3Dfiles={this.props.iphone3Dfiles}
                    removeIphone3Dfiles={this.props.removeIphone3Dfiles}
                    setScaleOverrideIos={this.props.setScaleOverrideIos}
                    setScaleOverrideAndroid={this.props.setScaleOverrideAndroid}
                    setMarkerWidthIos={this.props.setMarkerWidthIos}
                    setMarkerWidthAndroid={this.props.setMarkerWidthAndroid}
                    iphone3dFileName={this.props.iphone3dFileName}
                    android3dFileName={this.props.android3dFileName}
                    setAndroid3DFiles={this.props.setAndroid3DFiles}
                    android3Dfiles={this.props.android3Dfiles}
                    removeAndroid3Dfiles={this.props.removeAndroid3Dfiles}
                    scaleOverrideIos={this.props.scaleOverrideIos}
                    scaleOverrideAndroid={this.props.scaleOverrideAndroid}
                    markerWidthIos={this.props.markerWidthIos}
                    markerWidthAndroid={this.props.markerWidthAndroid}
                />
            case 2:
                return <LocationVideoContent
                    natureTrailId={this.props.natureTrailId}
                    setVideo={this.props.setVideo}
                    video={this.props.video}
                    removeVideo={this.props.removeVideo}
                    movieFileURL={this.props.movieFileURL}
                    setIsYoutube={this.props.setIsYoutube}
                    setYoutubeLink={this.props.setYoutubeLink}
                    isYoutube={this.props.isYoutube}
                    youtubeLink={this.props.youtubeLink}
                />
            case 3:
                return <LocationMusicContent
                    natureTrailId={this.props.natureTrailId}
                    music={this.props.music}
                    musicFileURL={this.props.musicFileURL}
                    setMusic={this.props.setMusic}
                    removeMusic={this.props.removeMusic} />

            default:
                break;
        }
    }

    render() {
        return (
            <div  >
                <Select style={{ width: 120 }} defaultValue={this.props.contentType !== undefined ? this.props.contentType : this.state.actualContent} onSelect={this.handleChange}>
                    {this.state.contentType.map(item => { return (< Option value={item.key} > {item.value}</Option>) })}
                </Select>
                {this.renderChoosedContent()}
            </div>
        )
    }
}
