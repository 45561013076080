import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loginReducer from "../reducers/login";
import registrationReducer from "../reducers/registration";
import forgotPasswordReducer from "../reducers/forgot-password";
import natureTrailReducer from "../reducers/natural-trail";
import userReducer from "../reducers/user";
import questionReducer from "../reducers/questions";
import spinningReducer from "../reducers/spinning";
import badgeReducer from "../reducers/badge";
import languagesReducer from "../reducers/languages";
import statisticsReducer from "../reducers/statistics";
import contentReducer from "../reducers/content";
import pairMatchReducer from "../reducers/pair-match";
import photoReducer from "../reducers/photo";
import mobileEnvReducer from "../reducers/mobile-env";
import { LOGIN_ACTIONS } from "../constants/action-types";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "natureTrailReducer",
    "userReducer",
    "questionReducer",
    "spinningReducer",
    "badgeReducer",
    "languagesReducer",
    "statisticsReducer",
    "contentReducer",
    "pairMatchReducer",
    "photoReducer",
    "mobileEnvReducer",
  ],
};

const rootReducer = (state, action) => {
  if (action.type === LOGIN_ACTIONS.LOGOUT) {
    state.registrationReducer = undefined;
    state.forgotPasswordReducer = undefined;
    state.natureTrailReducer = undefined;
    state.userReducer = undefined;
    state.questionReducer = undefined;
    state.spinningReducer = undefined;
    state.badgeReducer = undefined;
    state.languagesReducer = undefined;
    state.statisticsReducer = undefined;
    state.contentReducer = undefined;
    state.pairMatchReducer = undefined;
    state.photoReduce = undefined;
    state.mobileEnvReducer = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  loginReducer,
  registrationReducer,
  forgotPasswordReducer,
  natureTrailReducer,
  userReducer,
  questionReducer,
  spinningReducer,
  badgeReducer,
  languagesReducer,
  statisticsReducer,
  contentReducer,
  pairMatchReducer,
  photoReducer,
  mobileEnvReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
