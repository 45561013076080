import React, { Component } from 'react'

export default class NatureTrailLocations extends Component {
    render() {
        return (
            <div>
                <h1 >Tanösvény helyszín adatok</h1>
            </div>
        )
    }
}
