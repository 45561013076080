import React, { Component } from 'react'
import { Col, Row, Divider } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons'
import ReactPlayer from 'react-player'

import { connect } from 'react-redux'
import { getSoundContent, deleteMusicFile } from '../../actions/content'

const mapDispatch = (dispatch) => {
    return {
        getSoundContent: (locationId) => dispatch(getSoundContent(locationId)),
        deleteMusicFile: (locationId) => dispatch(deleteMusicFile(locationId)),
    }
}

class ConnectedLocationMusicContent extends Component {
    state = {
        audioFromUser: ""
    }
    componentDidMount = async () => {
        await this.props.getSoundContent(this.props.natureTrailId)
        this.setPreview()
        // if (this.props.natureTrailId && (this.props.musicFileURL === null || this.props.musicFileURL === undefined || this.props.musicFileURL === "")) {
        //     await this.props.getSoundContent(this.props.natureTrailId)
        //     this.setState({ audioFromUser:  process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.sound.musicFileURL })
        // } else if (this.props.musicFileURL !== undefined) {
        //     this.setState({ audioFromUser:  process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.musicFileURL })
        // } else {
        //     this.setState({ audioFromUser: "" })
        // }
    }

    setPreview = () => {
        if (this.props.music[0] !== undefined && this.props.music[0] !== null) {
            const audioTeszt = URL.createObjectURL(this.props.music[0])
            this.setState({ audioFromUser: audioTeszt })
        }
        else {
            if (this.props.sound.musicFileURL !== null) {
                this.setState({ audioFromUser: process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.sound.musicFileURL })
            } else {
                this.setState({ audioFromUser: "" })
            }
        }
    }
    handleChange = info => {
        if (this.props.music[0] !== undefined && this.props.music[0] !== null) {
            const audioTeszt = URL.createObjectURL(this.props.music[0])
            this.setState({ audioFromUser: audioTeszt })
        } else {
            if (this.props.musicFileURL !== undefined) {
                this.setState({ audioFromUser: process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.musicFileURL })
            } else {
                this.setState({ audioFromUser: "" })
            }
        }
    };

    deleteMusic = async () => {
        if (this.props.natureTrailId) {
            await this.props.deleteMusicFile(this.props.natureTrailId)
            this.props.removeMusic()
            this.forceUpdate()
        }
        this.setState({ audioFromUser: "" })
    }

    render() {
        return (
            <React.Fragment>
                <Row gutte={[24, 24]} align="middle" justify="center" style={{ textAlign: "-webkit-center" }}>
                    <Col span={12} >
                        <Dragger
                            style={{ maxHeight: "10em" }}
                            name="file"
                            multiple={true}
                            beforeUpload={this.props.setMusic}
                            // fileList={this.props.music}
                            listType={"text"}
                            showUploadList={false}
                            onRemove={this.props.removeMusic}
                            // onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            accept=".mp3"
                        >
                            <div>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Hanganyag helye </p>
                                <p className="ant-upload-text">*.mp3</p>
                            </div>
                        </Dragger>
                    </Col>
                    <Col span={2} style={{
                        height: "2em !important",
                        width: "2em !important"
                    }} >
                        <DeleteOutlined
                            style={{
                                height: "2em !important",
                                width: "2em !important"
                            }}
                            onClick={this.deleteMusic}></DeleteOutlined>
                    </Col>
                    <Divider plain>Alkalmazásban lejátszható hanganyag</Divider>
                    <Col span={48} style={{ textAlign: "-webkit-center" }}>
                        <ReactPlayer volume={1} controls url={this.state.audioFromUser !== undefined ? this.state.audioFromUser : ""} />
                    </Col>
                    {/* <Col span={24} >
                        <ReactPlayer volume={1} controls url={this.state.audioFromUser !== undefined ? this.state.audioFromUser : ""} />
                    </Col> */}
                </Row>

            </React.Fragment>
        )
    }
}
const mapState = (state) => {
    return {
        sound: state.contentReducer.sound,
        message: state.contentReducer.message,
        code: state.contentReducer.code,
        status: state.contentReducer.status,
    }
}

const LocationMusicContent = connect(mapState, mapDispatch)(ConnectedLocationMusicContent)
export default LocationMusicContent;
