import axios from "axios";
import { BADGE } from "../constants/action-types";

export const getAllBadges = () => {
    return async (dispatch) => {
        dispatch({ type: BADGE.GET_ALL_BADGE_LOADING });
        await axios
            .get("/badge/get-all", {})
            .then((response) => {
                dispatch({
                    type: BADGE.GET_ALL_BADGE_LOADED,
                    payload: response.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: BADGE.GET_ALL_BADGE_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};

export const getOneBadgeById = (badgeId) => {
    return async (dispatch) => {
        dispatch({ type: BADGE.GET_ONE_BADGE_LOADING });
        await axios
            .get("/badge/one-by-id", {
                params: {
                    badgeId: badgeId
                }
            })
            .then((response) => {
                dispatch({
                    type: BADGE.GET_ONE_BADGE_LOADED,
                    payload: response.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: BADGE.GET_ONE_BADGE_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};
export const saveNewBadge = (badge) => {
    return async (dispatch) => {
        dispatch({ type: BADGE.SAVE_NEW_BADGE_SAVING });
        await axios
            .post("/badge/add-badge", badge)
            .then((response) => {
                dispatch({
                    type: BADGE.SAVE_NEW_BADGE_SAVED,
                    payload: response.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: BADGE.SAVE_NEW_BADGE_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};
export const modifyBadge = (badge, files, deactiveBadgeFile) => {
    const formData = new FormData();
    //files
    formData.append("badgeFile", files !== undefined && files[0] !== undefined ? files[0] : [])
    formData.append("deactiveBadgeFile", deactiveBadgeFile !== undefined && deactiveBadgeFile[0] !== undefined ? deactiveBadgeFile[0] : [])
    //data
    formData.append("badge", JSON.stringify(badge));
    return async (dispatch) => {
        dispatch({ type: BADGE.SAVE_EDITED_BADGE_SAVING });
        await axios
            .post("/badge/modify-badge", formData)
            .then((response) => {
                dispatch({
                    type: BADGE.SAVE_EDITED_BADGE_SAVED,
                    payload: response.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: BADGE.SAVE_EDITED_BADGE_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};
export const activateDeactivateBadgeById = (badgeId, isActive) => {
    return async (dispatch) => {
        dispatch({ type: BADGE.SET_ACTIVE_DEACTIVE_SENDING });
        await axios
            .post("/badge/deactivate-or-activate-badge-by-id", null, {
                params: {
                    badgeId: badgeId,
                    isActive: isActive
                }
            })
            .then((response) => {
                dispatch({
                    type: BADGE.SET_ACTIVE_DEACTIVE_SENDED
                });
            })
            .catch((err) => {
                dispatch({
                    type: BADGE.SET_ACTIVE_DEACTIVE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    }
};

export const getAllBadgeLanguageByBadgeId = (badgeId, languageCode) => {
    return async (dispatch) => {
        dispatch({ type: BADGE.GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_LOADING });
        await axios
            .get("/languages/language-badge-by-id-and-code", {
                params: {
                    badgeId: badgeId,
                    languageCode: languageCode
                }
            })
            .then((response) => {
                dispatch({
                    type: BADGE.GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_LOADED,
                    payload: response,
                });
            })
            .catch((err) => {
                dispatch({
                    type: BADGE.GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};

export const saveLanguagedBadge = (saveObject) => {
    return async (dispatch) => {
        dispatch({ type: BADGE.BADGE_LANGUAGE_SAVEING });
        await axios
            .post("/languages/create-badge-language",
                saveObject
            )
            .then((response) => {
                dispatch({
                    type: BADGE.BADGE_LANGUAGE_SAVED
                });
            })
            .catch((err) => {
                dispatch({
                    type: BADGE.BADGE_LANGUAGE_SAVE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    }
};