import { TESTER, USER } from "../constants/action-types";

const initialState = {
    users: [],
    usersStatus: null,
    user: undefined,
    userStatus: null,
    locationAdmins: [],
    locationAdminsStatus: null,
    admins: [],
    adminsStatus: null,
    testers: [],
    testersStatus: null,
    natureTrails: [],
    status: null,
    message: {},
    code: null,
    deleteStatus: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER.USER_NATURETRAIL_LOADING:
            return Object.assign({}, state, {
                natureTrails: [],
                message: {},
                status: "loading",
                code: null
            });
        case USER.USER_NATURETRAIL_LOADED:
            return Object.assign({}, state, {
                natureTrails: action.payload.natureTrails,
                status: "loaded",
                message: {},
                code: null
            });
        case USER.USER_NATURETRAIL_ERROR:
            return Object.assign({}, state, {
                natureTrails: [],
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case USER.GET_ALL_USER_LOADING:
            return Object.assign({}, state, {
                users: [],
                message: {},
                usersStatus: "loading",
                code: null
            });
        case USER.GET_ALL_USER_LOADED:
            return Object.assign({}, state, {
                users: action.payload,
                usersStatus: "loaded",
                message: {},
                code: null
            });
        case USER.GET_ALL_USER_ERROR:
            return Object.assign({}, state, {
                users: [],
                usersStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case USER.GET_ALL_LOCATION_ADMIN_LOADING:
            return Object.assign({}, state, {
                locationAdmins: [],
                message: {},
                locationAdminsStatus: "loading",
                code: null
            });
        case USER.GET_ALL_LOCATION_ADMIN_LOADED:
            return Object.assign({}, state, {
                locationAdmins: action.payload,
                locationAdminsStatus: "loaded",
                message: {},
                code: null
            });
        case USER.GET_ALL_LOCATION_ADMIN_ERROR:
            return Object.assign({}, state, {
                locationAdmins: [],
                locationAdminsStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case USER.GET_ALL_ADMIN_LOADING:
            return Object.assign({}, state, {
                admins: [],
                adminsStatus: "loading",
                message: {},
                code: null
            });
        case USER.GET_ALL_ADMIN_LOADED:
            return Object.assign({}, state, {
                admins: action.payload,
                adminsStatus: "loaded",
                message: {},
                code: null
            });
        case USER.GET_ALL_ADMIN_ERROR:
            return Object.assign({}, state, {
                admins: [],
                adminsStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case USER.USER_DATA_LOADING:
            return Object.assign({}, state, {
                user: {},
                message: {},
                userStatus: "loading",
                code: null
            });
        case USER.USER_DATA_LOADED:
            return Object.assign({}, state, {
                user: action.payload.user[0],
                userStatus: "loaded",
                message: {},
                code: null
            });
        case USER.USER_DATA_ERROR:
            return Object.assign({}, state, {
                user: undefined,
                userStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case USER.USER_DELETE_SENDING:
            return Object.assign({}, state, {
                message: {},
                deleteStatus: 'sending',
                code: null
            });
        case USER.USER_DELETE_SENDED:
            return Object.assign({}, state, {
                message: {},
                deleteStatus: 'sended',
                code: null
            });
        case USER.USER_DELETE_ERROR:
            return Object.assign({}, state, {
                deleteStatus: 'error',
                message: action.payload.message,
                code: action.payload.code
            });
        case USER.SAVE_LOCATION_ADMIN_START:
            return Object.assign({}, state, {
                locationAdminsStatus: "",
                message: {},
                code: null,
            });
        case USER.SAVE_LOCATION_ADMIN_SUCCESS:
            return Object.assign({}, state, {
                locationAdminsStatus: "saved",
                message: {},
                code: null,
            });
        case USER.SAVE_LOCATION_ADMIN_ERROR:
            return Object.assign({}, state, {
                locationAdminsStatus: "error",
                message: action.payload.message,
                code: action.payload.code,
            });
        case USER.SAVE_ADMIN_START:
            return Object.assign({}, state, {
                adminsStatus: "",
                message: {},
                code: null,
            });
        case USER.SAVE_ADMIN_SUCCESS:
            return Object.assign({}, state, {
                adminsStatus: "saved",
                message: {},
                code: null,
            });
        case USER.SAVE_ADMIN_ERROR:
            return Object.assign({}, state, {
                adminsStatus: "error",
                message: action.payload.message,
                code: action.payload.code,
            });
        case TESTER.SAVE_TESTER_START:
            return Object.assign({}, state, {
                testersStatus: "saving",
                message: {},
                code: null,
            });
        case TESTER.SAVE_TESTER_FINISH:
            return Object.assign({}, state, {
                testersStatus: "saved",
                message: {},
                code: null,
            });
        case TESTER.SAVE_TESTER_ERROR:
            return Object.assign({}, state, {
                testersStatus: "error",
                message: action.payload.message,
                code: action.payload.code,
            });
        case TESTER.GET_ALL_TESTER_LOADING:
            return Object.assign({}, state, {
                testers: [],
                testersStatus: "loading",
                message: {},
                code: null
            });
        case TESTER.GET_ALL_TESTER_LOADED:
            return Object.assign({}, state, {
                testers: action.payload.testers,
                testersStatus: "loaded",
                message: {},
                code: null
            });
        case TESTER.GET_ALL_TESTER_ERROR:
            return Object.assign({}, state, {
                testers: [],
                testersStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        default:
            return state;
    }
};

export default userReducer;
