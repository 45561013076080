import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllBadges, modifyBadge, saveNewBadge, activateDeactivateBadgeById } from '../../actions/badge';
import { Row, Col, Table, Button, Space, Spin, message, Checkbox } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import SelectedBadge from './selected-badge';
import './badge.scss'

const mapDispatchToProps = (dispatch) => {
    return {
        getAllBadges: () => dispatch(getAllBadges()),
        modifyBadge: (badge, badgeFiles) => dispatch(modifyBadge(badge, badgeFiles)),
        saveNewBadge: (badge) => dispatch(saveNewBadge(badge)),
        activateDeactivateBadgeById: (badgeId, isActive) => dispatch(activateDeactivateBadgeById(badgeId, isActive))
    }
};

class ConnectedBadges extends Component {

    state = {
        selectedRowKey: -1,
        selectedRow: {},
        badges: [],
        spinning: false,
        columns: [
            {
                title: 'Kitűző név',
                dataIndex: 'name',
                // width: '30',
            },
            // {
            //     title: 'Tipus',
            //     dataIndex: 'title',
            //     // width: '30',
            // },
            {
                title: "Aktív",
                key: "delete",
                // width: "2vw",

                render: (text, row) => (
                    <Space size="middle">
                        <Checkbox onChange={() => this.activateDeactivate(row)} checked={row.isActive} ></Checkbox>

                        {/* <Button onClick={() => this.activateDeactivate(row)}
                            style={{
                                backgroundColor: row.isActive === true ? "forestgreen" : "",
                                color: row.isActive === true ? "white" : ""
                            }}>
                            {row.isActive === true ? "Deactivate" : "Activate"}
                        </Button> */}
                    </Space>
                ),
            },
        ],
    }

    componentDidMount = async () => {
        await this.updateList()
    }

    activateDeactivate = async (row) => {
        this.setState({ spinning: true })
        const isActive = !row.isActive
        await this.props.activateDeactivateBadgeById(row.id, isActive);
        if (this.props.statusActivateDeactivate === "saved") {
            this.setState({ spinning: false })
            if (isActive === true) {
                message.success("Sikeres aktiválás")
            }
            else {
                message.success("Sikeres deaktiválás")

            }
        }
        if (this.props.statusActivateDeactivate === "error") {
            message.error("Hibás kitöltés! A kitűző képe nem maradhat üres.")
            this.setState({ spinning: false })

        }
        await this.updateList();
        this.forceUpdate()
    }

    updateList = async () => {
        await this.props.getAllBadges()
        const filteredBadge = this.props.allBadge.filter(item => item.natureTrailId === null)
        this.setState({ badges: filteredBadge })
    }

    selectRow = (row, key) => {
        this.setState({ selectedRowKey: key, selectedRow: row })
    }
    addNewBadge = async () => {
        const newBadge = {
            name: "Új mentettlen kitűző",
            requiredNumber: 1,
            seasonId: 1,
        }
        await this.props.saveNewBadge(newBadge);
        await this.props.getAllBadges()
        const filteredBadge = this.props.allBadge.filter(item => item.natureTrailId === null)
        this.setState({ badges: filteredBadge })

        //this.setState({ badges: [newBadge, ...this.state.badges], selectedRow: newBadge })

    }
    setSpinningTrue = () => {
        this.setState({ spinning: true })
    }
    setSpinningFalse = () => {
        this.setState({ spinning: false })
    }
    render() {
        return <Spin spinning={this.state.spinning} className="customSpin" style={{
            zIndex: 100000000
        }} size="large">
            <div>
                <h1>Kitűzők</h1>
                <Row gutter={24} style={{ height: "5vh" }}>
                    <Button type="primary" onClick={this.addNewBadge}> <PlusCircleOutlined />Új Kitűző</Button>
                </Row>

                <Row gutter={24} style={{ height: "60vh" }}>
                    <Col span={6}>
                        <Table
                            scroll={{ y: '60vh', x: 'max-content' }}
                            size="small"
                            overflowY="auto"
                            rowKey="id"
                            dataSource={this.state.badges}
                            columns={this.state.columns}
                            pagination={false}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => { this.selectRow(record, rowIndex) },
                                    style: { backgroundColor: rowIndex === this.state.selectedRowKey ? "#e6f3ff" : "" }
                                };
                            }}
                        />
                    </Col>
                    <Col span={18}>
                        {this.state.selectedRowKey !== -1 &&
                            <SelectedBadge
                                selectedRow={this.state.selectedRow}
                                updateList={this.updateList}
                                setSpinningTrue={this.setSpinningTrue}
                                setSpinningFalse={this.setSpinningFalse}
                            />}
                    </Col>
                </Row>
            </div >
        </Spin>;
    }
}
const mapStateToProps = (state) => {
    return {
        allBadge: state.badgeReducer.allBadge,
        code: state.badgeReducer.code,
        status: state.badgeReducer.status,
        statusActivateDeactivate: state.badgeReducer.statusActivateDeactivate
    }
};
const Badges = connect(mapStateToProps, mapDispatchToProps)(ConnectedBadges);

export default Badges;