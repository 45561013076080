import React, { Component } from "react";
import { Input, Form, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./registration.scss";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { registration } from "../../actions/registration";

const mapDispatch = (dispatch) => {
    return { registration: (email, password, name) => dispatch((registration(email, password, name))) }
}
class ConnectedRegistration extends Component {
    state = {
        password: null,
        passwordAgain: null,
        email: null,
        userName: null,
        registrationSuccesful: false,
        passwordValid: false
    }

    handlePasswordChange = (event) => {
        if (event.target.value !== undefined) {
            this.setState({ password: event.target.value }, () => { this.checkIfPasswordEquals() })
        }
    }

    handlePasswordAgainChange = (event) => {
        if (event.target.value !== undefined) {
            this.setState({ passwordAgain: event.target.value }, () => { this.checkIfPasswordEquals() })
        }
    }

    handleEmailChange = (event) => {
        if (event.target.value !== undefined) {
            this.setState({ email: event.target.value })
        }
    }
    handleNameChange = (event) => {
        if (event.target.value !== undefined) {
            this.setState({ userName: event.target.value })
        }
    }
    checkIfPasswordEquals = () => {
        if (this.state.password === this.state.passwordAgain && this.state.password !== null && this.state.passwordAgain !== null) { this.setState({ passwordValid: true }) }
        else { this.setState({ passwordValid: false }) }
    }
    clickOnRegistrationButton = async () => {
        if (this.state.passwordValid) {
            await this.props.registration(this.state.email, this.state.password, this.state.userName);
            if (this.props.status && this.props.status !== 'sended') {
                if (this.props.code === 1002 || this.props.code === 1003 || this.props.code === 1001) {
                    message.error(this.props.message);
                }
                else {
                    message.error("Something went wrong");
                }
            } else {
                this.setState({ registrationSuccesful: true })
                this.props.history.push("/login")
                message.success("Sikeres regisztráció")
            }
        } else
            message.error("Passwords do not match.");
    }


    render() {
        return (
            <div style={{
                height: "100vh",
                backgroundImage: `url("/login_background.jpg")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                minHeight: "100%",
                minWidth: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <div className="registration-page-container">
                    <Form
                        name="normal_login"
                        className="login-form registrationform"
                        initialValues={{ remember: true }}
                    //onFinish={onFinish}
                    >
                        <h1>
                            Registation
                    </h1>

                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: "Please input your email address!" }]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="email"
                                onChange={this.handleEmailChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            rules={[{ required: true, message: "Please input your nickname!" }]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="name"
                                onChange={this.handleNameChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: "Please input your Password!" }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                value={this.state.password}
                                // type="password"
                                placeholder="Password"
                                onChange={this.handlePasswordChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password again"
                            rules={[{ required: true, message: "Please input your Password again!" }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                value={this.state.password}
                                //type="password"
                                placeholder="Password"
                                onChange={this.handlePasswordAgainChange}
                            />
                        </Form.Item>
                        {/* <Form.Item>
                        {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item> 


                    </Form.Item> */}

                        <Form.Item>
                            {/* <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            onClick={this.clickOnRegistrationButton}
                        >
                            Registration
            </Button> */}
                        Or <a href="/login">Back to Login page</a>
                        </Form.Item>{
                            this.state.registrationSuccesful && <Redirect Form="**" to="/login" />
                        }
                    </Form>
                </div >
            </div>
        );
    }
}
const mapState = (state) => {
    return {
        message: state.registrationReducer.message,
        code: state.registrationReducer.code,
        status: state.registrationReducer.status
    }
}

const Registration = connect(mapState, mapDispatch)(ConnectedRegistration)
export default Registration;
