export const LOGIN_ACTIONS = {
  TOKEN_LOADING: "TOKEN_LOADING",
  TOKEN_LOADED: "TOKEN_LOADED",
  TOKEN_ERROR: "TOKEN_ERROR",
  LOGOUT: "LOGOUT",
};
export const REGISTRATION = {
  REGISTRATION_LOADING: "REGISTRATION_LOADING",
  REGISTRATION_LOADED: "REGISTRATION_LOADED",
  REGISTRATION_ERROR: "REGISTRATION_ERROR",
}
export const FORGOTPASSWORD = {
  FORGOTPASSWORD_LOADING: "FORGOTPASSWORD_LOADING",
  FORGOTPASSWORD_LOADED: "FORGOTPASSWORD_LOADED",
  FORGOTPASSWORD_ERROR: "FORGOTPASSWORD_ERROR",
}
export const NATURETRAIL = {
  NATURETRAIL_LOADING: "NATURETRAIL_LOADING",
  NATURETRAIL_LOADED: "NATURETRAIL_LOADED",
  NATURETRAIL_ERROR: "NATURELTRAIL_ERROR",
  NATURETRAIL_SAVE_ERROR: "NATURETRAIL_SAVE_ERROR",
  NATURETRAIL_SAVE_SENDING: "NATURETRAIL_SAVE_SENDING",
  NATURETRAIL_SAVE_SENDED: "NATURETRAIL_SAVE_SENDED",
  NATURETRAIL_LOCATION_BY_ID_LOADED: "NATURETRAIL_LOCATION_BY_ID_LOADED",
  NATURETRAIL_LOCATION_BY_ID_LOADING: "NATURETRAIL_LOCATION_BY_ID_LOADING",
  NATURETRAIL_LOCATION_BY_ID_ERROR: "NATURETRAIL_LOCATION_BY_ID_ERROR",
  NATURETRAIL_TYPE_LOADING: "NATURETRAIL_TYPE_LOADING",
  NATURETRAIL_TYPE_LOADED: "NATURETRAIL_TYPE_LOADED",
  NATURETRAIL_TYPE_ERROR: "NATURETRAIL_TYPE_ERROR",
  ALL_NATURETRAIL_TYPE_LOADING: "ALL_NATURETRAIL_TYPE_LOADING",
  ALL_NATURETRAIL_TYPE_LOADED: "ALL_NATURETRAIL_TYPE_LOADED",
  ALL_NATURETRAIL_TYPE_ERROR: "ALL_NATURETRAIL_TYPE_ERROR",
  NATURETRAIL_DELETE_SENDING: "NATURETRAIL_DELETE_SENDING",
  NATURETRAIL_DELETE_SENDED: "NATURETRAIL_DELETE_SENDED",
  NATURETRAIL_DELETE_ERROR: "NATURETRAIL_DELETE_ERROR",
  NATURETRAIL_TEST_SENDING: "NATURETRAIL_TEST_SENDING",
  NATURETRAIL_TEST_SENDED: "NATURETRAIL_TEST_SENDED",
  NATURETRAIL_TEST_ERROR: "NATURETRAIL_TEST_ERROR",
  NATURETRAIL_LOCATION_SAVING: "NATURETRAIL_LOCATION_SAVING",
  NATURETRAIL_LOCATION_SAVED: "NATURETRAIL_LOCATION_SAVED",
  NATURETRAIL_LOCATION_SAVE_ERROR: "NATURETRAIL_LOCATION_SAVE_ERROR",
  TRIGGERTYPES_LOADING: "TRIGGERTYPES_LOADING",
  TRIGGERTYPES_LOADED: "TRIGGERTYPES_LOADED",
  TRIGGERTYPES_ERROR: "TRIGGERTYPES_ERROR",
  REGION_LOADING: "REGION_LOADING",
  REGION_LOADED: "REGION_LOADED",
  REGION_ERROR: "REGION_ERROR",
  NATIONAL_PARK_LOADING: "NATIONAL_PARK_LOADING",
  NATIONAL_PARK_LOADED: "NATIONAL_PARK_LOADED",
  NATIONAL_PARK_ERROR: "NATIONAL_PARK_ERROR",
  ALL_NATIONAL_PARK_LOADING: "ALL_NATIONAL_PARK_LOADING",
  ALL_NATIONAL_PARK_LOADED: "ALL_NATIONAL_PARK_LOADED",
  ALL_NATIONAL_PARK_ERROR: "ALL_NATIONAL_PARK_ERROR",
  NATURETRAIL_LOCATION_BY_LOCATION_ID_LOADING: "NATURETRAIL_LOCATION_BY_LOCATION_ID_LOADING",
  NATURETRAIL_LOCATION_BY_LOCATION_ID_LOADED: "NATURETRAIL_LOCATION_BY_LOCATION_ID_LOADED",
  NATURETRAIL_LOCATION_BY_LOCATION_ID_ERROR: "NATURETRAIL_LOCATION_BY_LOCATION_ID_ERROR",
  NATURETRAIL_LOCATION_DELETE_SENDING: "NATURETRAIL_LOCATION_DELETE_SENDING",
  NATURETRAIL_LOCATION_DELETE_SENDED: "NATURETRAIL_LOCATION_DELETE_SENDED",
  NATURETRAIL_LOCATION_DELETE_ERROR: "NATURETRAIL_LOCATION_DELETE_ERROR",
  NATURETRAIL_RATING_BY_ID_LOADING: "NATURETRAIL_RATING_BY_ID_LOADING",
  NATURETRAIL_RATING_BY_ID_LOADED: "NATURETRAIL_RATING_BY_ID_LOADED",
  NATURETRAIL_RATING_BY_ID_ERROR: "NATURETRAIL_RATING_BY_ID_ERROR",
  NATURETRAIL_COMMENTS_BY_ID_LOADING: "NATURETRAIL_COMMENTS_BY_ID_LOADING",
  NATURETRAIL_COMMENTS_BY_ID_LOADED: "NATURETRAIL_COMMENTS_BY_ID_LOADED",
  NATURETRAIL_COMMENTS_BY_ID_ERROR: "NATURETRAIL_COMMENTS_BY_ID_ERROR",
  NATURETRAIL_TYPE_CREATE_ERROR: "NATURETRAIL_TYPE_CREATE_ERROR",
  NATURETRAIL_TYPE_CREATE_SAVING: "NATURETRAIL_TYPE_CREATE_SAVING",
  NATURETRAIL_TYPE_CREATE_SAVED: "NATURETRAIL_TYPE_CREATE_SAVED",
  NATURETRAIL_TYPE_UPDATE_SAVING: "NATURETRAIL_TYPE_UPDATE_SAVING",
  NATURETRAIL_TYPE_UPDATE_SAVED: "NATURETRAIL_TYPE_UPDATE_SAVED",
  NATURETRAIL_TYPE_UPDATE_ERROR: "NATURETRAIL_TYPE_UPDATE_ERROR",
  NATIONAL_PARK_SAVING: "NATIONAL_PARK_SAVING",
  NATIONAL_PARK_SAVED: "NATIONAL_PARK_SAVED",
  NATIONAL_PARK_SAVING_ERROR: "NATIONAL_PARK_SAVING_ERROR",
  NATIONAL_PARK_CREATING: "NATIONAL_PARK_CREATING",
  NATIONAL_PARK_CREATED: "NATIONAL_PARK_CREATED",
  NATIONAL_PARK_CREATING_ERROR: "NATIONAL_PARK_CREATING_ERROR",
  PUSH_SENDING: "PUSH_SENDING",
  PUSH_SENDED: "PUSH_SENDED",
  PUSH_ERROR: "PUSH_ERROR",
  OWNER_1_SAVING: "OWNER_1_SAVING",
  OWNER_1_SAVED: "OWNER_1_SAVED",
  OWNER_1_ERROR: "OWNER_1_ERROR",
  OWNER_2_SAVING: "OWNER_2_SAVING",
  OWNER_2_SAVED: "OWNER_2_SAVED",
  OWNER_2_ERROR: "OWNER_2_ERROR",
}
export const USER = {
  USER_NATURETRAIL_LOADING: "USER_NATURETRAIL_LOADING",
  USER_NATURETRAIL_LOADED: "USER_NATURETRAIL_LOADED",
  USER_NATURETRAIL_ERROR: "USER_NATURELTRAIL_ERROR",
  GET_ALL_USER_LOADING: "GET_ALL_USER_LOADING",
  GET_ALL_USER_LOADED: "GET_ALL_USER_LOADED",
  GET_ALL_USER_ERROR: "GET_ALL_USER_ERROR",
  GET_ALL_LOCATION_ADMIN_LOADING: "GET_ALL_LOCATION_ADMIN_LOADING",
  GET_ALL_LOCATION_ADMIN_LOADED: "GET_ALL_LOCATION_ADMIN_LOADED",
  GET_ALL_LOCATION_ADMIN_ERROR: "GET_ALL_LOCATION_ADMIN_ERROR",
  GET_ALL_ADMIN_LOADING: "GET_ALL_ADMIN_LOADING",
  GET_ALL_ADMIN_LOADED: "GET_ALL_ADMIN_LOADED",
  GET_ALL_ADMIN_ERROR: "GET_ALL_ADMIN_ERROR",
  USER_DATA_LOADING: "USER_DATA_LOADING",
  USER_DATA_LOADED: "USER_DATA_LOADED",
  USER_DATA_ERROR: "USER_DATA_ERROR",
  USER_DELETE_ERROR: "USER_DELETE_ERROR",
  USER_DELETE_SENDED: "USER_DELETE_SENDED",
  USER_DELETE_SENDING: "USER_DELETE_SENDIND",
  SAVE_LOCATION_ADMIN_START: "SAVE_LOCATION_ADMIN_START",
  SAVE_LOCATION_ADMIN_SUCCESS: "SAVE_LOCATION_ADMIN_SUCCESS",
  SAVE_LOCATION_ADMIN_ERROR: "SAVE_LOCATION_ADMIN_ERROR",
  SAVE_ADMIN_START: "SAVE_ADMIN_START",
  SAVE_ADMIN_SUCCESS: "SAVE_ADMIN_SUCCESS",
  SAVE_ADMIN_ERROR: "SAVE_ADMIN_ERROR",
}

export const QUESTION = {
  QUESTION_ANSWERS_SAVING: "QUESTION_ANSWERS_SAVING",
  QUESTION_ANSWERS_SAVED: "QUESTION_ANSWERS_SAVED",
  QUESTION_ANSWERS_ERROR: "QUESTION_ANSWERS_ERROR",
  QUESTION_ANSWERS_BY_ID_LOADING: "QUESTION_ANSWERS_BY_ID_LOADING",
  QUESTION_ANSWERS_BY_ID_LOADED: "QUESTION_ANSWERS_BY_ID_LOADED",
  QUESTION_ANSWERS_BY_ID_ERROR: "QUESTION_ANSWERS_BY_ID_ERROR",
  QUESTION_ANSWERS_BY_NATURETRAIL_ID_LOADING: "QUESTION_ANSWERS_BY_NATURETRAIL_ID_LOADING",
  QUESTION_ANSWERS_BY_NATURETRAIL_ID_LOADED: "QUESTION_ANSWERS_BY_NATURETRAIL_ID_LOADED",
  QUESTION_ANSWERS_BY_NATURETRAIL_ID_ID_ERROR: "QUESTION_ANSWERS_BY_NATURETRAIL_ID_ERROR",
  QUESTION_ANSWER_BY_NATURETRAIL_ID_LOADING: "QUESTION_ANSWER_BY_NATURETRAIL_ID_LOADING",
  QUESTION_ANSWER_BY_NATURETRAIL_ID_LOADED: "QUESTION_ANSWER_BY_NATURETRAIL_ID_LOADED",
  QUESTION_ANSWER_BY_NATURETRAIL_ID_ID_ERROR: "QUESTION_ANSWER_BY_NATURETRAIL_ID_ERROR",
  QUESTION_ANSWER_BY_QUESTION_ID_LOADING: "QUESTION_ANSWER_BY_QUESTION_ID_LOADING",
  QUESTION_ANSWER_BY_QUESTION_ID_LOADED: "QUESTION_ANSWER_BY_QUESTION_ID_LOADED",
  QUESTION_ANSWER_BY_QUESTION_ID_ERROR: "QUESTION_ANSWER_BY_QUESTION_ID_ERROR",
  QUESTION_ANSWER_SAVING: "QUESTION_ANSWER_SAVING",
  QUESTION_ANSWER_SAVED: "QUESTION_ANSWER_SAVED",
  QUESTION_ANSWER_ERROR: "QUESTION_ANSWER_ERROR",

}

export const SPINNING = {
  TRUE: "TRUE",
  FALSE: "FALSE"
}
export const BADGE = {
  GET_ALL_BADGE_LOADING: "GET_ALL_BADGE_LOADING",
  GET_ALL_BADGE_LOADED: "GET_ALL_BADGE_LOADED",
  GET_ALL_BADGE_ERROR: "GET_ALL_BADGE_EROOR",
  GET_ONE_BADGE_LOADING: "GET_ONE_BADGE_LOADING",
  GET_ONE_BADGE_LOADED: "GET_ONE_BADGE_LOADED",
  GET_ONE_BADGE_ERROR: "GET_ONE_BADGE_ERROR",
  SAVE_NEW_BADGE_SAVING: "SAVE_NEW_BADGE_SAVING",
  SAVE_NEW_BADGE_SAVED: "SAVE_NEW_BADGE_SAVED",
  SAVE_NEW_BADGE_ERROR: "SAVE_NEW_BADGE_ERROR",
  SAVE_EDITED_BADGE_SAVING: "SAVE_EDITED_BADGE_SAVING",
  SAVE_EDITED_BADGE_SAVED: "SAVE_EDITED_BADGE_SAVED",
  SAVE_EDITED_BADGE_ERROR: "SAVE_EDITED_BADGE_ERROR",
  SET_ACTIVE_DEACTIVE_SENDING: "SET_ACTIVE_DEACTIVE_SENDING",
  SET_ACTIVE_DEACTIVE_SENDED: "SET_ACTIVE_DEACTIVE_SENDED",
  SET_ACTIVE_DEACTIVE_ERROR: "SET_ACTIVE_DEACTIVE_ERROR",
  GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_LOADING: "GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_LOADING",
  GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_LOADED: "GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_LOADED",
  GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_ERROR: "GET_ONE_BADGE_BY_BADGE_ID_AND_LANGUAGE_ERROR",
  BADGE_LANGUAGE_SAVEING: "BADGE_LANGUAGE_SAVEING",
  BADGE_LANGUAGE_SAVED: "BADGE_LANGUAGE_SAVED",
  BADGE_LANGUAGE_SAVE_ERROR: "BADGE_LANGUAGE_SAVE_ERROR",
};
export const LANGUAGES = {
  LANGUAGES_CODE_LOADING: "LANGUAGES_CODE_LOADING",
  LANGUAGES_CODE_LOADED: "LANGUAGES_CODE_LOADED",
  LANGUAGES_CODE_ERROR: "LANGUAGES_CODE_ERROR",
  NATURE_TRAIL_BY_LAGUAGE_CODE_LOADING: "NATURE_TRAIL_BY_LAGUAGE_CODE_LOADING",
  NATURE_TRAIL_BY_LAGUAGE_CODE_LOADED: "NATURE_TRAIL_BY_LAGUAGE_CODE_LOADED",
  NATURE_TRAIL_BY_LAGUAGE_CODE_ERROR: "NATURE_TRAIL_BY_LAGUAGE_CODE_ERROR",
  LANGUAGE_SAVING: "LANGUAGE_SAVING",
  LANGUAGE_SAVED: "LANGUAGE_SAVED",
  LANGUAGE_ERROR: "LANGUAGE_ERROR",
  NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_LOADED: "NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_LOADED",
  NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_LOADING: "NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_LOADING",
  NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_ERROR: "NATURE_TRAIL_ACTIVATE_OR_DEACTIVATE_ERROR",
  LANGUAGED_QUESTION_LOADING: "LANGUAGED_QUESTION_LOADING",
  LANGUAGED_QUESTION_LOADED: "LANGUAGED_QUESTION_LOADED",
  LANGUAGED_QUESTION_ERROR: "LANGUAGED_QUESTION_ERROR",
  LANGUAGED_PAIR_MATCH_LOADING: "LANGUAGED_PAIR_MATCH_LOADING",
  LANGUAGED_PAIR_MATCH_LOADED: "LANGUAGED_PAIR_MATCH_LOADED",
  LANGUAGED_PAIR_MATCH_ERROR: "LANGUAGED_PAIR_MATCH_ERROR"
}
export const STATISTICS = {
  TOP_NATURE_TRAIL_LOADING: "TOP_NATURE_TRAIL_LOADING",
  TOP_NATURE_TRAIL_LOADED: "TOP_NATURE_TRAIL_LOADED",
  TOP_NATURE_TRAIL_ERROR: "TOP_NATURE_TRAIL_ERROR",
  BEST_USER_LOADING: "BEST_USER_LOADING",
  BEST_USER_LOADED: "BEST_USER_LOADED",
  BEST_USER_ERROR: "BEST_USER_ERROR",

}
export const CONTENT = {
  MOVIE_LOADING: "MOVIE_LOADING",
  MOVIE_LOADED: "MOVIE_LOADED",
  MOVIE_ERROR: "MOVIE_ERROR",
  SOUND_LOADING: "SOUND_LOADING",
  SOUND_LOADED: "SOUND_LOADED",
  SOUND_ERROR: "SOUND_ERROR",
  D3D_LOADING: "D3D_LOADING",
  D3D_LOADED: "D3D_LOADED",
  D3D_ERROR: "D3D_ERROR",
  DELETE_ANDROID_3D_DELETING: "DELETE_ANDROID_3D_DELETING",
  DELETE_ANDROID_3D_DELETED: "DELETE_ANDROID_3D_DELETED",
  DELETE_ANDROID_3D_ERROR: "DELETE_ANDROID_3D_ERROR",
  DELETE_IPHONE_3D_DELETING: "DELETE_IPHONE_3D_DELETING",
  DELETE_IPHONE_3D_DELETED: "DELETE_IPHONE_3D_DELETED",
  DELETE_IPHONE_3D_ERROR: "DELETE_IPHONE_3D_ERROR",
  DELETE_MUSIC_DELETING: "DELETE_MUSIC_DELETING",
  DELETE_MUSIC_DELETED: "DELETE_MUSIC_DELETED",
  DELETE_MUSIC_ERROR: "DELETE_MUSIC_ERROR",
  DELETE_MOVIE_DELETING: "DELETE_MOVIE_DELETING",
  DELETE_MOVIE_DELETED: "DELETE_MOVIE_DELETED",
  DELETE_MOVIE_ERROR: "DELETE_MOVIE_ERROR"
}

export const TESTER = {
  GET_ALL_TESTER_LOADING: "GET_ALL_TESTER_LOADING",
  GET_ALL_TESTER_LOADED: "GET_ALL_TESTER_LOADED",
  GET_ALL_TESTER_ERROR: "GET_ALL_TESTER_ERROR",
  SAVE_TESTER_START: "GET_TESTER_START",
  SAVE_TESTER_FINISH: "GET_TESTER_FINISH",
  SAVE_TESTER_ERROR: "GET_TESTER_ERROR",
}

export const PAIR_MATCH = {
  GET_ALL_PAIR_MATCH_BY_NT_ID_LOADING: "GET_ALL_PAIR_MATCH_BY_NT_ID_LOADING",
  GET_ALL_PAIR_MATCH_BY_NT_ID_LOADED: "GET_ALL_PAIR_MATCH_BY_NT_ID_LOADED",
  GET_ALL_PAIR_MATCH_BY_NT_ID_ERROR: "GET_ALL_PAIR_MATCH_BY_NT_ID_ERROR",
  GET_PAIR_MATCH_BY_ID_LOADING: "GET_PAIR_MATCH_BY_ID_LOADING",
  GET_PAIR_MATCH_BY_ID_LOADED: "GET_PAIR_MATCH_BY_ID_LOADED",
  GET_PAIR_MATCH_BY_ID_ERROR: "GET_PAIR_MATCH_BY_ID_ERROR",
  DELETE_ONE_PAIR_MATCH_BY_ID_DELETING: "DELETE_ONE_PAIR_MATCH_BY_ID_DELETING",
  DELETE_ONE_PAIR_MATCH_BY_ID_DELETED: "DELETE_ONE_PAIR_MATCH_BY_ID_DELETED",
  DELETE_ONE_PAIR_MATCH_BY_ID_ERROR: "DELETE_ONE_PAIR_MATCH_BY_ID_ERROR",
  SAVE_PAIR_MATCH_SAVING: "SAVE_PAIR_MATCH_SAVEING",
  SAVE_PAIR_MATCH_SAVED: "SAVE_PAIR_MATCH_SAVED",
  SAVE_PAIR_MATCH_ERROR: "SAVE_PAIR_MATCH_ERROR",
  ADD_NEW_PAIR_MATCH_SAVING: "ADD_NEW_PAIR_MATCH_SAVING",
  ADD_NEW_PAIR_MATCH_SAVED: "ADD_NEW_PAIR_MATCH_SAVED",
  ADD_NEW_PAIR_MATCH_ERROR: "ADD_NEW_PAIR_MATCH_ERROR",
  MODIFY_PAIR_MATCH_SAVING: "MODIFY_PAIR_MATCH_SAVING",
  MODIFY_PAIR_MATCH_SAVED: "MODIFY_PAIR_MATCH_SAVED",
  MODIFY_PAIR_MATCH_ERROR: "MODIFY_PAIR_MATCH_ERROR",
}

export const PHOTO = {
  PHOTO_ANSWER_BY_NATURETRAIL_ID_LOADING: "PHOTO_ANSWER_BY_NATURETRAIL_ID_LOADING",
  PHOTO_ANSWER_BY_NATURETRAIL_ID_LOADED: "PHOTO_ANSWER_BY_NATURETRAIL_ID_LOADED",
  PHOTO_ANSWER_BY_NATURETRAIL_ID_ID_ERROR: "PHOTO_ANSWER_BY_NATURETRAIL_ID_ERROR",
  PHOTO_ANSWER_BY_QUESTION_ID_LOADING: "PHOTO_ANSWER_BY_QUESTION_ID_LOADING",
  PHOTO_ANSWER_BY_QUESTION_ID_LOADED: "PHOTO_ANSWER_BY_QUESTION_ID_LOADED",
  PHOTO_ANSWER_BY_QUESTION_ID_ERROR: "PHOTO_ANSWER_BY_QUESTION_ID_ERROR",
  PHOTO_ANSWER_SAVING: "PHOTO_ANSWER_SAVING",
  PHOTO_ANSWER_SAVED: "PHOTO_ANSWER_SAVED",
  PHOTO_ANSWER_ERROR: "PHOTO_ANSWER_ERROR",

}
export const MOBILE_ENV = {
  GET_ALL_MOBILE_ENV_LOADING: "GET_ALL_MOBILE_ENV_LOADING",
  GET_ALL_MOBILE_ENV_LOADED: "GET_ALL_MOBILE_ENV_LOADED",
  GET_ALL_MOBILE_ENV_ERROR: "GET_ALL_MOBILE_ENV_ERROR",
  SAVE_MOBILE_ENV_START: "SAVE_MOBILE_ENV_START",
  SAVE_MOBILE_ENV_FINISH: "SAVE_MOBILE_ENV_FINISH",
  SAVE_MOBILE_ENV_ERROR: "SAVE_MOBILE_ENV_ERROR",
};