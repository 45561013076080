import { PAIR_MATCH, } from "../constants/action-types";

const initialState = {
    pairMatches: [],
    pairMatch: {},
    saveStatus: null,
    loadingStatus: null,
    deleteStatus: null,
    newPairMatch: {},
    deletedPairMatch: {},
    error: null,
    createNewStatus: null,
    editStatus: null,
    message: {}
};

const pairMatchReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAIR_MATCH.GET_ALL_PAIR_MATCH_BY_NT_ID_LOADING:
            return Object.assign({}, state, {
                pairMatches: [],
                message: {},
                loadingStatus: "loading",
                code: null
            });
        case PAIR_MATCH.GET_ALL_PAIR_MATCH_BY_NT_ID_LOADED:
            return Object.assign({}, state, {
                pairMatches: action.payload.data,
                loadingStatus: "loaded",
                message: {},
                code: null
            });
        case PAIR_MATCH.GET_ALL_PAIR_MATCH_BY_NT_ID_ERROR:
            return Object.assign({}, state, {
                questionAnswers: [],
                loadingStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case PAIR_MATCH.GET_PAIR_MATCH_BY_ID_LOADING:
            return Object.assign({}, state, {
                pairMatch: {},
                message: {},
                loadingStatus: "loading",
                code: null
            });
        case PAIR_MATCH.GET_PAIR_MATCH_BY_ID_LOADED:
            return Object.assign({}, state, {
                pairMatch: action.payload.data,
                loadingStatus: "loaded",
                message: {},
                code: null
            });
        case PAIR_MATCH.GET_PAIR_MATCH_BY_ID_ERROR:
            return Object.assign({}, state, {
                pairMatch: {},
                loadingStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case PAIR_MATCH.SAVE_PAIR_MATCH_SAVING:
            return Object.assign({}, state, {
                message: {},
                saveStatus: "saving",
                code: null
            });
        case PAIR_MATCH.SAVE_PAIR_MATCH_SAVED:
            return Object.assign({}, state, {
                saveStatus: "saved",
                message: {},
                code: null
            });
        case PAIR_MATCH.SAVE_PAIR_MATCH_ERROR:
            return Object.assign({}, state, {
                saveStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case PAIR_MATCH.DELETE_ONE_PAIR_MATCH_BY_ID_DELETING:
            return Object.assign({}, state, {
                message: {},
                deletedPairMatch: {},
                deleteStatus: "deleting",
                code: null
            });
        case PAIR_MATCH.DELETE_ONE_PAIR_MATCH_BY_ID_DELETED:
            return Object.assign({}, state, {
                deleteStatus: "deleted",
                deletedPairMatch: action.payload.data.deletedPairMatch,
                message: {},
                code: null
            });
        case PAIR_MATCH.DELETE_ONE_PAIR_MATCH_BY_ID_ERROR:
            return Object.assign({}, state, {
                deleteStatus: "error",
                deletedPairMatch: {},
                message: action.payload.message,
                code: action.payload.code
            });
        case PAIR_MATCH.ADD_NEW_PAIR_MATCH_SAVING:
            return Object.assign({}, state, {
                message: {},
                newPairMatch: {},
                createNewStatus: "saving",
                code: null
            });
        case PAIR_MATCH.ADD_NEW_PAIR_MATCH_SAVED:
            return Object.assign({}, state, {
                createNewStatus: "saved",
                newPairMatch: action.payload.pairMatch,
                message: {},
                code: null
            });
        case PAIR_MATCH.ADD_NEW_PAIR_MATCH_ERROR:
            return Object.assign({}, state, {
                createNewStatus: "error",
                newPairMatch: {},
                message: action.payload.message,
                code: action.payload.code
            });
        case PAIR_MATCH.MODIFY_PAIR_MATCH_SAVING:
            return Object.assign({}, state, {
                message: {},
                editStatus: "saving",
                code: null
            });
        case PAIR_MATCH.MODIFY_PAIR_MATCH_SAVED:
            return Object.assign({}, state, {
                editStatus: "saved",
                message: {},
                code: null
            });
        case PAIR_MATCH.MODIFY_PAIR_MATCH_ERROR:
            return Object.assign({}, state, {
                editStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        default:
            return state;
    }
};

export default pairMatchReducer;
