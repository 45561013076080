import axios from "axios";
import { STATISTICS } from "../constants/action-types";

export const getTop10NatureTrailByRating = () => {
    return async (dispatch) => {
        dispatch({ type: STATISTICS.TOP_NATURE_TRAIL_LOADING });
        await axios
            .get("/statistics/top-nature-trail-by-rating", {})
            .then((response) => {
                dispatch({
                    type: STATISTICS.TOP_NATURE_TRAIL_LOADED,
                    payload: response.data.userVisitedNatureTrailCount,
                });
            })
            .catch((err) => {
                dispatch({
                    type: STATISTICS.TOP_NATURE_TRAIL_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};
export const getBestUsers = () => {
    return async (dispatch) => {
        dispatch({ type: STATISTICS.BEST_USER_LOADING });
        await axios
            .get("/statistics/user-visited-nature-trails", {})
            .then((response) => {
                dispatch({
                    type: STATISTICS.BEST_USER_LOADED,
                    payload: response.data.bestUser,
                });
            })
            .catch((err) => {
                dispatch({
                    type: STATISTICS.BEST_USER_ERROR,
                    payload: {
                        message: err.response.data.error.message,
                        code: err.response.data.error.code,
                    },
                });
            });
    };
};