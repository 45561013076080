import { PHOTO } from "../constants/action-types";

const initialState = {
    photo: [],
    photoByLocation: [],
    status: null,
    saveStatus: null,
};

const photoReducer = (state = initialState, action) => {
    switch (action.type) {
        case PHOTO.PHOTO_ANSWER_SAVING:
            return Object.assign({}, state, {
                photo: [],
                message: {},
                saveStatus: "saving",
                code: null
            });
        case PHOTO.PHOTO_ANSWER_SAVED:
            return Object.assign({}, state, {
                photo: action.payload.data,
                saveStatus: "saved",
                message: {},
                code: null
            });
        case PHOTO.PHOTO_ANSWER_ERROR:
            return Object.assign({}, state, {
                photo: [],
                saveStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case PHOTO.PHOTO_ANSWER_BY_QUESTION_ID_LOADING:
            return Object.assign({}, state, {
                photo: [],
                message: {},
                status: "loading",
                code: null
            });
        case PHOTO.PHOTO_ANSWER_BY_QUESTION_ID_LOADED:
            return Object.assign({}, state, {
                photo: action.payload.photo,
                status: "loaded",
                message: {},
                code: null
            });
        case PHOTO.PHOTO_ANSWER_BY_QUESTION_ID_ERROR:
            return Object.assign({}, state, {
                photo: [],
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case PHOTO.PHOTO_ANSWER_BY_NATURETRAIL_ID_LOADING:
            return Object.assign({}, state, {
                photoByLocation: [],
                message: {},
                status: "loading",
                code: null
            });
        case PHOTO.PHOTO_ANSWER_BY_NATURETRAIL_ID_LOADED:
            return Object.assign({}, state, {
                photoByLocation: action.payload.data,
                status: "loaded",
                message: {},
                code: null
            });
        case PHOTO.PHOTO_ANSWER_BY_NATURETRAIL_ID_ID_ERROR:
            return Object.assign({}, state, {
                photoByLocation: [],
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        default:
            return state;
    }
};

export default photoReducer;
