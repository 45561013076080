import axios from "axios";
import { REGISTRATION } from "../constants/action-types";


export const registration = (email, password, name) => {
    return async (dispatch) => {
        dispatch({ type: REGISTRATION.REGISTRATION_LOADING });
        await axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + "/users/registration", {
                email,
                password,
                name
            })
            .then((response) => {
                dispatch({
                    type: REGISTRATION.REGISTRATION_LOADED,
                    payload: { user: response.data.user },
                });
            })
            .catch((err) => {
                dispatch({
                    type: REGISTRATION.REGISTRATION_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
