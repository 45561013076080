import React, { Component } from "react";
import Body from "./body";
import { Layout, Dropdown, Spin } from "antd";
import { Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  TeamOutlined,
  ProjectOutlined,
  CommentOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import "./main.scss";
import { NavLink } from "react-router-dom";
import { logout } from "../../actions/login";
import { connect } from "react-redux";
import logo from "../../static/login_vandor_logo.png";
import leafLeft from "../../static/leaf_left_cutted.png";
import leafRight from "../../static/leaf_right_cutted.png";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const mapDispatch = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

class ConnectedMain extends Component {
  state = {
    collapsed: false,
    selectedKey: null,
  };

  componentDidMount = () => {
    this.setDefaultSelectedKeyForMenu();
  };

  handleMenuClick = async (event) => {
    switch (event.key) {
      case "logout":
        await this.props.logout();
        this.props.history.push("/login");
        break;
      default:
        break;
    }
  };

  setDefaultSelectedKeyForMenu = () => {
    switch (this.props.location.pathname) {
      case "/nt/landing":
        this.setState({ selectedKey: "1" });
        break;
      case "/nt/naure-trail":
        this.setState({ selectedKey: "2" });
        break;
      case "/nt/gamers":
        this.setState({ selectedKey: "5" });
        break;
      case "/nt/admins":
        this.setState({ selectedKey: "6" });
        break;
      case "/nt/location-admins":
        this.setState({ selectedKey: "7" });
        break;
      case "/nt/languages":
        this.setState({ selectedKey: "8" });
        break;
      case "/nt/test-users":
        this.setState({ selectedKey: "9" });
        break;
      default:
        this.setState({ selectedKey: "1" });
        break;
    }
  };

  handleSelect = (event) => {
    this.setState({ selectedKey: event.key });
  };

  renderMenu = () => {
    return (
      <Menu
        style={{ backgroundColor: "forestgreen" }}
        onClick={this.handleMenuClick} >
        <Menu.Item key="logout" style={{ backgroundColor: "forestgreen", color: "white" }}>Kilépés</Menu.Item>
      </Menu>
    );
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  render() {
    return (
      <Spin
        spinning={false}
        className="customSpin"
        style={{
          zIndex: 100000000,
        }}
        size="large"
      >
        <div style={{ height: "100%" }}>
          <Layout style={{ height: "100vh" }}>
            <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className={
                  this.state.collapsed
                    ? "logo-container logo-container-collapsed"
                    : "logo-container"
                }
              >
                <img
                  src={leafLeft}
                  alt="Nature Trail logo"
                  className="logo-icon-leaf"
                />
                <img src={logo} alt="Nature Trail logo" className="logo-icon" />
                <img
                  src={leafRight}
                  alt="Nature Trail logo"
                  className="logo-icon-leaf"
                />
              </div>
              <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[this.state.selectedKey]}
                onSelect={this.handleSelect}
              >
                <Menu.Item key="1" icon={<PieChartOutlined />}>
                  <NavLink to="/nt/landing" className="link-color">
                    Statisztikák
                  </NavLink>
                </Menu.Item>
                <SubMenu
                  key="sub1"
                  icon={<ProjectOutlined />}
                  title="Tanösvény kezelés"
                >
                  <Menu.Item key="2">
                    <NavLink to="/nt/naure-trail" className="link-color">
                      Tanösvények
                    </NavLink>
                  </Menu.Item>
                  {this.props.user.groupId === 1 && (
                    <Menu.Item key="3">
                      <NavLink to="/nt/badges" className="link-color">
                        Kitűzők
                      </NavLink>
                    </Menu.Item>
                  )}
                  {/* <Menu.Item key="3">
                  <NavLink to="/nt/naure-trail-locations" className="link-color"> Tanösvény helszínek</NavLink>
                </Menu.Item>
                <Menu.Item key="4" >
                  <NavLink to="/nt/naure-trail-objects" className="link-color"> Tanösvény objektumok</NavLink>
                </Menu.Item> */}
                </SubMenu>
                {(this.props.user.groupId === 1 ||
                  this.props.user.groupId === 5) && (
                  <SubMenu
                    key="sub2"
                    icon={<TeamOutlined />}
                    title="Felhasználó kezelés"
                  >
                    <Menu.Item key="5">
                      <NavLink to="/nt/gamers" className="link-color">
                        Játékosok
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="6">
                      <NavLink to="/nt/admins" className="link-color">
                        Adminok
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="7">
                      <NavLink to="/nt/location-admins" className="link-color">
                        Helyszín adminok
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="11">
                      <NavLink to="/nt/test-users" className="link-color">
                        Teszt felhasználók
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                )}
                {(this.props.user.groupId === 1 ||
                  this.props.user.groupId === 5) && (
                  <Menu.Item key="9" icon={<CommentOutlined />}>
                    <NavLink to="/nt/national-pakrs" className="link-color">
                      Nemzeti parkok
                    </NavLink>
                  </Menu.Item>
                )}
                {(this.props.user.groupId === 1 ||
                  this.props.user.groupId === 5) && (
                  <Menu.Item key="10" icon={<CommentOutlined />}>
                    <NavLink to="/nt/nature-trail-types" className="link-color">
                      Tanösvény tipusok
                    </NavLink>
                  </Menu.Item>
                )}
                {(this.props.user.groupId === 1 ||
                  this.props.user.groupId === 5) && (
                  <Menu.Item key="8" icon={<CommentOutlined />}>
                    <NavLink to="/nt/languages" className="link-color">
                      Nyelvek
                    </NavLink>
                  </Menu.Item>
                )}
                {(this.props.user.groupId === 1 ||
                  this.props.user.groupId === 5) && (
                  <Menu.Item key="12" icon={<NotificationOutlined />}>
                    <NavLink to="/nt/push-notification" className="link-color">
                      Inaktivitási értesítő
                    </NavLink>
                  </Menu.Item>
                )}
              </Menu>
            </Sider>
            <Layout className="site-layout">
              <Header
                className="site-layout-background"
                style={{ padding: "0 0 0 26px" }}
              >
                {React.createElement(
                  this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: this.toggle,
                  }
                )}
                <Dropdown.Button
                  type="primary"
                  overlay={this.renderMenu}
                  style={{ float: "right", margin: "12px 26px 0 0" }}
                />
              </Header>
              <Content
                className="site-layout-background"
                style={{
                  margin: "24px 16px",
                  padding: 24,
                  height: "100%",
                  //minHeight: 500,
                }}
              >
                <Body />
              </Content>
            </Layout>
          </Layout>
        </div>
      </Spin>
    );
  }
}

const mapState = (state) => {
  return {
    message: state.userReducer.message,
    code: state.userReducer.code,
    locationAdminsStatus: state.userReducer.locationAdminsStatus,
    users: state.userReducer.users,
    user: state.loginReducer.user,
    spinning: state.spinningReducer.spinning,
  };
};

const Main = connect(mapState, mapDispatch)(ConnectedMain);
export default Main;
