import React, { Component } from 'react'
import QuestionAnswers from './question-answers'
import { Row, Col, Button, List, Skeleton, Popconfirm } from 'antd'
import { connect } from 'react-redux'
import { saveQuestionAnsers, getQuestionAnswersById, getQuestionAndAnswersByNatureTrailId, deleteQuestionAndAswersByQuestionId } from '../../actions/question'
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons'

const mapDispatch = (dispatch) => {
    return {
        saveQuestionAnsers: (questionAnswers, files) => dispatch((saveQuestionAnsers(questionAnswers, files))),
        getQuestionAnswersById: (questionIds) => dispatch((getQuestionAnswersById(questionIds))),
        deleteQuestionAndAswersByQuestionId: (questionId) => dispatch((deleteQuestionAndAswersByQuestionId(questionId))),
        getQuestionAndAnswersByNatureTrailId: (ntlocationId) => dispatch((getQuestionAndAnswersByNatureTrailId(ntlocationId)))
    }
}

//natureTrailLocationId
class ConnectedQuestionAnswersModal extends Component {

    state = {
        questionAnswersData: [
            {
                id: 0,
                question: "",
                answers: [{ key: 0, text: "", image: "", isGood: false, imageURL: "" }]
            }
        ],
        selectedQuestion: [],
        questionAnswersId: [],
        showData: false
    }

    componentDidMount = async () => {
        await this.props.getQuestionAndAnswersByNatureTrailId(this.props.natureTrailLocationId)

        const convertedQuestionsData = this.props.questionAnswersByLocation.map(item => {
            return {
                key: item.id,
                question: item.question,
                isImage: item.isImage,
                answers: item.answers,
                questionType: item.questionType,
                questionContentType: item.questionContentType,
                answerCount: item.answerCount,
                goodAnswerMessage: item.goodAnswerMessage,
                wrongAnswerMessage: item.wrongAnswerMessage,
                soundURL: item.soundURL
            }
        });
        this.setState({ questionAnswersData: convertedQuestionsData })
        const questionIds = convertedQuestionsData.map(item => { return item.key })
        this.setState({ questionAnswersId: questionIds })
    }

    clickOnListItem = async (row, i) => {
        console.log(this.state.questionAnswersData[i]);
        this.setState({ selectedId: i, selectedQuestion: [this.state.questionAnswersData[i]] },
            async () => {
                if (this.state.selectedQuestion[0].key !== undefined) {
                    await this.props.getQuestionAnswersById(this.state.selectedQuestion[0].key)
                    const saveQuestionData = this.props.questionAnswers
                    this.setState({ selectedId: i, selectedQuestion: [saveQuestionData] }, () => {
                    })
                }
            }
        )
        this.setState({ showData: true })
        this.props.setVisible()
    }

    addNewQuestionsAnswer = () => {
        this.setState({
            questionAnswersData: [...this.state.questionAnswersData, {
                isImage: false,
                question: "",
                answers: [],
                new: true
            }],
        })
    }

    deleteChoosedQuestion = (selectedQuestion) => {
        this.setState({ showData: false })
    }

    saveQuestionAndAnswers = async (questionAnswersData, files) => {
        const saveQuestionData = this.state.questionAnswersData
        saveQuestionData[this.state.selectedId].answerCount = questionAnswersData.answerCount;
        saveQuestionData[this.state.selectedId].question = questionAnswersData.question;
        saveQuestionData[this.state.selectedId].answers = questionAnswersData.answers;
        saveQuestionData[this.state.selectedId].questionType = questionAnswersData.questionType;
        saveQuestionData[this.state.selectedId].goodAnswerMessage = questionAnswersData.goodAnswerMessage;
        saveQuestionData[this.state.selectedId].wrongAnswerMessage = questionAnswersData.wrongAnswerMessage;
        saveQuestionData[this.state.selectedId].questionContentType = questionAnswersData.questionContentType

        this.setState({ questionAnswersData: saveQuestionData })


        const saveSendObj = {
            question: {
                natureTrailLocationId: this.props.natureTrailLocationId,
                answerCount: this.state.questionAnswersData[this.state.selectedId].answerCount,
                key: this.state.questionAnswersData[this.state.selectedId].key || this.state.questionAnswersData[this.state.selectedId].id,
                question: this.state.questionAnswersData[this.state.selectedId].question,
                isImage: this.state.questionAnswersData[this.state.selectedId].isImage,
                questionType: this.state.questionAnswersData[this.state.selectedId].questionType,
                goodAnswerMessage: this.state.questionAnswersData[this.state.selectedId].goodAnswerMessage,
                wrongAnswerMessage: this.state.questionAnswersData[this.state.selectedId].wrongAnswerMessage,
                questionContentType: this.state.questionAnswersData[this.state.selectedId].questionContentType
            },
            answers: this.state.questionAnswersData[this.state.selectedId].answers
        }
        await this.props.saveQuestionAnsers(saveSendObj, files);

        if (this.props.saveStatus === "saved") {
            const temp = this.state.questionAnswersData;
            temp[this.state.selectedId].key = this.props.questionAnswers.key;
            await this.props.getQuestionAndAnswersByNatureTrailId(this.props.natureTrailLocationId)

            // const convertedQuestionsData = this.props.questionAnswersByLocation.map(item => {
            //     return {
            //         key: item.id,
            //         question: item.question,
            //         isImage: item.isImage,
            //         answers: item.answers,
            //     }
            // });


            const teszt = this.state.questionAnswersId.filter(item => item === this.props.questionAnswers.key);
            if (teszt.length === 0) {
                this.setState({ questionAnswersId: [...this.state.questionAnswersId, this.props.questionAnswers.key] },
                    () => {
                        this.props.setQuestionAnswersId(this.state.questionAnswersId);
                    })
            }
            this.setState({
                questionAnswersData: temp
            })


        }
        else {
            // alert("HIBAAAA")
        }
    }
    handleDelete = (row, i) => {
        //leszűrni a listából
        const filteredList = this.state.questionAnswersData.filter(item => item !== this.state.questionAnswersData[i])
        //törlés a listából
        this.setState({ questionAnswersData: filteredList, showData: false })
        // törlésa z id listából és a szerverről
        if (this.state.questionAnswersData[i].key !== undefined) {
            const filterIdList = this.state.questionAnswersId.filter(item => item !== this.state.questionAnswersData[i].key);
            this.setState({ questionAnswersId: filterIdList },
                () => {
                    this.props.setQuestionAnswersId(this.state.questionAnswersId);
                })
            this.props.deleteQuestionAndAswersByQuestionId(this.state.questionAnswersData[i].key)
        }
    }

    render() {
        return (
            <div >
                <Row gutter={[24, 16]}>
                    <Col span={24} >
                        <Button onClick={this.addNewQuestionsAnswer}>Új Kérdőív hozzáadása</Button>
                    </Col>
                    <Col span={7} >
                        <List
                            style={{ height: "85vh", overflowY: "auto" }}
                            bordered
                            size={Row}
                            dataSource={this.state.questionAnswersData}
                            renderItem={(item, i) => (
                                <List.Item
                                    actions={[<Button type="primary" shape="circle" onClick={() => this.clickOnListItem(item, i)} icon={<EditOutlined />} size="small" />
                                        , <Popconfirm title="Biztos törlöd?" onConfirm={() => this.handleDelete(item, i)}>
                                        {<DeleteOutlined />}
                                    </Popconfirm>

                                    ]}
                                >
                                    <Skeleton avatar title={false} loading={item.loading} active>
                                        <List.Item.Meta

                                            description={<p style={{
                                                width: "8vw",
                                                // height: "66px",
                                                // overflow: "hidden"
                                            }}
                                            >{item.question.substr(0, 48)} {item.question.length > 48 ? "..." : ""}</p>}
                                            title={
                                                <Row>
                                                    <Col span={4}>
                                                        <QuestionCircleOutlined />
                                                    </Col>
                                                    <Col span={20}>
                                                        <p>{(i + 1) + ", kérdés:"}</p>
                                                    </Col>
                                                </Row>
                                            }
                                        />
                                    </Skeleton>
                                </List.Item>
                            )}
                        // renderItem={(item, i) => (
                        //     <Row gutter={20}>
                        //         <Col span={16}>
                        //             <List.Item value={i} onClick={this.clickOnListItem}>
                        //                 <Typography.Text strong >{i + 1 + ":"}</Typography.Text> {item.question}
                        //             </List.Item>
                        //         </Col>
                        //         <Col span={4}>
                        //             <Button onClick={() => { console.log(item); }}>aa</Button>
                        //         </Col>

                        //     </Row>
                        // )}
                        />
                    </Col>
                    <Col span={17} >
                        {this.state.showData && <QuestionAnswers
                            setQuestionAnswersId={this.props.setQuestionAnswersId}
                            isVisible={this.props.isVisible}
                            selectedId={this.state.selectedId}
                            questionAnswersData={this.state.selectedQuestion}
                            saveQuestionAndAnswers={this.saveQuestionAndAnswers}></QuestionAnswers>}
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapState = (state) => {
    return {
        message: state.natureTrailReducer.message,
        code: state.natureTrailReducer.code,
        status: state.natureTrailReducer.status,
        natureTrails: state.natureTrailReducer.data,
        savedNatureTrailLocation: state.natureTrailReducer.savedNatureTrailLocation,
        locationSavingStatus: state.natureTrailReducer.locationSavingStatus,
        triggerTypes: state.natureTrailReducer.triggerTypes,
        triggerTypeStatus: state.natureTrailReducer.triggerTypeStatus,
        questionAnswers: state.questionReducer.questionAnswers,
        saveStatus: state.questionReducer.saveStatus,
        questionAnswersByLocation: state.questionReducer.questionAnswersByLocation,
    }
}

const QuestionAnswersModal = connect(mapState, mapDispatch)(ConnectedQuestionAnswersModal)
export default QuestionAnswersModal;