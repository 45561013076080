import { MOBILE_ENV } from "../constants/action-types";

const initialState = {
  envs: [],
  status: null,
  saveStatus: null,
  message: {},
  code: null,
};

const mobileEnvReducer = (state = initialState, action) => {
  switch (action.type) {
    case MOBILE_ENV.GET_ALL_MOBILE_ENV_LOADING:
      return Object.assign({}, state, {
        envs: [],
        message: {},
        status: "loading",
        code: null,
      });
    case MOBILE_ENV.GET_ALL_MOBILE_ENV_LOADED:
      return Object.assign({}, state, {
        envs: action.payload,
        status: "loaded",
        message: {},
        code: null,
      });
    case MOBILE_ENV.GET_ALL_MOBILE_ENV_ERROR:
      return Object.assign({}, state, {
        envs: [],
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case MOBILE_ENV.SAVE_MOBILE_ENV_START:
      return Object.assign({}, state, {
        message: {},
        saveStatus: "saving",
        code: null,
      });
    case MOBILE_ENV.SAVE_MOBILE_ENV_FINISH:
      return Object.assign({}, state, {
        saveStatus: "saved",
        message: {},
        code: null,
      });
    case MOBILE_ENV.SAVE_MOBILE_ENV_ERROR:
      return Object.assign({}, state, {
        saveStatus: "error",
        message: action.payload.message,
        code: action.payload.code,
      });

    default:
      return state;
  }
};
export default mobileEnvReducer;
