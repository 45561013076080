import axios from "axios";
import { PAIR_MATCH, QUESTION } from "../constants/action-types";


export const getOnePairMatchById = (id) => {
    return async (dispatch) => {
        dispatch({ type: PAIR_MATCH.GET_PAIR_MATCH_BY_ID_LOADING });
        await axios
            .get("pair-match/one-by-id", {
                params: {
                    id: id
                }
            })
            .then(({ data }) => {
                dispatch({
                    type: PAIR_MATCH.GET_PAIR_MATCH_BY_ID_LOADED,
                    payload: { data: data.pairMatch },
                });
            })
            .catch((err) => {
                dispatch({
                    type: PAIR_MATCH.GET_PAIR_MATCH_BY_ID_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getAllPairMatchByNatureTrailLocationId = (natureTrailLocationId) => {
    return async (dispatch) => {
        dispatch({ type: PAIR_MATCH.GET_ALL_PAIR_MATCH_BY_NT_ID_LOADING });
        await axios
            .get("pair-match/all-by-nature-trail-location-id", {
                params: {
                    natureTrailLocationId: natureTrailLocationId
                }
            })
            .then((response) => {
                console.log(response.data.pairMatches);
                dispatch({
                    type: PAIR_MATCH.GET_ALL_PAIR_MATCH_BY_NT_ID_LOADED,
                    payload: { data: response.data.pairMatches },
                });
            })
            .catch((err) => {
                dispatch({
                    type: PAIR_MATCH.GET_ALL_PAIR_MATCH_BY_NT_ID_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};

export const deletePairMatchById = (id) => {
    return async (dispatch) => {
        dispatch({ type: PAIR_MATCH.DELETE_ONE_PAIR_MATCH_BY_ID_DELETING });
        await axios
            .delete("pair-match/delete-pair-match-by-id/" + id)
            .then((response) => {
                dispatch({
                    type: PAIR_MATCH.DELETE_ONE_PAIR_MATCH_BY_ID_DELETED,
                    payload: { data: response.data },
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: PAIR_MATCH.DELETE_ONE_PAIR_MATCH_BY_ID_ERROR,
                    //payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};

export const savePairMatchWithAnswers = (files, pairMatch) => {
    const formData = new FormData();
    //files
    formData.append("picture1afile", files[0] ? files[0] : []);
    formData.append("picture1bfile", files[1] ? files[1] : []);
    formData.append("picture2afile", files[2] ? files[2] : []);
    formData.append("picture2bfile", files[3] ? files[3] : []);
    formData.append("picture3afile", files[4] ? files[4] : []);
    formData.append("picture3bfile", files[5] ? files[5] : []);
    formData.append("picture4afile", files[6] ? files[6] : []);
    formData.append("picture4bfile", files[7] ? files[7] : []);
    //data
    formData.append("pairMatch", JSON.stringify(pairMatch));
    return async (dispatch) => {
        dispatch({ type: PAIR_MATCH.MODIFY_PAIR_MATCH_SAVING });
        await axios
            .post("/pair-match/modify-pair-match", formData
            )
            .then((response) => {
                dispatch({
                    type: PAIR_MATCH.MODIFY_PAIR_MATCH_SAVED,
                    // payload: { questionAnsers: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: PAIR_MATCH.MODIFY_PAIR_MATCH_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}
export const addNewPairMatch = (natureTrailLocationId) => {
    return async (dispatch) => {
        dispatch({ type: PAIR_MATCH.ADD_NEW_PAIR_MATCH_SAVING });
        await axios
            .post("/pair-match/add-new-pair-match", null,
                { params: { natureTrailLocationId: natureTrailLocationId } }
            )
            .then((response) => {
                dispatch({
                    type: PAIR_MATCH.ADD_NEW_PAIR_MATCH_SAVED,
                    payload: { pairMatch: response.data.pairMatch },
                });
            })
            .catch((err) => {
                dispatch({
                    type: PAIR_MATCH.ADD_NEW_PAIR_MATCH_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}