import { LOGIN_ACTIONS } from "../constants/action-types";

const initialState = {
  token: null,
  user: {},
  message: {},
  code: null
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ACTIONS.LOGOUT:
      return Object.assign({}, state, {
        token: null,
        user: {},
        message: {},
        code: null
      })
    case LOGIN_ACTIONS.TOKEN_LOADING:
      return Object.assign({}, state, {
        token: null,
        user: {},
        message: {},
        status: "loading",
        code: null
      });
    case LOGIN_ACTIONS.TOKEN_LOADED:
      return Object.assign({}, state, {
        token: action.payload.token,
        user: action.payload.user,
        status: "loaded",
        message: {},
        code: null
      });
    case LOGIN_ACTIONS.TOKEN_ERROR:
      return Object.assign({}, state, {
        token: null,
        user: {},
        status: "error",
        message: action.payload.message,
        code: action.payload.code
      });


    default:
      return state;
  }
};

export default loginReducer;
