import axios from "axios";
import { QUESTION } from "../constants/action-types";

export const saveQuestionAnsers = (questionAnswers, files) => {
    const formData = new FormData();
    //files
    formData.append("picture1", files.picture1 !== undefined && files.picture1.name !== undefined ? new File([files.picture1], files.picture1.name) : []);
    formData.append("picture2", files.picture2 !== undefined && files.picture2.name !== undefined ? new File([files.picture2], files.picture2.name) : []);
    formData.append("picture3", files.picture3 !== undefined && files.picture3.name !== undefined ? new File([files.picture3], files.picture3.name) : []);
    formData.append("picture4", files.picture4 !== undefined && files.picture4.name !== undefined ? new File([files.picture4], files.picture4.name) : []);
    formData.append("questionSound", files.questionSound !== undefined ? files.questionSound : []);
    formData.append("questionPicture", files.questionPicture !== undefined && files.questionPicture[0] !== undefined ? files.questionPicture[0] : []);
    formData.append("sound1", files.sound1 !== undefined ? files.sound1 : []);
    formData.append("sound2", files.sound2 !== undefined ? files.sound2 : []);
    formData.append("sound3", files.sound3 !== undefined ? files.sound3 : []);
    formData.append("sound4", files.sound4 !== undefined ? files.sound4 : []);


    // formData.append("picture1", files.picture1[0] !== undefined ? new File(files.picture1, files.picture1[0].name) : []);
    // formData.append("picture2", files.picture2[0] !== undefined ? new File(files.picture2, files.picture2[0].name) : []);
    // formData.append("picture3", files.picture3[0] !== undefined ? new File(files.picture3, files.picture3[0].name) : []);
    // formData.append("picture4", files.picture4[0] !== undefined ? new File(files.picture4, files.picture4[0].name) : []);
    //data
    formData.append("questionAnswers", JSON.stringify(questionAnswers));
    return async (dispatch) => {
        dispatch({ type: QUESTION.QUESTION_ANSWERS_SAVING });
        await axios
            .post("/questions/save-question-answers", formData
            )
            .then((response) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWERS_SAVED,
                    payload: { questionAnsers: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWERS_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}
export const getQuestionAnswersById = (id) => {
    return async (dispatch) => {
        dispatch({ type: QUESTION.QUESTION_ANSWERS_BY_ID_LOADING });
        await axios
            .get("questions/one-by-id", {
                params: {
                    id: id
                }
            })
            .then(({ data }) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWERS_BY_ID_LOADED,
                    payload: { data: data.questionAnsers },
                });
            })
            .catch((err) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWERS_BY_ID_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getQuestionAndAnswersByNatureTrailId = (id) => {
    return async (dispatch) => {
        dispatch({ type: QUESTION.QUESTION_ANSWERS_BY_NATURETRAIL_ID_LOADING });
        await axios
            .get("questions/all-by-nature-trail-id", {
                params: {
                    id: id
                }
            })
            .then((response) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWERS_BY_NATURETRAIL_ID_LOADED,
                    payload: { data: response.data.questionAnsers },
                });
            })
            .catch((err) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWERS_BY_NATURETRAIL_ID_ID_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};

export const deleteQuestionAndAswersByQuestionId = (id) => {
    return async () => {
        await axios
            .delete("questions/delete-question-andanswers-by-question-id/" + id)
            .catch((err) => console.log(err));
    };
};
//---------------------------------
//Kérdés válasz actions
//---------------------------------
export const getQuestionAnswerByNatureTrailId = (id) => {
    return async (dispatch) => {
        dispatch({ type: QUESTION.QUESTION_ANSWER_BY_NATURETRAIL_ID_LOADING });
        await axios
            .get("questions/all-question-by-nature-trail-id", {
                params: {
                    id: id
                }
            })
            .then((response) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWER_BY_NATURETRAIL_ID_LOADED,
                    payload: { data: response.data.questionAnser },
                });
            })
            .catch((err) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWER_BY_NATURETRAIL_ID_ID_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}

export const getQuestionAnswerByQuestionId = (id) => {
    return async (dispatch) => {
        dispatch({ type: QUESTION.QUESTION_ANSWER_BY_QUESTION_ID_LOADING });
        await axios
            .get("questions/save-question-answer", {
                params: {
                    id: id
                }
            })
            .then((response) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWER_BY_QUESTION_ID_LOADED,
                    payload: { data: response.data.questionAnser },
                });
            })
            .catch((err) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWER_BY_QUESTION_ID_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}

export const saveQuestionAnswer = (questionAnswer) => {
    return async (dispatch) => {
        dispatch({ type: QUESTION.QUESTION_ANSWER_SAVING });
        await axios
            .post("/questions/save-question-answer", questionAnswer
            )
            .then((response) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWER_SAVED,
                    payload: { questionAnsers: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: QUESTION.QUESTION_ANSWER_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}