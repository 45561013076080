import { Button, Col, Input, message, Row } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveOwner1, saveOwner2 } from "../../actions/nature-trail"
const mapDispatchToProps = (dispatch) => {
    return {
        saveOwner1: (ownerObj) => dispatch(saveOwner1(ownerObj)),
        saveOwner2: (ownerObj) => dispatch(saveOwner2(ownerObj)),
    };
};
class ConnectedNatureTrailOwner extends Component {
    state = {
        email1: "",
        email2: "",
        email1IsActive: false,
        email2IsActive: false
    }

    componentDidMount = () => {
        this.setState({ email1: this.props.ownerEmail1, email2: this.props.ownerEmail2 })
    }
    changeEmail1 = (event) => {
        const actualValue = event.target.value
        this.setState({ email1: actualValue, email1IsActive: true })
    }
    changeEmail2 = (event) => {
        const actualValue = event.target.value
        this.setState({ email2: actualValue, email2IsActive: true })
    }

    saveEmail1 = async () => {
        this.setState({ email1IsActive: false })

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(this.state.email1)) {
            const ownerObj = {
                natureTrailId: this.props.natureTrailId,
                email: this.state.email1
            }
            await this.props.saveOwner1(ownerObj)
            if (this.props.owner1SaveStatus === "saved") {
                message.success("Sikeres hozzáadás")
            } else {
                message.error("Hiba a mentés során")
            }
        }
        else {
            message.error("Az email cím nem megfelelő formátumú")
        }
    }

    saveEmail2 = async () => {
        this.setState({ email2IsActive: false })

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(this.state.email2)) {
            const ownerObj = {
                natureTrailId: this.props.natureTrailId,
                email: this.state.email2
            }
            await this.props.saveOwner2(ownerObj)
            if (this.props.owner2SaveStatus === "saved") {
                message.success("Sikeres hozzáadás")
            } else {
                message.error("Hiba a mentés során")
            }
        }
        else {
            message.error("Az email cím nem megfelelő formátumú")
        }
    }
    render() {
        return <div>
            <Row align="middle" justify="center" gutter={16}>
                <Col span={12}>
                    <Row gutter={16} justify="space-between" align="middle" style={{ paddingBottom: 16 }}>
                        <Col span={24}><h3>Elsődleges email cím: </h3></Col>
                        <Col span={20} >
                            <Input
                                type="email"
                                onChange={this.changeEmail1}
                                value={this.state.email1}
                            />
                        </Col>

                        <Col span={4} >
                            <Button
                                disabled={!this.state.email1IsActive}
                                onClick={this.saveEmail1}>
                                Hozzáad
                                </Button>
                        </Col>
                    </Row>
                    <Row gutter={16} justify="space-between" align="middle">
                        <Col span={24}><h3>Másodlagos email cím: </h3></Col>
                        <Col span={20} >
                            <Input
                                type="email"
                                onChange={this.changeEmail2}
                                value={this.state.email2}
                            />
                        </Col>
                        <Col span={4} >
                            <Button
                                disabled={!this.state.email2IsActive}
                                onClick={this.saveEmail2}>Hozzáad</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>;
    }
}
const mapStateToProps = (state) => ({
    owner1SaveStatus: state.natureTrailReducer.owner1SaveStatus,
    owner2SaveStatus: state.natureTrailReducer.owner2SaveStatus
});
const NatureTrailOwner = connect(mapStateToProps, mapDispatchToProps)(ConnectedNatureTrailOwner);
export default NatureTrailOwner;