import axios from "axios";
import { FORGOTPASSWORD } from "../constants/action-types";

export const forgotPassword = (email) => {
    return async (dispatch) => {
        dispatch({ type: FORGOTPASSWORD.FORGOTPASSWORD_LOADING });
        await axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + "/users/forgot-password", {
                email
            })
            .then((response) => {
                dispatch({
                    type: FORGOTPASSWORD.FORGOTPASSWORD_LOADED,
                });
            })
            .catch((err) => {
                dispatch({
                    type: FORGOTPASSWORD.FORGOTPASSWORD_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
