import React from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Login from "./components/Login/login";
import Main from "./components/skeleton/main";
import ProtectedRoute from "./components/ProtectedRoute/protected-route";
import Registration from "./components/Registration/registration";
import ForgotPassword from "./components/ForgotPassword/forgot-password";
import Gdpr from "./components/Gdpr/gdpr";
import TermsOfUse from "./components/Gdpr/terms-of-use";
import PrivacyEN from "./components/Gdpr/privacy_EN";
import TermsOfUseEN from "./components/Gdpr/terms-of-use_EN";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/gdpr" component={Gdpr} />
          <Route exact path="/term-of-use" component={TermsOfUse} />
          <Route exact path="/privacy" component={PrivacyEN} />
          <Route exact path="/term-of-use-en" component={TermsOfUseEN} />
          <ProtectedRoute path="/nt" component={Main} rights={[1, 2, 4, 5]} />
          <Redirect from="**" to="/login" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
