import { QUESTION } from "../constants/action-types";

const initialState = {
    questionAnswers: [],
    status: null,
};

const questionReducer = (state = initialState, action) => {
    switch (action.type) {
        case QUESTION.QUESTION_ANSWERS_SAVING:
            return Object.assign({}, state, {
                questionAnswers: [],
                message: {},
                saveStatus: "saving",
                code: null
            });
        case QUESTION.QUESTION_ANSWERS_SAVED:
            return Object.assign({}, state, {
                questionAnswers: action.payload.questionAnsers,
                saveStatus: "saved",
                message: {},
                code: null
            });
        case QUESTION.QUESTION_ANSWERS_ERROR:
            return Object.assign({}, state, {
                questionAnswers: [],
                saveStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case QUESTION.QUESTION_ANSWERS_BY_ID_LOADING:
            return Object.assign({}, state, {
                questionAnswers: [],
                message: {},
                status: "loading",
                code: null
            });
        case QUESTION.QUESTION_ANSWERS_BY_ID_LOADED:
            return Object.assign({}, state, {
                questionAnswers: action.payload.data,
                status: "loaded",
                message: {},
                code: null
            });
        case QUESTION.QUESTION_ANSWERS_BY_ID_ERROR:
            return Object.assign({}, state, {
                questionAnswers: [],
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case QUESTION.QUESTION_ANSWERS_BY_NATURETRAIL_ID_LOADING:
            return Object.assign({}, state, {
                questionAnswersByLocation: [],
                message: {},
                status: "loading",
                code: null
            });
        case QUESTION.QUESTION_ANSWERS_BY_NATURETRAIL_ID_LOADED:
            return Object.assign({}, state, {
                questionAnswersByLocation: action.payload.data,
                status: "loaded",
                message: {},
                code: null
            });
        case QUESTION.QUESTION_ANSWERS_BY_NATURETRAIL_ID_ID_ERROR:
            return Object.assign({}, state, {
                questionAnswersByLocation: [],
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case QUESTION.QUESTION_ANSWER_SAVING:
            return Object.assign({}, state, {
                questionAnswer: [],
                message: {},
                saveStatus: "saving",
                code: null
            });
        case QUESTION.QUESTION_ANSWER_SAVED:
            return Object.assign({}, state, {
                questionAnswer: action.payload.questionAnsers,
                saveStatus: "saved",
                message: {},
                code: null
            });
        case QUESTION.QUESTION_ANSWER_ERROR:
            return Object.assign({}, state, {
                questionAnswer: [],
                saveStatus: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case QUESTION.QUESTION_ANSWER_BY_QUESTION_ID_LOADING:
            return Object.assign({}, state, {
                questionAnswer: [],
                message: {},
                status: "loading",
                code: null
            });
        case QUESTION.QUESTION_ANSWER_BY_QUESTION_ID_LOADED:
            return Object.assign({}, state, {
                questionAnswer: action.payload.questionAnsers,
                status: "loaded",
                message: {},
                code: null
            });
        case QUESTION.QUESTION_ANSWER_BY_QUESTION_ID_ERROR:
            return Object.assign({}, state, {
                questionAnswer: [],
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        case QUESTION.QUESTION_ANSWER_BY_NATURETRAIL_ID_LOADING:
            return Object.assign({}, state, {
                questionAnswerByLocation: [],
                message: {},
                status: "loading",
                code: null
            });
        case QUESTION.QUESTION_ANSWER_BY_NATURETRAIL_ID_LOADED:
            return Object.assign({}, state, {
                questionAnswerByLocation: action.payload.data,
                status: "loaded",
                message: {},
                code: null
            });
        case QUESTION.QUESTION_ANSWER_BY_NATURETRAIL_ID_ID_ERROR:
            return Object.assign({}, state, {
                questionAnswerByLocation: [],
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        default:
            return state;
    }
};

export default questionReducer;
