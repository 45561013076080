import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getNatureTrailRatingById } from '../../actions/nature-trail';
import StarRatingComponent from 'react-star-rating-component';
import { Table } from 'antd';

const mapDispatchToProps = (dispatch) => {
    return {
        getNatureTrailRatingById: (natureTrailId) => dispatch(getNatureTrailRatingById(natureTrailId))
    };
};
class ConnectedNatureTrailRatingByUser extends Component {
    state = {
        columns: [
            { title: 'Felhasználó név', dataIndex: 'userName', key: 'userName' },
            {
                title: 'Értékelés', dataIndex: 'ratingPoint', key: 'ratingPoint', width: "10vw", render: (ratingPoint) => {
                    return (<StarRatingComponent
                        name="rate2"
                        editing={false}
                        // renderStarIcon={() => <span></span>}
                        starCount={5}
                        value={ratingPoint}
                    />)
                }
            },

            {
                title: 'Értékelés dátuma', dataIndex: 'createdDate', key: 'createdDate',
                render: (createdDate) => {
                    const created = new Date(createdDate * 1000)
                    return (<div>{created.toLocaleDateString()}</div>)
                }
            },

        ]

    }
    componentDidMount = async () => {
        await this.props.getNatureTrailRatingById(this.props.natureTrailId)
    }

    render() {
        return <div> <Table
            scroll={{ y: "48vh" }}
            rowKey="id"
            columns={this.state.columns}
            expandable={{
                expandedRowRender: record => <p style={{ margin: 0 }}>{record.opinion !== null && record.opinion !== undefined ? record.opinion : "Nincs szöveges értékelés"}</p>,
                //rowExpandable: record => record.opinion !== null && record.opinion !== undefined,
            }}
            dataSource={this.props.ratings}
            pagination={false}
        />,</div>;
    }
}
const mapState = (state) => {
    return {
        ratings: state.natureTrailReducer.ratings,
        ratingStatus: state.natureTrailReducer.ratingStatus,
        message: state.natureTrailReducer.message,
        code: state.natureTrailReducer.code
    }
}
const NatureTrailRatingByUser = connect(mapState, mapDispatchToProps)(ConnectedNatureTrailRatingByUser);
export default NatureTrailRatingByUser;