import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLanguagesList, getNatureTrailByLanguageCode, saveNatureTraiLanguages, activeDeactiveLanguagedNatureTrail, getLangugedQuestionAnswersByIdAndLanguageCode, getLangugedPairMatchByIdAndLanguageCode } from '../../actions/language';
import { Row, Col, Select, Divider, Input, Mentions, Table, Tabs, Button, Tooltip, Checkbox, Upload, message, Spin } from 'antd';
import { QuestionCircleOutlined, UploadOutlined, DeleteOutlined, SaveFilled, EyeFilled, CheckCircleOutlined, CloseCircleOutlined, PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player'
    ;

const { Option } = Select;
const { TabPane } = Tabs;


const mapDispatchToProps = (dispatch) => {
    return {
        getLanguagesList: () => dispatch(getLanguagesList()),
        getNatureTrailByLanguageCode: (natureTrailid, userId, userLanguage) => dispatch(getNatureTrailByLanguageCode(natureTrailid, userId, userLanguage)),
        saveNatureTraiLanguages: (languageFile, files) => dispatch(saveNatureTraiLanguages(languageFile, files)),
        activeDeactiveLanguagedNatureTrail: (natureTrailid, languageCode) => dispatch(activeDeactiveLanguagedNatureTrail(natureTrailid, languageCode)),
        getLangugedQuestionAnswersByIdAndLanguageCode: (questionId, languageCode) => dispatch(getLangugedQuestionAnswersByIdAndLanguageCode(questionId, languageCode)),
        getLangugedPairMatchByIdAndLanguageCode: (pairMatchId, languageCode) => dispatch(getLangugedPairMatchByIdAndLanguageCode(pairMatchId, languageCode))
    };
};
class ConnectedNatureTrailLanguage extends Component {

    state = {
        asnwerPlay: [{ play: false },
        { play: false },
        { play: false },
        { play: false }],
        isLoaded: false,
        tabKey: "1",
        spinning: false,
        isSavable: false,
        isEditable: false,
        selectedLanguage: { code: "en", id: 0 },
        columns: [
            {
                title: 'Állomás név',
                dataIndex: 'name',

            },
            // {
            //     title: ' ',
            //     width: '4em',
            //     dataIndex: 'operation',
            //     render: (text, record) =>
            //             <Button type="primary" shape="circle" onClick={() => this.handleShow(record)} icon={<EditOutlined />} size="small" />
            // }
        ],
        questionColumns: [
            {
                title: 'Kérdés',
                dataIndex: 'question',
            }
        ],
        pairMatchColumns: [
            {
                title: 'Kérdés',
                dataIndex: 'question',
            }
        ],
        selectedLocation: undefined,
        movieFile: [],
        musicFile: [],
        loadedNatureTrailByLanguage: {
            title: "",
            description_short: "",
            description_long: "",
            isActive: false
        },
        filteredLanguageLocation: {
            id: null,
            name: "enn",
            description: "en",
            startPictureFileURL: null,
            movieFile: null,
            movieFileURL: undefined,
            movieFileName: "",
            movieFileSize: 0,
            moviePreview: "",
            musicFile: null,
            musicFileURL: " ",
            musicFileName: "",
            musicPreview: "",
            finishDescription: "en",
            questionnaires: [
            ],
            question: [
            ]
        }
    }

    audio = new Audio("")
    answer1audio = new Audio("")
    answer2audio = new Audio("")
    answer3audio = new Audio("")
    answer4audio = new Audio("")

    setSaveButtonActive = () => {
        this.setState({ isSavable: true })
    }

    componentDidMount = async () => {
        await this.props.getLanguagesList()
        this.setState({
            selectedLanguage: {
                code: this.props.languages[0].code,
                id: this.props.languages[0].id
            },
            isEditable: false
        })
    }

    changeTab = (key) => {
        this.setState({
            tabKey: key
        })
        this.stopAllAudio()
    }

    selectLanguageCode = async (value) => {
        this.changeTab("1")
        const resetfilteredLanguageLocation = [{
            id: null,
            name: "",
            description: "",
            startPictureFileURL: null,
            movieFile: null,
            movieFileURL: undefined,
            movieFileName: "",
            movieFileSize: 0,
            moviePreview: "",
            musicFile: null,
            musicFileURL: " ",
            musicFileSize: 0,
            musicFileName: "",
            musicPreview: "",
            finishDescription: "",
            questionnaires: [
            ],
            question: [
            ],
            pairMatchQuestions: []
        }]
        this.setState({ filteredLanguageLocation: resetfilteredLanguageLocation, selectedQuestion: undefined, selectedLocation: undefined, selectedRowKey: -1, })
        const filteredCode = this.props.languages.filter(item =>
            item.code === value)
        if (filteredCode[0].code === "hu") {
            this.setState({
                selectedLanguage: {
                    code: filteredCode[0].code,
                    id: filteredCode[0].id
                },
                isEditable: false
            }, () => this.clickOnEditNatureTrailBySelectedLanguage())

        } else {
            this.setState({
                selectedLanguage: {
                    code: filteredCode[0].code,
                    id: filteredCode[0].id
                },
                isEditable: true
            }, () => { this.clickOnEditNatureTrailBySelectedLanguage() })
        }
        this.stopAllAudio()
    }
    handleShow(record) {
    }

    selectRow = (row, key) => {
        this.stopAllAudio()
        this.setState({ selectedRowKey: key, selectedLocation: row, isLoaded: false })
        if (this.state.filteredLanguageLocation !== undefined) {
            const loadedNTLocactions = this.state.loadedNatureTrailByLanguage
            console.log(loadedNTLocactions);
            loadedNTLocactions.locations.forEach(element => {
                if (element.id === this.state.filteredLanguageLocation.id) {
                    element = this.state.filteredLanguageLocation
                }
                this.setState({ loadedNatureTrailByLanguage: loadedNTLocactions })
            })
        }
        const filteredLanguageLocation = this.state.loadedNatureTrailByLanguage.locations.filter(item => item.id === row.id)
        this.setState({ filteredLanguageLocation: filteredLanguageLocation })
    }

    selectQuestionRow = async (row, key) => {
        this.stopAllAudio()
        await this.props.getLangugedQuestionAnswersByIdAndLanguageCode(row.key, this.state.selectedLanguage.code)
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].questionnaires.filter(item => item.key === row.key)
        this.setState({ selectedQuestionKey: key, selectedQuestion: findedQuestion, isLoaded: true })
        if (this.props.languagedQuestion && this.props.languagedQuestion.soundURL && this.props.languagedQuestion.soundURL !== null) {
            this.audio.src = process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.languagedQuestion.soundURL
        } else {
            const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
            const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
            const findedQuestion = findedActualLocation[0].questionnaires.filter(item => item.key === row.key)
            if (findedQuestion[0].musicFile) {
                this.audio.src = URL.createObjectURL(findedQuestion[0].musicFile[0])
            } else {
                this.audio.src = ""
            }
        }
        //Hangok
        if (this.props.languagedQuestion && this.props.languagedQuestion.answers && this.props.languagedQuestion.answers.length > 0) {
            this.props.languagedQuestion.answers.forEach((element, index) => {
                if (element.soundURL && element.soundURL !== null)
                    switch (index) {
                        case 0:
                            this.answer1audio.src = process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + element.soundURL;
                            break;
                        case 1:
                            this.answer2audio.src = process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + element.soundURL
                            break;
                        case 2:
                            this.answer3audio.src = process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + element.soundURL
                            break;
                        case 3:
                            this.answer4audio.src = process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + element.soundURL
                            break;
                        default:
                            break;
                    }
            });
        } else {
            const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
            const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
            const findedQuestion = findedActualLocation[0].questionnaires.filter(item => item.key === row.key)
            if (findedQuestion[0].answers && findedQuestion[0].answers.length > 0) {
                findedQuestion[0].answers.forEach((element, index) => {
                    if (element.soundURL && element.soundURL !== null)
                        switch (index) {
                            case 0:
                                this.answer1audio.src = process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + element.soundURL;
                                break;
                            case 1:
                                this.answer2audio.src = process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + element.soundURL
                                break;
                            case 2:
                                this.answer3audio.src = process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + element.soundURL
                                break;
                            case 3:
                                this.answer4audio.src = process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + element.soundURL
                                break;
                            default:
                                break;
                        }
                });
            } else {
                this.answer1audio.src = ""
                this.answer2audio.src = ""
                this.answer3audio.src = ""
                this.answer4audio.src = ""
            }
        }
        const tempSelected = row
        const tempFilteredAnswers = tempSelected.answers.filter((item, i) => i < row.answerCount)
        tempSelected.answers = tempFilteredAnswers
        this.setState({ selectedQuestionKey: key, selectedQuestion: tempSelected, filtereddeLanguageQuestion: findedQuestion })
    }

    beforeUploadMovie = (file) => {
        this.stopAllAudio()
        const isLt100M = file.size / 1024 / 1024 < 100;
        if (!isLt100M) {
            message.error('A videónak kissebbnek kell lennie mint 100MB!');
        } else {
            const actualLocation = this.state.filteredLanguageLocation;
            actualLocation[0].movieFileName = file.name
            actualLocation[0].movieFileSize = file.size
            actualLocation[0].movieFile = [file]
            actualLocation[0].moviePreview = URL.createObjectURL(file)
            this.setState({ filteredLanguageLocation: actualLocation })
            this.setSaveButtonActive()
        }
        return isLt100M;
    }

    removeMovie = () => {
        this.stopAllAudio()
        const actualLocation = this.state.filteredLanguageLocation;
        actualLocation[0].movieFileName = undefined
        actualLocation[0].movieFile = []
        actualLocation[0].moviePreview = ""
        this.setState({ filteredLanguageLocation: actualLocation })
        this.setSaveButtonActive()
    }

    beforeUploadMusic = (file) => {
        this.stopAllAudio()
        const isLt100M = file.size / 1024 / 1024 < 10;
        if (!isLt100M) {
            message.error('A hanganyagnak kissebbnek kell lennie mint 10MB!');
        } else {
            this.stopAllAudio();
            if (file.type && file.type === "audio/mpeg") {
                const actualLocation = this.state.filteredLanguageLocation;
                actualLocation[0].musicFileName = file.name
                actualLocation[0].musicFileSize = file.size
                actualLocation[0].musicFile = [file]
                actualLocation[0].musicPreview = URL.createObjectURL(file)
                this.setState({ filteredLanguageLocation: actualLocation })
                this.setSaveButtonActive()
            } else {
                message.error("Nem megfelelő formátum lett kiválasztva")
            }
        }
        return isLt100M;
    }

    removeMusic = () => {
        this.stopAllAudio()
        const actualLocation = this.state.filteredLanguageLocation;
        actualLocation[0].musicFileName = undefined
        actualLocation[0].musicFile = []
        actualLocation[0].musicPreview = ""
        this.setState({ filteredLanguageLocation: actualLocation })
        this.setSaveButtonActive()
    }

    clickOnEditNatureTrailBySelectedLanguage = async () => {
        this.stopAllAudio()
        await this.props.getNatureTrailByLanguageCode(this.props.natureTrailData.id, undefined, this.state.selectedLanguage.code)
        if (this.props.natureTrailByLanguage !== undefined) {
            const newaa = {
                isActive: this.props.natureTrailByLanguage.languageIsActive,
                title: this.props.natureTrailByLanguage.title,
                description_short: this.props.natureTrailByLanguage.description_short,
                description_long: this.props.natureTrailByLanguage.description_long,
                locations: this.props.natureTrailData.natureTrailLocation.map(location => {
                    let savedLang = this.props.natureTrailByLanguage.locations.find(aaa => aaa.id === location.id)
                    return {
                        id: location.id,
                        name: savedLang.name,
                        description: savedLang.description,
                        startPictureFileURL: location.startPictureFileURL,
                        movieFileURL: savedLang.movieFileURL,
                        musicFileURL: savedLang.musicFileURL,
                        finishDescription: savedLang.finishDescription,
                        questionnaires: location.question[0] !== undefined ? location.question.map(q => {
                            let savedlangQuestion = savedLang.questionnaires.find(actualQuestion => actualQuestion.key === q.key)
                            console.log(savedlangQuestion);
                            return {
                                isImage: q.isImage,
                                key: q.key,
                                question: savedlangQuestion ? savedlangQuestion.question : "",
                                goodAnswerMessage: savedlangQuestion ? savedlangQuestion.goodAnswerMessage : "",
                                wrongAnswerMessage: savedlangQuestion ? savedlangQuestion.wrongAnswerMessage : "",
                                questionType: q.questionType,
                                pictureURL: q.pictureURL,
                                soundURL: savedlangQuestion && savedlangQuestion.soundURL ? savedlangQuestion.soundURL : "",
                                answers: q.answers && q.answers[0] ? q.answers.map(a => {
                                    const savedAnswers = savedlangQuestion !== undefined && savedlangQuestion.answers !== undefined ? savedlangQuestion.answers.find(actA => actA.key === a.key) : undefined
                                    return {
                                        key: a.key,
                                        answerId: a.answerId,
                                        text: savedAnswers && savedAnswers.text ? savedAnswers.text : "",
                                        // text: a.text,
                                        image: a.image,
                                        imageBase64: a.imageBase64,
                                        isGood: a.isGood,
                                        soundURL: savedAnswers && savedAnswers.soundURL ? savedAnswers.soundURL : ""
                                    }
                                }) : []
                            }
                        }) : [],
                        pairMatchQuestions: location.pairMatchQuestions[0] !== undefined ? location.pairMatchQuestions.map(q => {
                            let savedlangPairMatch = savedLang.pairMatches.find(actualQuestion => actualQuestion.key === q.key)
                            return {
                                key: q.key,
                                languagedId: savedlangPairMatch && savedlangPairMatch.languagedId ? savedlangPairMatch.languagedId : null,
                                answerCount: q.answerCount,
                                goodAnswerMessage: savedlangPairMatch ? savedlangPairMatch.goodAnswerMessage : "",
                                question: savedlangPairMatch ? savedlangPairMatch.question : "",
                                wrongAnswerMessage: savedlangPairMatch ? savedlangPairMatch.wrongAnswerMessage : "",
                                pairMatchTypes: q.pairMatchTypes,
                                answers: q.answers && q.answers[0] ? q.answers.map(a => {
                                    const savedAnswers = savedlangPairMatch !== undefined && savedlangPairMatch.answers !== undefined ? savedlangPairMatch.answers.find(actA => actA.id === a.id) : undefined
                                    return {
                                        id: a.id,
                                        languagedId: savedAnswers && savedAnswers.languagedId ? savedAnswers.languagedId : null,
                                        answer: savedAnswers && savedAnswers.answerPair ? savedAnswers.answer : "",
                                        answerImageName: a.answerImageName,
                                        answerImagePairSavedName: a.answerImagePairSavedName,
                                        answerImageSavedName: a.answerImageSavedName,
                                        answerImageURL: a.answerImageURL,
                                        answerPair: savedAnswers && savedAnswers.answerPair ? savedAnswers.answerPair : "",
                                        answerPairImageName: a.answerPairImageName,
                                        answerPairMatchAnswerURL: a.answerPairMatchAnswerURL
                                    }
                                }) : []
                            }
                        }) : [],
                        question: savedLang.question.map(questionAnswer => {
                            return {
                                key: questionAnswer.key,
                                questionAnswer: questionAnswer.questionAnswer,
                                questionText: questionAnswer.questionText
                            }
                        }),
                        photos: savedLang.photos[0] ? savedLang.photos : []
                    }
                })
            }
            this.setState({ loadedNatureTrailByLanguage: newaa, isLoaded: false })
        } else {
            //csinálni kell  mert még nem szerepel ezen a nyelven.
            const newaa = {
                title: "",
                description_short: "",
                description_long: "",
                locations: this.props.natureTrailData.natureTrailLocation.map(location => {
                    return {
                        id: location.id,
                        name: "",
                        description: "",
                        startPictureFileURL: "",
                        movieFileURL: null,
                        musicFileURL: null,
                        finishDescription: "",
                        questionnaires: location.question.map(q => {
                            return {
                                isImage: q.isImage,
                                key: q.key,
                                question: "",
                                questionType: q.questionType,
                                pictureURL: q.pictureURL,
                                soundURL: q.soundURL,
                                answers: q.answers.map(a => {
                                    return {
                                        key: a.key,
                                        answerId: a.answerId,
                                        text: "",
                                        image: a.image,
                                        imageBase64: a.imageBase64,
                                        isGood: a.isGood
                                    }
                                })
                            }
                        }),
                        question: location.questionAnswer.map(questionAnser => {
                            return {
                                key: questionAnser.key,
                                questionAnswer: questionAnser.questionAnswer,
                                questionText: questionAnser.questionText
                            }
                        }),
                        pairMatchQuestions: location.pairMatchQuestions[0] !== undefined ? location.pairMatchQuestions.map(q => {
                            return {
                                key: q.key,
                                answerCount: q.answerCount,
                                languagedId: null,
                                goodAnswerMessage: "",
                                question: "",
                                wrongAnswerMessage: "",
                                pairMatchTypes: q.pairMatchTypes,
                                answers: q.answers && q.answers[0] ? q.answers.map(a => {
                                    return {
                                        id: a.id,
                                        languagedId: null,
                                        answer: "",
                                        answerImageName: a.answerImageName,
                                        answerImagePairSavedName: a.answerImagePairSavedName,
                                        answerImageSavedName: a.answerImageSavedName,
                                        answerImageURL: a.answerImageURL,
                                        answerPair: "",
                                        answerPairImageName: a.answerPairImageName,
                                        answerPairMatchAnswerURL: a.answerPairMatchAnswerURL
                                    }
                                }) : []
                            }
                        }) : [],
                        photos: [{
                            id: null,
                            description: "",
                            title: ""
                        }]
                    }
                })
            }
            this.setState({ loadedNatureTrailByLanguage: newaa })
        }
    }

    changeSelectedQuestionAnswer = (event, i, item) => {
        this.stopAllAudio()
        console.log(event.target.value);
        const value = event.target.value
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].questionnaires.filter(item => item.key === this.state.selectedQuestion.key)
        console.log(findedQuestion);
        if (findedQuestion !== undefined && findedQuestion[0] !== undefined) {
            console.log(findedQuestion[0]);
            const filteredQuestionary = this.state.filteredLanguageLocation[0].questionnaires.filter(asd => asd.key === findedQuestion[0].key)

            filteredQuestionary[0].answers[i].text = value;
            this.setState({ loadedNatureTrailByLanguage: loadedNatureTrailByLanguage, isLoaded: true })
        } else {
            const asdawd = {
                id: this.state.selectedLocation.id,
                name: this.state.selectedLocation.name,
                description: this.state.selectedLocation.description,
                startPictureFileURL: this.state.selectedLocation.startPictureFileURL,
                movieFileURL: this.state.selectedLocation.movieFileURL,
                musicFileURL: this.state.selectedLocation.musicFileURL,
                finishDescription: this.state.selectedLocation.finishDescription,
                questionnaires: this.state.selectedLocation.question.map(q => {
                    return {
                        isImage: q.isImage,
                        key: q.key,
                        question: "",
                        questionType: q.questionType,
                        pictureURL: q.pictureURL,
                        soundURL: q.soundURL,
                        answers: q.answers.map(a => {
                            return {
                                key: a.key,
                                answerId: a.answerId,
                                text: "",
                                image: a.image,
                                imageBase64: a.imageBase64,
                                isGood: a.isGood
                            }
                        })
                    }
                }),
                question: this.state.selectedLocation.questionAnswer
            }
            this.setState({ filteredLanguageLocation: asdawd })
        }
        this.setSaveButtonActive()
    }

    changeStartTitle = (event) => {
        this.stopAllAudio()
        const aa = this.state.filteredLanguageLocation;
        aa[0].name = event.target.value
        this.setState({ filteredLanguageLocation: aa })
        this.setSaveButtonActive()
    }

    changeStartDescription = (value) => {
        this.stopAllAudio()
        const aa = this.state.filteredLanguageLocation;
        aa[0].description = value
        this.setState({ filteredLanguageLocation: aa })
        this.setSaveButtonActive()
    }

    changeFinishDescription = (value) => {
        this.stopAllAudio()
        const aab = this.state.filteredLanguageLocation;
        aab[0].finishDescription = value
        this.setState({ filteredLanguageLocation: aab })
        this.setSaveButtonActive()
    }
    changeQuestion = (value) => {
        this.stopAllAudio()
        const aa = this.state.filteredLanguageLocation;
        aa[0].question[0].questionText = value
        this.setState({ filteredLanguageLocation: aa })
        this.setSaveButtonActive()
    }
    changeQuestionAnswer = (value) => {
        this.stopAllAudio()
        const aa = this.state.filteredLanguageLocation;
        aa[0].question[0].questionAnswer = value
        this.setState({ filteredLanguageLocation: aa })
        this.setSaveButtonActive()
    }

    changeName = (event) => {
        this.stopAllAudio()
        const aa = this.state.loadedNatureTrailByLanguage;
        aa.title = event.target.value
        this.setState({ loadedNatureTrailByLanguage: aa })
        this.setSaveButtonActive()
    }

    changeShortDesc = (value) => {
        this.stopAllAudio()
        const aa = this.state.loadedNatureTrailByLanguage;
        aa.description_short = value
        this.setState({ loadedNatureTrailByLanguage: aa })
        this.setSaveButtonActive()
    }

    changeLongDesc = (value) => {
        this.stopAllAudio()
        const aa = this.state.loadedNatureTrailByLanguage;
        aa.description_long = value
        this.setState({ loadedNatureTrailByLanguage: aa })
        this.setSaveButtonActive()
    }

    changeYoutube = (event) => {
        this.stopAllAudio()
        const aa = this.state.filteredLanguageLocation;
        aa[0].movieFileURL = event.target.value
        this.setState({ filteredLanguageLocation: aa })
        this.setSaveButtonActive()
    }

    changeQuestionAnswersQuestion = (event, selectedQuestion) => {
        this.stopAllAudio()
        const value = event.target.value
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].questionnaires.filter(item => item.key === this.state.selectedQuestion.key)
        // const gggg = this.state.filtereddeLanguageQuestion;
        if (findedQuestion !== undefined && findedQuestion[0] !== undefined) {
            const filteredQuestionary = this.state.filteredLanguageLocation[0].questionnaires.filter(asd => asd.key === findedQuestion[0].key)
            filteredQuestionary[0].question = value;
            //const filteredloc = this.state.filteredLanguageLocation.filter(aaaaa => { return aaaaa.questionnaires.filter(asd => asd.key === findedQuestion[0].key) })
            this.setState({ loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
        } else {
            const obj = {
                id: this.state.selectedLocation.id,
                name: this.state.selectedLocation.name,
                description: this.state.selectedLocation.description,
                startPictureFileURL: this.state.selectedLocation.startPictureFileURL,
                movieFileURL: this.state.selectedLocation.movieFileURL,
                musicFileURL: this.state.selectedLocation.musicFileURL,
                finishDescription: this.state.selectedLocation.finishDescription,
                questionnaires: this.state.selectedLocation.question.map(q => {
                    return {
                        isImage: q.isImage,
                        key: q.key,
                        question: "",
                        questionType: q.questionType,
                        pictureURL: q.pictureURL,
                        answers: q.answers.map(a => {
                            return {
                                key: a.key,
                                answerId: a.answerId,
                                text: "",
                                image: a.image,
                                imageBase64: a.imageBase64,
                                isGood: a.isGood
                            }
                        })
                    }
                }),
                question: this.state.selectedLocation.questionAnswer
            }
            this.setState({ filteredLanguageLocation: obj })
        }
        this.setSaveButtonActive()
    }
    changeQuestionAnswersGoodAnswerMessage = (event, selectedQuestion) => {
        this.stopAllAudio()
        const value = event.target.value
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].questionnaires.filter(item => item.key === this.state.selectedQuestion.key)
        // const gggg = this.state.filtereddeLanguageQuestion;
        if (findedQuestion !== undefined && findedQuestion[0] !== undefined) {
            const filteredQuestionary = this.state.filteredLanguageLocation[0].questionnaires.filter(asd => asd.key === findedQuestion[0].key)
            filteredQuestionary[0].goodAnswerMessage = value;
            //const filteredloc = this.state.filteredLanguageLocation.filter(aaaaa => { return aaaaa.questionnaires.filter(asd => asd.key === findedQuestion[0].key) })
            this.setState({ loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
        } else {
            const obj = {
                id: this.state.selectedLocation.id,
                name: this.state.selectedLocation.name,
                description: this.state.selectedLocation.description,
                startPictureFileURL: this.state.selectedLocation.startPictureFileURL,
                movieFileURL: this.state.selectedLocation.movieFileURL,
                musicFileURL: this.state.selectedLocation.musicFileURL,
                finishDescription: this.state.selectedLocation.finishDescription,
                questionnaires: this.state.selectedLocation.question.map(q => {
                    return {
                        isImage: q.isImage,
                        key: q.key,
                        question: "",
                        questionType: q.questionType,
                        pictureURL: q.pictureURL,
                        goodAnswerMessage: q.goodAnswerMessage,
                        wrongAnswerMessage: q.wrongAnswerMessage,
                        answers: q.answers.map(a => {
                            return {
                                key: a.key,
                                answerId: a.answerId,
                                text: "",
                                image: a.image,
                                imageBase64: a.imageBase64,
                                isGood: a.isGood
                            }
                        })
                    }
                }),
                question: this.state.selectedLocation.questionAnswer
            }
            this.setState({ filteredLanguageLocation: obj })
        }
        this.setSaveButtonActive()
    }

    changeQuestionAnswersWrongAnswerMessage = (event, selectedQuestion) => {
        this.stopAllAudio()
        const value = event.target.value
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].questionnaires.filter(item => item.key === this.state.selectedQuestion.key)
        // const gggg = this.state.filtereddeLanguageQuestion;
        if (findedQuestion !== undefined && findedQuestion[0] !== undefined) {
            const filteredQuestionary = this.state.filteredLanguageLocation[0].questionnaires.filter(asd => asd.key === findedQuestion[0].key)
            filteredQuestionary[0].wrongAnswerMessage = value;
            //const filteredloc = this.state.filteredLanguageLocation.filter(aaaaa => { return aaaaa.questionnaires.filter(asd => asd.key === findedQuestion[0].key) })
            this.setState({ loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
        } else {
            const obj = {
                id: this.state.selectedLocation.id,
                name: this.state.selectedLocation.name,
                description: this.state.selectedLocation.description,
                startPictureFileURL: this.state.selectedLocation.startPictureFileURL,
                movieFileURL: this.state.selectedLocation.movieFileURL,
                musicFileURL: this.state.selectedLocation.musicFileURL,
                finishDescription: this.state.selectedLocation.finishDescription,
                questionnaires: this.state.selectedLocation.question.map(q => {
                    return {
                        isImage: q.isImage,
                        key: q.key,
                        question: "",
                        questionType: q.questionType,
                        pictureURL: q.pictureURL,
                        goodAnswerMessage: q.goodAnswerMessage,
                        wrongAnswerMessage: q.wrongAnswerMessage,
                        answers: q.answers.map(a => {
                            return {
                                key: a.key,
                                answerId: a.answerId,
                                text: "",
                                image: a.image,
                                imageBase64: a.imageBase64,
                                isGood: a.isGood
                            }
                        })
                    }
                }),
                question: this.state.selectedLocation.questionAnswer
            }
            this.setState({ filteredLanguageLocation: obj })
        }
        this.setSaveButtonActive()
    }

    saveLanguagedNatureTrail = async () => {
        this.setState({ isSavable: false })
        this.setState({ spinning: true })
        this.stopAllAudio()

        if (this.state.selectedLanguage.code !== "hu") {
            const saveObj = {
                natureTrailId: this.props.natureTrailData.id,
                languageCode: this.state.selectedLanguage.code,
                languageObj: {
                    title: this.state.loadedNatureTrailByLanguage.title,
                    description_short: this.state.loadedNatureTrailByLanguage.description_short,
                    description_long: this.state.loadedNatureTrailByLanguage.description_long,
                    natureTrailLocation: this.state.loadedNatureTrailByLanguage.locations,
                }
            }
            await this.props.saveNatureTraiLanguages(saveObj, null)
            if (this.props.languagesSavingStatus === "saved") {
                message.success("Sikeres mentés.")
                this.setState({ spinning: false })
                //await this.clickOnEditNatureTrailBySelectedLanguage()

            } if (this.props.languagesSavingStatus === "error") {
                message.error("Hiba a mentés közben.")
                this.setState({ isSavable: true })
            }
        } else {
            this.setState({ spinning: false })
            message.warning("Alapértelmezett nyelv nem felüldefiniálható.")
        }
    }

    activateLanguagedNatureTrail = async () => {
        this.stopAllAudio()
        await this.props.activeDeactiveLanguagedNatureTrail(this.props.natureTrailData.id, this.state.selectedLanguage.code)
        if (this.props.activeDeactiveStatus === "error") {
            if (this.props.code && this.props.code === 7003 && this.props.message) {
                message.error(this.props.message)
            }
        }
        await this.clickOnEditNatureTrailBySelectedLanguage()
    }

    stopAllAudio = () => {
        this.audio.pause(); this.audio.currentTime = 0
        this.answer1audio.pause(); this.answer1audio.currentTime = 0
        this.answer2audio.pause(); this.answer2audio.currentTime = 0
        this.answer3audio.pause(); this.answer3audio.currentTime = 0
        this.answer4audio.pause(); this.answer4audio.currentTime = 0
    }
    togglePlay = () => {
        this.stopAllAudio()
        const asnwerPlay = this.state.asnwerPlay
        asnwerPlay.map(item => item.play = false)
        this.setState({ asnwerPlay: asnwerPlay })
        this.setState({ play: !this.state.play }, () => {
            this.state.play ? this.audio.play() : this.audio.pause(); this.audio.currentTime = 0
        });
    }
    togglePlayAnswer = (event, index) => {
        this.stopAllAudio()
        const asnwerPlay = this.state.asnwerPlay
        const filtered = asnwerPlay.filter((item, i) => i !== index)
        filtered.map(item => item.play = false)
        asnwerPlay[index].play = !asnwerPlay[index].play;

        this.setState({ asnwerPlay: asnwerPlay }, () => {
            if (asnwerPlay[index].play) {
                switch (index) {
                    case 0:
                        this.answer1audio.play();
                        break;
                    case 1:
                        this.answer2audio.play()
                        break;
                    case 2:
                        this.answer3audio.play()
                        break;
                    case 3:
                        this.answer4audio.play()
                        break;
                    default:
                        break;
                }
            } else {
                switch (index) {
                    case 0:
                        this.answer1audio.pause()
                        this.answer1audio.currentTime = 0
                        break;
                    case 1:
                        this.answer2audio.pause()
                        this.answer2audio.currentTime = 0
                        break;
                    case 2:
                        this.answer3audio.pause()
                        this.answer3audio.currentTime = 0
                        break;
                    case 3:
                        this.answer4audio.pause()
                        this.answer4audio.currentTime = 0
                        break;
                    default:
                        break;
                }
            }
        });
    }
    handleChange = info => {
        this.stopAllAudio()
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.setState({ uploaded: true })
        }
    };
    beforeUploadQuestionAudio = (file) => {
        this.stopAllAudio();
        if (file.type && file.type === "audio/mpeg") {
            // const findedActualQuestion = this.state.loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedQuestion.key)
            const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
            const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
            const findedQuestion = findedActualLocation[0].questionnaires.filter(item => item.key === this.state.selectedQuestion.key)
            findedQuestion[0].musicFileName = file.name
            findedQuestion[0].musicFileSize = file.size
            findedQuestion[0].musicFile = [file]
            findedQuestion[0].musicPreview = URL.createObjectURL(file)
            this.audio = new Audio(URL.createObjectURL(file))
            this.setState({ audio: this.audio, play: false, loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
            this.setSaveButtonActive()
        } else {
            message.error("Nem megfelelő formátum lett kiválasztva")
        }
        return false;
    }
    beforeUploadAnswerAudio = (file, j, index) => {
        this.stopAllAudio();
        this.setSaveButtonActive()
        if (file.type && file.type === "audio/mpeg") {
            const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
            const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
            const findedQuestion = findedActualLocation[0].questionnaires.filter(item => item.key === this.state.selectedQuestion.key)
            findedQuestion[0].answers[index].musicFileName = file.name
            findedQuestion[0].answers[index].musicFileSize = file.size
            findedQuestion[0].answers[index].musicFile = [file]
            findedQuestion[0].answers[index].musicPreview = URL.createObjectURL(file)

            switch (index) {
                case 0:
                    this.answer1audio.src = URL.createObjectURL(file)
                    break;
                case 1:
                    this.answer2audio.src = URL.createObjectURL(file)
                    break;
                case 2:
                    this.answer3audio.src = URL.createObjectURL(file)
                    break;
                case 3:
                    this.answer4audio.src = URL.createObjectURL(file)
                    break;
                default:
                    break;
            }
            this.setState({ play: false, loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
        } else {
            message.error("Nem megfelelő formátum lett kiválasztva")
        }

        return false;
    }

    //Pair Match
    selectPairMatchRow = async (row, key) => {
        this.stopAllAudio()
        await this.props.getLangugedPairMatchByIdAndLanguageCode(row.key, this.state.selectedLanguage.code)
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].pairMatchQuestions.filter(item => item.key === row.key)
        this.setState({ selectedPairMatchKey: key, selectedPairMatch: findedQuestion, isLoaded: true })
    }
    changePairMatchQuestion = (event) => {
        this.stopAllAudio()
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].pairMatchQuestions.filter(item => item.key === this.state.selectedPairMatch[0].key)
        findedQuestion[0].question = event.target.value
        this.setState({ loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
        this.setSaveButtonActive()
    }
    changePairMatchGoodAnswer = (event) => {
        this.stopAllAudio()
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].pairMatchQuestions.filter(item => item.key === this.state.selectedPairMatch[0].key)
        findedQuestion[0].goodAnswerMessage = event.target.value
        this.setState({ loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
        this.setSaveButtonActive()
    }
    changePairMatchWrongAnswer = (event) => {
        this.stopAllAudio()
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].pairMatchQuestions.filter(item => item.key === this.state.selectedPairMatch[0].key)
        findedQuestion[0].wrongAnswerMessage = event.target.value
        this.setState({ loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
        this.setSaveButtonActive()
    }
    changePairMatchAnswer = (event, i) => {
        this.stopAllAudio()
        const value = event.target.value
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].pairMatchQuestions.filter(item => item.key === this.state.selectedPairMatch[0].key)
        findedQuestion[0].answers[i].answer = value
        this.setState({ loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
        this.setSaveButtonActive()
    }
    changePairMatchAnswerPair = (event, i) => {
        this.stopAllAudio()
        const value = event.target.value
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].pairMatchQuestions.filter(item => item.key === this.state.selectedPairMatch[0].key)
        findedQuestion[0].answers[i].answerPair = value
        this.setState({ loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
        this.setSaveButtonActive()
    }

    //PHOTOS
    changePhotosTitle = (value) => {
        this.stopAllAudio()
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].photos
        findedQuestion[0].title = value
        this.setState({ loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
        this.setSaveButtonActive()

    }
    changePhotosDescription = (value) => {
        this.stopAllAudio()
        const loadedNatureTrailByLanguage = this.state.loadedNatureTrailByLanguage
        const findedActualLocation = loadedNatureTrailByLanguage.locations.filter(item => item.id === this.state.selectedLocation.id)
        const findedQuestion = findedActualLocation[0].photos
        findedQuestion[0].description = value
        this.setState({ loadedNatureTrailByLanguage: loadedNatureTrailByLanguage })
        this.setSaveButtonActive()
    }


    render() {
        return <Spin spinning={this.state.spinning} className="customSpin" style={{
            zIndex: 100000000
        }
        } size="large" ><div >
                <Row gutter={24}>
                    <Col span={24}>
                        <Col span={24}>
                            <h4>Nyelv</h4>
                        </Col>
                        <Row gutter={24} align="middle">
                            <Col span={4}>
                                <Select
                                    style={{ width: "100%" }}
                                    onSelect={this.selectLanguageCode}
                                    value={this.state.selectedLanguage.code}
                                >
                                    {this.props.languages.map(item => {
                                        return (<Option key={item.id} value={item.code}>{item.code}</Option>)
                                    })}
                                </Select>
                            </Col>
                            {/* <Col span={10}>
                            <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={this.clickOnEditNatureTrailBySelectedLanguage} ></Button>
                        </Col> */}
                            {this.state.loadedNatureTrailByLanguage.isActive && this.state.loadedNatureTrailByLanguage.isActive === true ?
                                <Col span={1} justify="center" align="center"><CheckCircleOutlined style={{ color: "#00ff44" }} /></Col>
                                :
                                <Col span={1} justify="center" align="center"><CloseCircleOutlined style={{ color: "#ff0019" }} /></Col>
                            }


                            <Col span={18} style={{
                                // justifyContent: "space-between",
                                display: "flex"
                            }}>
                                {this.state.isEditable &&
                                    <Button
                                        type="default"
                                        style={{ marginRight: "1vw" }}
                                        icon={<EyeFilled />}
                                        onClick={this.activateLanguagedNatureTrail} >
                                        {this.state.loadedNatureTrailByLanguage.isActive && this.state.loadedNatureTrailByLanguage.isActive === true ? "Deaktiválás" : "Aktiválás"}  </Button>}
                                {this.state.isEditable &&
                                    <Button disabled={!this.state.isSavable} type="primary" icon={<SaveFilled />} onClick={this.saveLanguagedNatureTrail} >Mentés </Button>}

                            </Col>


                        </Row>
                    </Col>
                </Row>
                <Tabs defaultActiveKey={"1"} activeKey={this.state.tabKey} style={{ paddingTop: "0px", height: "75vh" }} onTabClick={(event) => { this.changeTab(event) }} >
                    {this.state.isEditable &&
                        <TabPane
                            style={{ height: "75vh", overflowY: "auto" }}
                            tab="Tanösvény alapadatok"
                            key="1"
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Col span={24}>
                                        <h4><Tooltip
                                            placement="topLeft"
                                            title={this.props.natureTrailData.name ? this.props.natureTrailData.name : ""}
                                        >
                                            <QuestionCircleOutlined />
                                        </Tooltip>Név:</h4>
                                    </Col>
                                    <Col span={24}>
                                        <Input disabled={!this.state.isEditable} value={this.state.loadedNatureTrailByLanguage.title} onChange={this.changeName}></Input>
                                    </Col>
                                    <Col span={24}>
                                        <h4><Tooltip
                                            placement="topLeft"
                                            title={this.props.natureTrailData.description_short ? this.props.natureTrailData.description_short : ""}
                                        >
                                            <QuestionCircleOutlined />
                                        </Tooltip>Rövid leírás:</h4>
                                    </Col>
                                    <Col span={24}>
                                        <Mentions disabled={!this.state.isEditable} rows="3" value={this.state.loadedNatureTrailByLanguage.description_short} onChange={this.changeShortDesc}>
                                        </Mentions>
                                    </Col>
                                    <Col span={24}>
                                        <h4><Tooltip
                                            placement="topLeft"
                                            title={this.props.natureTrailData.description_long ? this.props.natureTrailData.description_long : ""}
                                        >
                                            <QuestionCircleOutlined />
                                        </Tooltip>Hosszú leírás:</h4>
                                    </Col>
                                    <Col span={24}>
                                        <Mentions disabled={!this.state.isEditable} rows="8" value={this.state.loadedNatureTrailByLanguage.description_long} onChange={this.changeLongDesc}>
                                        </Mentions>
                                    </Col>
                                </Col>
                                <Col span={12} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                    <img
                                        src={
                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.props.natureTrailData.natureTrailSavedFileURL}
                                        alt=" Nincs kép"
                                        style={{ maxHeight: '40vh', maxWidth: "40vw" }}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                    }{this.state.isEditable && <TabPane tab="Tanösvény állomások" key="2" >
                        <Row gutter={24} >

                            <Col span={6}>
                                <Table

                                    scroll={{ y: '60vh', x: 'max-content' }}
                                    size="small"
                                    overflowY="auto"
                                    rowKey="id"
                                    dataSource={this.props.natureTrailData.natureTrailLocation}
                                    columns={this.state.columns}
                                    pagination={false}

                                    onRow={(record, rowIndex) => {

                                        return {

                                            onClick: event => {
                                                if (this.state.isEditable) { this.selectRow(record, rowIndex) } else {
                                                    message.info("Az elvégzendő művelett előtt nyelvet kell választani.")
                                                }
                                            },
                                            style: { backgroundColor: rowIndex === this.state.selectedRowKey ? "#e6f3ff" : "" }
                                        };
                                    }}
                                />
                            </Col>
                            {this.state.selectedLocation && <Col span={18} style={{ maxHeight: "65vh", overflowY: "auto" }}>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <h4>
                                            <Tooltip
                                                placement="topLeft"
                                                title={this.state.selectedLocation !== undefined ? this.state.selectedLocation.name : ""}
                                            >
                                                <QuestionCircleOutlined />
                                            </Tooltip> Állomás név:
                                       </h4>
                                    </Col>
                                    <Col span={12}>
                                        <Input disabled={!this.state.isEditable} value={this.state.filteredLanguageLocation[0].name} onChange={this.changeStartTitle}></Input>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <h4><Tooltip
                                            placement="topLeft"
                                            title={this.state.selectedLocation !== undefined ? this.state.selectedLocation.description : ""}
                                        >
                                            <QuestionCircleOutlined />
                                        </Tooltip> Állomás kezdő leírás</h4>
                                    </Col>
                                    <Col span={12}>
                                        <h4><Tooltip
                                            placement="topLeft"
                                            title={this.state.selectedLocation !== undefined ? this.state.selectedLocation.finishDescription : ""}
                                        >
                                            <QuestionCircleOutlined />
                                        </Tooltip> Állomás befejező leírás:</h4>
                                    </Col>
                                    <Col span={12}>
                                        <Mentions disabled={!this.state.isEditable} rows="6" value={this.state.filteredLanguageLocation[0].description} name="startDescription" onChange={this.changeStartDescription}>
                                        </Mentions>
                                    </Col>

                                    <Col span={12}>
                                        <Mentions disabled={!this.state.isEditable} rows="6" value={this.state.filteredLanguageLocation[0].finishDescription} name="finishDescription" onChange={this.changeFinishDescription}>
                                        </Mentions>
                                    </Col>
                                    {this.state.selectedLocation.question[0] && this.state.selectedLocation.triggerType.id === 3 &&
                                        <React.Fragment>
                                            <Divider orientation="center">Kérdőív</Divider>
                                            <Col span={8}>
                                                <Table
                                                    scroll={{ y: '30vh', x: 'max-content' }}
                                                    size="small"
                                                    overflowY="auto"
                                                    rowKey="id"
                                                    dataSource={this.state.selectedLocation.question}
                                                    columns={this.state.questionColumns}
                                                    pagination={false}

                                                    onRow={(record, rowIndex) => {
                                                        return {
                                                            onClick: event => { this.selectQuestionRow(record, rowIndex) },
                                                            style: { backgroundColor: rowIndex === this.state.selectedQuestionKey ? "#e6f3ff" : "" }
                                                        };
                                                    }}
                                                />
                                            </Col>
                                            {this.state.isLoaded && this.state.selectedQuestion && this.state.isEditable &&
                                                < Col span={16}>
                                                    {this.state.selectedQuestion.questionType === 1 &&
                                                        <React.Fragment >
                                                            <Row gutter={[24, 8]}>
                                                                <Col span={4}>
                                                                    <h4>
                                                                        <Tooltip
                                                                            placement="topLeft"
                                                                            title={this.state.selectedQuestion.question !== undefined ? this.state.selectedQuestion.question : ""}
                                                                        >
                                                                            <QuestionCircleOutlined />
                                                                        </Tooltip> Kérdés
                                                                    </h4>
                                                                </Col>
                                                                {this.state.selectedQuestion.questionContentType === 2 && <Col span={3} >
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.filtereddeLanguageQuestion[0].pictureURL}
                                                                        alt=" Nincs kép"
                                                                        style={{ maxWidth: "100%", maxHeight: "6vh" }}
                                                                    />
                                                                </Col>}
                                                                {this.state.selectedQuestion.questionContentType === 3 && <Col span={10}>
                                                                    <div style={{ display: 'flex', paddingTop: "16px" }}>

                                                                        <div style={{ paddingRight: '24px' }}>
                                                                            <Button
                                                                                onClick={this.togglePlay}
                                                                                icon={
                                                                                    !this.state.play ?
                                                                                        <PlayCircleOutlined style={{ fontSize: "2vh" }} /> :
                                                                                        <PauseCircleOutlined style={{ fontSize: "2vh" }} />
                                                                                }>
                                                                                {this.state.play ? 'Pause' : 'Play'}
                                                                            </Button>
                                                                        </div>
                                                                        <Upload
                                                                            multiple={false}
                                                                            beforeUpload={this.beforeUploadQuestionAudio}
                                                                            fileList={this.state.selectedQuestion.musicFile}
                                                                            // onRemove={this.removeFile}
                                                                            onChange={this.handleChange}
                                                                            showUploadList={false}
                                                                            accept=".mp3">
                                                                            <Button>Hang kiválasztása (kérdés)</Button>
                                                                        </Upload>
                                                                    </div>
                                                                </Col>}
                                                                <Col span={this.state.selectedQuestion.questionContentType === 2 ? 19 : 24}>
                                                                    <React.Fragment>
                                                                        <Input
                                                                            disabled={!this.state.isEditable}
                                                                            onChange={(event) => this.changeQuestionAnswersQuestion(event, this.state.selectedQuestion)}
                                                                            value={
                                                                                this.state.filtereddeLanguageQuestion &&
                                                                                    this.state.filtereddeLanguageQuestion[0] &&
                                                                                    this.state.filtereddeLanguageQuestion[0].question ?
                                                                                    this.state.filtereddeLanguageQuestion[0].question : ""}></Input>
                                                                    </React.Fragment>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={[24, 8]}>
                                                                <Col span={12}>
                                                                    <h4>Helyes válasz</h4>
                                                                    <Input
                                                                        disabled={!this.state.isEditable}
                                                                        onChange={(event) => this.changeQuestionAnswersGoodAnswerMessage(event, this.state.selectedQuestion)}
                                                                        value={this.state.filtereddeLanguageQuestion &&
                                                                            this.state.filtereddeLanguageQuestion[0] &&
                                                                            this.state.filtereddeLanguageQuestion[0].goodAnswerMessage ?
                                                                            this.state.filtereddeLanguageQuestion[0].goodAnswerMessage : ""}></Input>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <h4>Helytelen válasz</h4>
                                                                    <Input
                                                                        disabled={!this.state.isEditable}
                                                                        onChange={(event) => this.changeQuestionAnswersWrongAnswerMessage(event, this.state.selectedQuestion)}
                                                                        value={this.state.filtereddeLanguageQuestion &&
                                                                            this.state.filtereddeLanguageQuestion[0] &&
                                                                            this.state.filtereddeLanguageQuestion[0].wrongAnswerMessage ?
                                                                            this.state.filtereddeLanguageQuestion[0].wrongAnswerMessage : ""}></Input>
                                                                </Col>

                                                            </Row>
                                                            {this.state.selectedQuestion.answers.map((item, i) => {
                                                                return <Row gutter={[24, 4]}>
                                                                    <Col span={2} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                                                        <Checkbox disabled={true} checked={item.isGood}></Checkbox>
                                                                    </Col>
                                                                    <Col span={22} style={{ justifyContent: "center", display: "flex", alignItems: "center" }} >

                                                                        <Tooltip
                                                                            placement="topLeft"
                                                                            title={item.text !== undefined ? item.text : ""}
                                                                        >
                                                                            <QuestionCircleOutlined />
                                                                        </Tooltip>

                                                                        <Input disabled={!this.state.isEditable} style={{ width: "100%" }} index={i} item={item} onChange={
                                                                            (event) => this.changeSelectedQuestionAnswer(event, i, item)}
                                                                            value={this.state.filtereddeLanguageQuestion &&
                                                                                this.state.filtereddeLanguageQuestion[0] &&
                                                                                this.state.filtereddeLanguageQuestion[0].answers &&
                                                                                this.state.filtereddeLanguageQuestion[0].answers[i] &&
                                                                                this.state.filtereddeLanguageQuestion[0].answers[i].text
                                                                                ?
                                                                                this.state.filtereddeLanguageQuestion[0].answers[i].text : ""}></Input>
                                                                    </Col>
                                                                </Row>
                                                            })}


                                                        </React.Fragment>
                                                    }
                                                    {this.state.selectedQuestion.questionType === 2 &&
                                                        <React.Fragment >
                                                            <Row gutter={[24, 8]}>
                                                                <Col span={2}>
                                                                    <h4>
                                                                        <Tooltip
                                                                            placement="topLeft"
                                                                            title={this.state.selectedQuestion.question !== undefined ? this.state.selectedQuestion.question : ""}
                                                                        >
                                                                            <QuestionCircleOutlined />
                                                                        </Tooltip> Kérdés
                                                                    </h4>
                                                                </Col>
                                                                {this.state.selectedQuestion.questionContentType === 2 && <Col span={3} >
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.filtereddeLanguageQuestion[0].pictureURL}
                                                                        alt=" Nincs kép"
                                                                        style={{ maxWidth: "100%", maxHeight: "6vh" }}
                                                                    />
                                                                </Col>}
                                                                {this.state.selectedQuestion.questionContentType === 3 && <Col span={12}>
                                                                    <div style={{ display: 'flex', paddingTop: "16px" }}>

                                                                        <div style={{ paddingRight: '24px' }}>
                                                                            <Button
                                                                                onClick={this.togglePlay}
                                                                                icon={
                                                                                    !this.state.play ?
                                                                                        <PlayCircleOutlined style={{ fontSize: "2vh" }} /> :
                                                                                        <PauseCircleOutlined style={{ fontSize: "2vh" }} />
                                                                                }>
                                                                                {this.state.play ? 'Pause' : 'Play'}
                                                                            </Button>
                                                                        </div>
                                                                        <Upload
                                                                            multiple={false}
                                                                            beforeUpload={this.beforeUploadQuestionAudio}
                                                                            fileList={this.state.selectedQuestion.musicFile}
                                                                            // onRemove={this.removeFile}
                                                                            onChange={this.handleChange}
                                                                            showUploadList={false}
                                                                            accept=".mp3">
                                                                            <Button>Hang kiválasztása (kérdés)</Button>
                                                                        </Upload>
                                                                    </div>
                                                                </Col>}
                                                                <Col span={this.state.selectedQuestion.questionContentType === 2 ? 19 : 22}>
                                                                    <React.Fragment>
                                                                        <Input disabled={!this.state.isEditable} onChange={(event) => this.changeQuestionAnswersQuestion(event, this.state.selectedQuestion)} value={
                                                                            this.state.filtereddeLanguageQuestion &&
                                                                                this.state.filtereddeLanguageQuestion[0] &&
                                                                                this.state.filtereddeLanguageQuestion[0].question ?
                                                                                this.state.filtereddeLanguageQuestion[0].question : ""}></Input>
                                                                    </React.Fragment>
                                                                </Col>
                                                            </Row>
                                                            {this.state.selectedQuestion.answers.map((item) => {
                                                                return <Row gutter={[24, 4]}>
                                                                    <Col span={2} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                                                        <Checkbox disabled={true} checked={item.isGood}></Checkbox>
                                                                    </Col>
                                                                    <Col span={3} >
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + item.imageURL}
                                                                            alt=" Nincs kitűző kép"
                                                                            style={{ maxWidth: "100%", maxHeight: "6vh" }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            })}


                                                        </React.Fragment>
                                                    }
                                                    {this.state.selectedQuestion.questionType === 3 &&
                                                        <React.Fragment >

                                                            <Row gutter={[24, 8]}>
                                                                <Col span={2}>
                                                                    <h4>
                                                                        <Tooltip
                                                                            placement="topLeft"
                                                                            title={this.state.selectedQuestion.question !== undefined ? this.state.selectedQuestion.question : ""}
                                                                        >
                                                                            <QuestionCircleOutlined />
                                                                        </Tooltip> Kérdés
                                                                    </h4>
                                                                </Col>
                                                                {this.state.selectedQuestion.questionContentType === 2 && <Col span={3} >
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.filtereddeLanguageQuestion[0].pictureURL}
                                                                        alt=" Nincs kép"
                                                                        style={{ maxWidth: "100%", maxHeight: "6vh" }}
                                                                    />
                                                                </Col>}
                                                                {this.state.selectedQuestion.questionContentType === 3 && <Col span={12}>
                                                                    <div style={{ display: 'flex', paddingTop: "16px" }}>

                                                                        <div style={{ paddingRight: '24px' }}>
                                                                            <Button
                                                                                onClick={this.togglePlay}
                                                                                icon={
                                                                                    !this.state.play ?
                                                                                        <PlayCircleOutlined style={{ fontSize: "2vh" }} /> :
                                                                                        <PauseCircleOutlined style={{ fontSize: "2vh" }} />
                                                                                }>
                                                                                {this.state.play ? 'Pause' : 'Play'}
                                                                            </Button>
                                                                        </div>
                                                                        <Upload
                                                                            multiple={false}
                                                                            beforeUpload={this.beforeUploadQuestionAudio}
                                                                            fileList={this.state.selectedQuestion.musicFile}
                                                                            // onRemove={this.removeFile}
                                                                            onChange={this.handleChange}
                                                                            showUploadList={false}
                                                                            accept=".mp3">
                                                                            <Button>Hang kiválasztása (kérdés)</Button>
                                                                        </Upload>
                                                                    </div>
                                                                </Col>}
                                                                <Col span={this.state.selectedQuestion.questionContentType === 2 ? 19 : 22}>
                                                                    <React.Fragment>
                                                                        <Input disabled={!this.state.isEditable} onChange={(event) => this.changeQuestionAnswersQuestion(event, this.state.selectedQuestion)} value={
                                                                            this.state.filtereddeLanguageQuestion &&
                                                                                this.state.filtereddeLanguageQuestion[0] &&
                                                                                this.state.filtereddeLanguageQuestion[0].question ?
                                                                                this.state.filtereddeLanguageQuestion[0].question : ""}></Input>
                                                                    </React.Fragment>
                                                                </Col>
                                                            </Row>
                                                            {this.state.selectedQuestion.answers.map((item, i) => {
                                                                return <Row gutter={[24, 4]}>
                                                                    <Col span={2} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                                                        <Checkbox disabled={true} checked={item.isGood}></Checkbox>
                                                                    </Col>
                                                                    <Col span={3} >
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + item.imageURL}
                                                                            alt=" Nincs kitűző kép"
                                                                            style={{ maxWidth: "100%", maxHeight: "6vh" }}
                                                                        />
                                                                    </Col>
                                                                    <Col span={19} style={{ justifyContent: "center", display: "flex", alignItems: "center" }} >
                                                                        <Tooltip
                                                                            placement="topLeft"
                                                                            title={item.text !== undefined ? item.text : ""}
                                                                        >
                                                                            <QuestionCircleOutlined />
                                                                        </Tooltip>
                                                                        <Input disabled={!this.state.isEditable} style={{ width: "100%" }} index={i} item={item} onChange={
                                                                            (event) => this.changeSelectedQuestionAnswer(event, i, item)}
                                                                            value={this.state.filtereddeLanguageQuestion &&
                                                                                this.state.filtereddeLanguageQuestion[0] &&
                                                                                this.state.filtereddeLanguageQuestion[0].answers &&
                                                                                this.state.filtereddeLanguageQuestion[0].answers[i] &&
                                                                                this.state.filtereddeLanguageQuestion[0].answers[i].text
                                                                                ?
                                                                                this.state.filtereddeLanguageQuestion[0].answers[i].text : ""}></Input>
                                                                    </Col>
                                                                </Row>
                                                            })}
                                                        </React.Fragment>
                                                    }
                                                    {this.state.selectedQuestion.questionType === 4 &&
                                                        <React.Fragment >
                                                            <Row gutter={[24, 8]}>
                                                                <Col span={2}>
                                                                    <h4>
                                                                        <Tooltip
                                                                            placement="topLeft"
                                                                            title={this.state.selectedQuestion.question !== undefined ? this.state.selectedQuestion.question : ""}
                                                                        >
                                                                            <QuestionCircleOutlined />
                                                                        </Tooltip> Kérdés
                                                                    </h4>
                                                                </Col>
                                                                {this.state.selectedQuestion.questionContentType === 2 && <Col span={3} >
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.filtereddeLanguageQuestion[0].pictureURL}
                                                                        alt=" Nincs kép"
                                                                        style={{ maxWidth: "100%", maxHeight: "6vh" }}
                                                                    />
                                                                </Col>}
                                                                {this.state.selectedQuestion.questionContentType === 3 && <Col span={12}>
                                                                    <div style={{ display: 'flex', paddingTop: "16px" }}>

                                                                        <div style={{ paddingRight: '24px' }}>
                                                                            <Button
                                                                                onClick={this.togglePlay}
                                                                                icon={
                                                                                    !this.state.play ?
                                                                                        <PlayCircleOutlined style={{ fontSize: "2vh" }} /> :
                                                                                        <PauseCircleOutlined style={{ fontSize: "2vh" }} />
                                                                                }>
                                                                                {this.state.play ? 'Pause' : 'Play'}
                                                                            </Button>
                                                                        </div>
                                                                        <Upload
                                                                            multiple={false}
                                                                            beforeUpload={this.beforeUploadQuestionAudio}
                                                                            fileList={this.state.selectedQuestion.musicFile}
                                                                            // onRemove={this.removeFile}
                                                                            onChange={this.handleChange}
                                                                            showUploadList={false}
                                                                            accept=".mp3">
                                                                            <Button>Hang kiválasztása (kérdés)</Button>
                                                                        </Upload>
                                                                    </div>
                                                                </Col>}
                                                                <Col span={this.state.selectedQuestion.questionContentType === 2 ? 19 : 22}>
                                                                    <React.Fragment>
                                                                        <Input disabled={!this.state.isEditable} onChange={(event) => this.changeQuestionAnswersQuestion(event, this.state.selectedQuestion)} value={
                                                                            this.state.filtereddeLanguageQuestion &&
                                                                                this.state.filtereddeLanguageQuestion[0] &&
                                                                                this.state.filtereddeLanguageQuestion[0].question ?
                                                                                this.state.filtereddeLanguageQuestion[0].question : ""}></Input>
                                                                    </React.Fragment>
                                                                </Col>
                                                            </Row>
                                                            {this.state.selectedQuestion.answers.map((item, i) => {
                                                                return <Row gutter={[24, 4]}>
                                                                    <Col span={2} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                                                        <Checkbox disabled={true} checked={item.isGood}></Checkbox>
                                                                    </Col>
                                                                    <Col span={22} >

                                                                        <div style={{ display: 'flex', paddingTop: "16px" }}>
                                                                            <Tooltip
                                                                                placement="topLeft"
                                                                                title={item.text !== undefined ? item.text : ""}
                                                                            >
                                                                                <QuestionCircleOutlined />
                                                                            </Tooltip>
                                                                            <div style={{ paddingRight: '24px' }}>
                                                                                <Button
                                                                                    onClick={(event) => this.togglePlayAnswer(event, i)}
                                                                                    icon={
                                                                                        !this.state.asnwerPlay[i].play ?
                                                                                            <PlayCircleOutlined style={{ fontSize: "2vh" }} /> :
                                                                                            <PauseCircleOutlined style={{ fontSize: "2vh" }} />
                                                                                    }>
                                                                                    {this.state.asnwerPlay[i].play ? 'Pause' : 'Play'}
                                                                                </Button>
                                                                            </div>
                                                                            <Upload
                                                                                multiple={false}
                                                                                beforeUpload={(file, j, index) => this.beforeUploadAnswerAudio(file, j, i)}
                                                                                fileList={this.state.selectedQuestion.musicFile}
                                                                                // onRemove={this.removeFile}
                                                                                onChange={this.handleChange}
                                                                                showUploadList={false}
                                                                                accept=".mp3">
                                                                                <Button>Hang kiválasztása (kérdés)</Button>
                                                                            </Upload>
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={19} style={{ justifyContent: "center", display: "flex", alignItems: "center" }} >

                                                                        <Input disabled={!this.state.isEditable} style={{ width: "100%" }} index={i} item={item} onChange={
                                                                            (event) => this.changeSelectedQuestionAnswer(event, i, item)}
                                                                            value={this.state.filtereddeLanguageQuestion &&
                                                                                this.state.filtereddeLanguageQuestion[0] &&
                                                                                this.state.filtereddeLanguageQuestion[0].answers &&
                                                                                this.state.filtereddeLanguageQuestion[0].answers[i] &&
                                                                                this.state.filtereddeLanguageQuestion[0].answers[i].text
                                                                                ?
                                                                                this.state.filtereddeLanguageQuestion[0].answers[i].text : ""}></Input>
                                                                    </Col>
                                                                </Row>
                                                            })}
                                                        </React.Fragment>
                                                    }

                                                </Col>}
                                        </React.Fragment>
                                    }
                                    {this.state.selectedLocation.questionAnswer[0] && this.state.selectedLocation.triggerType.id === 2 &&
                                        <React.Fragment>
                                            <Col span={12}><h4>
                                                <Tooltip
                                                    placement="topLeft"
                                                    title={this.state.selectedLocation !== undefined ? this.state.selectedLocation.questionAnswer[0].questionText : ""}
                                                >
                                                    <QuestionCircleOutlined />
                                                </Tooltip>Kérdés</h4>
                                            </Col>
                                            <Col span={12} >
                                                <h4><Tooltip
                                                    placement="topLeft"
                                                    title={this.state.selectedLocation !== undefined ? this.state.selectedLocation.questionAnswer[0].uestionAnswer : ""}
                                                >
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                        Válasz</h4>
                                            </Col>
                                            <Col span={12}>
                                                <Mentions
                                                    disabled={!this.state.isEditable}
                                                    rows="10"
                                                    title="Kérdés"
                                                    value={
                                                        this.state.filteredLanguageLocation[0] !== undefined &&
                                                            this.state.filteredLanguageLocation[0].question !== undefined &&
                                                            this.state.filteredLanguageLocation[0].question[0] !== undefined
                                                            ? this.state.filteredLanguageLocation[0].question[0].questionText : ""}
                                                    onChange={this.changeQuestion}
                                                >
                                                </Mentions>
                                            </Col>
                                            <Col span={12}>
                                                <Mentions
                                                    disabled={!this.state.isEditable}
                                                    rows="10"
                                                    title="Kérdés"
                                                    value={this.state.filteredLanguageLocation[0] !== undefined &&
                                                        this.state.filteredLanguageLocation[0].question !== undefined &&
                                                        this.state.filteredLanguageLocation[0].question[0] !== undefined ? this.state.filteredLanguageLocation[0].question[0].questionAnswer : ""}
                                                    onChange={this.changeQuestionAnswer}
                                                ></Mentions>
                                            </Col>
                                        </React.Fragment>
                                    }
                                    {this.state.selectedLocation.pairMatchQuestions[0] && this.state.selectedLocation.triggerType.id === 5 &&
                                        <React.Fragment>
                                            <Divider orientation="center">Pár  kereső</Divider>
                                            <Col span={8}>
                                                <Table
                                                    scroll={{ y: '30vh', x: 'max-content' }}
                                                    size="small"
                                                    overflowY="auto"
                                                    rowKey="id"
                                                    dataSource={this.state.selectedLocation.pairMatchQuestions}
                                                    columns={this.state.pairMatchColumns}
                                                    pagination={false}

                                                    onRow={(record, rowIndex) => {
                                                        return {
                                                            onClick: event => { this.selectPairMatchRow(record, rowIndex) },
                                                            style: { backgroundColor: rowIndex === this.state.selectedQuestionKey ? "#e6f3ff" : "" }
                                                        };
                                                    }}
                                                />
                                            </Col>
                                            {this.state.isLoaded && this.state.selectedPairMatch && this.state.selectedPairMatch[0] &&
                                                <Col span={16}>
                                                    <Row>
                                                        <h4>Kerdes</h4  >
                                                        <Input
                                                            disabled={!this.state.isEditable}
                                                            value={this.state.selectedPairMatch[0].question}
                                                            onChange={this.changePairMatchQuestion}
                                                        ></Input>
                                                    </Row>
                                                    <Row gutter={16}>
                                                        {/* <Col span={24}>
                                                            <h4>Popup üzenetek</h4>
                                                        </Col> */}
                                                        <Col span={12}>
                                                            <h5>Helyes</h5>
                                                            <Input
                                                                disabled={!this.state.isEditable}
                                                                value={this.state.selectedPairMatch[0].goodAnswerMessage}
                                                                onChange={this.changePairMatchGoodAnswer}
                                                            ></Input>
                                                        </Col>
                                                        <Col span={12}>
                                                            <h5>Helytelen</h5>
                                                            <Input
                                                                disabled={!this.state.isEditable}
                                                                value={this.state.selectedPairMatch[0].wrongAnswerMessage}
                                                                onChange={this.changePairMatchWrongAnswer}
                                                            ></Input>
                                                        </Col>
                                                    </Row>
                                                    <Row span={24}>
                                                        <Col>
                                                            <h4>Párok</h4>
                                                        </Col>
                                                    </Row>
                                                    {this.state.selectedPairMatch[0].answers.map((item, i) => {
                                                        if (i < this.state.selectedPairMatch[0].answerCount) {
                                                            return <Row gutter={[16, 8]} justify="space-between">
                                                                {this.state.selectedPairMatch[0].pairMatchTypes.id === 3 && < Col span={12}>
                                                                    {i + 1}/a:
                                                                <Input
                                                                        value={item.answer}
                                                                        onChange={(event) => this.changePairMatchAnswer(event, i)}
                                                                    ></Input>
                                                                </Col>}
                                                                {(this.state.selectedPairMatch[0].pairMatchTypes.id === 2 || this.state.selectedPairMatch[0].pairMatchTypes.id === 1) && < Col span={12}>
                                                                    {i + 1}/a:
                                                                <img
                                                                        src={
                                                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + item.answerImageURL}
                                                                        alt=" Nincs kép"
                                                                        style={{ maxHeight: '100%', maxWidth: "6vh" }}
                                                                    />
                                                                </Col>}
                                                                {(this.state.selectedPairMatch[0].pairMatchTypes.id === 2 || this.state.selectedPairMatch[0].pairMatchTypes.id === 3) && < Col span={12}>
                                                                    {i + 1}/b
                                                                <Input
                                                                        value={item.answerPair}
                                                                        onChange={(event) => this.changePairMatchAnswerPair(event, i)}
                                                                    ></Input>
                                                                </Col>}
                                                                {(this.state.selectedPairMatch[0].pairMatchTypes.id === 1) && < Col span={12}>
                                                                    {i + 1}/a:
                                                                <img
                                                                        src={
                                                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + item.answerPairMatchAnswerURL}
                                                                        alt=" Nincs kép"
                                                                        style={{ maxHeight: '100%', maxWidth: "6vh" }}
                                                                    />
                                                                </Col>}
                                                            </Row>
                                                        }
                                                    })}
                                                </Col>}
                                        </React.Fragment>}

                                    {this.state.selectedLocation.photos[0] && this.state.selectedLocation.triggerType.id === 6 &&
                                        <React.Fragment>
                                            <Divider orientation="center">Photo pop up</Divider>
                                            <Col span={24}><h4>
                                                Cím</h4>
                                            </Col>
                                            <Col span={24}>
                                                <Mentions
                                                    disabled={!this.state.isEditable}
                                                    rows="1"
                                                    title="Kérdés"
                                                    value={
                                                        this.state.filteredLanguageLocation[0] !== undefined &&
                                                            this.state.filteredLanguageLocation[0].photos !== undefined &&
                                                            this.state.filteredLanguageLocation[0].photos[0] !== undefined
                                                            ? this.state.filteredLanguageLocation[0].photos[0].title : ""}
                                                    onChange={this.changePhotosTitle}
                                                >
                                                </Mentions>
                                            </Col>
                                            <Col span={24} >
                                                <h4>
                                                    Leírás
                                            </h4>
                                            </Col>
                                            <Col span={24}>
                                                <Mentions
                                                    disabled={!this.state.isEditable}
                                                    rows="2"
                                                    title="Kérdés"
                                                    value={this.state.filteredLanguageLocation[0] !== undefined &&
                                                        this.state.filteredLanguageLocation[0].photos !== undefined &&
                                                        this.state.filteredLanguageLocation[0].photos[0] !== undefined
                                                        ? this.state.filteredLanguageLocation[0].photos[0].description : ""}
                                                    onChange={this.changePhotosDescription}
                                                >
                                                </Mentions>
                                            </Col>
                                        </React.Fragment>}
                                    {this.state.selectedLocation.contentType.id === 2 && !this.state.selectedLocation.isYoutube &&
                                        <React.Fragment>
                                            <Divider orientation="center">Video</Divider>
                                            <Col span={4}>
                                                <Col span={24}>
                                                    <Upload
                                                        disabled={!this.state.isEditable}
                                                        fileList={this.state.filteredLanguageLocation[0].movieFile}
                                                        beforeUpload={this.beforeUploadMovie}
                                                        onRemove={this.removeMovie}
                                                        accept=".mp4, .mov"
                                                        showUploadList={false}
                                                    >
                                                        <Button>
                                                            <UploadOutlined /> Videó kiválasztása
                                                </Button>
                                                    </Upload>

                                                </Col>
                                                <Col span={24}  >
                                                    {this.state.filteredLanguageLocation[0].movieFile !== undefined
                                                        && this.state.filteredLanguageLocation[0].movieFile[0] !== undefined
                                                        && this.state.filteredLanguageLocation[0].movieFile[0].name !== undefined ?
                                                        <Row gutter={16} align="middle" justify="center">

                                                            <Col span={20}>
                                                                <p> {this.state.filteredLanguageLocation[0].movieFile[0].name}</p>
                                                            </Col>
                                                            <Col span={4} style={{ paddingTop: "-1em" }}>
                                                                <p> <DeleteOutlined onClick={this.removeMovie} /></p>
                                                            </Col>
                                                        </Row> :
                                                        <Row>
                                                            <Col span={24}>
                                                                <p>{this.state.filteredLanguageLocation[0].movieFileName !== undefined ? this.state.filteredLanguageLocation[0].movieFileName : ""}</p>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Col></Col>

                                            <Col span={20} style={{ justifyContent: "center", display: "flex", alignItems: "center" }} >
                                                < ReactPlayer volume={1} width="60%" controls
                                                    url={this.state.filteredLanguageLocation[0].moviePreview !== undefined
                                                        && this.state.filteredLanguageLocation[0].moviePreview !== null
                                                        && this.state.filteredLanguageLocation[0].moviePreview !== ""
                                                        ?
                                                        this.state.filteredLanguageLocation[0].moviePreview
                                                        : (process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.filteredLanguageLocation[0].movieFileURL)} />
                                            </Col>


                                        </React.Fragment>}
                                    {this.state.selectedLocation.contentType.id === 2 && this.state.selectedLocation.isYoutube &&
                                        <React.Fragment>
                                            <Divider orientation="center">Youtube Video</Divider>
                                            <Col span={24}>
                                                <Input disabled={!this.state.isEditable} value={this.state.filteredLanguageLocation[0].movieFileURL} onChange={this.changeYoutube}></Input>

                                            </Col>
                                            <Col span={24} style={{ justifyContent: "center", display: "flex", alignItems: "center" }} >
                                                < ReactPlayer volume={1} width="60%" controls
                                                    url={this.state.filteredLanguageLocation[0].movieFileURL !== undefined
                                                        && this.state.filteredLanguageLocation[0].movieFileURL !== null
                                                        && this.state.filteredLanguageLocation[0].movieFileURL !== ""
                                                        ?
                                                        this.state.filteredLanguageLocation[0].movieFileURL
                                                        : (process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.filteredLanguageLocation[0].movieFileURL)} />
                                            </Col>


                                        </React.Fragment>}
                                    {this.state.selectedLocation.contentType.id === 3 &&
                                        <React.Fragment>
                                            <Divider orientation="center">Hang</Divider>
                                            <Col span={4}>
                                                <Col span={24}>

                                                    <Upload
                                                        disabled={!this.state.isEditable}
                                                        fileList={this.state.filteredLanguageLocation[0].musicFile}
                                                        beforeUpload={this.beforeUploadMusic}
                                                        onRemove={this.removeMusic}
                                                        showUploadList={false}
                                                        accept=".mp3"

                                                    >
                                                        <Button>
                                                            <UploadOutlined /> Hang kiválasztása
                                                </Button>
                                                    </Upload>

                                                </Col>
                                                <Col span={24}>
                                                    {this.state.filteredLanguageLocation[0].musicFile !== undefined
                                                        && this.state.filteredLanguageLocation[0].musicFile[0] !== undefined
                                                        && this.state.filteredLanguageLocation[0].musicFile[0].name !== undefined ?
                                                        <Row gutter={16} align="middle" justify="center">

                                                            <Col span={20}>
                                                                <p> {this.state.filteredLanguageLocation[0].musicFile[0].name}</p>
                                                            </Col>
                                                            <Col span={4} style={{ paddingTop: "-1em" }}>
                                                                <p> <DeleteOutlined onClick={this.removeMusic} /></p>
                                                            </Col>
                                                        </Row> :
                                                        <Row>
                                                            <Col span={24}>
                                                                <p>{this.state.filteredLanguageLocation[0].musicFileName !== undefined ? this.state.filteredLanguageLocation[0].musicFileName : ""}</p>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Col>
                                            </Col>
                                            <Col span={18} >
                                                < ReactPlayer volume={1} height="40vh" controls
                                                    // url={this.state.filteredLanguageLocation[0].musicPreview !== undefined ? this.state.filteredLanguageLocation[0].musicPreview : " "} />
                                                    url={this.state.filteredLanguageLocation[0].musicPreview !== undefined
                                                        && this.state.filteredLanguageLocation[0].musicPreview !== null
                                                        && this.state.filteredLanguageLocation[0].musicPreview !== ""
                                                        ?
                                                        this.state.filteredLanguageLocation[0].musicPreview
                                                        : (process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.filteredLanguageLocation[0].musicFileURL)} />
                                            </Col>
                                        </React.Fragment>}

                                </Row>
                            </Col>}
                        </Row>
                    </TabPane>
                    }</Tabs >
                <Col style={{ maxHeight: "75vh", overflowY: "auto", overflowX: "hidden" }}>

                </Col>
            </div >
        </Spin >;
    }
}
const mapStateToProps = (state) => {
    return {
        languages: state.languagesReducer.languages,
        languagesStatus: state.languagesReducer.languagesStatus,
        languagesSavingStatus: state.languagesReducer.languagesSavingStatus,
        natureTrailByLanguage: state.languagesReducer.natureTrailByLanguage,
        natureTrailByLanguageStatus: state.languagesReducer.natureTrailByLanguageStatus,
        activeDeactive: state.languagesReducer.activeDeactive,
        activeDeactiveStatus: state.languagesReducer.activeDeactiveStatus,
        languagedQuestion: state.languagesReducer.languagedQuestion,
        languagedQuestionStatus: state.languagesReducer.languagedQuestionStatus,
        languagedPairMatch: state.languagesReducer.languagedPairMatch,
        languagedPairMatchStatus: state.languagesReducer.languagedPairMatchStatus,
        message: state.languagesReducer.message,
        code: state.languagesReducer.code
    }
};
const NatureTrailLanguage = connect(mapStateToProps, mapDispatchToProps)(ConnectedNatureTrailLanguage);
export default NatureTrailLanguage;