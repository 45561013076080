import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { getNatureTrailCommentsById } from '../../actions/nature-trail';
const mapDispatchToProps = (dispatch) => {
    return {
        getNatureTrailCommentsById: (natureTrailId) => dispatch(getNatureTrailCommentsById(natureTrailId))
    };
};
class ConnectedNatureTrailReportMessageByUser extends Component {
    state = {
        columns: [
            { title: 'Felhasználó név', dataIndex: 'userName', key: 'userName' },
            {
                title: 'Visszajelzés dátuma', dataIndex: 'created', key: 'created',
                render: (created) => {
                    const creatednew = new Date(created * 1000)
                    return (<div>{creatednew.toLocaleDateString()}</div>)
                }
            },

        ]

    }
    componentDidMount = async () => {
        await this.props.getNatureTrailCommentsById(this.props.natureTrailId)
    }

    render() {
        return <div>
            <Table
                scroll={{ y: "50vh" }}
                rowKey="id"
                columns={this.state.columns}
                expandable={{
                    expandedRowRender: record => <p style={{ margin: 0 }}>{record.comment !== null && record.comment !== undefined ? record.comment : "Nem tartozik szöveges tartalom a visszajelzéshez."}</p>,
                    // rowExpandable: record => record.comment !== null && record.comment !== undefined,
                }}
                dataSource={this.props.comments}
                pagination={false}
            /></div>;
    }
}
const mapState = (state) => {
    return {
        comments: state.natureTrailReducer.comments,
        commentsStatus: state.natureTrailReducer.commentsStatus,
        message: state.natureTrailReducer.message,
        code: state.natureTrailReducer.code
    }
}
const NatureTrailReportMessageByUser = connect(mapState, mapDispatchToProps)(ConnectedNatureTrailReportMessageByUser);
export default NatureTrailReportMessageByUser;