import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Form,
  message,
  Popconfirm,
} from "antd";

import { SaveFilled } from "@ant-design/icons";
import { deleteTester, saveTester, modifyTester, getAllTester } from "../../actions/tester";

const mapDispatch = (dispatch) => {
  return {
    getAllTester: () => dispatch(getAllTester()),
    saveTester: (locationAdmin) =>
      dispatch(saveTester(locationAdmin)),
    deleteTester: (id) => dispatch(deleteTester(id)),
    modifyTester: (locationAdmin) =>
      dispatch(modifyTester(locationAdmin)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedNatureTrailTesters extends Component {
  formRef = React.createRef();

  state = {
    selectedId: null,
    email: "",
    name: "",
    password: "",
    modify: false,
  };

  componentDidMount = async () => {
    await this.props.getAllTester();
  };


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  modifyTester = (tester) => {
    this.setState({
      modify: true,
      password: "",
      email: tester.email,
      name: tester.name,
      selectedId: tester.id,
    });
    this.formRef.current.setFieldsValue({
      password: "",
      email: tester.email,
      name: tester.name,
    });
  };

  handleCancelModify = () => {
    this.setToDefault();
  }

  setToDefault = () => {
    this.formRef.current.resetFields();
    this.setState({
      modify: false,
      password: "",
      name: "",
      email: "",
      selectedId: null,
    });
  };

  submitForm = async () => {
    const testerToSave = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
    };
    if (this.state.modify) {
      testerToSave.id = this.state.selectedId;
      if (testerToSave.password === "") {
        delete testerToSave.password;
      }
      await this.props.modifyTester(testerToSave);
    } else {
      await this.props.saveTester(testerToSave);
    }

    if (this.props.testersStatus && this.props.testersStatus === "saved") {
      message.success("Sikeres mentés!", 2);
      await this.props.getAllTester();
      this.setToDefault();
    } else {
      if (this.props.testersCode && this.props.testersCode === 1003) {
        message.error("Ezzel az e-mail címmel már regisztrálva van felhasználó!", 2);
      } else {
        message.error("Sikertelen mentés!", 2);
      }
    }
  };

  deleteUser = async (user) => {
    await this.props.deleteTester(user.id);
    await this.props.getAllTester();
  };

  render() {
    const columns = [
      { title: "E-mail cím", dataIndex: "email", key: "email" },
      { title: "Név", dataIndex: "name", key: "name" },
      {
        title: "Adatok",
        key: "showData",
        render: (text, row) => (
          <Space size="middle">
            <Button onClick={() => this.modifyTester(row)}>
              Módosítás
            </Button>
          </Space>
        ),
      },
      {
        title: "Törlés",
        key: "delete",
        render: (text, row) => (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarja a felhasználót?"
              okText="Igen"
              cancelText="Nem"
              onConfirm={() => this.deleteUser(row)}
            >
              <Button>Törlés</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col span={16} style={{ padding: "1em" }}>
            <h1>Teszt felhasználók</h1>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={this.props.testers}
              locale={{ emptyText: "Nincsenek tesztelők!" }}
              //loading={!this.props.status && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
              }}
              size="small"
            />
          </Col>
          <Col span={8} style={{ padding: "1em" }}>
            <h1>{this.state.modify ? "Tesztelő módosítás" : "Új tesztelő"}</h1>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="project"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="Név"
                rules={[
                  {
                    required: true,
                    message: "Kérem adja meg a nevet!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email cím"
                rules={[
                  {
                    required: true,
                    message: "Kérem adja meg az email címet!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Jelszó"
                rules={[
                  {
                    required: !this.state.modify,
                    message: "Kérem adja meg a jelszót!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ margin: "0.5em" }}
                >
                  <SaveFilled />
                  Mentés
                </Button>
                <Button onClick={this.handleCancelModify}>
                  Mégse
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    message: state.natureTrailReducer.message,
    code: state.natureTrailReducer.code,
    status: state.natureTrailReducer.status,
    natureTrails: state.natureTrailReducer.data,
    testers: state.userReducer.testers,
    testersStatus: state.userReducer.testersStatus,
    testersCode: state.userReducer.code,
    nationalParks: state.natureTrailReducer.nationalParks,
    regions: state.natureTrailReducer.regions,
    locationAdminsStatus: state.userReducer.locationAdminsStatus,
  };
};

const NatureTrailTesters = connect(mapState, mapDispatch)(ConnectedNatureTrailTesters);
export default NatureTrailTesters;
