import React, { Component } from "react";
import { Divider, Col, Row } from "antd";
import { connect } from "react-redux";
import { getTop10NatureTrailByRating, getBestUsers } from "../../actions/statistics";
import { Polar, Bar } from "react-chartjs-2";


const mapDispatchToProps = (dispatch) => {
  return {
    getTop10NatureTrailByRating: () => dispatch(getTop10NatureTrailByRating()),
    getBestUsers: () => dispatch(getBestUsers()),
  }
};


class ConnectedLanding extends Component {
  state = {
    hoveredCell: false,
    dataTop10NT: {},
    bestUser: {}
  };

  componentDidMount = async () => {
    await this.props.getTop10NatureTrailByRating();
    await this.props.getBestUsers()
    const filteredNAtureTrails = this.props.natureTrailByRating.filter(item => item.rating > 0)
    const convertData = {
      datasets: [{
        data: filteredNAtureTrails.map(item => { return item.rating }),
        backgroundColor: [
          '#A64C4B  ',
          '#2F7778  ',
          '#935382  ',
          '#D0F4E7',
          '#C97256',
          '#DFC03D',
          '#371E06',
          '#503232',
          '#AF9985',
          'forestgreen'
        ],
        hoverBackgroundColor: 'rgba(34, 224, 193,1)',
        hoverBorderColor: 'rgba(34, 224, 193,1)',
        label: 'My dataset' // for legend
      }],
      labels: filteredNAtureTrails.map(item => { return item.title })
    }
    const convertedBestUser =
    {

      datasets: [
        {
          data: this.props.bestUsers.map(item => {
            return item.userVisited
          }),

          // label: this.props.bestUsers.map(item => { return item.userName !== null ? item.userName : "Nincs név" }),
          backgroundColor: [
            '#A64C4B  ',
            '#2F7778  ',
            '#935382  ',
            '#D0F4E7',
            '#C97256',
            '#DFC03D',
            '#371E06',
            '#503232',
            '#AF9985',
            'forestgreen'
          ],
          borderColor: [
            '#A64C4B',
            '#2F7778',
            '#935382  ',
            '#D0F4E7',
            '#C97256',
            '#DFC03D',
            '#371E06',
            '#503232',
            '#AF9985',
            'forestgreen'
          ],
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(34, 224, 193,1)',
          hoverBorderColor: 'rgba(34, 224, 193,1)',

        }
      ],
      labels: this.props.bestUsers.map(item => { return item.userName !== null ? item.userName : "Nincs név" }),
    }
    this.setState({ dataTop10NT: convertData, bestUser: convertedBestUser })
  }

  render() {
    return <div>
      <h1 >
        Kezdőfelület
      </h1>
      <Divider />

      <Row gutter={24} align="middle" justify="center ">
        <Col span={12} style={{ textAlign: "center" }}>
          <h1>Legjobban értékelt 10 tanösvény</h1>
        </Col>
        <Col span={12} style={{ textAlign: "center" }}>
          <h1>10 legtöbb tanösvényt bejáró felhasználó</h1>
        </Col>
      </Row>
      <Row gutter={24} align="middle" justify="center ">
        <Col span={12} style={{ textAlign: "center" }}>

          <Polar data={this.state.dataTop10NT} />
        </Col>
        <Col span={12} style={{ textAlign: "center" }}>
          <Bar

            data={this.state.bestUser}

            height={400}
            options={{
              legend: { display: false },
              maintainAspectRatio: false,
            }}
          /></Col>

      </Row>
      <Row>
      </Row>
      <Divider />

    </div>
  }
}
const mapStateToProps = (state) => {
  return {
    natureTrailByRating: state.statisticsReducer.natureTrailByRating,
    natureTrailByRatingStatus: state.statisticsReducer.natureTrailByRatingStatus,
    bestUsers: state.statisticsReducer.bestUsers,
    bestUsersStatus: state.statisticsReducer.bestUsersStatus,
  }
};
const Landing = connect(mapStateToProps, mapDispatchToProps)(ConnectedLanding);
export default Landing;
