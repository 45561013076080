import React, { Component } from 'react'
import { Col, Row, Input } from 'antd'
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import { get3DContent, deleteAndroid3dFile, deleteIphone3dFile } from '../../actions/content'
import { connect } from 'react-redux';

const mapDispatch = (dispatch) => {
    return {
        get3DContent: (locationId) => dispatch(get3DContent(locationId)),
        deleteAndroid3dFile: (locationId) => dispatch(deleteAndroid3dFile(locationId)),
        deleteIphone3dFile: (locationId) => dispatch(deleteIphone3dFile(locationId)),
    }
}

class ConnectedLocation3DContent extends Component {
    state = { file: [] }
    componentDidMount = async () => {
        this.setState({ file: this.props._3dFiles })
        if (this.props.natureTrailId) {
            await this.props.get3DContent(this.props.natureTrailId)
        }
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.setState({ uploaded: true })

            this.forceUpdate()
        }
    };

    deletePropsAndroidFile = async () => {
        if (this.props.natureTrailId) {
            await this.props.deleteAndroid3dFile(this.props.natureTrailId)
            await this.props.get3DContent(this.props.natureTrailId)
        }
    }

    deletePropsIphoneFile = async () => {
        if (this.props.natureTrailId) {
            await this.props.deleteIphone3dFile(this.props.natureTrailId)
            await this.props.get3DContent(this.props.natureTrailId)
        }
    }

    render() {
        return (
            <React.Fragment>
                <Row gutter={24} style={{ paddingTop: "1em" }}>
                    <Col span={12}> Scale ios</Col>
                    <Col span={12}> Scale android</Col>
                    <Col span={12}>
                        <Input type="number" step=".1" value={this.props.scaleOverrideIos} onChange={this.props.setScaleOverrideIos}></Input>
                    </Col>
                    <Col span={12}>
                        <Input type="number" step=".1" value={this.props.scaleOverrideAndroid} onChange={this.props.setScaleOverrideAndroid}></Input>
                    </Col>
                </Row>
                <Row gutter={24} style={{ paddingBottom: "1em", paddingTop: "1em" }}>
                    <Col span={12}>Marker szélesség ios (cm)</Col>
                    <Col span={12}></Col>
                    <Col span={12}>
                        <Input type="number" value={this.props.markerWidthIos} onChange={this.props.setMarkerWidthIos}></Input>
                    </Col>
                    {/* <Col span={12}>
                        <Input type="number" value={this.props.markerWidthAndroid} onChange={this.props.setMarkerWidthAndroid}></Input>
                    </Col> */}
                </Row>
                <Row gutter={24} style={{ paddingBottom: "1em" }}>
                    <Col span={12}>
                        <Dragger
                            style={{ maxHeight: "10em" }}
                            name="file"
                            multiple={true}
                            beforeUpload={this.props.setIphone3Dfiles}
                            fileList={this.props.iphone3Dfiles}
                            listType={"text"}
                            onRemove={this.props.removeIphone3Dfiles}
                            showUploadList={false}

                            // onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            accept=".zip"
                        ><div>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Iphone 3D tartalom helye </p>
                                <p className="ant-upload-text">*.zip</p>
                            </div>

                        </Dragger>
                    </Col>
                    <Col span={12}>
                        <Dragger
                            name="files"
                            style={{ maxHeight: "10em" }}
                            multiple={true}
                            beforeUpload={this.props.setAndroid3DFiles}
                            fileList={this.props.android3Dfiles}
                            listType={"text"}
                            onRemove={this.props.removeAndroid3Dfiles}
                            showUploadList={false}
                            // onPreview={this.handlePreview}
                            // onChange={this.handleChange}
                            accept=".zip">
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Android 3d tartalom </p>
                            <p className="ant-upload-text">*.zip </p>
                        </Dragger>

                    </Col>
                </Row>
                <Row gutter={24} style={{ paddingBottom: "1em" }}>
                    <Col span={12}>{this.props.iphone3Dfiles !== undefined && this.props.iphone3Dfiles[0] !== undefined && this.props.iphone3Dfiles[0].name !== undefined ?
                        <Row gutter={24} align="middle" justify="center">
                            <Col span={20}>
                                <p> {this.props.iphone3Dfiles[0].name}</p>
                            </Col>
                            <Col span={4} style={{ paddingTop: "-1em" }}>
                                <p> <DeleteOutlined onClick={() => this.props.removeIphone3Dfiles()} /></p>
                            </Col>
                        </Row> :
                        <Row gutter={24} align="middle" justify="center">
                            <Col span={20}>
                                <p>{this.props.iphone3dFileName !== undefined && this.props.natureTrailId && this.props.d3d.iphone3dFileName !== null ? this.props.iphone3dFileName : ""}</p>
                            </Col>
                            <Col span={4} style={{ paddingTop: "-1em" }}>
                                {this.props.iphone3dFileName !== undefined && this.props.natureTrailId && this.props.d3d.iphone3dFileName !== null ?
                                    <DeleteOutlined onClick={() => this.deletePropsIphoneFile()} /> : null}
                            </Col>
                        </Row>}
                    </Col>
                    <Col span={12}>{this.props.android3Dfiles !== undefined && this.props.android3Dfiles[0] !== undefined && this.props.android3Dfiles[0].name !== undefined ?
                        <Row gutter={24} align="middle" justify="center">

                            <Col span={20}>
                                <p> {this.props.android3Dfiles[0].name}</p>
                            </Col>
                            <Col span={4} style={{ paddingTop: "-1em" }}>
                                <p> <DeleteOutlined onClick={() => this.props.removeAndroid3Dfiles()} /></p>
                            </Col>
                        </Row> :
                        <Row gutter={24} align="middle" justify="center">
                            <Col span={20}>
                                <p>{this.props.android3dFileName !== undefined && this.props.natureTrailId && this.props.d3d.android3dFileName !== null ? this.props.android3dFileName : ""}</p>
                            </Col>
                            <Col span={4} style={{ paddingTop: "-1em" }}>
                                {this.props.android3dFileName !== undefined && this.props.natureTrailId && this.props.d3d.android3dFileName !== null ?
                                    <DeleteOutlined onClick={() => this.deletePropsAndroidFile()} /> : null}
                            </Col>
                        </Row>}
                    </Col>

                </Row>
            </React.Fragment>
        )
    }
}
const mapState = (state) => {
    return {
        d3d: state.contentReducer.d3d,
        message: state.contentReducer.message,
        code: state.contentReducer.code,
        status: state.contentReducer.status,
    }
}

const Location3DContent = connect(mapState, mapDispatch)(ConnectedLocation3DContent)
export default Location3DContent;