import Landing from "../components/landing/landing";
import NatureTrailLocations from "../components/NaturalTrailLocations/nature-trail-locations";
import NatureTrailObjects from "../components/NaturalTrailObjects/nature-trail-objects";
import NatureTrail from "../components/NaturalTrails/nature-trails";
import Gamers from "../components/Gamers/gamers";
import Languages from "../components/Languages/languages";
import Admins from "../components/Admins/admins";
import LocationAdmins from "../components/LocationAdmins/locationadmins";
import Badges from "../components/Badges/badges";
import NatureTrailTesters from "../components/natureTrailTesters/nautre-trail-testers";
import NationalParkEditor from "../components/NationalParks/nationalPark";
import NatureTrailTypeEditor from "../components/NaturetrailTypes/natureTrailTypeEditor";
import PushNotificationManager from "../components/PushNotificationManager/push-notification-manager";

export const routes = [
  {
    path: "/nt/landing",
    component: Landing,
    rights: [1, 2, 4, 5],
  },
  {
    path: "/nt/naure-trail",
    component: NatureTrail,
    rights: [1, 2, 4, 5],
  },
  {
    path: "/nt/naure-trail-locations",
    component: NatureTrailLocations,
    rights: [1, 2, 4, 5],
  },
  {
    path: "/nt/naure-trail-objects",
    component: NatureTrailObjects,
    rights: [1, 2, 4, 5],
  },
  {
    path: "/nt/gamers",
    component: Gamers,
    rights: [1, 2, 4, 5],
  },
  {
    path: "/nt/admins",
    component: Admins,
    rights: [1, 2, 4, 5],
  },
  {
    path: "/nt/test-users",
    component: NatureTrailTesters,
    rights: [1, 2, 4, 5],
  },
  {
    path: "/nt/location-admins",
    component: LocationAdmins,
    rights: [1, 2, 4, 5],
  },
  {
    path: "/nt/languages",
    component: Languages,
    rights: [1, 2, 4, 5],
  },
  {
    path: "/nt/push-notification",
    component: PushNotificationManager,
    rights: [1, 2, 4, 5],
  },
  {
    path: "/nt/badges",
    component: Badges,
    rights: [1, 2, 4, 5],
  },
  {
    path: "/nt/national-pakrs",
    component: NationalParkEditor,
    rights: [1, 5],
  },
  {
    path: "/nt/nature-trail-types",
    component: NatureTrailTypeEditor,
    rights: [1, 5],
  },
];
