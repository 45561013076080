import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Select, Mentions, Divider, message, Button, Modal } from 'antd';
import { getOneBadgeById, modifyBadge, getAllBadges } from '../../actions/badge';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined, CommentOutlined } from '@ant-design/icons';

import { getNationalParks, getRegions } from '../../actions/nature-trail';
import BageLanguages from './badge-lanuages';
import ImgCrop from 'antd-img-crop';

const { Option } = Select;

const mapDispatchToProps = (dispatch) => {
    return {
        getAllBadges: () => dispatch(getAllBadges()),
        getOneBadgeById: (badgeId) => dispatch(getOneBadgeById(badgeId)),
        getNationalParks: () => dispatch(getNationalParks()),
        getRegions: () => dispatch(getRegions()),
        modifyBadge: (badge, badgeFile, deactiveBadgeFile) => dispatch(modifyBadge(badge, badgeFile, deactiveBadgeFile))
    }
};

class ConnectedSelectedBadge extends Component {
    state = {
        name: "",
        description: "",
        badgeSavedPictureURL: "",
        badgeType: [
            { id: 0, value: "Régió" },
            { id: 1, value: "Nemzeti park" },
            { id: 2, value: "Évszak" },
        ],
        seasons: [
            { id: 1, value: "Tavasz" },
            { id: 2, value: "Nyár" },
            { id: 3, value: "Ősz" },
            { id: 4, value: "Tél" },
        ],
        region: [],
        nationalParks: [],
        selectedBadgeType: { id: 0, value: "Régió" },
        selectedRegio: { id: 0, value: "Régió" },
        selectedNationalPark: { id: 0, value: "Nemzeti Park" },
        selectedSeason: { id: 0, value: "Tavasz" },
        requiredNumber: 1,
        badgeFile: [],
        deactiveBadgeFile: [],
        previewBadgeImage: "",
        previewDeactiveBadgeImage: ""
    }

    componentDidMount = async () => {
        await this.props.getNationalParks();
        await this.props.getRegions();

        if (this.props.selectedRow.id !== undefined) {
            await this.props.getOneBadgeById(this.props.selectedRow.id);
            const actualTypeId = this.props.selectedRow.nationalPark !== null ?
                1 : (this.props.selectedRow.region !== null) ? 0 : 2
            if (actualTypeId === 1) {
                this.setState({
                    selectedNationalPark: this.props.selectedRow.nationalPark
                })
            }
            if (actualTypeId === 0) {
                this.setState({
                    selectedRegio: this.props.selectedRow.region
                })
            }

            if (actualTypeId === 2) {
                const filteredSeason = this.state.seasons.filter(item => item.id === this.props.selectedRow.season.id)
                this.setState({
                    selectedSeason: filteredSeason[0]
                })
            }
            const filteredTypes = this.state.badgeType.filter(item => item.id === actualTypeId)
            this.setState({
                name: this.props.selectedRow.name,
                description: this.props.selectedRow.description,
                badgeSavedPictureURL: this.props.selectedRow.badgeSavedPictureURL,
                badgeDeactiveSavedPictureURL: this.props.selectedRow.badgeDeactiveSavedPictureURL,
                requiredNumber: this.props.selectedRow.requiredNumber,
                selectedBadgeType: filteredTypes[0]
            })
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.selectedRow !== this.props.selectedRow) {
            if (this.props.selectedRow.id !== undefined) {
                await this.props.getOneBadgeById(this.props.selectedRow.id);
                const actualTypeId = this.props.selectedRow.nationalPark !== null ?
                    1 : (this.props.selectedRow.region !== null) ? 0 : 2
                if (actualTypeId === 1) {
                    this.setState({
                        selectedNationalPark: this.props.selectedRow.nationalPark
                    })
                }
                if (actualTypeId === 0) {
                    this.setState({
                        selectedRegio: this.props.selectedRow.region
                    })
                }
                if (actualTypeId === 2) {
                    const filteredSeason = this.state.seasons.filter(item => item.id === this.props.selectedRow.season.id)
                    this.setState({
                        selectedSeason: filteredSeason[0]
                    })
                }
                const filteredTypes = this.state.badgeType.filter(item => item.id === actualTypeId)
                this.setState({
                    name: this.props.selectedRow.name,
                    description: this.props.selectedRow.description,
                    badgeSavedPictureURL: this.props.selectedRow.badgeSavedPictureURL,
                    badgeDeactiveSavedPictureURL: this.props.selectedRow.badgeDeactiveSavedPictureURL,
                    requiredNumber: this.props.selectedRow.requiredNumber,
                    selectedBadgeType: filteredTypes[0],
                    badgeFile: [],
                    previewBadgeImage: "",
                    previewDeactiveBadgeImage: ""
                })
            }
        }
    }

    setName = (event) => {
        const name = event.target.value
        this.setState({ name: name })
    }

    selectBadgeType = (event) => {
        const filteredTypes = this.state.badgeType.filter(item =>
            item.value === event)
        this.setState({ selectedBadgeType: filteredTypes[0] })
    }

    selectNationalPark = (event) => {
        const filteredTypes = this.props.nationalParks.filter(item =>
            item.value === event)
        this.setState({ selectedNationalPark: filteredTypes[0] })
    }

    selectSeason = (event) => {
        const filteredTypes = this.state.seasons.filter(item =>
            item.value === event)
        this.setState({ selectedSeason: filteredTypes[0] })
    }
    selectRegio = (event) => {
        const filteredTypes = this.props.regions.filter(item =>
            item.value === event)
        this.setState({ selectedRegio: filteredTypes[0] })
    }
    changeRequiredNumber = (event) => {
        this.setState({ requiredNumber: event.target.value })
    }
    changeDescription = (value) => {
        this.setState({ description: value })
    }
    beforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 1MB!');
        } else {
            this.setState({ previewBadgeImage: URL.createObjectURL(file) })
            this.setState({ badgeFile: [file] })
        }
        return isLt2M;
    }
    beforeUploadDeactive = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 1MB!');
        } else {
            this.setState({ previewDeactiveBadgeImage: URL.createObjectURL(file) })
            this.setState({ deactiveBadgeFile: [file] })
        }
        return isLt2M;
    }
    removeBadgefile = () => {
        this.setState({ badgeFile: [] })
    }
    removeDeactiveBadgefile = () => {
        this.setState({ deactiveBadgeFile: [] })
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.setState({ uploaded: true })

        }
    };

    saveBadge = async () => {
        this.props.setSpinningTrue()
        const saveObj = {
            id: this.props.selectedRow.id !== undefined ? this.props.selectedRow.id : undefined,
            name: this.state.name,
            description: this.state.description,
            requiredNumber: this.state.requiredNumber,

            natualTrail: undefined,
            regionId: this.state.selectedBadgeType.id === 0 && this.state.selectedRegio !== undefined ? this.state.selectedRegio.id : undefined,
            nationalParkId: this.state.selectedBadgeType.id === 1 && this.state.selectedNationalPark !== undefined ? this.state.selectedNationalPark.id : undefined,
            seasonId: this.state.selectedBadgeType.id === 2 && this.state.selectedSeason !== undefined ? this.state.selectedSeason.id : undefined,

        }
        await this.props.modifyBadge(saveObj, this.state.badgeFile, this.state.deactiveBadgeFile)
        if (this.props.status === "saved") {
            message.success("Sikeres mentés.")
        }
        else { message.error("Hiba a mentés során.") }
        await this.props.getAllBadges()
        await this.props.updateList()
        this.props.setSpinningFalse()


    }

    closeLanguageModal = () => {
        this.setState({ languageModalOpen: false })
    }

    openLanguageModal = () => {
        this.setState({ languageModalOpen: true })
    }

    render() {
        const beforeCrop = (file) => {
            const isLt2M = file.size / 1024 / 1024 < 1;
            if (!isLt2M) {
                message.error('A képnek kissebbnek kell lennie mint 1MB!');
                return false
            } else {
                return true
            }

        }
        return <div>
            {/* <Row>
                <Col span={24}>
                    <h2>Kitűző adatai</h2>
                </Col>
            </Row>
            <Divider></Divider> */}
            <Row gutter={[8, 8]} style={{ height: "55vh" }}>
                <Col span={12} >
                    <Col span={24}>
                        <h4>Kitűző neve:</h4>
                    </Col>
                    <Col span={24}>
                        <Input placeholder={"Kitűző neve"} value={this.state.name} onChange={this.setName}></Input>
                    </Col>
                    <Col span={24}>
                        <h4>Választott típus:</h4>
                    </Col>
                    <Col span={24}>
                        <Select
                            style={{ width: "100%" }}
                            onSelect={this.selectBadgeType}
                            // onChange={this.selectBadgeType}
                            value={this.state.selectedBadgeType.value}
                        >
                            {this.state.badgeType.map(item => {
                                return (<Option key={item.id} value={item.value}>{item.value}</Option>)
                            })}
                        </Select>
                    </Col>
                    <Col span={24}>
                        <h4>Típusnak megfelelő feltétel:</h4>
                    </Col>
                    <Col span={24}>
                        {this.state.selectedBadgeType.id === 0 &&
                            <Select
                                style={{ width: "100%" }}
                                onSelect={this.selectRegio}
                                value={this.state.selectedRegio.value}
                            >
                                {this.props.regions.map(item => {
                                    return (<Option key={item.id} value={item.value}>{item.value}</Option>)
                                })}
                            </Select>}
                        {this.state.selectedBadgeType.id === 1 &&
                            <Select
                                style={{ width: "100%" }}
                                onSelect={this.selectNationalPark}
                                // onChange={this.selectBadgeType}
                                value={this.state.selectedNationalPark.value}
                            >
                                {this.props.nationalParks.map(item => {
                                    return (<Option key={item.id} value={item.value}>{item.value}</Option>)
                                })}
                            </Select>}
                        {this.state.selectedBadgeType.id === 2 &&
                            <Select
                                style={{ width: "100%" }}
                                onSelect={this.selectSeason}
                                // onChange={this.selectBadgeType}
                                value={this.state.selectedSeason.value}
                            >
                                {this.state.seasons.map(item => {
                                    return (<Option key={item.id} value={item.value}>{item.value}</Option>)
                                })}
                            </Select>}
                    </Col>
                    <Col span={24} >
                        <h4>Szükséges mennyiség:</h4>
                    </Col>
                    <Col span={24}>
                        <Input type="number" min={1} value={this.state.requiredNumber} onChange={this.changeRequiredNumber}></Input>
                    </Col>
                    <Col span={24}>
                        <h4>Leírás:</h4>
                    </Col>
                    <Col span={24}>
                        <Mentions rows="6"
                            placeholder="Kitűző rövid leírásának a helye."
                            title="Válasz"
                            value={this.state.description}
                            onChange={this.changeDescription}
                        >
                        </Mentions>
                    </Col>
                    <Col span={24}>
                        <Button type="primary" onClick={this.saveBadge}>Kitűző  mentése</Button>
                    </Col>
                </Col>
                <Col span={12}>
                    <Row gutter={[12, 12]}>
                        <Col span={24}><Button icon={<CommentOutlined />} onClick={this.openLanguageModal}> Nyelvek kezelése</Button></Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <h4>Aktív kép feltöltése:</h4>
                        </Col>
                        <Col span={12}>
                            <h4>Deaktív kép feltöltése:</h4>
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <ImgCrop rotate
                                aspect={1 / 1}
                                shape="round"
                                fillColor="transparent"
                                modalTitle="Kép (maximum 1 MB)"
                                beforeCrop={beforeCrop}>
                                <Dragger
                                    style={{ maxHeight: "10em" }}
                                    name="file"
                                    beforeUpload={this.beforeUpload}
                                    fileList={this.state.badgeFile}
                                    showUploadList={false}
                                    onRemove={this.removeBadgefile}
                                    onChange={this.handleChange}
                                    accept=".png,.jpg,.jpeg">
                                    <div>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Aktív</p>
                                        <p className="ant-upload-text">*.png, *.jpg, *.jpeg (max 1MB)</p>
                                    </div>
                                </Dragger>
                            </ImgCrop>
                            <Divider></Divider>
                            <Col span={24}>
                                <Col span={24} justify="center" align="center">
                                    {this.state.badgeFile[0] === undefined ?
                                        <img
                                            src={
                                                this.state.badgeSavedPictureURL !== undefined ? (process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.badgeSavedPictureURL) : null}
                                            alt=" Nincs kitűző kép"
                                            style={{ height: '20vh', maxWidth: "20vw" }}
                                        /> :
                                        <img
                                            alt="" style={{ height: 'vh', maxWidth: "20vw" }}
                                            src={this.state.previewBadgeImage} />}
                                </Col>
                            </Col>
                        </Col>
                        <Col span={12}>
                            <ImgCrop rotate
                                aspect={1 / 1}
                                modalTitle="Kép (maximum 1 MB)"
                                shape="round"
                                fillColor="transparent"
                                beforeCrop={beforeCrop}>
                                <Dragger
                                    style={{ maxHeight: "10em" }}
                                    name="file"
                                    beforeUpload={this.beforeUploadDeactive}
                                    fileList={this.state.deactiveBadgeFile}
                                    showUploadList={false}
                                    onRemove={this.removeDeactiveBadgefile}
                                    onChange={this.handleChange}
                                    accept=".png,.jpg,.jpeg">
                                    <div>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Deaktív</p>
                                        <p className="ant-upload-text">*.png, *.jpg, *.jpeg (max 1MB)</p>
                                    </div>
                                </Dragger>
                            </ImgCrop>
                            <Divider></Divider>
                            {/* Deakktív */}
                            <Col span={24}>
                                <Col span={24} justify="center" align="center">
                                    {this.state.deactiveBadgeFile[0] === undefined ?
                                        <img
                                            src={
                                                this.state.badgeDeactiveSavedPictureURL !== undefined ? (process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + this.state.badgeDeactiveSavedPictureURL) : null}
                                            alt=" Nincs kitűző kép"
                                            style={{ height: '20vh', maxWidth: "20vw" }}
                                        /> :
                                        <img
                                            alt="" style={{ height: '20vh', maxWidth: "20vw" }}
                                            src={this.state.previewDeactiveBadgeImage} />}
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal
                visible={this.state.languageModalOpen}
                title="Kitűző nyelvi kezelés"
                onOk={this.handleSave}
                destroyOnClose={true}
                onCancel={this.closeLanguageModal}
                footer={[
                ]}
            >
                <BageLanguages
                    updateList={this.props.updateList}
                    close={this.closeLanguageModal}
                    badgeId={this.props.selectedRow.id !== undefined ? this.props.selectedRow.id : undefined}
                    name={this.state.name}
                    description={this.state.description} />
            </Modal>
        </div>
    }
}
const mapStateToProps = (state) => {
    return {
        nationalParks: state.natureTrailReducer.nationalParks,
        regions: state.natureTrailReducer.regions,
        status: state.badgeReducer.status,

    }
};
const SelectedBadge = connect(mapStateToProps, mapDispatchToProps)(ConnectedSelectedBadge);
export default SelectedBadge;