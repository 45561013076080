import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Col, Popconfirm, Row, Skeleton, List, message } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addNewPairMatch, deletePairMatchById, getAllPairMatchByNatureTrailLocationId, getOnePairMatchById } from '../../actions/pairMatch';
import PairMatch from './pair-match';
const mapDispatchToProps = (dispatch) => {
    return {
        getAllPairMatchByNatureTrailLocationId: (natureTrailLocationId) => dispatch(getAllPairMatchByNatureTrailLocationId(natureTrailLocationId)),
        getOnePairMatchById: (id) => dispatch(getOnePairMatchById(id)),
        addNewPairMatch: (natureTrailLocationId) => dispatch(addNewPairMatch(natureTrailLocationId)),
        deletePairMatchById: (id) => dispatch(deletePairMatchById(id))

    };
};
class ConnectedPairMatchModal extends Component {

    state = {
        pairMatchData: [],
        selectedQuestion: undefined,
        loadedPairMatch: {},
        pairMatchKeys: [],
        showData: false
    }

    componentDidMount = async () => {
        if (this.props.natureTrailLocationId) {
            await this.props.getAllPairMatchByNatureTrailLocationId(this.props.natureTrailLocationId)
            if (this.props.loadingStatus === "loaded") {
                const pairMatchKeys = this.props.pairMatches.map(item => { return item.key })
                this.setState({ pairMatchKeys: pairMatchKeys }, () => { this.props.setPairMatchId(this.state.pairMatchKeys) })
            } else {
                message.warning("Hiba az adatok betöltésénél!")
            }
            this.setState({ pairMatchData: this.props.pairMatches })
        }
    }

    addNewQuestionsAnswer = async () => {
        await this.props.addNewPairMatch(this.props.natureTrailLocationId)
        this.setState({
            pairMatchData: [...this.state.pairMatchData, this.props.newPairMatch],
            pairMatchKeys: [...this.state.pairMatchKeys, this.props.newPairMatch.key]
        }, () => { this.props.setPairMatchId(this.state.pairMatchKeys) })
    }

    clickOnListItem = async (item, i) => {
        await this.props.getOnePairMatchById(item.key)
        this.setState({ loadedPairMatch: this.props.pairMatch, showData: true })
    }

    handleDelete = async (item, i) => {
        await this.props.deletePairMatchById(item.key)
        const filteredList = this.state.pairMatchData.filter(item => item.key !== this.props.deletedPairMatch.key)
        const filteredPairMatchKeys = filteredList.map(item => { return item.key })
        this.setState({
            pairMatchData: filteredList,
            pairMatchKeys: filteredPairMatchKeys,
            showData: false
        })
    }

    render() {
        return <div>
            <Row gutter={[24, 16]} >
                <Col span={24} >
                    <Button onClick={this.addNewQuestionsAnswer}>Új "Pár" hozzáadása</Button>
                </Col>
                <Col span={7} >

                    <List
                        style={{ height: "85vh", overflowY: "auto" }}
                        bordered
                        size={Row}
                        dataSource={this.state.pairMatchData}
                        renderItem={(item, i) => (
                            <List.Item
                                actions={[<Button type="primary" shape="circle" onClick={() => this.clickOnListItem(item, i)} icon={<EditOutlined />} size="small" />
                                    , <Popconfirm title="Biztos törlöd?" onConfirm={() => this.handleDelete(item, i)}>
                                    {<DeleteOutlined />}
                                </Popconfirm>

                                ]}
                            >
                                <Skeleton avatar title={false} loading={item.loading} active>
                                    <List.Item.Meta
                                        description={<p style={{
                                            // width: "8vw",
                                            // height: "66px",
                                            // overflow: "hidden"
                                        }}
                                        >
                                            {/* {item.question.substr(0, 48)} {item.question.length > 48 ? "..." : ""} */}
                                        </p>}
                                        title={
                                            <Row gutter={24}>


                                                <Col span={24}>
                                                    <QuestionCircleOutlined />
                                                    {" " + (i + 1) + ". feladat:"}
                                                </Col>
                                            </Row>
                                        }
                                    />
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                </Col>

                <Col span={17} >
                    {this.state.loadedPairMatch && this.state.loadedPairMatch.key && this.state.showData && <PairMatch
                        natureTrailLocationId={this.props.natureTrailLocationId}
                        loadedPairMatch={this.state.loadedPairMatch} />}
                </Col>

            </Row>
        </div>
    }
}

const mapStateToProps = (state) => ({
    pairMatches: state.pairMatchReducer.pairMatches,
    loadingStatus: state.pairMatchReducer.loadingStatus,
    pairMatch: state.pairMatchReducer.pairMatch,
    newPairMatch: state.pairMatchReducer.newPairMatch,
    deletedPairMatch: state.pairMatchReducer.deletedPairMatch
});
const PairMatchModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedPairMatchModal);
export default PairMatchModal;