import React, { Component } from 'react'
import { connect } from 'react-redux'
import Photo from './photo'

const mapDispatch = (dispatch) => {
    return {
    }
}

class ConnectedPhotoModal extends Component {

    state = {
    }

    componentDidMount = async () => {
    }

    render() {
        return (
            <div >
                <Photo
                    naturalTrailLocationId={this.props.naturalTrailLocationId}
                    // setQuestionAnswerId={this.props.setQuestionAnswerId}
                    setPhotoId={this.props.setPhotoId}
                ></Photo>
            </div>
        )
    }
}


const mapState = (state) => {
    return {
    }
}

const PhotoModal = connect(mapState, mapDispatch)(ConnectedPhotoModal)
export default PhotoModal; 