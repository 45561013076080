import { Button, Col, Divider, Input, message, Modal, Row, Select, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { savePairMatchWithAnswers } from '../../actions/pairMatch';

const { Option } = Select;

const mapDispatchToProps = (dispatch) => {
    return {
        savePairMatchWithAnswers: (files, params) => dispatch(savePairMatchWithAnswers(files, params))
    };
};


class ConnectedPairMatch extends Component {

    state = {
        pairType: [
            {
                id: 1,
                value: "Kép - kép"
            },
            {
                id: 2,
                value: "Kép - szöveg"
            },
            {
                id: 3,
                value: "Szöveg – szöveg"
            },
        ],
        actualType: [{
            id: 1,
            value: "Kép - kép"
        },],
        actualImagePreview: "",
        actualAnswerCount: 4,
        question: "",
        saveButtonActive: false,
        wrongMessage: "",
        goodMessage: "",
        pairs: [
            {
                id: 1,
                answer: "",
                answerPair: "",
                imageName: "",
                imageURL: "",
                imageSavedFileName: ""
            },
            {
                id: 2,
                answer: "",
                answerPair: "",
                imageName: "",
                imageURL: "",
                imageSavedFileName: ""
            },
            {
                id: 3,
                answer: "",
                answerPair: "",
                imageName: "",
                imageURL: "",
                imageSavedFileName: ""
            },
            {
                id: 4,
                answer: "",
                answerPair: "",
                imageName: "",
                imageURL: "",
                imageSavedFileName: ""
            },
        ],
        questionLimit: 200,
        answersLimit: 20,
        popupLimit: 160,
        picture1afile: [],
        picture1bfile: [],
        picture2afile: [],
        picture2bfile: [],
        picture3afile: [],
        picture3bfile: [],
        picture4afile: [],
        picture4bfile: [],
        preview1a: "",
        preview1b: "",
        preview2a: "",
        preview2b: "",
        preview3a: "",
        preview3b: "",
        preview4a: "",
        preview4b: "",

    }

    componentDidMount = async () => {
        console.log(this.props.loadedPairMatch);
        this.setInitialValue(this.props.loadedPairMatch)
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.loadedPairMatch !== this.props.loadedPairMatch) {
            console.log(this.props.loadedPairMatch);
            this.setInitialValue(this.props.loadedPairMatch)
        }
    }

    setInitialValue = (pairMatchObject) => {
        this.setState({
            actualAnswerCount: pairMatchObject.answerCount,
            question: pairMatchObject.question,
            goodMessage: pairMatchObject.goodAnswerMessage,
            wrongMessage: pairMatchObject.wrongAnswerMessage,
            actualType: [pairMatchObject.pairMatchTypes],
            pairs: pairMatchObject.answers,
            preview1a: pairMatchObject.answers[0].answerImageURL ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + pairMatchObject.answers[0].answerImageURL : "",
            preview1b: pairMatchObject.answers[0].answerPairMatchAnswerURL ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + pairMatchObject.answers[0].answerPairMatchAnswerURL : "",
            preview2a: pairMatchObject.answers[1].answerImageURL ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + pairMatchObject.answers[1].answerImageURL : "",
            preview2b: pairMatchObject.answers[1].answerPairMatchAnswerURL ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + pairMatchObject.answers[1].answerPairMatchAnswerURL : "",
            preview3a: pairMatchObject.answers[2].answerImageURL ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + pairMatchObject.answers[2].answerImageURL : "",
            preview3b: pairMatchObject.answers[2].answerPairMatchAnswerURL ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + pairMatchObject.answers[2].answerPairMatchAnswerURL : "",
            preview4a: pairMatchObject.answers[3].answerImageURL ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + pairMatchObject.answers[3].answerImageURL : "",
            preview4b: pairMatchObject.answers[3].answerPairMatchAnswerURL ? process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + pairMatchObject.answers[3].answerPairMatchAnswerURL : "",
        })
    }

    selectAnswerCount = (event) => {
        this.setState({ actualAnswerCount: event, saveButtonActive: true })
    }

    selectType = (event) => {
        const filteredType = this.state.pairType.filter(currentType => currentType.value === event)
        this.setState({ actualType: filteredType, saveButtonActive: true });
    }

    changeQuestion = (event) => {
        const actualValue = event.target.value
        if (actualValue.length <= this.state.questionLimit) {
            this.setState({ question: actualValue, saveButtonActive: true })
        } else {
            message.error("A kérdés karakterszáma maximum " + this.state.questionLimit + " lehet.")
        }
    }

    changePair1String = (arrayCount, event) => {
        const actualPairs = this.state.pairs;
        const actualValue = event.target.value
        if (actualValue.length <= this.state.answersLimit) {
            actualPairs[arrayCount].answer = actualValue;
            this.setState({ pairs: actualPairs, saveButtonActive: true })
        } else {
            message.error("Az első opció karakterszáma maximum " + this.state.answersLimit + " lehet.")
        }

    }

    changePair2String = (arrayCount, event) => {
        const actualPairs = this.state.pairs;
        const actualValue = event.target.value
        if (actualValue.length <= this.state.answersLimit) {
            actualPairs[arrayCount].answerPair = actualValue;
            this.setState({ pairs: actualPairs, saveButtonActive: true })
        } else {
            message.error("Az első opcióhoz tartozó párnak a karakterszáma maximum " + this.state.answersLimit + " lehet.")
        }
    }



    beforeUploadPicture1a = async (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
            return true
        } else {
            this.setState({ preview1a: URL.createObjectURL(file), picture1afile: file, saveButtonActive: true })
            return false
        }
    }

    beforeUploadPicture1b = async (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
        } else {
            this.setState({ preview1b: URL.createObjectURL(file), picture1bfile: file, saveButtonActive: true })
        }
        return isLt2M;
    }
    beforeUploadPicture2a = async (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
        } else {
            this.setState({ preview2a: URL.createObjectURL(file), picture2afile: file, saveButtonActive: true })
        }
        return isLt2M;;
    }
    beforeUploadPicture2b = async (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
        } else {
            this.setState({ preview2b: URL.createObjectURL(file), picture2bfile: file, saveButtonActive: true })
        }
        return isLt2M;;

    }
    beforeUploadPicture3a = async (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
        } else {
            this.setState({ preview3a: URL.createObjectURL(file), picture3afile: file, saveButtonActive: true })
        }
        return isLt2M;;
    }
    beforeUploadPicture3b = async (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
        } else {
            this.setState({ preview3b: URL.createObjectURL(file), picture3bfile: file, saveButtonActive: true })
        }
        return isLt2M;;

    }
    beforeUploadPicture4a = async (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
        } else {
            this.setState({ preview4a: URL.createObjectURL(file), picture4afile: file, saveButtonActive: true })
        }
        return isLt2M;;

    }
    beforeUploadPicture4b = async (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
        } else {
            this.setState({ preview4b: URL.createObjectURL(file), picture4bfile: file, saveButtonActive: true })
        }
        return isLt2M;;
    }

    priviewModalOpen = (event) => {
        this.setState({
            previewVisible: true,
            actualPictureInModal: event.target.src
        });
    }
    handleCancelPhoto = () => {
        this.setState({ previewVisible: false })
    }
    changeWrongMessage = (event) => {
        const value = event.target.value
        if (value.length < this.state.popupLimit) {
            this.setState({ wrongMessage: value, saveButtonActive: true })
        } else {
            message.warning("Az üzenet karakterszámának kell lennie mint " + this.state.popupLimit + ".")
        }

    }
    changeGoodMessage = (event) => {
        const value = event.target.value
        if (value.length < this.state.popupLimit) {
            this.setState({ goodMessage: value, saveButtonActive: true })
        } else {
            message.warning("Az üzenet karakterszámának kell lennie mint " + this.state.popupLimit + ".")
        }

    }

    saveActualPairQuestion = async () => {
        this.setState({ saveButtonActive: false })
        const saveObj = {
            id: this.props.loadedPairMatch.key,
            question: this.state.question,
            answerCount: this.state.actualAnswerCount,
            questionType: this.state.actualType,
            wrongAnswerMessage: this.state.wrongMessage,
            goodAnswerMessage: this.state.goodMessage,
            pairMatchTypes: this.state.actualType,
            answers: this.state.pairs
        }
        const files = [
            this.state.picture1afile,
            this.state.picture1bfile,
            this.state.picture2afile,
            this.state.picture2bfile,
            this.state.picture3afile,
            this.state.picture3bfile,
            this.state.picture4afile,
            this.state.picture4bfile]
        console.log(saveObj, files);
        await this.props.savePairMatchWithAnswers(files, saveObj)
        if (this.props.editStatus && this.props.editStatus === 'saved') {
            message.success("Sikeres mentés")
            this.setState({ saveButtonActive: false })
        } else {
            message.error("A mentés sikertelen")
            this.setState({ saveButtonActive: true })
        }
    }

    render() {
        const beforeCrop = (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                message.error('A képnek kissebbnek kell lennie mint 5MB!');
                return false
            } else {
                //this.setState({ preview1a: URL.createObjectURL(file), picture1afile: file, saveButtonActive: true })
                return true
            }

        }

        return <div style={{ paddingTop: '8px' }}>
            <Row gutter={[24, 16]} >
                <Col span={24} style={{
                    height: "65vh"
                }}>
                    <Row gutter={[24, 24]}>
                        <Button disabled={!this.state.saveButtonActive} type="primary" onClick={this.saveActualPairQuestion}>Mentés</Button>
                    </Row>
                    <Row gutter={[24, 16]} style={{ backgroundColor: "rgba(34,139,34,0.1)" }} >
                        <Col span={16} style={{
                        }}>
                            <div>Kérdés:</div>
                            <Input
                                value={this.state.question}
                                // prefix={<div>{this.state.questionLimit - this.state.question.length}<Divider type="vertical"></Divider></div>}
                                onChange={(event) => this.changeQuestion(event)}
                            ></Input>
                            <p><strong>Hátralévő karekterek: </strong>{this.state.questionLimit}/{this.state.questionLimit - this.state.question.length}</p>

                        </Col>
                        <Col span={4} style={{
                        }}>
                            <div>Típus:</div>
                            <Select value={this.state.actualType[0].value} style={{ width: "100%" }} onSelect={this.selectType}
                            >
                                {this.state.pairType.map(item => { return (< Option value={item.value} key={item.id} > {item.value}</Option>) })}
                            </Select>
                        </Col>
                        <Col span={4} style={{
                        }}>
                            <div>Kérdés szám:</div>
                            <Select style={{ width: "100%" }} value={this.state.actualAnswerCount} onSelect={this.selectAnswerCount}
                            >
                                < Option value={2} > {2}</Option>
                                < Option value={3} > {3}</Option>
                                < Option value={4} > {4}</Option>
                            </Select>
                        </Col>
                    </Row>
                    <Row gutter={[24, 16]} style={{ backgroundColor: "rgba(34,139,34,0.1)" }}>
                        <Col span={12}>
                            <div>Helyes válasz üzenet: </div>
                            <Input
                                // prefix={<div>{this.state.popupLimit - (this.state.goodMessage.length ? this.state.goodMessage.length : 0)}<Divider type="vertical"></Divider></div>}
                                value={this.state.goodMessage}
                                onChange={this.changeGoodMessage}
                            ></Input>
                            <p><strong>Hátralévő karekterek: </strong>{this.state.popupLimit}/{this.state.popupLimit - this.state.goodMessage.length}</p>

                        </Col>
                        <Col span={12}>
                            <div>Helytelen üzenet: </div>
                            <Input
                                // prefix={<div>{this.state.popupLimit - this.state.wrongMessage.length}<Divider type="vertical"></Divider></div>}
                                value={this.state.wrongMessage}
                                onChange={this.changeWrongMessage}
                            ></Input>
                            <p><strong>Hátralévő karekterek: </strong>{this.state.popupLimit}/{this.state.popupLimit - this.state.wrongMessage.length}</p>

                        </Col>

                    </Row>
                    <Row gutter={[24, 24]} justify="center" align="middle">
                        <Col>
                            <b>Párosító/párosítandó</b>
                        </Col>
                    </Row>
                    {this.state.actualType[0].id === 3 &&
                        <div>
                            <Row gutter={[24, 16]} style={{ marginTop: '1vh', backgroundColor: "rgba(34,139,34,0.05)" }} >
                                <Col span={12} >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <div>1. válasz</div>
                                            <Input
                                                value={this.state.pairs[0].answer}
                                                // prefix={<div>{this.state.answersLimit - this.state.pairs[0].answer.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair1String(0, event)}>
                                            </Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[0].answer.length}</p>

                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12} >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <div>1. válasz pár</div>
                                            <Input
                                                value={this.state.pairs[0].answerPair}
                                                // prefix={<div>{this.state.answersLimit - this.state.pairs[0].answerPair.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair2String(0, event)}>
                                            </Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[0].answerPair.length}</p>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={[24, 16]} style={{ marginTop: '1vh', backgroundColor: "rgba(34,139,34,0.05)" }} >
                                <Col span={12} >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <div>2. válasz</div>
                                            <Input
                                                value={this.state.pairs[1].answer}
                                                // prefix={<div>{this.state.answersLimit - this.state.pairs[1].answer.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair1String(1, event)}
                                            ></Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[1].answer.length}</p>

                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12} >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <div>2. válasz pár</div>
                                            <Input
                                                value={this.state.pairs[1].answerPair}
                                                // prefix={<div>{this.state.answersLimit - this.state.pairs[1].answerPair.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair2String(1, event)}>
                                            </Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[1].answerPair.length}</p>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {this.state.actualAnswerCount >= 3 && <Row gutter={[24, 16]} style={{ marginTop: '1vh', backgroundColor: "rgba(34,139,34,0.05)" }} >
                                <Col span={12} >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <div>3. válasz</div>
                                            <Input
                                                value={this.state.pairs[2].answer}
                                                // prefix={<div>{this.state.answersLimit - this.state.pairs[2].answer.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair1String(2, event)}>
                                            </Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[2].answer.length}</p>

                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12} >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <div>3. válasz pár</div>
                                            <Input
                                                value={this.state.pairs[2].answerPair}
                                                // prefix={<div>{this.state.answersLimit - this.state.pairs[2].answerPair.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair2String(2, event)}>
                                            </Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[2].answerPair.length}</p>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>}
                            {this.state.actualAnswerCount >= 4 && <Row gutter={[24, 16]} style={{ marginTop: '1vh', backgroundColor: "rgba(34,139,34,0.05)" }} >
                                <Col span={12} >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <div>4. válasz</div>
                                            <Input
                                                value={this.state.pairs[3].answer}
                                                // prefix={<div>{this.state.answersLimit - this.state.pairs[3].answer.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair1String(3, event)}>
                                            </Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[3].answer.length}</p>

                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12} >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <div>4. válasz pár</div>
                                            <Input
                                                value={this.state.pairs[3].answerPair}
                                                //prefix={<div>{this.state.answersLimit - this.state.pairs[3].answerPair.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair2String(3, event)}>
                                            </Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[3].answerPair.length}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>}
                        </div>
                    }
                    {this.state.actualType[0].id === 2 &&
                        <div>

                            <Row gutter={[24, 16]} style={{ marginTop: '1vh', backgroundColor: "rgba(34,139,34,0.05)" }} >
                                <Col span={8} >
                                    <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview1a}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                beforeCrop={beforeCrop}
                                                aspect={1 / 1}
                                                modalTitle="Kép (maximum 5 MB)">
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture1a}
                                                    fileList={this.state.picture1afile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>1. kép kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>
                                    {this.state.actualAnswerCount >= 2 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview2a}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                aspect={1 / 1}
                                                modalTitle="Kép (maximum 5 MB)">
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture2a}
                                                    fileList={this.state.picture1afile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>2. kép kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>
                                    }
                                    {this.state.actualAnswerCount >= 3 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview3a}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                beforeCrop={beforeCrop}
                                                aspect={1 / 1}
                                                modalTitle="Kép (maximum 5 MB)">
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture3a}
                                                    fileList={this.state.picture1afile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>3. kép kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>}
                                    {this.state.actualAnswerCount >= 4 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview4a}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                beforeCrop={beforeCrop}
                                                aspect={1 / 1}
                                                modalTitle="Kép (maximum 5 MB)">
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture4a}
                                                    fileList={this.state.picture1afile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>4. kép kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>}
                                </Col>
                                <Col span={16} >
                                    <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col span={4}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}>1. pár</div>
                                        </Col>
                                        <Col span={20} >
                                            <Input
                                                value={this.state.pairs[0].answerPair}
                                                // prefix={<div>{this.state.answersLimit - this.state.pairs[0].answerPair.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair2String(0, event)}>
                                            </Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[0].answerPair.length}</p>

                                        </Col>
                                    </Row>
                                    {this.state.actualAnswerCount >= 2 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col span={4}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}>2. pár</div>
                                        </Col>
                                        <Col span={20} >
                                            <Input
                                                value={this.state.pairs[1].answerPair}
                                                // prefix={<div>{this.state.answersLimit - this.state.pairs[1].answerPair.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair2String(1, event)}>
                                            </Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[1].answerPair.length}</p>

                                        </Col>
                                    </Row>}
                                    {this.state.actualAnswerCount >= 3 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col span={4}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}>3. pár</div>
                                        </Col>
                                        <Col span={20} >
                                            <Input
                                                value={this.state.pairs[2].answerPair}
                                                // prefix={<div>{this.state.answersLimit - this.state.pairs[2].answerPair.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair2String(2, event)}>
                                            </Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[2].answerPair.length}</p>

                                        </Col>
                                    </Row>}
                                    {this.state.actualAnswerCount >= 4 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col span={4}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}
                                            >4. pár</div>
                                        </Col>
                                        <Col span={20} >
                                            <Input
                                                value={this.state.pairs[3].answerPair}
                                                // prefix={<div>{this.state.answersLimit - this.state.pairs[3].answerPair.length}<Divider type="vertical"></Divider></div>}
                                                onChange={(event) => this.changePair2String(3, event)}>
                                            </Input>
                                            <p><strong>Hátralévő karekterek: </strong>{this.state.answersLimit}/{this.state.answersLimit - this.state.pairs[3].answerPair.length}</p>
                                        </Col>
                                    </Row>}
                                </Col>
                            </Row>
                        </div>}
                    {this.state.actualType[0].id === 1 &&
                        <div>

                            <Row gutter={[24, 16]} style={{ marginTop: '1vh', backgroundColor: "rgba(34,139,34,0.05)" }} >
                                <Col span={12} >
                                    <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview1a}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                aspect={1 / 1}
                                                modalTitle="Kép (maximum 5 MB)"
                                                beforeCrop={beforeCrop}>
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture1a}
                                                    fileList={this.state.picture1afile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>1. kép kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>
                                    {this.state.actualAnswerCount >= 2 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview2a}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                aspect={1 / 1}
                                                modalTitle="Kép (maximum 5 MB)"
                                                beforeCrop={beforeCrop}>

                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture2a}
                                                    fileList={this.state.picture2afile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>2. kép kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>
                                    }
                                    {this.state.actualAnswerCount >= 3 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview3a}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                aspect={1 / 1}
                                                modalTitle="Kép (maximum 5 MB)"
                                                beforeCrop={beforeCrop}>
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture3a}
                                                    fileList={this.state.picture3afile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>3. kép kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>}
                                    {this.state.actualAnswerCount >= 4 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview4a}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                aspect={1 / 1}

                                                modalTitle="Kép (maximum 5 MB)"
                                                beforeCrop={beforeCrop}>
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture4a}
                                                    fileList={this.state.picture4afile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>4. kép kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>}
                                </Col>
                                <Col span={12} >
                                    <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview1b}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                aspect={1 / 1}
                                                modalTitle="Kép (maximum 5 MB)"
                                                beforeCrop={beforeCrop}>
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture1b}
                                                    fileList={this.state.picture1bfile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>1. kép párjának kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>
                                    {this.state.actualAnswerCount >= 2 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview2b}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                aspect={1 / 1}
                                                modalTitle="Kép (maximum 5 MB)"
                                                beforeCrop={beforeCrop}>
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture2b}
                                                    fileList={this.state.picture2bfile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>2. kép párjának kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>
                                    }
                                    {this.state.actualAnswerCount >= 3 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview3b}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                aspect={1 / 1}
                                                modalTitle="Kép (maximum 5 MB)"
                                                beforeCrop={beforeCrop}>
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture3b}
                                                    fileList={this.state.picture3bfile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>3. kép párjának kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>}
                                    {this.state.actualAnswerCount >= 4 && <Row gutter={24} align="middle" style={{ height: "10vh" }}>
                                        <Col className="gutter-row" span={6} >
                                            <img onClick={this.priviewModalOpen}
                                                id={1}
                                                alt="" style={{ width: "8vh", height: "8vh", cursor: 'zoom-in' }}
                                                src={this.state.preview4b}
                                            ></img>
                                        </Col>
                                        <Col className="gutter-row" span={18} >
                                            <ImgCrop rotate
                                                aspect={1 / 1}
                                                modalTitle="Kép (maximum 5 MB)"
                                                beforeCrop={beforeCrop}>
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={this.beforeUploadPicture4b}
                                                    fileList={this.state.picture1bfile}
                                                    // onRemove={this.removeFile}
                                                    onChange={this.handleChange}
                                                    showUploadList={false}
                                                    accept=".png,.jpg,.jpeg">
                                                    <Button>4. kép párjának kiválasztása</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>}
                                </Col>
                            </Row>
                        </div>}
                </Col>
            </Row >
            <Modal
                visible={this.state.previewVisible}
                title={"Kép"}
                footer={null}
                onCancel={this.handleCancelPhoto}
            >
                <div>
                    <img alt="example" style={{ width: '100%' }} src={this.state.actualPictureInModal} />
                </div>
            </Modal></div >;
    }
}
const mapStateToProps = (state) => ({
    editStatus: state.pairMatchReducer.editStatus
});
const PairMatch = connect(mapStateToProps, mapDispatchToProps)(ConnectedPairMatch);
export default PairMatch;