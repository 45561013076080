import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Form,
  message,
  Popconfirm,
} from "antd";
import { SaveFilled } from "@ant-design/icons";
import {
  getAllAdmin,
  saveAdmin,
  deleteAdmin,
  modifyAdmin,
} from "../../actions/admin";

const mapDispatch = (dispatch) => {
  return {
    getAllAdmin: () => dispatch(getAllAdmin()),
    saveAdmin: (admin) => dispatch(saveAdmin(admin)),
    deleteAdmin: (id) => dispatch(deleteAdmin(id)),
    modfiyAdmin: (admin) => dispatch(modifyAdmin(admin)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedAdmins extends Component {
  formRef = React.createRef();

  state = {
    selectedId: null,
    email: "",
    name: "",
    password: "",
    selectedNationalPark: null,
    modify: false,
  };

  componentDidMount = async () => {
    await this.props.getAllAdmin();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  modifyAdmin = (locationAdmin) => {
    this.setState({
      modify: true,
      password: "",
      email: locationAdmin.email,
      name: locationAdmin.name,
      selectedId: locationAdmin.id,
    });
    this.formRef.current.setFieldsValue({
      password: "",
      email: locationAdmin.email,
      name: locationAdmin.name,
    });
  };

  handleCancelModify = () => {
    this.setToDefault();
  };

  setToDefault = () => {
    this.formRef.current.resetFields();
    this.setState({
      modify: false,
      password: "",
      name: "",
      email: "",
      selectedId: null,
    });
  };

  submitForm = async () => {
    const adminToSave = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
    };
    if (this.state.modify) {
      adminToSave.id = this.state.selectedId;
      if (adminToSave.password === "") {
        delete adminToSave.password;
      }
      await this.props.modfiyAdmin(adminToSave);
    } else {
      await this.props.saveAdmin(adminToSave);
    }

    if (this.props.adminsStatus) {
      message.success("Sikeres mentés!", 2);
      await this.props.getAllAdmin();
      this.setToDefault();
    } else {
      message.error("Sikertelen mentés!", 2);
    }
  };

  deleteUser = async (user) => {
    await this.props.deleteAdmin(user.id);
    await this.props.getAllAdmin();
  };

  render() {
    const columns = [
      { title: "E-mail cím", dataIndex: "email", key: "email" },
      { title: "Név", dataIndex: "name", key: "name" },
      {
        title: "Adatok",
        key: "showData",
        render: (text, row) => (
          <Space size="middle">
            <Button onClick={() => this.modifyAdmin(row)}>Módosítás</Button>
          </Space>
        ),
      },
      {
        title: "Törlés",
        key: "delete",
        render: (text, row) => (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarja a felhasználót?"
              okText="Igen"
              cancelText="Nem"
              onConfirm={() => this.deleteUser(row)}
              disabled={row.id === this.props.user.id}
            >
              <Button disabled={row.id === this.props.user.id}>Törlés</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col span={16} style={{ padding: "1em" }}>
            <h1>Adminok</h1>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={this.props.admins}
              locale={{ emptyText: "Nincsenek adminok!" }}
              //loading={!this.props.status && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
              }}
              size="small"
            />
          </Col>
          <Col span={8} style={{ padding: "1em" }}>
            <h1>{this.state.modify ? "Admin módosítása" : "Új admin"}</h1>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="project"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="Név"
                rules={[
                  {
                    required: true,
                    message: "Kérem adja meg a nevet!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email cím"
                rules={[
                  {
                    required: true,
                    message: "Kérem adja meg az email címet!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Jelszó"
                rules={[
                  {
                    required: !this.state.modify,
                    message: "Kérem adja meg a jelszót!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ margin: "0.5em" }}
                >
                  <SaveFilled />
                  Mentés
                </Button>
                <Button onClick={this.handleCancelModify}>Mégse</Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    message: state.natureTrailReducer.message,
    code: state.natureTrailReducer.code,
    status: state.natureTrailReducer.status,
    natureTrails: state.natureTrailReducer.data,
    admins: state.userReducer.admins.admins,
    adminsStatus: state.userReducer.adminsStatus,
    user: state.loginReducer.user,
  };
};

const Admins = connect(mapState, mapDispatch)(ConnectedAdmins);
export default Admins;
