import React, { Component } from 'react'
import { Modal, Button, Table } from 'antd'
import { connect } from 'react-redux'

const mapDispatch = (dispatch) => {
    return {
    }
}

class ConnectedGamerDataModal extends Component {
    state = {
        userNatureTrails: []
    }
    // componentDidUpdate (prevProps, pp) {
    //     if (this.props.user && this.props.user.natureTrails !== prevProps.user.natureTrails) {

    //         const userNatureTrails = await this.props.user.natureTrails.map((natureTrail, i) => {
    //             return {
    //                 key: i,
    //                 id: natureTrail.id,
    //                 name: natureTrail.name
    //             };
    //         });
    //         this.setState({ userNatureTrails: userNatureTrails });
    //     }
    // }


    render() {
        const columns = [
            { title: "Key", dataIndex: "key", key: "key" },
            { title: "Name", dataIndex: "name", key: "emnameail" },
        ];
        return (
            <Modal
                visible={this.props.open}
                title="Felhasználói adatok, statisztikák, eredmények"
                onOk={this.handleSave}
                onCancel={this.props.close}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Mégsem
                    </Button>
                ]}
            >
                <Table
                    columns={columns}
                    dataSource={this.props.userNatureTrails}
                /></Modal>

        )
    }
}
const mapState = (state) => {
    return {
        message: state.userReducer.message,
        code: state.userReducer.code,
        user: state.userReducer.user,
        userStatus: state.userReducer.userStatus
    }
}

const GamerDataModal = connect(mapState, mapDispatch)(ConnectedGamerDataModal)
export default GamerDataModal;
