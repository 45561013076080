import React, { Component } from "react";
import { Input, Button, Form, message, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { forgotPassword } from "../../actions/forgor-password";

const mapDispatch = (dispatch) => {
    return { forgotPassword: (email) => dispatch((forgotPassword(email))) }
}
class ConnectedForgotPassword extends Component {
    state = {
        email: null,
        buttonDisabled: false,
        forgotPasswordSendedSuccesful: false,
        spinning: false,
        savingDescription: "A jelszó küldése folyamatban!"
    }

    handleEmailChange = (event) => {
        if (event.target.value !== undefined) {
            this.setState({ email: event.target.value })
        }
    }

    clickOnGetNewPassword = async () => {

        this.setState({ buttonDisabled: true, spinning: true })
        await this.props.forgotPassword(this.state.email);
        if (this.props.status && this.props.status === "sended") {
            message.success("We send a new password to the " + this.state.email)
            this.setState({ buttonDisabled: false, forgotPasswordSendedSuccesful: true, spinning: false })
        } else {
            if (this.props.message !== undefined && this.props.message !== null) {
                this.setState({ buttonDisabled: false, spinning: false })
                message.error(this.props.message)
            }

            else {
                this.setState({ buttonDisabled: false, spinning: false })
                message.error("Something went wrong!")
            }
        }

    }


    render() {
        return (
            <Spin spinning={this.state.spinning} tip={this.state.savingDescription} className="customSpin" style={{
                zIndex: 100000000
            }} size="large">
                <div style={{
                    height: "100vh",
                    backgroundImage: `url("/login_background.jpg")`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    minHeight: "100%",
                    minWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>

                    <div className="registration-page-container">
                        <Form
                            name="normal_login"
                            className="login-form registrationform"
                            initialValues={{ remember: true }}
                        >

                            <div style={{
                                height: "27px",
                                width: " 336px",
                                color: "#FFFFFF",
                                fontWeight: 900,
                                fontFamily: "Publica Sans",
                                fontSize: "27px",
                                letterSpacing: 0,
                                lineHeight: "35px",
                                paddingBottom: "6vh"
                            }}>
                                Új jelszó igénylés
                    </div>

                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: "Please input your email address!" }]}
                            >
                                <Input
                                    className="site-form-item-icon"
                                    prefix={<UserOutlined />}
                                    placeholder="email"
                                    onChange={this.handleEmailChange}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{
                                    textAlignLast: "center",
                                }}>
                                <Button
                                    disabled={this.state.buttonDisabled}
                                    type="primary"
                                    htmlType="submit"
                                    className="login-button"
                                    onClick={this.clickOnGetNewPassword}
                                >
                                    Új jelszó küldése
                                </Button>
                            </Form.Item>{
                                this.state.forgotPasswordSendedSuccesful && <Redirect Form="**" to="/login" />
                            }
                            <a className="login-form-forgot" href="/login">Vissza a bejelentkezéshez</a>
                        </Form>
                    </div >

                </div>
            </Spin>
        );
    }
}
const mapState = (state) => {
    return {
        message: state.forgotPasswordReducer.message,
        code: state.forgotPasswordReducer.code,
        status: state.forgotPasswordReducer.status
    }
}

const ForgotPassword = connect(mapState, mapDispatch)(ConnectedForgotPassword)
export default ForgotPassword;
