import axios from "axios";
import { NATURETRAIL, SPINNING } from "../constants/action-types";

export const getNatureTrails = (userId) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_LOADING });
        await axios
            .get("nature-trail/nature-trails", {
                params: {
                    userId: userId
                }
            })
            .then(({ data }) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_LOADED,
                    payload: { data: data.natureTrails },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getNatureTrailLocationsByNatureTrailId = (natureTrailId) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_LOCATION_BY_ID_LOADING });
        await axios
            .get("nature-trail/nature-trail-location-by-nt-id", {
                params: {
                    natureTrailId: natureTrailId
                }
            })
            .then(({ data }) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_LOCATION_BY_ID_LOADED,
                    payload: { data: data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_LOCATION_BY_ID_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const saveNatureTrail = (natureTrail, files) => {

    const formData = new FormData();
    formData.append("base", files.base[0]);
    formData.append("gpx", files.gpx[0]);
    formData.append("badge", files.badge[0]);
    formData.append("deactiveBadge", files.deactiveBadge[0]);
    formData.append("logo", files.logo[0]);
    formData.append("NTlocationData", JSON.stringify(natureTrail));
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_SAVE_SENDING });
        await axios
            .post("/nature-trail/upload-nt-files?id=69", formData,
        )
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_SAVE_SENDED,
                    // payload: { user: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_SAVE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}
export const updateNatureTrail = (natureTrail) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_SAVE_SENDING });
        await axios
            .post("/nature-trail/update-nature-trail", natureTrail
            )
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_SAVE_SENDED,
                    // payload: { user: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_SAVE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}
export const getNatureTrailTypes = () => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_TYPE_LOADING });
        await axios
            .get("nature-trail/nature-trail-types", {})
            .then(({ data }) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_TYPE_LOADED,
                    payload: { data: data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_TYPE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getAllNatureTrailTypes = () => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.ALL_NATURETRAIL_TYPE_LOADING });
        await axios
            .get("nature-trail/all-nature-trail-types", {})
            .then(({ data }) => {
                dispatch({
                    type: NATURETRAIL.ALL_NATURETRAIL_TYPE_LOADED,
                    payload: { data: data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.ALL_NATURETRAIL_TYPE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const deleteNatureTrailById = (id, isActive) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_DELETE_SENDING });
        await axios
            .post("/nature-trail/deactivate-or-activate-nt-by-id", null, {
                params: {
                    naturalTrailId: id,
                    isActive: isActive
                }
            })
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_DELETE_SENDED

                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_DELETE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    }
};
export const setTestableNatureTrailById = (id, isTestabled) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_TEST_SENDING });
        await axios
            .post("/nature-trail/set-test-nt-by-id", null, {
                params: {
                    naturalTrailId: id,
                    isTestabled: isTestabled
                }
            })
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_TEST_SENDED

                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_TEST_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    }
};
export const saveNatureTrailLocation = (natureTrailLocationData, files) => {
    const formData = new FormData();
    //files
    formData.append("startPicture", files.startPicture[0] !== undefined ? files.startPicture[0] : []);
    formData.append("finishPicture", files.finishPicture[0] !== undefined ? files.finishPicture[0] : []);
    formData.append("android3d", files.android3Dfiles[0] !== undefined ? files.android3Dfiles[0] : []);
    formData.append("iphone3d", files.iphone3Dfiles[0] !== undefined ? files.iphone3Dfiles[0] : []);
    formData.append("finishMusic", files.finishMusic[0] !== undefined ? files.finishMusic[0] : []);
    formData.append("music", files.music[0] !== undefined ? files.music[0] : []);
    formData.append("movie", files.video[0] !== undefined ? files.video[0] : []);
    //data
    formData.append("natureTrailLocationData", JSON.stringify(natureTrailLocationData));

    return async (dispatch) => {
        dispatch({ type: SPINNING.TRUE });
        dispatch({ type: NATURETRAIL.NATURETRAIL_LOCATION_SAVING });
        await axios
            .post("/nature-trail/save-nt-location", formData,
        )
            .then((response) => {
                dispatch({ type: SPINNING.FALSE });
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_LOCATION_SAVED,
                    payload: { data: response.data },
                });
            })
            .catch((err) => {
                dispatch({ type: SPINNING.FALSE });
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_LOCATION_SAVE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}
export const getTriggerTypes = () => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.TRIGGERTYPES_LOADING });
        await axios
            .get("nature-trail/trigger-types", {})
            .then(({ data }) => {
                dispatch({
                    type: NATURETRAIL.TRIGGERTYPES_LOADED,
                    payload: { data: data.triggerTypes },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.TRIGGERTYPES_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};

export const getRegions = () => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.REGION_LOADING });
        await axios
            .get("nature-trail/regions", {})
            .then(({ data }) => {
                dispatch({
                    type: NATURETRAIL.REGION_LOADED,
                    payload: { data: data.regions },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.REGION_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getNationalParks = () => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATIONAL_PARK_LOADING });
        await axios
            .get("nature-trail/national-park", {})
            .then(({ data }) => {
                dispatch({
                    type: NATURETRAIL.NATIONAL_PARK_LOADED,
                    payload: { data: data.nationalParks },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATIONAL_PARK_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getAllNationalParks = () => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.ALL_NATIONAL_PARK_LOADING });
        await axios
            .get("nature-trail/all-national-park", {})
            .then(({ data }) => {
                dispatch({
                    type: NATURETRAIL.ALL_NATIONAL_PARK_LOADED,
                    payload: { data: data.nationalParks },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.ALL_NATIONAL_PARK_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getNatureTrailLocationById = (id) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_LOCATION_BY_LOCATION_ID_LOADING });
        await axios
            .get("nature-trail/nature-trail-location-by-id", {
                params: {
                    id: id
                }
            })
            .then(({ data }) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_LOCATION_BY_LOCATION_ID_LOADED,
                    payload: { data: data.natureTrailLocation },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_LOCATION_BY_LOCATION_ID_LOADERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const deleteLocationById = (id) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_LOCATION_DELETE_SENDING });
        await axios
            .delete("/nature-trail/delete-locatation-by-id/" + id)
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_LOCATION_DELETE_SENDED
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_LOCATION_DELETE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    }
};
export const getNatureTrailRatingById = (natureTrailId) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_RATING_BY_ID_LOADING });
        await axios
            .get("statistics/rating-by-nature-trail-id", {
                params: {
                    natureTrailId: natureTrailId
                }
            })
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_RATING_BY_ID_LOADED,
                    payload: response.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_RATING_BY_ID_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};
export const getNatureTrailCommentsById = (natureTrailId) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_COMMENTS_BY_ID_LOADING });
        await axios
            .get("comments/get-all-comment-by-nature-trail-id", {
                params: {
                    id: natureTrailId
                }
            })
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_COMMENTS_BY_ID_LOADED,
                    payload: response.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_COMMENTS_BY_ID_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
};


export const createOneNatureTrailType = () => {
    const sendObj = {
        value: "uj tipsu",
        description: null
    }
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_TYPE_CREATE_SAVING });
        await axios
            .post("/nature-trail-types/create-one", sendObj)
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_TYPE_CREATE_SAVED,
                    payload: response.data,

                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_TYPE_CREATE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    }
};
export const createOneNationalPark = () => {

    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATIONAL_PARK_CREATING });
        await axios
            .post("/nature-trail/create-national-park", {})
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.NATIONAL_PARK_CREATED,
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATIONAL_PARK_CREATING_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    }
};
export const updateNatureTrailType = (natureTrailType) => {

    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATURETRAIL_TYPE_UPDATE_SAVING });
        await axios
            .post("/nature-trail-types/update-nature-trail-type", natureTrailType)
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_TYPE_UPDATE_SAVED,
                    payload: response.data,

                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATURETRAIL_TYPE_UPDATE_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    }
};
export const setActiveodDeactive = (id) => {
    return async () => {
        await axios
            .post("/nature-trail-types/setActiveodDeactive/" + id)
            .then((response) => {
            })
            .catch((err) => {
                console.log(err);
            });
    }
};

export const setActiveOrDeactiveNationalPark = (id) => {
    return async () => {
        await axios
            .post("/nature-trail/set-active-or-deactive-national-park/" + id)
            .then((response) => {
            })
            .catch((err) => {
                console.log(err);
            });
    }
};

export const saveNatinalPark = (nationalParkData, files) => {
    const formData = new FormData();
    //files
    formData.append("logo", files[0] !== undefined ? files[0] : []);

    //data
    formData.append("nationalParkData", JSON.stringify(nationalParkData));
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.NATIONAL_PARK_SAVING });
        await axios
            .post("/nature-trail/save-national-park", formData,
        )
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.NATIONAL_PARK_SAVED,
                    payload: { data: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.NATIONAL_PARK_SAVING_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}

export const sendPushMessage = (pushObj) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.PUSH_SENDING });
        await axios
            .post("/nature-trail/send-push-message", pushObj
            )
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.PUSH_SENDED,
                    // payload: { user: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.PUSH_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}
export const saveOwner1 = (ownerObj) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.OWNER_1_SAVING });
        await axios
            .post("/nature-trail/save-owner-1", { ownerObj }
            )
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.OWNER_1_SAVED,
                    // payload: { user: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.OWNER_1_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}
export const saveOwner2 = (ownerObj) => {
    return async (dispatch) => {
        dispatch({ type: NATURETRAIL.OWNER_2_SAVING });
        await axios
            .post("/nature-trail/save-owner-2", { ownerObj }
            )
            .then((response) => {
                dispatch({
                    type: NATURETRAIL.OWNER_2_SAVED,
                    // payload: { user: response.data },
                });
            })
            .catch((err) => {
                dispatch({
                    type: NATURETRAIL.OWNER_2_ERROR,
                    payload: { message: err.response.data.error.message, code: err.response.data.error.code },
                });
            });
    };
}
