import React, { Component } from 'react'
import { connect } from 'react-redux'
import OneQuestionOneAnswer from './one-question-one-answer'

const mapDispatch = (dispatch) => {
    return {
    }
}

class ConnectedOneQuestionOneAnswerModal extends Component {

    state = {
    }

    componentDidMount = async () => {
    }

    render() {
        return (
            <div >
                <OneQuestionOneAnswer
                    naturalTrailLocationId={this.props.naturalTrailLocationId}
                    setQuestionAnswerId={this.props.setQuestionAnswerId}
                ></OneQuestionOneAnswer>
            </div>
        )
    }
}


const mapState = (state) => {
    return {
    }
}

const OneQuestionOneAnswerModal = connect(mapState, mapDispatch)(ConnectedOneQuestionOneAnswerModal)
export default OneQuestionOneAnswerModal;