import { REGISTRATION } from "../constants/action-types";

const initialState = {
    user: {},
    message: {},
    code: null
};

const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTRATION.REGISTRATION_LOADING:
            return Object.assign({}, state, {
                user: {},
                message: {},
                status: "sending",
                code: null
            });
        case REGISTRATION.REGISTRATION_LOADED:
            return Object.assign({}, state, {
                user: action.payload.user,
                status: "sended",
                message: {},
                code: null
            });
        case REGISTRATION.REGISTRATION_ERROR:
            return Object.assign({}, state, {
                user: {},
                status: "error",
                message: action.payload.message,
                code: action.payload.code
            });
        default:
            return state;
    }
};

export default registrationReducer;
