import React, { Component } from 'react'
import { connect } from 'react-redux'
import Form from 'antd/lib/form/Form'
import { Row, Col, Input, Button, Select, Mentions, message, Drawer } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import Dragger from 'antd/lib/upload/Dragger'
import { PlusCircleOutlined } from '@ant-design/icons'
import Modal from 'antd/lib/modal/Modal'
import NatureTrailLocationContent from './nature-trail-location-content'
import { saveNatureTrailLocation, getNatureTrailLocationsByNatureTrailId } from '../../actions/nature-trail'
import QuestionAnswersModal from './question-answer-modal'
import OneQuestionOneAnswerModal from './one-question-one-answer-modal'

import ImgCrop from 'antd-img-crop'
import PairMatchModal from './pair-match-modal'
import PhotoModal from './photo-modal'
var QRCode = require('qrcode.react');
var randomstring = require("randomstring");


const mapDispatch = (dispatch) => {
    return {
        saveNatureTrailLocation: (natureTrailLocationData, files) => dispatch(saveNatureTrailLocation(natureTrailLocationData, files)),
        getNatureTrailLocationsByNatureTrailId: (natureTrailId) => dispatch((getNatureTrailLocationsByNatureTrailId(natureTrailId))),
    }
}

const { Option } = Select;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
class ConnectedNatureTrailLocationData extends Component {
    state = {
        submitIsActive: true,
        locationContentModal: false,
        questionAnswerModal: false,
        questionAnswersModal: false,
        photoModal: false,
        pairMatchModal: false,
        triggerType: [
            { key: 1, value: "GPS alapú" },
            { key: 2, value: "QR kód alapú" },
        ],
        qrCodeVisible: false,

        locationObject: {
            id: undefined,
            startTitle: "",
            startDescription: "",
            startPictureSavedFileName: "",
            finishPictureSavedFileName: "",
            sequenceId: 0,
            lat: 0.0,
            lon: 0.0,
            finishTitle: "",
            finishDescription: "",
            musicFileURL: "",
            iphone3dFileName: "",
            android3dFileName: "",
            qrcode: "",
            contentType: 1,
            triggerType: [{ id: 1, value: "QR" }],
            triggerDistance: 0,
            questionAnswersId: [],
            pairMatchIds: [],
            isYoutube: false,
            scaleOverrideAndroid: 1,
            scaleOverrideIos: 1,
            markerWidthIos: 10,
            markerWidthAndroid: 10,
            files: {
                startPicture: [],
                finishPicture: [],
                finishMusic: [],
                android3Dfiles: [],
                iphone3Dfiles: [],
                questionChoise: null,
                questionAnswers: null,
                movie: [],
                music: []
            }
        }
    }

    formRef = React.createRef()

    componentDidMount = () => {
        const actualLocation = {
            key: this.props.record.key,
            id: this.props.record.id,
            sequenceId: this.props.record.sequenceId,
            natureTrailId: this.props.record.natureTrailId,
            startTitle: this.props.record.startTitle !== undefined ? this.props.record.startTitle : "",
            startDescription: this.props.record.startDescription !== undefined ? this.props.record.startDescription : "",
            startPictureSavedFileName: this.props.record.startPictureSavedFileName !== undefined ? this.props.record.startPictureSavedFileName : "",
            finishPictureSavedFileName: this.props.record.finishPictureSavedFileName !== undefined ? this.props.record.finishPictureSavedFileName : "",
            lat: this.props.record.lat !== undefined ? this.props.record.lat : 0.0,
            lon: this.props.record.lon !== undefined ? this.props.record.lon : 0.0,
            triggerDistance: this.props.record.triggerDistance !== undefined ? this.props.record.triggerDistance : 0,
            scaleOverrideIos: this.props.record.scaleOverrideIos !== undefined ? this.props.record.scaleOverrideIos : 1,
            scaleOverrideAndroid: this.props.record.scaleOverrideAndroid !== undefined ? this.props.record.scaleOverrideAndroid : 1,
            markerWidthIos: this.props.record.markerWidthIos !== undefined ? this.props.record.markerWidthIos : 1,
            markerWidthAndroid: this.props.record.markerWidthAndroid !== undefined ? this.props.record.markerWidthAndroid : 1,
            finishTitle: this.props.record.finishTitle !== undefined ? this.props.record.finishTitle : "",
            finishDescription: this.props.record.finishDescription !== undefined ? this.props.record.finishDescription : "",
            scaleOverride: this.props.record.scaleOverride !== undefined ? this.props.record.scaleOverride : 1,
            qrcode: this.props.record.qrcode !== undefined ? this.props.record.qrcode : "",
            questions: this.props.record.questions !== undefined ? this.props.record.questions : [],
            contentType: this.props.record.contentType !== undefined ? this.props.record.contentType : 1,
            triggerType: this.props.record.triggerType !== undefined
                && this.props.record.triggerType !== null ? [{ key: this.props.record.triggerType.id, value: this.props.record.triggerType.value }] : [{ key: this.props.triggerTypes[0].id, value: this.props.triggerTypes[0].value }],
            movieFileURL: this.props.record.movieFileURL !== undefined && this.props.record.movieFileURL !== null ? this.props.record.movieFileURL : "",
            musicFileURL: this.props.record.musicFileURL !== undefined && this.props.record.musicFileURL !== null ? this.props.record.musicFileURL : "",
            iphone3dFileName: this.props.record.iphone3dFileName !== undefined && this.props.record.iphone3dFileName !== null ? this.props.record.iphone3dFileName : "",
            android3dFileName: this.props.record.android3dFileName !== undefined && this.props.record.android3dFileName !== null ? this.props.record.android3dFileName : "",
            isYoutube: this.props.record.isYoutube !== undefined && this.props.record.isYoutube !== null ? this.props.record.isYoutube : false,
            youtubeLink: this.props.record.youtubeLink !== undefined && this.props.record.youtubeLink !== null ? this.props.record.youtubeLink : "",
            files: {
                startPicture: this.props.record.startPicture !== undefined ? this.props.record.startPicture : [],
                finishPicture: this.props.record.finishPicture !== undefined ? this.props.record.finishPicture : [],
                //befejező  zene
                finishMusic: this.props.record.finishAudio !== undefined ? this.props.record.finishAudio : [],
                android3Dfiles: this.props.record.ar !== undefined ? this.props.record.ar : [],
                iphone3Dfiles: this.props.record.d3d !== undefined ? this.props.record.d3d : [],
                questionChoise: this.props.record.questionChoice !== undefined ? this.props.record.questionChoice : [],
                questionAnswers: this.props.record.questionAnswers !== undefined ? this.props.record.questionAnswers : [],
                video: this.props.record.movie !== undefined ? this.props.record.movie : [],
                //tartalom zene
                music: this.props.record.audio !== undefined ? this.props.record.audio : []
            }
        }
        if (this.props.record !== undefined) {
            this.setState({ locationObject: actualLocation }, () => {
                this.formRef.current.setFieldsValue({
                    title: this.state.locationObject.startTitle,
                    lat: this.state.locationObject.lat,
                    lon: this.state.locationObject.lon,
                    triggerDistance: this.state.locationObject.triggerDistance,
                    startDescription: this.state.locationObject.startDescription,
                    finishDescription: this.state.locationObject.finishDescription,
                    triggerType: this.state.locationObject.triggerType.value
                })
            })
        }
    }



    setLocationSaveButtonToFalse = () => {
        this.setState({ submitIsActive: true });
        this.props.setDeactiveAddNewButton();
    }

    beforeUploadStartPicture = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
        } else {
            this.setState({ previewStartImage: URL.createObjectURL(file) })
            const locationObj = this.state.locationObject;
            locationObj.files.startPicture = [file]
            this.setState({ locationObject: locationObj })
            this.setLocationSaveButtonToFalse();
        }

        return isLt2M;
    }

    removeFileStartPicture = () => {
        const locationObj = this.state.locationObject;
        locationObj.files.startPicture = []
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    };

    beforeUploadFinishPicture = (file) => {

        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('A képnek kissebbnek kell lennie mint 5MB!');
        } else {
            this.setState({ previewFinishImage: URL.createObjectURL(file) })
            const locationObj = this.state.locationObject;
            locationObj.files.finishPicture = [file]
            this.setState({ locationObject: locationObj })
            this.setLocationSaveButtonToFalse();
        }
        return isLt2M;
    }

    removeFileFinishPicture = () => {
        const locationObj = this.state.locationObject;
        locationObj.files.finishPicture = []
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();

    };

    beforeAndroid3D = (file) => {
        const locationObj = this.state.locationObject;
        locationObj.files.android3Dfiles = [...this.state.locationObject.files.android3Dfiles, file]
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
        return false;
    };

    removeAndroid3D = (file) => {
        const locationObj = this.state.locationObject;
        // const filtered = locationObj.files.android3Dfiles.filter(item => item !== file);
        locationObj.files.android3Dfiles = []
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    };

    beforeIphone3D = (file) => {
        const locationObj = this.state.locationObject;
        locationObj.files.iphone3Dfiles = [...this.state.locationObject.files.iphone3Dfiles, file]
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
        return false;
    }

    removeIphone3D = (file) => {
        const locationObj = this.state.locationObject;
        // const filtered = locationObj.files.iphone3Dfiles.filter(item => item !== file);
        locationObj.files.iphone3Dfiles = []
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    };

    setMusic = (music) => {
        const locationObj = this.state.locationObject;
        locationObj.files.music = [music]
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }

    removeMusic = () => {
        const locationObj = this.state.locationObject;
        locationObj.files.music = []
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    };

    beforeMovie = (file) => {
        const locationObj = this.state.locationObject;
        locationObj.files.movie = [file]
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
        return false;
    }

    removeMovie = () => {
        const locationObj = this.state.locationObject;
        locationObj.files.movie = []
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    };

    setFinishMusic = (finishMusic) => {
        const locationObj = this.state.locationObject;
        locationObj.files.finishMusic = [finishMusic]
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
        return false
    }
    removeFinishMusic = () => {
        const locationObj = this.state.locationObject;
        locationObj.files.finishMusic = []
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    };

    setVideo = async (file) => {

        const isLt2M = file.size / 1024 / 1024 < 100;
        if (!isLt2M) {
            message.error('A videónak kissebbnek kell lennie mint 100MB!');
        } else {
            const locationObj = this.state.locationObject;
            locationObj.files.video = [file]
            await this.setState({ locationObject: locationObj })
            this.setLocationSaveButtonToFalse();
        }
        return false
    }
    removeVideo = () => {
        const locationObj = this.state.locationObject;
        locationObj.files.video = []
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.setState({ uploaded: true })
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
            this.setLocationSaveButtonToFalse();
            this.forceUpdate()
        }
    };

    closeContentModal = () => {
        this.setState({ locationContentModal: false })
    }

    openContentModal = () => {
        this.setState({ locationContentModal: true })
    }

    closeQuestionAnswerModal = () => {
        this.setState({ questionAnswerModal: false, isVisible: false })
    }

    closePhotoModal = () => {
        this.setState({ photoModal: false, isVisible: false })
    }

    setVisible = () => {
        this.setState({ isVisible: true })
    }

    openQuestionAnswerModal = () => {
        this.setState({ questionAnswerModal: true })
    }

    closeQuestionAnswersModal = () => {
        this.setState({ questionAnswersModal: false })
    }

    openQuestionAnswersModal = () => {
        this.setState({ questionAnswersModal: true })

    }
    openPhotoModal = () => {
        this.setState({ photoModal: true })

    }
    closePairMatchModal = () => {
        this.setState({ pairMatchModal: false })
    }

    openPairMatchModa = () => {
        this.setState({ pairMatchModal: true })

    }

    generateQR = () => {
        const randomString = randomstring.generate();
        const locationObj = this.state.locationObject;
        locationObj.qrcode = randomString;
        this.setLocationSaveButtonToFalse();
        this.setState({ locationObject: locationObj })
    }

    selectTriggerType = (event) => {
        const filteredTypes = this.props.triggerTypes.filter(item =>
            item.value === event)
        const locationObj = this.state.locationObject;
        locationObj.triggerType = filteredTypes
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
        this.forceUpdate()
    }

    changeStartTitle = (event) => {
        const locationObj = this.state.locationObject;
        locationObj.startTitle = event.target.value;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }

    changeLat = (event) => {
        const locationObj = this.state.locationObject;
        locationObj.lat = event.target.value;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }
    setScaleOverrideAndroid = (event) => {
        const locationObj = this.state.locationObject;
        locationObj.scaleOverrideAndroid = event.target.value;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }
    setScaleOverrideIos = (event) => {
        const locationObj = this.state.locationObject;
        locationObj.scaleOverrideIos = event.target.value;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }

    setMarkerWidthIos = (event) => {
        const locationObj = this.state.locationObject;
        locationObj.markerWidthIos = event.target.value;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }
    setMarkerWidthAndroid = (event) => {
        const locationObj = this.state.locationObject;
        locationObj.markerWidthAndroid = event.target.value;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }

    changeLon = (event) => {
        const locationObj = this.state.locationObject;
        locationObj.lon = event.target.value;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }

    changeTriggerDistace = (event) => {
        const locationObj = this.state.locationObject;
        locationObj.triggerDistance = event.target.value;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }

    changeStartDescription = (event) => {
        const locationObj = this.state.locationObject;
        locationObj.startDescription = event;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }

    changeFinishTitle = (event) => {
        const locationObj = this.state.locationObject;
        locationObj.finishTitle = event.target.value;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }

    changeFinishDescription = (event) => {
        const locationObj = this.state.locationObject;
        locationObj.finishDescription = event;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }

    setQuestionAnswersId = (questionAnswersId) => {
        this.setState({ questionAnswersId: questionAnswersId })
        this.setLocationSaveButtonToFalse();
    }

    setPairMatchId = (pairMatchIds) => {
        this.setState({ pairMatchIds: pairMatchIds })
        this.setLocationSaveButtonToFalse();
    }

    setContentType = (contentType) => {
        const locationObj = this.state.locationObject;
        locationObj.contentType = contentType;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();

    }

    setQuestionAnswerId = (questionAnswerId) => {
        this.setState({ questionAnswerId: questionAnswerId })
        this.setLocationSaveButtonToFalse();
    }

    setPhotoId = (photoId) => {
        this.setState({ photoId: photoId })
        this.setLocationSaveButtonToFalse();
    }

    setIsYoutube = (isYoutube) => {
        const locationObj = this.state.locationObject;
        locationObj.isYoutube = isYoutube;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }
    setYoutubeLink = (youtubeLink) => {
        const locationObj = this.state.locationObject;
        locationObj.youtubeLink = youtubeLink;
        this.setState({ locationObject: locationObj })
        this.setLocationSaveButtonToFalse();
    }


    saveNatureTrailLocation = async () => {
        this.props.setSpinningTrue();

        const savedLocationObj = {
            id: this.state.locationObject.id,
            name: this.state.locationObject.startTitle,
            sequenceId: this.state.locationObject.sequenceId,
            description: this.state.locationObject.startDescription,
            lat: this.state.locationObject.lat,
            lon: this.state.locationObject.lon,
            qrcode: this.state.locationObject.qrcode,
            triggerDistance: this.state.locationObject.triggerDistance,
            scaleOverrideIos: this.state.locationObject.scaleOverrideIos,
            scaleOverrideAndroid: this.state.locationObject.scaleOverrideAndroid,
            markerWidthIos: this.state.locationObject.markerWidthIos,
            markerWidthAndroid: this.state.locationObject.markerWidthAndroid,
            finishDescription: this.state.locationObject.finishDescription,
            contentType: this.state.locationObject.contentType,
            triggerTypeId: this.state.locationObject.triggerType[0].key !== undefined ? this.state.locationObject.triggerType[0].key : this.state.locationObject.triggerType[0].id,
            natureTrailId: this.props.record.natureTrailId,
            questionAnswersId: this.state.questionAnswersId,
            pairMatchIds: this.state.pairMatchIds,
            questionAnswerId: this.state.questionAnswerId,
            photoId: this.state.photoId,
            isYoutube: this.state.locationObject.isYoutube,
            youtubeLink: this.state.locationObject.youtubeLink,
        }

        await this.props.saveNatureTrailLocation(savedLocationObj, this.state.locationObject.files);
        if (this.props.locationSavingStatus === 'sended') {
            this.props.setSpinningFalse();
            message.success("Sikeres mentés")
            this.props.setEditedLocation(this.props.savedNatureTrailLocation)

            const locationObj = this.state.locationObject;
            locationObj.id = this.props.savedNatureTrailLocation.id;
            this.props.addLocationToList(this.props.savedNatureTrailLocation.id)
            this.setState({ locationObject: locationObj })
            if (this.props.record.natureTrailId !== undefined) {
                this.props.getNatureTrailLocationsByNatureTrailId(this.props.record.natureTrailId)
            }
        } else {
            this.props.setSpinningFalse();
            message.error("Hiba a mentés során")
        }
    }


    render() {
        const onFinish = async values => {
            await this.saveNatureTrailLocation()
            this.props.setActiveAddNewButton()
            //const currentForm = this.formRef.current.getFieldsValue();
            //this.props.setFiles(this.props.record.key, currentForm.fileList.file)

            this.setState({ submitIsActive: false, })
        };
        const beforeCrop = (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                message.error('A képnek kissebbnek kell lennie mint 5MB!');
                return false
            } else {
                //this.setState({ preview1a: URL.createObjectURL(file), picture1afile: file, saveButtonActive: true })
                return true
            }
        }


        return (
            <div>
                <Form
                    style={{ width: "95%" }}
                    ref={this.formRef}
                    name="validate_other"
                    onFinish={onFinish}>
                    <FormItem>
                        <Button
                            disabled={!this.state.submitIsActive}
                            type="primary" htmlType="submit">
                            Állomás mentése
                        </Button>
                    </FormItem>
                    <Row gutter={24}>

                        <Col span={6}>
                            <FormItem
                                label="Név"
                                name="title"
                                rules={[{ required: true, message: 'A név mező nem maradhat üresen.' }]}>
                                <Input value={this.state.locationObject.startTitle} onChange={this.changeStartTitle}></Input>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem name="lat"
                                label="Lat"
                                rules={[{ required: true, message: 'Az adat formátuma nem megfelelő' }]}
                            >
                                <Input type="number" value={this.state.locationObject.lat} onChange={this.changeLat}></Input>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem name="lon"
                                label="Lon"
                                rules={[{ required: true, message: 'Az adat formátuma nem megfelelő' }]}
                            >
                                <Input type="number" value={this.state.locationObject.lon} onChange={this.changeLon}></Input>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem name="triggerDistance"
                                label="Érzékelési távolság"
                            >
                                <Input type="number" value={this.state.locationObject.triggerDistance} onChange={this.changeTriggerDistace}></Input>
                            </FormItem>
                        </Col>
                        <Col span={4}>
                            <Col span={24}>
                                <FormItem name="fileList">
                                    <ImgCrop rotate aspect={1 / 1}
                                        beforeCrop={beforeCrop}
                                        modalTitle="Kezdő kép (maximum 5 MB)">
                                        <Dragger
                                            name="fileList"
                                            multiple={false}
                                            beforeUpload={this.beforeUploadStartPicture}
                                            fileList={this.state.locationObject.files.startPicture}
                                            listType={"picture"}
                                            showUploadList={false}
                                            onRemove={this.removeFileStartPicture}
                                            // onPreview={this.handlePreview}
                                            onChange={this.handleChange}
                                            accept=".png,.jpg,.jpeg,.gif">
                                            {/* <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p> */}
                                            <p className="ant-upload-text">Állomás kezdő képe</p>
                                            <p> max 5MB</p>
                                        </Dragger>
                                    </ImgCrop>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                {this.state.previewStartImage === undefined ?
                                    <img
                                        src={
                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + "/files/get-nature-trail-location-picture?id=" + this.state.locationObject.id + "&pictureName=" + this.state.locationObject.startPictureSavedFileName}
                                        alt="Hiányzó kép"
                                        style={{ maxWidth: "100%" }}
                                    /> :
                                    <img
                                        alt="" style={{ maxWidth: "100%" }}
                                        src={this.state.previewStartImage} />}
                            </Col>
                        </Col>
                        <Col span={6}>
                            <Col span={24}>

                                <FormItem name="startDescription"
                                // rules={[{ required: true, message: 'Please enter description' }]}
                                >
                                    <Mentions rows="14" value={this.state.locationObject.startDescription} name="startDescription" onChange={this.changeStartDescription}>
                                    </Mentions>
                                </FormItem>
                            </Col>
                        </Col>
                        <Col span={4}>
                            <Col span={24}>
                                <FormItem name="fileList">
                                    <ImgCrop rotate aspect={1 / 1}
                                        beforeCrop={beforeCrop}
                                        modalTitle="Befejező kép (maximum 5 MB)">
                                        <Dragger
                                            name="fileList"
                                            multiple={false}
                                            beforeUpload={this.beforeUploadFinishPicture}
                                            fileList={this.state.locationObject.files.finishPicture}
                                            listType={"picture"}
                                            showUploadList={false}
                                            onRemove={this.removeFileFinishPicture}
                                            // onPreview={this.handlePreview}
                                            onChange={this.handleChange}
                                            accept=".png,.jpg,.jpeg">
                                            {/* <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p> */}
                                            <p className="ant-upload-text">Állomás befejező képe</p>
                                            <p> max 5MB</p>
                                        </Dragger>
                                    </ImgCrop>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                {this.state.previewFinishImage === undefined ?
                                    <img
                                        src={
                                            process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION + "/files/get-nature-trail-location-picture?id=" + this.state.locationObject.id + "&pictureName=" + this.state.locationObject.finishPictureSavedFileName}
                                        alt="Hiányzó kép"
                                        style={{ maxWidth: "100%" }}
                                    /> :
                                    <img
                                        alt="" style={{ maxWidth: "100%" }}
                                        src={this.state.previewFinishImage} />}
                            </Col>
                        </Col>
                        <Col span={6}>
                            <Col span={24}>
                                <FormItem name="finishDescription"
                                // rules={[{ required: true, message: 'Please enter finish description' }]}
                                >
                                    <Mentions rows="14" value={this.state.locationObject.finishDescription} name="finishDescription" onChange={this.changeFinishDescription}>
                                    </Mentions>
                                    {/* <Input initialValues={this.props.description}></Input> */}
                                </FormItem>
                            </Col>
                        </Col>
                        <Col span={4}>
                            <Row gutter={[8, 16]}>
                                <Col span={24}>
                                    <Select
                                        style={{ width: "100%" }}
                                        onSelect={this.selectTriggerType}
                                        // defaultValue={this.state.locationObject.triggerType !== undefined && this.state.locationObject.triggerType[0] !== undefined ? this.state.locationObject.triggerType[0].value : this.props.triggerTypes[0].value
                                        value={this.state.locationObject.triggerType[0].value}
                                    >
                                        {this.props.triggerTypes.map(item => {
                                            return (<Option key={item.id} value={item.value}>{item.value}</Option>)
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                            <Row gutter={24} align="middle" justify="start">
                                <Col span={24} justify="start" >
                                    <Button onClick={this.openContentModal} style={{ width: "100%" }} type="primary">
                                        <PlusCircleOutlined />Tartalom
                                    </Button>
                                </Col>
                            </Row>
                            {(this.state.locationObject.triggerType[0].key === 1 || this.state.locationObject.triggerType[0].id === 1) && <Row gutter={16} align="middle" justify="center" style={{ paddingTop: "2em" }}>
                                {this.state.locationObject.qrcode !== "" &&
                                    <Col span={24} justify="center" align="middle">
                                        <QRCode size="1500" level="Q" value={this.state.locationObject.qrcode} style={{ height: "15vh" }} />
                                    </Col>}
                                <Col span={24} justify="center" align="middle">
                                    <Button onClick={this.generateQR} style={{ width: "100%" }}>Új QRCode</Button>
                                </Col>
                            </Row>}
                            {(this.state.locationObject.triggerType[0].key === 2 || this.state.locationObject.triggerType[0].id === 2) && <Row gutter={16} align="middle" justify="center" style={{ paddingTop: "2em" }}>
                                <Col span={24} justify="center" align="middle">
                                    <Button onClick={this.openQuestionAnswerModal}>Kérdés létrehozása</Button>
                                </Col>
                            </Row>}
                            {(this.state.locationObject.triggerType[0].key === 3 || this.state.locationObject.triggerType[0].id === 3) && <Row gutter={16} align="middle" justify="center" style={{ paddingTop: "2em" }}>
                                <Col span={24} justify="center" align="middle">
                                    <Button onClick={this.openQuestionAnswersModal}>Kérdőív létrehozása</Button>
                                </Col>
                            </Row>}
                            {(this.state.locationObject.triggerType[0].key === 6 || this.state.locationObject.triggerType[0].id === 6) && <Row gutter={16} align="middle" justify="center" style={{ paddingTop: "2em" }}>
                                <Col span={24} justify="center" align="middle">
                                    <Button onClick={this.openPhotoModal}>Photo tartalom</Button>
                                </Col>
                            </Row>}
                            {(this.state.locationObject.triggerType[0].key === 5 || this.state.locationObject.triggerType[0].id === 5) && <Row gutter={16} align="middle" justify="center" style={{ paddingTop: "2em" }}>
                                <Col span={24} justify="center" align="middle">
                                    <Button onClick={this.openPairMatchModa}>Páros kérdőív</Button>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Form >
                <Modal
                    width={"85%"}
                    centered={true}
                    visible={this.state.locationContentModal}
                    title="Állomás tartalom"
                    // onOk={this.handleSave}
                    onCancel={this.closeContentModal}
                    footer={[
                        <Button type="primary" onClick={this.closeContentModal}>Mentés</Button>,
                        <Button key="back" onClick={this.closeContentModal}>
                            Mégsem
                    </Button>
                    ]}
                >
                    {this.state.locationContentModal && <NatureTrailLocationContent
                        natureTrailId={this.state.locationObject.id}

                        iphone3Dfiles={this.state.locationObject.files.iphone3Dfiles}
                        android3Dfiles={this.state.locationObject.files.android3Dfiles}
                        questionAnswers={this.state.locationObject.files.questionAnswers}
                        questionChoice={this.state.locationObject.files.questionChoise}

                        movieFileURL={this.state.locationObject.movieFileURL}

                        video={this.state.locationObject.files.video}
                        setVideo={this.setVideo}
                        removeVideo={this.removeVideo}

                        setIphone3Dfiles={this.beforeIphone3D}
                        setAndroid3DFiles={this.beforeAndroid3D}
                        iphone3dFileName={this.state.locationObject.iphone3dFileName}
                        android3dFileName={this.state.locationObject.android3dFileName}

                        setScaleOverrideAndroid={this.setScaleOverrideAndroid}
                        setScaleOverrideIos={this.setScaleOverrideIos}
                        scaleOverrideIos={this.state.locationObject.scaleOverrideIos}
                        scaleOverrideAndroid={this.state.locationObject.scaleOverrideAndroid}

                        markerWidthIos={this.state.locationObject.markerWidthIos}
                        markerWidthAndroid={this.state.locationObject.markerWidthAndroid}
                        setMarkerWidthIos={this.setMarkerWidthIos}
                        setMarkerWidthAndroid={this.setMarkerWidthAndroid}


                        music={this.state.locationObject.files.music}
                        setMusic={this.setMusic}
                        removeMusic={this.removeMusic}
                        musicFileURL={this.state.locationObject.musicFileURL}

                        removeIphone3Dfiles={this.removeIphone3D}
                        removeAndroid3Dfiles={this.removeAndroid3D}


                        setContentType={this.setContentType}
                        contentType={this.state.locationObject.contentType}
                        setIsYoutube={this.setIsYoutube}
                        setYoutubeLink={this.setYoutubeLink}
                        isYoutube={this.state.locationObject.isYoutube}
                        youtubeLink={this.state.locationObject.youtubeLink}
                    >
                    </NatureTrailLocationContent>}
                </Modal>


                <Drawer
                    width={"85%"}
                    centered={true}
                    visible={this.state.questionAnswerModal}
                    title="Kérdés"
                    // onOk={this.handleSave}
                    onCancel={this.closeQuestionAnswerModal}
                    onClose={this.closeQuestionAnswerModal}
                // footer={[
                //     <Button key="back" onClick={this.closeQuestionAnswerModal}>
                //         Mégsem
                // </Button>
                // ]
                // }
                >
                    <OneQuestionOneAnswerModal
                        naturalTrailLocationId={this.state.locationObject.id}
                        setQuestionAnswerId={this.setQuestionAnswerId}></OneQuestionOneAnswerModal>
                </Drawer>
                <Drawer
                    width={"85%"}
                    centered={true}
                    visible={this.state.photoModal}
                    title="Kérdés"
                    // onOk={this.handleSave}
                    onCancel={this.closePhotoModal}
                    onClose={this.closePhotoModal}
                // footer={[
                //     <Button key="back" onClick={this.closeQuestionAnswerModal}>
                //         Mégsem
                // </Button>
                // ]
                // }
                >
                    <PhotoModal
                        naturalTrailLocationId={this.state.locationObject.id}
                        setPhotoId={this.setPhotoId}></PhotoModal>
                </Drawer>
                <Drawer
                    width={"85%"}
                    centered={true}
                    visible={this.state.questionAnswersModal}
                    bodyStyle={{
                        paddingRight: "24px",
                        paddingLeft: "24px",
                        paddingTop: "16px", paddingBottom: "0px"
                    }}
                    title="Kérdőív"
                    // onOk={this.handleSave}
                    onCancel={this.closeQuestionAnswersModal}
                    onClose={this.closeQuestionAnswersModal}
                // footer={[
                //     <Button key="back" onClick={this.closeQuestionAnswersModal}>
                //         Mégsem
                // </Button>
                // ]}
                >                    <QuestionAnswersModal
                        natureTrailLocationId={this.state.locationObject.id}
                        setVisible={this.setVisible}
                        isVisible={this.state.isVisible}
                        questions={this.state.locationObject.questions}
                        setQuestionAnswersId={this.setQuestionAnswersId}
                    />

                </Drawer>
                <Drawer
                    width={"85%"}
                    centered={true}
                    visible={this.state.pairMatchModal}
                    title="Párosítás"
                    // onOk={this.handleSave}
                    onCancel={this.closePairMatchModal}
                    onClose={this.closePairMatchModal}
                // footer={[
                //     <Button key="back" onClick={this.closePairMatchModal}>
                //         Mégsem
                // </Button>
                // ]}
                >
                    <PairMatchModal
                        natureTrailLocationId={this.state.locationObject.id}
                        setPairMatchId={this.setPairMatchId}
                    />

                </Drawer>
            </div >
        )
    }
}

const mapState = (state) => {
    return {
        message: state.natureTrailReducer.message,
        code: state.natureTrailReducer.code,
        status: state.natureTrailReducer.status,
        natureTrails: state.natureTrailReducer.data,
        savedNatureTrailLocation: state.natureTrailReducer.savedNatureTrailLocation,
        locationSavingStatus: state.natureTrailReducer.locationSavingStatus,
        triggerTypes: state.natureTrailReducer.triggerTypes,
        triggerTypeStatus: state.natureTrailReducer.triggerTypeStatus
    }
}

const NatureTrailLocationData = connect(mapState, mapDispatch)(ConnectedNatureTrailLocationData)
export default NatureTrailLocationData;